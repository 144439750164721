import { useEffect, useState } from 'react';
import { useActionCableConsumer } from '../contexts/actionCable';
import { useAuth } from '../contexts/auth';
import { usePageVisibility } from './usePageVisibility';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type RealtimeDataEventPayloadType = {
  eventType: string;
  entityType: string;
  entityIds: number[];
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

const useRealtimeData = (
  channelName: string,
  onReceived: (payload: RealtimeDataEventPayloadType) => void,
  receivedDependenciesArray: any[],
  onTabReactivate: () => void,
) => {
  const { user } = useAuth();
  const { actionCableConsumer } = useActionCableConsumer();
  const [channel, setChannel] = useState<any>(null);
  const pageIsVisible = usePageVisibility();
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const { realtimeMvp } = useFlags();

  useEffect(() => {
    // Use isInitialised to avoid refetching data straight away
    if (realtimeMvp && isInitialised && pageIsVisible) {
      onTabReactivate();
    }
  }, [pageIsVisible, realtimeMvp]);

  // actionCableConsumer will be null if realtimeMvp is false, so no need to check again.
  useEffect(() => {
    if (actionCableConsumer && user) {
      setChannel(
        actionCableConsumer.subscriptions.create(
          {
            channel: channelName,
            company_id: user.company.id,
          },
          {
            received: onReceived,
          },
        ),
      );
    }
  }, [actionCableConsumer]);

  useEffect(() => {
    if (channel) {
      channel.received = onReceived;
    }
  }, receivedDependenciesArray);

  useEffect(() => {
    setIsInitialised(true);
  }, []);
};

export default useRealtimeData;
