import { useEffect, useState } from 'react';
import {
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { isUndefined } from 'lodash';
import {
  ButtonGroup,
  Center,
  VStack,
  Box,
  Spacer,
  Button,
} from '@chakra-ui/react';

import { useCurrentUser, useSourceCategories } from '../../hooks/useStores';
import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import OneUpWrapper from '../../components/Layouts/OneUpWrapper';
import ActionBar from '../../components/ActionBar/ActionBar';
import PageLoadSpinner from '../../components/PageLoadSpinner/PageLoadSpinner';
import LoadingToast from '../../components/LoadingToast/LoadingToast';
import OneUpScrollPanel from '../../components/Layouts/OneUpScrollPanel';
import CompanyProfileDetail from './Detail/CompanyProfileDetail';

const ProfilePage = (routeProps: RouteComponentProps): JSX.Element => {
  const { isLoading } = useCurrentUser();

  return (
    <AppWrapper>
      {isLoading ? (
        <>
          <PageLoadSpinner />
          <LoadingToast isOpen={isLoading} />
        </>
      ) : (
        <OneUpWrapper headerChildren={<ActionBar title="Company Profile" />}>
          <OneUpScrollPanel>
            <CompanyProfileDetail />
          </OneUpScrollPanel>
        </OneUpWrapper>
      )}
    </AppWrapper>
  );
};

export default observer(ProfilePage);
