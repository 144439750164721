import React from 'react';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import OrderItem from '../../../models/OrderItem';
import Unit from '../../../models/Unit';

import {
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Spacer,
  Tr,
  Td,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {
  PencilIcon,
  CrossIcon16x16,
  UndoIcon16x16,
  MessageIcon16x16,
} from '../../../components/Icons/Icons';
import NoteBox from '../../../components/NoteBox/NoteBox';
import NoteEditorWrapper from '../../../components/NoteEditor/NoteEditorWrapper';
import Tag from '../../../components/Tag/Tag';

export type EditableOrderItemRowProps = {
  orderItem: Instance<typeof OrderItem>;
  onDelete: (orderItem: Instance<typeof OrderItem>) => void;
};

const EditableOrderItemRow = observer(
  ({ orderItem, onDelete }: EditableOrderItemRowProps): JSX.Element => {
    const product = orderItem.buyable.product;

    const bgColor = () => {
      if (orderItem.id && orderItem.markedForDeletion) {
        return 'gray.50';
      } else {
        return 'auto';
      }
    };

    const toggleLabel = orderItem.markedForDeletion ? 'Restore' : 'Remove';
    const noteEditorLabel = orderItem.supplier_note ? 'Edit note' : 'Add note';

    // Ideally product.units would be available units, however there are many products that have 0 'units', and only a 'unit'.
    // This is probably because the mobile app treats 'units' as optional. This is currently the still case in Mobile app 2.4.41.
    let availableUnits = product.units; //.map((u) => u.id);
    if (
      product.unit &&
      availableUnits.map((u) => u.id).indexOf(product.unit.id) === -1
    ) {
      availableUnits.concat([product.unit]);
    }

    const hasNotes = !!orderItem.notes || !!orderItem.supplier_note;

    return (
      <>
        <Tr bg={bgColor()} p="0">
          <Td
            p="16px"
            width="226px"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            <VStack align="left">
              <Text
                lineHeight="5"
                fontSize="sm"
                fontWeight="500"
                textColor="gray.900"
                textDecoration={
                  orderItem.markedForDeletion ? 'line-through' : 'none'
                }
              >
                {product.name}
              </Text>
            </VStack>
          </Td>
          <Td
            p="16px 16px 16px 0"
            textAlign="left"
            width="144px"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            <Text
              fontSize="xs"
              fontWeight="500"
              lineHeight="4"
              color="gray.600"
              letterSpacing="wider"
              textDecoration={
                orderItem.markedForDeletion ? 'line-through' : 'none'
              }
              mt="2px"
            >
              {product.product_code}
            </Text>
          </Td>
          <Td
            p="16px 16px 16px 0"
            textAlign="right"
            width="88px"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            {orderItem.is_added && (
              <Tag bg="yellow.300" color="yellow.900">
                Added
              </Tag>
            )}
            {!orderItem.is_added &&
              orderItem.initial_amount &&
              orderItem.initial_unit && (
                <Text
                  fontSize="sm"
                  lineHeight="20px"
                  color="gray.500"
                  textDecoration={
                    orderItem.markedForDeletion ? 'line-through' : 'none'
                  }
                >
                  {orderItem.initial_amount} {orderItem.initial_unit.name}
                </Text>
              )}
          </Td>
          <Td
            textAlign="right"
            p="2px 16px 2px 0"
            height="32px"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            <HStack justifyContent="right" verticalAlign="middle" spacing="8px">
              <HStack spacing="2px">
                <InputGroup size="sm" maxW="128px">
                  <Input
                    data-testid="amountField"
                    type="number"
                    min={0}
                    value={orderItem.amount || ''}
                    isInvalid={orderItem.isValid !== null && !orderItem.isValid}
                    onFocus={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      target.select();
                    }}
                    onChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      orderItem.setAmount(Number(target.value));
                    }}
                    textAlign="right"
                    borderRadius="6px"
                    placeholder="0"
                    minWidth="75px"
                  />
                  {availableUnits.length == 1 && (
                    <InputRightAddon
                      children={availableUnits[0].name}
                      borderRadius="6px"
                    />
                  )}
                </InputGroup>

                {availableUnits.length > 1 && (
                  <Select
                    data-testid="unitSelect"
                    onChange={(e: React.SyntheticEvent) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      const unit = availableUnits.find(
                        (unit: Instance<typeof Unit>) =>
                          unit.id === Number(value),
                      );
                      if (unit) {
                        orderItem.setUnit(unit);
                      }
                    }}
                    value={orderItem.unit?.id}
                    size="sm"
                    variant="unitSelector"
                    borderRadius="6px"
                  >
                    {availableUnits.map((unit: any) => {
                      return (
                        <option key={`unit-${unit.id}`} value={unit.id}>
                          {unit.name}
                        </option>
                      );
                    })}
                  </Select>
                )}
              </HStack>
              <ButtonGroup
                variant="transparentGhost"
                color="gray"
                verticalAlign="middle"
                py="12px"
                pl="4px"
                spacing="1"
              >
                <NoteEditorWrapper
                  onSave={(newValue: string) => {
                    orderItem.setSupplierNote(newValue);
                  }}
                  initialValue={orderItem.supplier_note || ''}
                  noteEditorLabel={noteEditorLabel}
                  iconForButton={<MessageIcon16x16 />}
                />
                <Tooltip label={toggleLabel}>
                  <IconButton
                    data-testid="toggleButton"
                    aria-label={toggleLabel}
                    minWidth="24px"
                    height="24px"
                    icon={
                      orderItem.markedForDeletion ? (
                        <UndoIcon16x16 />
                      ) : (
                        <CrossIcon16x16 />
                      )
                    }
                    onClick={() => {
                      if (orderItem.markedForDeletion) {
                        orderItem.restore();
                      } else {
                        onDelete(orderItem);
                      }
                    }}
                  />
                </Tooltip>
              </ButtonGroup>
            </HStack>
          </Td>
        </Tr>
        {hasNotes && (
          <Tr>
            <Td colSpan={4} p="0 16px 16px">
              {orderItem.notes && <NoteBox>"{orderItem.notes}"</NoteBox>}
              {orderItem.supplier_note && (
                <NoteBox mt={orderItem.notes ? '8px' : ''}>
                  <HStack alignItems="top">
                    <Text>Supplier: "{orderItem.supplier_note}"</Text>
                    <Spacer />
                    <NoteEditorWrapper
                      onSave={(newValue: string) => {
                        orderItem.setSupplierNote(newValue);
                      }}
                      initialValue={orderItem.supplier_note}
                      noteEditorLabel={noteEditorLabel}
                      iconForButton={<PencilIcon w="12px" h="12px" />}
                    />
                  </HStack>
                </NoteBox>
              )}
            </Td>
          </Tr>
        )}
      </>
    );
  },
);

export default EditableOrderItemRow;
