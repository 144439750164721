import { action, computed, makeObservable, observable } from 'mobx';
import * as Sentry from '@sentry/browser';

import { api } from '../api';
import { RootStore } from './rootStore';
import { PaginationStore } from './paginationStore';
import { Instance } from 'mobx-state-tree';

import Recipe, { RecipeProps } from '../models/Recipe';

interface RecipesListInterface {
  [id: string|number]: Instance<typeof Recipe>;
}

type idType = string | number;

export class RecipesStore {
  user: any = [];
  pageLimit = 20;

  @observable rootStore?: RootStore;

  @observable dataIsLoading: boolean = true;
  @observable currentSearchQuery: string = '';
  @observable currentPage: number = 1;

  @observable pagination = new PaginationStore();
  @observable totalCount?: number;
  @observable totalPages: number = 0;
  @observable nextPage?: number;
  @observable previousPage?: number;

  @observable recipesList: RecipesListInterface = {};
  @observable recipesIndex: (idType)[] = [];
  @observable currentRecipeId: idType | null = null;

  @observable saveButtonIsLoading: boolean = false;

  constructor(rootStore?: RootStore, user?: any) {
    if (rootStore) {
      this.rootStore = rootStore;
    }
    this.user = user;
    makeObservable(this);
  }

  @computed
  get recipes() {
    return this.recipesIndex.map((key) => {
      if (typeof key === 'number') return this.recipesList[key];
    });
  }

  @computed
  get currentRecipe(): any {
    if (this.currentRecipeId) {
      return this.recipesList[this.currentRecipeId];
    } else {
      return null;
    }
  }

  @action
  setRecipes = (recipes: any) => {
    this.pagination.setFromResponse(recipes);
    this.recipesIndex = [];
    recipes.results.forEach(
      (recipe: RecipeProps, index: number) => {
        if (!this.recipesList[recipe.id]) {
          this.recipesList[recipe.id] = Recipe.create({
            id: recipe.id,
            name: recipe.name,
          });
        }
        this.recipesIndex[index] = recipe.id;
      },
    );
  };

  @action
  clearCurrentRecipe = () => {
    if (this.currentRecipe) {
      this.currentRecipe.setIsCurrent(false);
    }
    this.currentRecipeId = null;
  };
  @action
  setCurrentRecipe = (recipe?: RecipeProps) => {
    if (this.currentRecipe) {
      this.currentRecipe.setIsCurrent(false);
    }

    if (recipe) {
      const recipeId = recipe.id || 'new'
      const recipeObject = this.recipesList[recipeId];
      if (recipeObject) {
        recipeObject.addData(recipe);
        recipeObject.setIsCurrent(true);
      } else {
        console.log('Creating new recipe');
        const newRecipe = Recipe.create({
          id: recipeId,
          name: recipe.name,
        });
        newRecipe.addData(recipe);
        newRecipe.setIsCurrent(true);

        this.recipesList[recipeId] = newRecipe;
      }
      this.currentRecipeId = recipeId;
    } else {
      this.currentRecipeId = null;
    }
  };
  @action setdataIsLoading = (dataIsLoading: boolean) =>
    (this.dataIsLoading = dataIsLoading);
  @action setCurrentSearchQuery = (query: string) => {
    this.currentSearchQuery = query;
  };
  @action setCurrentPage = (value: number) => (this.currentPage = value);
  @action setSaveButtonIsLoading = (value: boolean) =>
    (this.saveButtonIsLoading = value);

  @action clearFilters = () => {
    this.currentSearchQuery = '';
  };

  @action
  getRecipes = () => {
    return new Promise((resolve, reject) => {
      const searchParam = (this.currentSearchQuery && `&q=${this.currentSearchQuery}`) || '';
      const path = `/v4/recipes?limit=${this.pageLimit}&page=${this.currentPage}${searchParam}`;
      this.setdataIsLoading(true);
      
      api
        .get(path)
        .then(async (response) => {
          const data = await response.json();
          if (response.ok && response.status !== 401) {
            this.setRecipes(data);
            resolve(data);
          }
          reject(data);
          this.setdataIsLoading(false);
        })
        .catch((error) => reject(error));
    });
  };

  @action
  getRecipe = (recipeId: string) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v4/recipes/${recipeId}`)
        .then(async (response) => {
          this.setCurrentRecipe(await response.json());
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };

  @action
  updateRecipe = (
    recipe: Instance<typeof Recipe>,
    values: any,
  ) => {
    return new Promise((resolve, reject) => {
      api
        .put(
          `/v4/recipes/${recipe.id}`,
          JSON.stringify({
            recipe: { ...values },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setCurrentRecipe(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action
  createRecipe = (values: any) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/v4/recipes`,
          JSON.stringify({
            recipe: { ...values },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.pagination.addToCount();
            this.setCurrentRecipe(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action deleteRecipe = (recipe: Instance<typeof Recipe>) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/v4/recipes/${recipe.id}`)
        .then(async (response) => {
          if (response.ok) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
