import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { useSuccessToast, useErrorToast } from '../../components/toast';

import { Switch, Text, useDisclosure } from '@chakra-ui/react';
import { useProducts } from '../../hooks/useStores';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';

const ProductActiveToggle = ({ fieldId, product }: any) => {
  const { updateProduct } = useProducts();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pendingToggleChange, setPendingToggleChange] =
    React.useState<boolean | null>(null);

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const handleActiveToggle = (isChecked: boolean) => {
    // Just change it straight away. If there is an error it will get caught and reverted.
    product.setActive(isChecked);

    // Now update the record in the API.
    updateProduct({ active: isChecked }, product.id)
      .then(() => {
        successToast({ description: 'Product status updated' });
      })
      .catch(() => {
        // Revert the record if it failed.
        product.active = !isChecked;
        errorToast({ description: 'Product status failed to update' });
      });
  };

  const onConfirm = () => {
    if (pendingToggleChange != null) {
      handleActiveToggle(pendingToggleChange);
      onClose();
    }
  };

  const requireConfirmation = product.is_synced;

  // position: 'relative' only required until the following PR is released
  // and we upgrade Chakra:
  // https://github.com/chakra-ui/chakra-ui/pull/4950/files
  return (
    <>
      <Switch
        position="relative"
        id={fieldId ? fieldId : `product-active-toggle-${product.id}`}
        onChange={(e: ChangeEvent) => {
          const target = e.target as HTMLInputElement;
          const isChecked = target.checked;

          if (requireConfirmation) {
            setPendingToggleChange(isChecked);
            onOpen();
          } else {
            handleActiveToggle(isChecked);
          }
        }}
        isChecked={product.active}
      />

      <ConfirmationDialog
        isOpen={isOpen}
        onCancel={() => {
          onClose();
          setPendingToggleChange(null);
        }}
        titleText={'Are you sure you want to update the status?'}
        bodyContent={
          <Text color="red">
            WARNING: This product is synced. The active status may be overridden
            on the next sync cycle, if it is not also updated in the external
            system.
          </Text>
        }
        confirmButtonText={'Update status'}
        confirmButtonColorScheme="green"
        onConfirm={onConfirm}
      />
    </>
  );
};

export default observer(ProductActiveToggle);
