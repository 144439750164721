import { TextProps } from '@chakra-ui/layout';
import { format, parseISO, isToday, isYesterday, isSameYear } from 'date-fns';

import { Text } from '@chakra-ui/react';

type DateTextProps = {
  date: Date | string;
  showTime?: boolean;
};

const DateText = (props: TextProps & DateTextProps): JSX.Element => {
  const { date, children, showTime = true, ...textProps } = props;

  function formatTime(date: Date) {
    return format(date, 'h:mma');
  }

  function formatDate(date: Date) {
    if (isToday(date)) {
      return 'Today';
    } else if (isYesterday(date)) {
      return 'Yesterday';
    } else {
      if (isSameYear(date, new Date())) {
        return format(date, 'd MMM');
      } else {
        return format(date, 'd MMM y');
      }
    }
  }

  function formatDateString(date: Date | string) {
    if (typeof date === 'string') {
      date = parseISO(date);
    }

    if (showTime) {
      return `${formatTime(date)}, ${formatDate(date)}`;
    }

    return formatDate(date);
  }

  return (
    <Text {...textProps}>
      {formatDateString(date)}
      {children}
    </Text>
  );
};

export default DateText;
