import React, { useState } from 'react';
import { useAuth } from '../contexts/auth';
import * as Sentry from '@sentry/browser';
import { xClientHeaders } from '../utils';

// TODO update 'anys'
type Context = {
  accountDisconnect: (successCallback: () => any) => void;
  currentAccountingConnection: any;
  getCurrentAccountingConnection: (successCallback: () => any) => void;
  accountingConnectorContacts: any;
  exportInvoice: (
    path: any,
    user: any,
    orderId: string,
    successCallback: (data: any) => any,
    errorCallback: (data: any) => any,
  ) => void;
  connectionError: boolean;
  getAccountingConnectorsContacts: (
    path: any,
    user: any,
    successCallback: () => any,
    errorCallback: () => any,
  ) => void;
  matchConnectorsContacts: (
    path: any,
    user: any,
    connectorConactId: any,
    orderId: any,
    successCallback: () => any,
  ) => void;
  getAccountingErrors: () => void;
  setAccountingErrors: any;
  accountingErrors: any[];
  deleteAccountingError: (errorItem: any) => void;
  setConnectionError: any;
  setAccountingConnectorContacts: any;
};

const AccountingContext = React.createContext<Context>({
  accountDisconnect: () => {},
  accountingConnectorContacts: [],
  currentAccountingConnection: {},
  getCurrentAccountingConnection: () => {},
  exportInvoice: () => {},
  connectionError: false,
  getAccountingConnectorsContacts: () => {},
  matchConnectorsContacts: () => {},
  getAccountingErrors: () => {},
  setAccountingConnectorContacts: [],
  setAccountingErrors: [],
  accountingErrors: [],
  deleteAccountingError: () => {},
  setConnectionError: false,
});

const api_url = `${process.env.REACT_APP_API_URL}/api`;

function AccountingProvider(props: any) {
  const { user } = useAuth();
  const [accountingConnectorContacts, setAccountingConnectorContacts] =
    useState(null);
  const [currentAccountingConnection, setCurrentAccountingConnection] =
    useState(null);
  const [connectionError, setConnectionError] = useState(false);
  const [accountingErrors, setAccountingErrors] = useState([]);

  const getAccountingConnectorsContacts = (
    path: any,
    user: any,
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
      };

      const url = api_url + path;

      fetch(url, requestOptions)
        .then(async (response) => {
          if (response.status === 500) {
            if (errorCallback) {
              errorCallback();
            }
          } else {
            if (response.ok) {
              const data = await response.json();
              setAccountingConnectorContacts(data);
              if (successCallback) {
                successCallback();
              }
              return data;
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          if (errorCallback) {
            errorCallback();
          }
          return error;
        });
    }
  };

  const matchConnectorsContacts = (
    path: any,
    user: any,
    connectorConactId: any,
    customerCompanyId: any,
    successCallback: () => any,
  ) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'PUT',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
        body: JSON.stringify({
          connector_contact_id: connectorConactId,
          mapped_company_id: customerCompanyId,
        }),
      };
      const url = api_url + path;
      fetch(url, requestOptions)
        .then(async (response) => {
          const data = await response;
          if (successCallback) {
            successCallback();
          }
          return data;
        })
        .catch((error) => {
          Sentry.captureException(error);
          return error;
        });
    }
  };

  const exportInvoice = (
    path: any,
    user: any,
    orderId: string,
    successCallback: (data: any) => any,
    errorCallback: (data: any) => any,
  ) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
        body: JSON.stringify({
          order_id: orderId,
        }),
      };

      const url = api_url + path;

      fetch(url, requestOptions)
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            successCallback(data);
          } else {
            errorCallback(data);
          }
          return data;
        })
        .catch((error) => {
          Sentry.captureException(error);
          return error;
        });
    }
  };

  const getCurrentAccountingConnection = (successCallback: () => any) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
      };

      const url = api_url + '/v4/accounting_connectors/current';
      fetch(url, requestOptions)
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            setCurrentAccountingConnection(data.connection);

            if (successCallback) {
              successCallback();
            }
            return data;
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  const accountDisconnect = (successCallback: () => any) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;

      const requestOptions = {
        method: 'DELETE',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
      };

      fetch(api_url + '/v4/accounting_connectors', requestOptions).then(() => {
        setCurrentAccountingConnection(null);
        successCallback();
      });
    }
  };

  const getAccountingErrors = () => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
      };
      const url = api_url + '/v4/accounting_connectors/invoices/errors';
      fetch(url, requestOptions)
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            setAccountingErrors(data);
            return data;
          }
        })
        .catch((error) => {
          return error;
        });
    }
  };

  const deleteAccountingError = (errorItem: any) => {
    if (user) {
      setAccountingErrors(
        accountingErrors.filter(
          (error: any) => error.order_id !== errorItem.order_id,
        ),
      );

      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'DELETE',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
      };

      const url =
        api_url + `/v4/accounting_connectors/invoices/${errorItem.order_id}`;
      fetch(url, requestOptions)
        .then(() => {})
        .catch((error) => {
          return error;
        });
    }
  };

  return (
    <AccountingContext.Provider
      value={{
        accountDisconnect,
        accountingConnectorContacts,
        connectionError,
        currentAccountingConnection,
        getAccountingConnectorsContacts,
        getCurrentAccountingConnection,
        matchConnectorsContacts,
        setAccountingConnectorContacts,
        setConnectionError,
        exportInvoice,
        getAccountingErrors,
        setAccountingErrors,
        accountingErrors,
        deleteAccountingError,
      }}
      {...props}
    />
  );
}

const useAccounting = () => React.useContext(AccountingContext);

export { AccountingProvider, useAccounting };
