import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { useIngredients } from '../../../hooks/useStores';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import EditIngredient from './EditIngredient';
import ShowIngredient from './ShowIngredient';

const IngredientDetail = ({
  ingredientId = null,
}: {
  ingredientId: string | null;
}): JSX.Element => {
  const { currentIngredient, getIngredient, setCurrentIngredient } =
    useIngredients();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (ingredientId) {
      getIngredient(ingredientId).then(() => {
        setLoading(false);
      });
    } else {
      setCurrentIngredient(undefined);
      setLoading(false);
    }
  }, [ingredientId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/ingredients/new">
        {!currentIngredient && <EditIngredient />}
      </Route>
      <Route path="/ingredients/:ingredientId/edit">
        {currentIngredient && <EditIngredient ingredient={currentIngredient} />}
      </Route>
      <Route path="/ingredients/:ingredientId">
        {currentIngredient && <ShowIngredient ingredient={currentIngredient} />}
      </Route>
      <Route path="/ingredients">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(IngredientDetail);
