const Link = {
  baseStyle: {
    textDecoration: 'underline',
    color: 'green',
    fontWeight: 600,
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    xero: {
      margin: '10px 0px 10px 10px',
      paddingLeft: '10px',
      fontSize: '13px',
    },
    reverse: {
      textDecoration: 'none',
      _hover: {
        textDecoration: 'underline',
      },
    },
  },
};

export default Link;
