import React, { useState, FunctionComponent, useEffect } from 'react';

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
  Text,
} from '@chakra-ui/react';

export interface MaxLengthTextareaProps
  extends Omit<FormControlProps, 'onChange'> {
  id: string;
  label: string;
  maxLength: number;
  value?: string;
  placeholder?: string;
  textAreaRef?: React.MutableRefObject<null>;
  onChange: (value: string) => void;
}

const MaxLengthTextarea: FunctionComponent<MaxLengthTextareaProps> = ({
  id,
  label,
  maxLength,
  value,
  placeholder,
  textAreaRef,
  onChange,
  ...props
}) => {
  const [content, setContent] = useState<string>(value || '');
  const isError = content.length > maxLength;

  useEffect(() => {
    setContent(value || '');
  }, [value]);

  return (
    <FormControl id={id} isInvalid={isError} {...props}>
      <FormLabel>
        <Text fontSize="lg">{label}</Text>
      </FormLabel>
      <Textarea
        placeholder={placeholder}
        value={content}
        ref={textAreaRef}
        maxLength={maxLength}
        onChange={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          setContent(target.value);
          onChange(target.value);
        }}
        onFocus={(e: React.SyntheticEvent) => {
          const target = e.target as HTMLInputElement;
          target.setSelectionRange(target.value.length, target.value.length);
        }}
      />
      {isError ? (
        <FormErrorMessage>
          {maxLength - content.length} characters remaining
        </FormErrorMessage>
      ) : (
        <FormHelperText>
          {maxLength - content.length} characters remaining
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MaxLengthTextarea;
