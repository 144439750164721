import { useToast } from '@chakra-ui/react';

const useSuccessToast = () => {
  return useToast({
    duration: 3500,
    isClosable: true,
    position: 'bottom-right',
    status: 'success',
    title: 'Success',
    variant: 'left-accent',
  });
};

const useErrorToast = () => {
  return useToast({
    duration: 3500,
    isClosable: true,
    position: 'bottom-right',
    status: 'error',
    title: 'Error',
    description: 'Something went wrong.',
    variant: 'left-accent',
  });
};

export { useSuccessToast, useErrorToast };
