import { useState, ReactNode } from 'react';
import { useAccounting } from '../../../contexts/accounting';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';

import { Button, Text, VStack } from '@chakra-ui/react';

const DisconnectButton = ({
  platformName,
  additionalContent,
  requiresManualStep,
}: {
  platformName: string;
  additionalContent?: string | ReactNode;
  requiresManualStep?: boolean;
}): JSX.Element => {
  const { getCurrentAccountingConnection, accountDisconnect } = useAccounting();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  function disconnect() {
    setButtonIsLoading(true);
    accountDisconnect(() => {
      getCurrentAccountingConnection(() => {
        setButtonIsLoading(false);
      });
    });
    setModalIsOpen(false);
  }

  return (
    <>
      <Button
        onClick={() => setModalIsOpen(true)}
        variant="outline"
        isLoading={buttonIsLoading}
      >
        Disconnect
      </Button>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        titleText={`Disconnect ${platformName}?`}
        bodyContent={
          <VStack>
            <Text>
              Are you sure you want to disconnect {platformName} from your
              HospoConnect account?
            </Text>
            {typeof additionalContent == 'string' ? (
              <Text>{additionalContent}</Text>
            ) : (
              additionalContent
            )}
            {requiresManualStep && (
              <Text>
                To complete this process, also remove HospoConnect from your{' '}
                {platformName} settings.
              </Text>
            )}
          </VStack>
        }
        confirmButtonText={'Disconnect'}
        onConfirm={disconnect}
      />
    </>
  );
};

export default DisconnectButton;
