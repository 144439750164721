import {
  Text,
  Link,
  Textarea,
  Input,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import FormFieldWrapper, { FormFieldWrapperProps } from './FormFieldWrapper';

export type EditableFormWrapperProps = Omit<
  FormFieldWrapperProps,
  'children' | 'onChange'
> & {
  isEditable?: boolean;
  EditComponent?: typeof Textarea | typeof Input;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: string;
  editableValue?: string;
  placeholder?: string;
  href?: string;
  children?: ReactNode;
};

const EditableFormWrapper = ({
  isEditable,
  EditComponent = Input,
  onChange,
  value,
  editableValue,
  href,
  children,
  placeholder,
  error,
  valueWrapperProps,
  ...wrapperProps
}: EditableFormWrapperProps) => {
  if (isEditable && EditComponent) {
    return (
      <FormFieldWrapper {...wrapperProps}>
        <FormControl isInvalid={!!error} width="320px" {...valueWrapperProps}>
          <EditComponent
            name={wrapperProps.fieldName}
            placeholder={placeholder}
            onChange={onChange}
            value={editableValue != undefined ? editableValue : value}
          />
        </FormControl>
        {children}
        {error && <Text color="red.700">{error}</Text>}
      </FormFieldWrapper>
    );
  }

  return (
    <FormFieldWrapper {...wrapperProps}>
      {value ? (
        <Text>
          {href ? (
            <Link href={href} isExternal>
              {value}
            </Link>
          ) : (
            value
          )}
        </Text>
      ) : (
        <Text color="gray.400">{placeholder || '(None supplied)'}</Text>
      )}
      {error && <FormErrorMessage color="red.700">{error}</FormErrorMessage>}
      {children}
    </FormFieldWrapper>
  );
};

export default EditableFormWrapper;
