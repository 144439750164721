import { observer } from 'mobx-react-lite';

import { useCustomerGroups } from '../../../hooks/useStores';
import CustomerGroupSetBox from '../../../components/CustomerGroups/List/CustomerGroupSetBox';

const PricingTierList = ({
  isReadOnly = false,
}: {
  isReadOnly?: boolean;
}): JSX.Element => {
  const { pricingTiersGroupSet } = useCustomerGroups();

  return (
    <>
      {pricingTiersGroupSet && (
        <CustomerGroupSetBox
          key={pricingTiersGroupSet.id}
          groupSet={pricingTiersGroupSet}
          title={`${pricingTiersGroupSet.groups.length} tiers`}
          showNumCustomers={false}
          showUnassignedIfEmpty={true}
          isReadOnly={isReadOnly}
        />
      )}
    </>
  );
};

export default observer(PricingTierList);
