import React, { useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { useSuccessToast, useErrorToast } from '../../components/toast';
import { useMixpanel } from 'react-mixpanel-browser';
import { PasswordField } from '../../components/Auth/PasswordField';
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';

const LoginForm = (): JSX.Element => {
  const mixpanel = useMixpanel();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState(false);
  const { loginUser } = useAuth();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setSubmitButtonIsLoading(true);
    const payload = {
      user: {
        email: email,
        password: password,
      },
    };

    loginUser(
      payload,
      (data) => {
        setSubmitButtonIsLoading(false);
        successToast({ description: `Welcome back, ${data.firstname}.` });
        /** We identify the mixpanel user here to ensure the Login is tracked to the correct user */
        mixpanel.identify(data.id);
        mixpanel.people.set({
          $name: `${data.firstname} ${data.surname}`,
        });
        mixpanel.track('Log in');
      },
      () => {
        setSubmitButtonIsLoading(false);
        errorToast({
          description:
            'Login failed, please check your credentials and try again.',
        });
      },
    );
  }

  return (
    <chakra.form onSubmit={handleSubmit}>
      <Stack spacing="6">
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            tabIndex={1}
            name="email"
            type="email"
            autoComplete="email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        <FormControl id="password">
          <Flex justify="space-between">
            <FormLabel>Password</FormLabel>
            <Box
              as="a"
              href="/forgot-password"
              color="green"
              fontWeight="semibold"
              fontSize="sm"
            >
              Forgot password?
            </Box>
          </Flex>

          <PasswordField
            tabIndex={2}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>

        <Button
          tabIndex={3}
          type="submit"
          colorScheme="green"
          size="lg"
          fontSize="md"
          isLoading={submitButtonIsLoading}
        >
          Log in
        </Button>
      </Stack>
    </chakra.form>
  );
};

export default LoginForm;
