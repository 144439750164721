import { observer } from 'mobx-react';
import { Image, Link, Button } from '@chakra-ui/react';
import Banner from '../../../components/Banner/Banner';
import useAccountingContact from '../../../hooks/useAccountingContact';
import { Customer } from '../../../stores/customers/customer';

const AccountingContactBanner = ({
  customer,
  allowManualSync = true,
}: {
  customer: Customer;
  allowManualSync?: boolean;
}): JSX.Element => {
  const {
    currentAccountingConnection,
    accountingMeta,
    mappedContactData,
    syncNow,
    isSyncingNow,
  } = useAccountingContact(customer);

  if (
    currentAccountingConnection &&
    mappedContactData &&
    accountingMeta.syncedCustomerFields.length > 0
  ) {
    return (
      <>
        <Banner
          icon={<Image src={accountingMeta.accountingLogo} w="64px" pr="1" />}
          title={`Connected to ${accountingMeta.accountingName}`}
          description={`This customer is mapped to a customer in ${accountingMeta.accountingName}. The pricing tier field is being synced.`}
          variant="outline"
          status="info"
          bg="white"
          actions={
            allowManualSync
              ? [
                  <Button
                    as={Link}
                    variant="tertiary"
                    onClick={syncNow}
                    isLoading={isSyncingNow}
                    textDecoration="none"
                  >
                    Sync now
                  </Button>,
                ]
              : []
          }
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default observer(AccountingContactBanner);
