import { Flex, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface OneUpWrapperProps extends FlexProps {
  headerChildren: ReactNode;
  children: ReactNode;
  wrapperProps?: Omit<FlexProps, 'children'>;
}

const OneUpWrapper = ({
  headerChildren,
  children,
  wrapperProps,
  ...rest
}: OneUpWrapperProps) => {
  return (
    <>
      <Flex
        flexDirection="row"
        flex="1"
        borderBottom="1px solid"
        borderColor="gray.200"
        {...rest}
      >
        {headerChildren}
      </Flex>

      <Flex
        flexDirection="column"
        height="100%"
        flex="1 1 auto"
        overflow="hidden"
        overflowX="auto"
        {...wrapperProps}
      >
        {children}
      </Flex>
    </>
  );
};

export default OneUpWrapper;
