import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Instance } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import { Box, Flex } from '@chakra-ui/react';

import useEditSnapshot from '../../../hooks/useEditSnapshot';
import ProductForm from './ProductForm';
import Product from '../../../models/Product';
import { useProducts } from '../../../hooks/useStores';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import DetailView from '../../../components/DetailView/DetailView';
import { Button } from '../../../components/Button/Button';

const EditProduct = ({ product }: { product: Instance<typeof Product> }) => {
  const history = useHistory();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const { sourceCategories } = useProducts();

  const [editState, _, onStartEditing] =
    useEditSnapshot<typeof Product>(product);
  const { updateProduct, updatePricingTiers } = useProducts();
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  useEffect(() => {
    onStartEditing();
  }, []);

  const handleSave = () => {
    setSaveIsLoading(true);
    editState.validate(sourceCategories);
    if (editState.id && editState.isValid) {
      const { pricing_tiers, ...productFormState } = editState.formState;
      updateProduct(productFormState, editState.id)
        .then(() => {
          if (editState.id != null) {
            updatePricingTiers(pricing_tiers, editState.id).then(() => {
              handleSuccess();
            });
          } else {
            handleSuccess();
          }
        })
        .catch(() => {
          setSaveIsLoading(false);
          errorToast({ description: 'Failed to save.' });
        });
    } else {
      setSaveIsLoading(false);
      errorToast({
        title: 'Uh oh.',
        description: "There's an error on the page. Please try again.",
      });
    }
  };

  const handleSuccess = () => {
    setSaveIsLoading(false);
    successToast({ description: 'Product updated.' });
    history.push(`/products/${editState.id}`);
    window.scrollTo(0, 0);
  };

  const handleCancel = () => {
    history.push(`/products/${product.id}`);
  };

  return (
    <DetailView
      rightActions={
        <>
          <Button size="sm" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            isLoading={saveIsLoading}
            variant="primary"
            size="sm"
            onClick={handleSave}
          >
            Save
          </Button>
        </>
      }
    >
      {editState && (
        <Box bg="white" width="100%">
          <Flex flexDirection="column" maxWidth="525px">
            <ProductForm product={editState} />
          </Flex>
        </Box>
      )}
    </DetailView>
  );
};

export default observer(EditProduct);
