import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useCurrentUser, useOrders } from '../../../hooks/useStores';
import { useBatchOrders } from '../../../contexts/batchOrders';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { useSuccessToast, useErrorToast } from '../../../components/toast';

import { IconButton, Tooltip } from '@chakra-ui/react';
import { DeleteIcon } from '../../../components/Icons/Icons';

const OrdersDeleteAction = ({ orderIds }: any): JSX.Element => {
  const { setCurrentBulkIds, getOrders } = useOrders();
  const { isBuyer } = useCurrentUser();
  const { deleteBatchOrders } = useBatchOrders();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  let [buttonIsLoading, setButtonIsLoading] = useState(false);
  let [modalIsOpen, setModalIsOpen] = useState(false);

  const onConfirm = () => {
    setButtonIsLoading(true);
    setModalIsOpen(false);
    deleteBatchOrders(orderIds, onSuccess, onFailure);
  };

  const onSuccess = () => {
    getOrders(
      () => {
        setCurrentBulkIds([]);
        setButtonIsLoading(false);
        successToast({
          description: `We deleted ${orderIds.length} orders.`,
        });
      },
      () => { },
      isBuyer ? 'outgoing' : 'incoming'
    );
  };

  const onFailure = () => {
    setButtonIsLoading(false);
    errorToast();
  };

  return (
    <>
      <Tooltip label="Delete orders">
        <IconButton
          onClick={() => setModalIsOpen(true)}
          icon={<DeleteIcon />}
          isLoading={buttonIsLoading}
          aria-label="Delete orders"
        />
      </Tooltip>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        titleText={`Delete ${orderIds.length} orders?`}
        confirmButtonText={`Delete ${orderIds.length} orders`}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default observer(OrdersDeleteAction);
