import { observer } from 'mobx-react';
import {
  Text,
  HStack,
  Avatar,
  ModalFooter,
  Button as ChakraButton,
  ModalCloseButton,
} from '@chakra-ui/react';
import { CaretLeftIcon, ShoppingCartIcon } from '../Icons/Icons';
import { useAddOrder } from '../../contexts/addOrder';
import { pluralizeString } from '../../utils';

import ProductModalContent from '../ProductModalContent/ProductModalContent';
import { Button } from '../Button/Button';
import { useCurrentUser } from '../../hooks/useStores';
import { OrderFieldName } from './AddOrderModalContent';

const SelectProductsScreen = ({
  onSubmit,
  onBack,
  disabledFields = [],
}: {
  onSubmit: () => void;
  onBack: () => void;
  disabledFields?: OrderFieldName[];
}) => {
  const { order } = useAddOrder();
  const { isBuyer } = useCurrentUser();

  const isInvalid =
    order.items.length === 0 ||
    order.items.some((item: any) => {
      item.validate();
      return item.isValid === false;
    });

  return (
    <ProductModalContent
      order={order}
      headerUpperChildren={
        <>
          {!(
            disabledFields.includes('supplier') ||
            disabledFields.includes('customer')
          ) && (
            <ChakraButton
              onClick={onBack}
              variant="ghost"
              leftIcon={<CaretLeftIcon />}
              mb="6"
              pl="0"
            >
              Back
            </ChakraButton>
          )}
          <ModalCloseButton top={4} right={4} />
          <HStack mb="10">
            <Avatar
              size="lg"
              src={isBuyer ? order.supplier?.logo : order.customer?.logo}
            />
            <Text>{isBuyer ? order.supplier?.name : order.customer?.name}</Text>
          </HStack>
        </>
      }
      footerChildren={
        <ModalFooter
          backgroundColor="gray.100"
          borderRadius="0 0 8px 8px"
          justifyContent="space-between"
        >
          <HStack>
            <ShoppingCartIcon color="gray.400" />
            <Text color="gray.500">
              {order.items.length} {pluralizeString('item', order.items.length)}
            </Text>
          </HStack>
          <Button variant="primary" isDisabled={isInvalid} onClick={onSubmit}>
            Review order
          </Button>
        </ModalFooter>
      }
    />
  );
};

export default observer(SelectProductsScreen);
