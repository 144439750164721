import { useEffect, useState } from 'react';
import {
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { isUndefined } from 'lodash';
import {
  ButtonGroup,
  Center,
  VStack,
  Box,
  Spacer,
  Button,
} from '@chakra-ui/react';

import { useSourceCategories } from '../../hooks/useStores';
import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import OneUpWrapper from '../../components/Layouts/OneUpWrapper';
import ActionBar from '../../components/ActionBar/ActionBar';
import SourceCategoryDetail from './Detail/SourceCategoryDetail';
import SourceCategoriesList from './List/SourceCategoriesList';
import PageLoadSpinner from '../../components/PageLoadSpinner/PageLoadSpinner';
import LoadingToast from '../../components/LoadingToast/LoadingToast';
import { Body2, Headline } from '../../components/Typography/Typography';
import { PlusIcon } from '../../components/Icons/IconsNew';

const Header = () => {
  const history = useHistory();
  return (
    <ActionBar
      title="Source categories"
      rightChildren={
        <ButtonGroup>
          <Button
            leftIcon={<PlusIcon width="24px" />}
            variant="primary"
            colorScheme="green"
            onClick={() => history.push('/source-categories/new')}
          >
            Add new
          </Button>
        </ButtonGroup>
      }
    />
  );
};

const SourceCategoriesPage = ({
  match: {
    params: { sourceCategoryId },
  },
}: RouteComponentProps<{ sourceCategoryId: string }>): JSX.Element => {
  const {
    pagination: { totalCount },
    getSourceCategories,
    setCurrentSourceCategory,
  } = useSourceCategories();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    getSourceCategories().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (isUndefined(sourceCategoryId)) {
      setCurrentSourceCategory(undefined);
    }
  }, [sourceCategoryId]);

  const match = useRouteMatch('/source-categories/new');

  return (
    <AppWrapper>
      {isLoading ? (
        <>
          <PageLoadSpinner />
          <LoadingToast isOpen={isLoading} />
        </>
      ) : !match && totalCount === 0 ? (
        <OneUpWrapper headerChildren={<Header />}>
          <Center height="100%">
            <VStack align="left" width="30%" color="gray.600">
              <Headline>You have no Source Categories yet</Headline>
              <Body2>
                Group products together which are produced from the same batch.
                For example, breads which are derived from a single batch of
                bread dough.
              </Body2>
              <Spacer />
              <Body2>
                These Source Categories are used in the Production Sheet.
              </Body2>
              <Box>
                <Button
                  mt="10"
                  colorScheme="green"
                  variant="secondary"
                  leftIcon={<PlusIcon width="24px" />}
                  onClick={() => history.push('/source-categories/new')}
                >
                  Add new Source Category
                </Button>
              </Box>
            </VStack>
          </Center>
        </OneUpWrapper>
      ) : (
        <TwoUpWrapper
          headerChildren={<Header />}
          leftChildren={<SourceCategoriesList />}
          rightChildren={
            <SourceCategoryDetail sourceCategoryId={sourceCategoryId} />
          }
        />
      )}
    </AppWrapper>
  );
};

export default observer(SourceCategoriesPage);
