import { useState } from 'react';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import Recipe from '../../../models/Recipe';

import { useRecipes } from '../../../hooks/useStores';

import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { useSuccessToast, useErrorToast } from '../../../components/toast';
import { useHistory } from 'react-router-dom';

import { Button, Tooltip } from '@chakra-ui/react';
import { DeleteIcon } from '../../../components/Icons/Icons';

const RecipeDeleteAction = ({
  recipe,
}: {
  recipe: Instance<typeof Recipe>;
}): JSX.Element => {
  const { deleteRecipe, getRecipes } = useRecipes();
  const history = useHistory();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  let [buttonIsLoading, setButtonIsLoading] = useState(false);
  let [modalIsOpen, setModalIsOpen] = useState(false);

  const onConfirm = () => {
    setButtonIsLoading(true);
    setModalIsOpen(false);

    deleteRecipe(recipe).then(onSuccess).catch(onFailure);
  };

  const onSuccess = () => {
    getRecipes().then(() => {
      setButtonIsLoading(false);
      successToast({
        description: 'The recipe has been deleted.',
      });
      history.push('/recipes');
    });
  };

  const onFailure = () => {
    setButtonIsLoading(false);
    errorToast({
      description: 'There was an error deleting the recipe.',
    });
  };

  return (
    <>
      <Tooltip label="Delete recipe">
        <Button
          onClick={() => setModalIsOpen(true)}
          leftIcon={<DeleteIcon />}
          isLoading={buttonIsLoading}
        >
          Delete
        </Button>
      </Tooltip>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        titleText={'Are you sure you want to delete this recipe?'}
        confirmButtonText={'Delete this recipe'}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default observer(RecipeDeleteAction);
