import { AddIcon } from '@chakra-ui/icons';
import { ButtonGroup, Button, HStack } from '@chakra-ui/react';
import ProductsListView from './ProductsListView';
import { observer } from 'mobx-react';
import { useState } from 'react';

const ProductRow = observer(
  ({ product, addProduct, customerId = null }: any) => {
    const [hovered, setHovered] = useState(false);

    return (
      <HStack
        justify="space-between"
        boxShadow="inset 0px -1px 0px #E5E7EB"
        px={6}
        py={3}
        _hover={{
          bg: 'gray.50',
        }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <ProductsListView
          product={product}
          customerId={customerId}
          showFavourites={hovered}
          actions={
            <Button
              variant="outline"
              width="80px"
              mr="6"
              leftIcon={<AddIcon />}
              onClick={() => {
                addProduct(product);
              }}
            >
              Add
            </Button>
          }
        />
      </HStack>
    );
  },
);

export default ProductRow;
