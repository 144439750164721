import { action, computed, makeObservable, observable, remove } from 'mobx';
import * as Sentry from '@sentry/browser';
import update from 'immutability-helper';

import { api } from '../api';

import {
  ProductGroupsStore,
} from '../stores/productGroupsStore';
import { ProductGroup, BaseProductGroupProps } from './ProductGroup';
import { isUndefined } from 'lodash';

interface Product {
  id: number;
  name: string;
  product_groups: { product_group_id: number }[];
}

export interface ProductGroupSetProps {
  id: number;
  name: string;
  supplier_name: string;
  set_type: string;
  product_groups: BaseProductGroupProps[];
  unassigned_product_count?: number;
}

export class ProductGroupSet {
  id: number;
  @observable store: ProductGroupsStore;
  @observable name: string;
  @observable supplierName: string;
  @observable set_type: string;
  @observable _unassignedProductCount?: number;
  @observable unassignedProducts?: Product[];

  @observable groupsList: { [id: number]: ProductGroup } = {};
  @observable groupsIndex: number[] = [];

  constructor(store: ProductGroupsStore, props: ProductGroupSetProps) {
    this.store = store;

    const {
      id,
      name,
      supplier_name,
      set_type,
    } = props;
    this.id = id;
    this.name = name;
    this.supplierName = supplier_name;
    this.set_type = set_type;

    this.addData(props)

    makeObservable(this);
  }

  @action
  addData = (props: ProductGroupSetProps) => {
    const {
      name,
      supplier_name,
      product_groups,
      set_type,
      unassigned_product_count,
    } = props;

    this.name = name;
    this.supplierName = supplier_name;
    this.set_type = set_type;
    this._unassignedProductCount = unassigned_product_count;

    product_groups.forEach((group: any, index: number) => {
      if (!this.groupsList[group.id]) {
        this.groupsList[group.id] = new ProductGroup(this, group);
      } else {
        this.groupsList[group.id].addData(group);
      }
      this.groupsIndex[index] = group.id;
    });
  }

  findProductGroupById = (findId: number) => {
    const id = this.groupsIndex.filter((g) => g === findId)[0];
    return {
      item: this.groupsList[id!],
      index: this.groupsIndex.indexOf(id),
    };
  };

  @computed
  get indexUri(): string {
    if (this.set_type === 'pricing_tiers') {
      return '/pricing-tiers';
    } else {
      return `/product-group-sets`;
    }
  }

  @computed
  get baseUri(): string {
    if (this.set_type === 'pricing_tiers') {
      return '/pricing-tiers';
    } else {
      return `/product-group-sets/${this.id}`;
    }
  }

  @computed
  get unassignedGroupName(): string {
    if (this.set_type === 'pricing_tiers') {
      return 'Default';
    } else {
      return 'Unassigned';
    }
  }

  @computed
  get groups(): ProductGroup[] {
    return this.groupsIndex.map((key) => {
      return this.groupsList[key];
    });
  }

  @computed
  get unassignedProductCount(): number {
    if (!isUndefined(this.unassignedProducts)) {
      return this.unassignedProducts.length;
    }
    return this._unassignedProductCount || 0;
  }

  @computed
  get productCount() {
    return this.groups.reduce(
      (accumulator, group) => accumulator + group.productCount,
      0,
    );
  }

  @action
  moveGroup = (id: number, atIndex: number) => {
    const { index } = this.findProductGroupById(id);
    this.groupsIndex = update(this.groupsIndex, {
      $splice: [
        [index, 1],
        [atIndex, 0, id],
      ],
    });
  };

  @action
  private updateData(groupSet: any) {
    this.name = groupSet.name;
  }

  @action
  setUnassignedProducts = (products: Product[]) => {
    this.unassignedProducts = products;
  };

  @action
  removeUnassignedProduct = (product: Product) => {
    if (this.unassignedProducts) {
      this.unassignedProducts = this.unassignedProducts.filter(
        (c) => c.id !== product.id,
      );
    } else if (this._unassignedProductCount) {
      this._unassignedProductCount--;
    }
  };

  @action
  addUnassignedProduct = (product: Product) => {
    if (this.unassignedProducts) {
      this.unassignedProducts.push(product);
    } else if (this._unassignedProductCount) {
      this._unassignedProductCount++;
    }
  };

  @action
  getUnassignedProducts = () => {
    return new Promise<Response>((resolve, reject) => {
      api
        .get(`/v4/product_group_sets/${this.id}/unassigned_products`)
        .then(async (response) => {
          const products = await response.json();
          if (response.ok) {
            this.setUnassignedProducts(products);
            resolve(products);
          }
          reject(products);
        });
    });
  };

  @action
  createGroup = (formData: any) => {
    return new Promise<Response>((resolve, reject) => {
      api
        .post(
          `/v4/product_groups`,
          JSON.stringify({
            product_group: {
              sort_order: 0,
              product_group_set_id: this.id,
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const group = await response.json();
          if (response.ok) {
            this.groupsList[group.id] = new ProductGroup(this, group);
            this.groupsIndex.push(group.id);
            group.products.forEach((product: any) => {
              this.removeUnassignedProduct(product);
            });
            resolve(group);
          }
          reject(group);
        });
    });
  };

  @action
  update = (formData: any) => {
    return new Promise<Response>((resolve, reject) => {
      api
        .put(
          `/v4/product_group_sets/${this.id}`,
          JSON.stringify({
            product_group_set: {
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.updateData(data);
            resolve(data);
          }
          reject(data);
        });
    });
  };

  @action
  updateSortOrders = () => {
    this.groupsIndex.forEach((id, index) => {
      this.groupsList[id].setSortOrder(index);
    });
    return new Promise((resolve, reject) => {
      api
        .put(
          `/v4/product_group_sets/${this.id}`,
          JSON.stringify({
            product_group_set: {
              product_groups_attributes: this.groups.map((g) => ({
                id: g.id,
                sort_order: g.sortOrder,
              })),
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action
  delete = () => {
    return new Promise<Response>((resolve, reject) => {
      api
        .delete(`/v4/product_group_sets/${this.id}`)
        .then(async (response) => {
          if (response.ok) {
            const index = this.store.productGroupSetsIndex.indexOf(this.id);
            if (index > -1) {
              this.store.pagination.removeFromCount();
              remove(this.store.productGroupSetsIndex, `${index}`);
              remove(this.store.productGroupSetsList, `${this.id}`);
              resolve(response);
            }
          }
          reject(response);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };
}
