import { Flex } from '@chakra-ui/react';

function TwoUpLeftScrollPanel({ children, opacity }: any) {
  return (
    <Flex flexDirection="column" flex="1" overflowY="auto" opacity={opacity}>
      {children}
    </Flex>
  );
}

export default TwoUpLeftScrollPanel;
