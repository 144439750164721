import { useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import {
  Box,
  Text,
  HStack,
  IconButton,
  ButtonGroup,
  Flex,
  Tooltip,
} from '@chakra-ui/react';

import ListRow from '../../../components/ListRow/ListRow';
import { Body2, Footnote } from '../../../components/Typography/Typography';
import { pluralizeString } from '../../../utils';
import {
  ArrowUpDownIcon,
  EditBoxIcon,
  PlusIcon,
} from '../../../components/Icons/IconsNew';
import { CustomerGroupSet } from '../../../models/CustomerGroupSet';
import ListView from '../../../components/ListView/ListView';
import CustomerGroupRow from './CustomerGroupRow';
import { useState } from 'react';
import { HamburgerIcon } from '@chakra-ui/icons';

const UnassignedRow = observer(({ groupSet, isSorting, ...rest }: any) => {
  const history = useHistory();
  const match = useRouteMatch(`${groupSet.baseUri}/customer-groups/unassigned`);
  return (
    <ListRow
      isActive={false}
      isCurrent={match}
      onClick={() =>
        history.push(`${groupSet.baseUri}/customer-groups/unassigned`)
      }
      py="18px"
      {...rest}
    >
      {isSorting && (
        <IconButton
          icon={<HamburgerIcon />}
          aria-label="Drag"
          isDisabled={true}
        />
      )}
      <Flex
        flex="1"
        justifyContent="space-between"
        direction={{ base: 'column', xl: 'row' }}
      >
        <Body2>{groupSet.unassignedGroupName}</Body2>
        <Footnote color="gray.500">
          {groupSet.unassignedCustomerCount}{' '}
          {pluralizeString('customer', groupSet.unassignedCustomerCount)}
        </Footnote>
      </Flex>
    </ListRow>
  );
});

const CustomerGroupSetBox = ({
  groupSet,
  title,
  showNumCustomers = true,
  showUnassignedIfEmpty = false,
  isReadOnly = false,
}: {
  groupSet: CustomerGroupSet;
  title?: ReactNode;
  showNumCustomers?: boolean;
  showUnassignedIfEmpty?: boolean;
  isReadOnly?: boolean;
}) => {
  const history = useHistory();
  const [isSorting, setIsSorting] = useState(false);
  const toggleSorting = () => setIsSorting(!isSorting);
  return (
    <ListView
      isCard={true}
      actionChildren={
        <HStack
          bg="gray.200"
          py="8px"
          px="24px"
          width="100%"
          borderTopRadius="8px"
          justify="space-between"
        >
          <>
            <Box>
              <Text fontWeight="500" color="gray.900">
                {title ? title : groupSet.name}
              </Text>
              {showNumCustomers && (
                <Text fontWeight="400" fontSize="12px" color="gray.600">
                  {groupSet.customerCount}{' '}
                  {pluralizeString('customer', groupSet.customerCount)}
                </Text>
              )}
            </Box>
            <ButtonGroup>
              <Tooltip label={isSorting ? 'Stop sorting' : 'Start sorting'}>
                <IconButton
                  aria-label="Toggle sorting"
                  icon={<ArrowUpDownIcon width="24px" />}
                  onClick={toggleSorting}
                  isDisabled={isReadOnly}
                />
              </Tooltip>
              {groupSet.set_type == 'default' && (
                <Tooltip label="Edit group set">
                  <IconButton
                    aria-label="Edit group set"
                    icon={<EditBoxIcon width="24px" />}
                    onClick={() => {
                      history.push(`${groupSet.baseUri}/edit`);
                    }}
                    isDisabled={isReadOnly}
                  />
                </Tooltip>
              )}
              <Tooltip label="Add group">
                <IconButton
                  aria-label="Add group"
                  icon={<PlusIcon width="24px" />}
                  onClick={() => {
                    history.push(`${groupSet.baseUri}/customer-groups/new`);
                  }}
                  isDisabled={isReadOnly}
                />
              </Tooltip>
            </ButtonGroup>
          </>
        </HStack>
      }
      items={groupSet.groups}
      renderItem={({ item: group, extraProps }) => (
        <CustomerGroupRow
          key={group.id}
          group={group}
          groupSet={groupSet}
          canSort={isSorting}
          {...extraProps}
        />
      )}
      isEmpty={showUnassignedIfEmpty ? false : groupSet.groups.length === 0}
      lastItem={(extraProps) => (
        <UnassignedRow
          groupSet={groupSet}
          isSorting={isSorting}
          {...extraProps}
        />
      )}
    />
  );
};

export default observer(CustomerGroupSetBox);
