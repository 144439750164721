import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import DetailView from '../../../components/DetailView/DetailView';
import Recipe from '../../../models/Recipe';
import { Button, Tooltip, VStack, Text } from '@chakra-ui/react';
import { EditBoxIcon } from '../../../components/Icons/IconsNew';
import { Instance } from 'mobx-state-tree';
import { H4, Label } from '../../../components/Typography/Typography';
import ItemTable from './ItemTable';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import PrintAction from './PrintAction';
import RecipeDeleteAction from './RecipeDeleteAction';

const ShowRecipe = ({ recipe }: { recipe: Instance<typeof Recipe> }) => {
  const history = useHistory();

  return (
    <DetailView
      leftActions={<H4 my="3">{recipe ? recipe.name : 'New recipe'}</H4>}
      rightActions={
        <>
          <PrintAction recipe={recipe} />
          <RecipeDeleteAction recipe={recipe} />
          <Tooltip label="Edit recipe">
            <Button
              onClick={() => {
                history.push(`/recipes/${recipe.id}/edit`);
              }}
              leftIcon={<EditBoxIcon width="24px" />}
            >
              Edit
            </Button>
          </Tooltip>
        </>
      }
    >
      <VStack width="full" align="left" spacing="8" my="10">
        <FormFieldWrapper fieldName="name">
          <Text>{recipe.name}</Text>
        </FormFieldWrapper>
        <FormFieldWrapper fieldName="unit">
          <Text>{recipe.unit}</Text>
        </FormFieldWrapper>
        <FormFieldWrapper fieldName="size">
          {recipe.totalQuantity.toFixed(4)} {recipe.unit}
        </FormFieldWrapper>
        {recipe && <ItemTable recipe={recipe} isEditable={false} />}
      </VStack>
    </DetailView>
  );
};

export default observer(ShowRecipe);
