import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useCustomers } from '../../../hooks/useStores';
import { pluralizeString } from '../../../utils';
import ListView from '../../../components/ListView/ListView';
import { Footnote } from '../../../components/Typography/Typography';
import CustomersRow from './CustomersRow';

const CustomersList = () => {
  const {
    customers,
    customersAreLoading,
    pageLimit,
    totalCount,
    totalPages,
    currentPage,
    setCurrentPage,
    clearFilters,
  } = useCustomers();
  return (
    <ListView
      isLoading={customersAreLoading}
      isEmpty={!customersAreLoading && totalCount === 0}
      pagination={{
        handlePageChange: (pageNumber: number) => {
          setCurrentPage(pageNumber);
        },
        currentPage: currentPage,
        pageCount: totalPages || 0,
        totalCount: totalCount || 0,
        itemsPerPage: pageLimit,
        itemsType: 'customers',
      }}
      actionChildren={
        <HStack mx="24px" width="100%" justify="space-between">
          {totalCount && (
            <Footnote>
              {totalCount} {pluralizeString('customer', totalCount)}
            </Footnote>
          )}
        </HStack>
      }
      items={customers}
      renderItem={({ item: customer, extraProps }) => (
        <CustomersRow key={customer.id} customer={customer} {...extraProps} />
      )}
      emptyChildren={
        <Box
          mt="12"
          ml="6"
          maxWidth="360px"
          opacity={customersAreLoading ? 0.6 : 1}
        >
          <VStack spacing="4" alignItems="left">
            <Text fontSize="lg" fontWeight="500">
              No customers found
            </Text>
            <Link
              fontWeight="400"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear filters
            </Link>
          </VStack>
        </Box>
      }
    />
  );
};

export default observer(CustomersList);
