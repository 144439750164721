import ProductsListView from './ProductsListView';
import { observer } from 'mobx-react';
import { CrossIcon } from '../Icons/IconsNew';
import {
  Input,
  HStack,
  InputGroup,
  Button,
  IconButton,
  InputRightAddon,
  VStack,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import SelectUnit from '../SelectUnit/SelectUnit';
import { useState } from 'react';

const OrderItemRow = observer(
  ({ orderItem, customerId = null, remove, focusRef }: any) => {
    const {
      buyable: { product },
    } = orderItem;
    orderItem.validate();
    const [hovered, setHovered] = useState(false);

    const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
      e.currentTarget.blur();
    };

    const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      orderItem.setAmount(Number(e.target.value));
    };

    const removeItemIfEmpty = () => {
      if (!orderItem.amount || orderItem.amount === 0) {
        remove(orderItem);
      }
    };

    return (
      <HStack
        justify="space-between"
        boxShadow="inset 0px -1px 0px #E5E7EB"
        px={6}
        py={3}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        _hover={{
          bg: 'gray.50',
        }}
      >
        <ProductsListView
          product={product}
          showFavourites={hovered}
          customerId={customerId}
          actions={
            <>
              {orderItem.id && (
                <Button
                  variant="outline"
                  width="100px"
                  disabled={true}
                  leftIcon={<CheckIcon />}
                  mr="24px"
                >
                  Added
                </Button>
              )}
              {!orderItem.id && (
                <HStack>
                  {product.units.length > 1 ? (
                    <InputGroup w={36} display="flex">
                      <Input
                        ref={focusRef}
                        type="number"
                        min={0}
                        value={orderItem.amount || ''}
                        onChange={onAmountChange}
                        onWheel={onWheel}
                        onBlur={removeItemIfEmpty}
                        isInvalid={orderItem.amount && !orderItem.isValid}
                        textAlign="right"
                        placeholder="0"
                        maxW={16}
                      />
                      <SelectUnit
                        flex={1}
                        ml={2}
                        units={product.units}
                        unit={orderItem.unit}
                        onChange={(unit) => {
                          orderItem.setUnit(unit);
                        }}
                        w="72px"
                      />
                    </InputGroup>
                  ) : (
                    <InputGroup w={36} display="flex">
                      <Input
                        ref={focusRef}
                        type="number"
                        min={0}
                        value={orderItem.amount || ''}
                        onChange={onAmountChange}
                        onWheel={onWheel}
                        onBlur={removeItemIfEmpty}
                        maxW={20}
                        textAlign="right"
                        placeholder="0"
                      />
                      <InputRightAddon
                        flex={1}
                        justifyContent="center"
                        children={orderItem.unit.name}
                      />
                    </InputGroup>
                  )}

                  <IconButton
                    aria-label="Remove"
                    variant="ghost"
                    size="sm"
                    icon={<CrossIcon />}
                    isDisabled={orderItem.markedForDeletion}
                    onClick={() => remove(orderItem)}
                  />
                </HStack>
              )}
            </>
          }
        />
      </HStack>
    );
  },
);

export default OrderItemRow;
