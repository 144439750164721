import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { useCustomerGroups } from '../../../hooks/useStores';
import EditCustomerGroupSetModal from '../../../components/CustomerGroups/Detail/EditCustomerGroupSetModal';
import ShowCustomerGroup from '../../../components/CustomerGroups/Detail/ShowCustomerGroup';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EditCustomerGroup from '../../../components/CustomerGroups/Detail/EditCustomerGroup';
import UnassignedCustomers from '../../../components/CustomerGroups/Detail/UnassignedCustomers';

const CustomerGroupDetail = ({
  groupId,
  groupSetId,
}: {
  groupId: any;
  groupSetId: string;
}): JSX.Element => {
  const {
    currentGroup,
    currentGroupSet,
    getCustomerGroup,
    setCurrentGroup,
    setCurrentGroupSet,
  } = useCustomerGroups();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (groupSetId && !groupId) {
      setCurrentGroup(undefined);
      setCurrentGroupSet(Number(groupSetId));
    }

    if (groupId) {
      getCustomerGroup(groupId).then(() => {
        setLoading(false);
      });
    } else {
      setCurrentGroup(undefined);
      setLoading(false);
    }
  }, [groupId, groupSetId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/customer-group-sets/:groupSetId/customer-groups/new">
        {!currentGroup && currentGroupSet && (
          <EditCustomerGroup
            key={currentGroupSet.id}
            groupSet={currentGroupSet}
          />
        )}
      </Route>
      <Route path="/customer-group-sets/:groupSetId/customer-groups/unassigned">
        {!currentGroup && currentGroupSet && (
          <UnassignedCustomers
            key={currentGroupSet.id}
            groupSet={currentGroupSet}
          />
        )}
      </Route>
      <Route path="/customer-group-sets/:groupSetId/edit">
        {currentGroupSet && (
          <>
            <EditCustomerGroupSetModal key={currentGroupSet.id} />
            <EmptyDetail />
          </>
        )}
      </Route>
      <Route path="/customer-group-sets/:groupSetId/customer-groups/:groupId/edit">
        {currentGroup && currentGroupSet && (
          <EditCustomerGroup
            key={currentGroup.id}
            group={currentGroup}
            groupSet={currentGroupSet}
          />
        )}
      </Route>
      <Route path="/customer-group-sets/:groupSetId/customer-groups/:groupId">
        {currentGroup && (
          <ShowCustomerGroup
            group={currentGroup}
            groupSet={currentGroupSet}
            confirmationDialogTitleText={`Delete “${currentGroup.name}” group?`}
            confirmationDialogBodyContent="Deleting a group does not delete the customer records. This action cannot be undone."
          />
        )}
      </Route>
      <Route path="/customer-group-sets">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(CustomerGroupDetail);
