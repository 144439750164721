import { useState } from 'react';

import { Text, ListItem, List, ListIcon, VStack } from '@chakra-ui/react';

import { useAccounting } from '../../contexts/accounting';
import MatchContactsModal from '../MatchContactsModal/MatchContactsModal';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { CrossFillIcon } from '../Icons/IconsNew';
import { useSuccessToast } from '../toast';

export interface AccountingErrorMessageProps {
  accountingConnectorName: string;
  errorItem: any;
}

const AccountingErrorMessage = ({
  accountingConnectorName,
  errorItem,
}: AccountingErrorMessageProps): JSX.Element => {
  const successToast = useSuccessToast();
  const { deleteAccountingError } = useAccounting();
  const [matchContactModal, setMatchContactModal] = useState(false);
  const [contactIDToMatch, setContactIDToMatch] = useState(null);
  const [contactNameToMatch, setContactNameToMatch] = useState(null);

  let titleText = '';
  let description;
  let baseDescriptionText = `Order #${errorItem.order.order_number} from ${errorItem.order.customer_company.name} could not be sent to ${accountingConnectorName}.`;
  let isResolvable = false;

  switch (errorItem.result.error_key) {
    case 'contact_not_mapped':
    case 'fetch_contact_failed':
      isResolvable = true;
      titleText = `Unmatched contact in ${accountingConnectorName}`;
      description = (
        <Text fontSize="15px">
          {baseDescriptionText} Please resolve and retry.
        </Text>
      );
      break;
    case 'address_code_required':
      titleText = 'Address code required';
      description = (
        <Text fontSize="15px">
          {baseDescriptionText} Either add an Address code to this order, or add
          a Default address code for the customer.
        </Text>
      );
      break;
    case 'product_codes_required':
      titleText = 'Product codes required';
      description = (
        <Text fontSize="15px">
          {baseDescriptionText} Please ensure that the products have product
          codes.
        </Text>
      );
      break;
    case 'product_data_issue':
      titleText = 'Product data issue';
      if (accountingConnectorName == 'MYOB') {
        description = (
          <Text fontSize="15px">
            {baseDescriptionText} Please check your product codes match in MYOB
            and that your products in MYOB have a set price, unit and tax code.
          </Text>
        );
      } else {
        description = (
          <Text fontSize="15px">
            {baseDescriptionText} Please check your product codes match in{' '}
            {accountingConnectorName}.
          </Text>
        );
      }
      break;
    case 'rate_limited':
      titleText = 'Rate limited';
      description = (
        <Text fontSize="15px">
          {baseDescriptionText} Please wait a minute, and/or try sending less
          orders at once. If you keep getting this error, then please call us.
        </Text>
      );
      break;
    case 'create_invoice_failed':
      if (
        errorItem.result.error_messages &&
        errorItem.result.error_messages.length > 0
      ) {
        titleText = `Failed to send to ${accountingConnectorName}`;
        description = (
          <VStack alignItems={'left'} fontSize="15px">
            <Text>
              {baseDescriptionText} {accountingConnectorName} returned the
              following error
              {errorItem.result.error_messages.length > 1 ? 's' : ''}:
            </Text>
            <List ml="4">
              {errorItem.result.error_messages.map((error: any) => {
                return (
                  <ListItem>
                    <ListIcon as={CrossFillIcon} color="red.500" mb="1px" />
                    {error}
                  </ListItem>
                );
              })}
            </List>
          </VStack>
        );
      } else {
        titleText = 'Missing product code';
        description = (
          <Text fontSize="15px">
            {baseDescriptionText} Please check your product codes match in{' '}
            {accountingConnectorName}.
          </Text>
        );
      }
      break;
    case 'unexpected_error':
    default:
      titleText = `${accountingConnectorName} returned an unexpected error`;
      description = (
        <Text fontSize="15px">
          {baseDescriptionText} Our developers have been alerted. Please try
          again later.
        </Text>
      );
  }

  let resolveProp = isResolvable
    ? { onResolve: () => handleResolveError() }
    : {};

  const handleResolveError = () => {
    switch (errorItem.result.error_key) {
      case 'contact_not_mapped':
      case 'fetch_contact_failed':
        setContactIDToMatch(errorItem.order.customer_company.id);
        setContactNameToMatch(errorItem.order.customer_company.name);
        setMatchContactModal(true);
        break;
      default:
        // Not resolvable
        break;
    }
  };

  const handleDismissError = () => {
    deleteAccountingError(errorItem);
  };

  return (
    <>
      <MatchContactsModal
        isOpen={matchContactModal}
        onClose={() => {
          setMatchContactModal(false);
          deleteAccountingError(errorItem);
        }}
        onSuccess={() => {
          successToast({
            description: `Contact mapped. Please try sending to ${accountingConnectorName} again.`,
          });
        }}
        contactId={contactIDToMatch}
        contactName={contactNameToMatch}
      />
      <ErrorMessage
        title={titleText}
        description={description}
        onDismiss={() => handleDismissError()}
        {...resolveProp}
      />
    </>
  );
};

export default AccountingErrorMessage;
