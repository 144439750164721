import { observable, makeObservable } from 'mobx';
import moment from 'moment';
import { Instance } from 'mobx-state-tree';
import Order from '../../models/Order';
import { StandingOrder } from '../../stores/standingOrders/standingOrder';

export class AddOrderStore {
  @observable order: Instance<typeof Order>;

  constructor(user?: any) {
    this.order = Order.create({ id: 'new' });
    makeObservable(this);
  }

  setOrderDataFromStandingOrder = (standingOrder: StandingOrder, deliveryDate: string) => {
    const deliveryDateMmt = moment(deliveryDate, 'YYYY-MM-DD');
    this.order.setSupplierCompany(standingOrder.supplier);
    this.order.setCustomer(standingOrder.customer);
    this.order.setDeliveryDate(deliveryDateMmt.toDate());
    this.order.setMessage('This standing order was created manually.');

    standingOrder.items.forEach((item) => {
      const amountForDay = item.getAmountForIsoWeekday(
        deliveryDateMmt.isoWeekday(),
      );
      if (!item.buyable.product.id || !amountForDay) {
        return;
      }

      const scheduleData = standingOrder.scheduleDataForIsoWeekday(deliveryDateMmt.isoWeekday());
      const alreadyOrdered = scheduleData?.already_ordered_product_ids.includes(item.buyable.product.id);
      if (alreadyOrdered) { return; }

      this.order.addOrderItem(item.buyable.product, amountForDay, item.unit);
    });
  }

  repopulateOrderWithMissingItems = (standingOrder: StandingOrder, deliveryDate: string) => {
    const deliveryDateMmt = moment(deliveryDate, 'YYYY-MM-DD');

    standingOrder.items.forEach((item) => {
      const amountForDay = item.getAmountForIsoWeekday(
        deliveryDateMmt.isoWeekday(),
      );
      if (!item.buyable.product.id || !amountForDay) {
        return;
      }

      const existingItem = this.order.items.find(
        (i) => i.buyable.product.id === item.buyable.product.id,
      );

      if (!existingItem) {
        this.order.addOrderItem(item.buyable.product, 0, item.unit);
      }
    });
  }

  resetOrder = () => {
    this.order = Order.create({ id: 'new' });
  };
}

export default new AddOrderStore();
