import { useLayoutEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { CaretDownIcon, CaretUpIcon } from '../Icons/IconsNew';
import { TimeIcon } from '@chakra-ui/icons';

import {
  Box,
  HStack,
  VStack,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import InactiveTag from '../InactiveTag/InactiveTag';
import FavouriteButton from './FavouriteButton';
import { Caption } from '../Typography/Typography';
import { getMinUnits } from '../../models/Product';
import { useCurrentUser } from '../../hooks/useStores';
import ProductPhoto from '../ProductPhoto/ProductPhoto';

const ProductsListView = ({
  product,
  customerId = null,
  showFavourites = false,
  showPrices = true,
  showDescription = true,
  showMinimums = true,
  showLeadTimes = true,
  actions,
}: any) => {
  const isInactive = product.active === false;
  const minUnits = getMinUnits(product.units);
  const { isBuyer } = useCurrentUser();
  const { isOpen, onToggle } = useDisclosure();

  const collapseRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [showElipsis, setShowElipsis] = useState(true);

  useLayoutEffect(() => {
    const height = collapseRef.current?.getBoundingClientRect().height;
    setHeight(height || 0);
  }, [isOpen]);

  const isExpandable =
    showDescription && product.description?.length > 135 && isBuyer;

  return (
    <HStack w="100%">
      <VStack
        alignItems="left"
        verticalAlign={'middle'}
        spacing={1}
        w="300px"
        className="product-list-view-details"
      >
        {isInactive && (
          <Box mb={1}>
            <InactiveTag />
          </Box>
        )}
        <HStack>
          <Text color="gray.700" fontWeight="500" noOfLines={2}>
            {product.name}
          </Text>

          {!isBuyer && product.product_code && (
            <Caption color="gray.500">{product.product_code}</Caption>
          )}
        </HStack>
        {showLeadTimes && product.lead_time_hours > 0 && (
          <Box w="100%">
            <HStack spacing="1" mb="1">
              <TimeIcon color="error.amber" />
              <Caption fontWeight="semibold" color="error.amber">
                {product.lead_time_hours} hour lead time
              </Caption>
            </HStack>
          </Box>
        )}
        <AnimateHeight
          delay={50}
          height={height || 'auto'}
          onHeightAnimationEnd={() => {
            setShowElipsis(!isOpen);
          }}
        >
          <Box
            as="span"
            display="flex"
            maxWidth={'360px'}
            onClick={onToggle}
            ref={collapseRef}
            position="relative"
            cursor={isExpandable ? 'pointer' : undefined}
          >
            <Caption
              noOfLines={showElipsis && !isOpen ? 2 : undefined}
              maxHeight={!isOpen ? '36.4px' : undefined}
              color="gray.500"
              mr={isExpandable ? 4 : 2}
              fontSize="12px"
              flex={1}
            >
              {showPrices && product.price && product.pricing_unit && (
                <Text as="span" fontWeight="semibold">
                  ${Number(product.price).toFixed(2)}/
                  {product.pricing_unit.name}
                  {(isBuyer && showDescription && product.description) ||
                  (showMinimums && minUnits.length > 0)
                    ? ' • '
                    : ''}
                </Text>
              )}

              {showMinimums && minUnits.length > 0 && (
                <Text as="span" fontWeight="semibold">
                  Minimum&nbsp;
                  {minUnits.map((unit: any, i: number) => (
                    <span key={unit.unit_name}>
                      {Number(unit.quantity)}
                      {unit.unit_name}
                      {i < minUnits.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                  {isBuyer && showDescription && product.description
                    ? ' • '
                    : ''}
                </Text>
              )}

              {isBuyer && showDescription && product.description && (
                <Text as="span">{product.description}</Text>
              )}
            </Caption>

            {isExpandable && (
              <Box
                as="div"
                position="absolute"
                width="12px"
                top="24px"
                right="0"
                color="gray.500"
                marginX={1}
              >
                {isOpen ? <CaretUpIcon w="12px" /> : <CaretDownIcon w="12px" />}
              </Box>
            )}
          </Box>
        </AnimateHeight>
      </VStack>
      <Spacer />
      <VStack alignItems={'end'} className="product-list-view-actions">
        <HStack spacing={0}>
          <FavouriteButton
            product={product}
            show={showFavourites}
            customerId={customerId}
          />
          <ProductPhoto photo={product.photo_thumb_url} height={60} />
        </HStack>
        {actions}
      </VStack>
    </HStack>
  );
};

export default ProductsListView;
