import { ReactNode, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Text,
  Menu,
  MenuItem,
  MenuList,
  useMenuButton,
  forwardRef,
  MenuButtonProps,
  HStack,
} from '@chakra-ui/react';
import { isUndefined } from 'lodash';

import { CaretDownIcon, CaretUpIcon, CloseIcon } from '../Icons/IconsNew';

interface Option {
  id: number | string;
  name: string;
  leftAddon?: ReactNode;
}

interface DropdownButtonProps extends MenuButtonProps {
  label: string;
  isOpen: boolean;
  canClearFilter: boolean;
  currentOption?: Option;
  onClearFilter: () => void;
  onClose: () => void;
}

const DropdownButton = forwardRef<DropdownButtonProps, 'button'>(
  (
    {
      label,
      currentOption,
      isOpen,
      canClearFilter,
      onClearFilter,
      onClose,
      ...rest
    }: DropdownButtonProps,
    ref,
  ) => {
    const buttonProps = useMenuButton(rest, ref);
    const isFiltered = !!currentOption;

    return (
      <ButtonGroup>
        <Button
          variant="ghost"
          bg={isFiltered ? 'white' : 'gray.200'}
          borderRadius="9999px"
          {...buttonProps}
        >
          <Text
            fontWeight="500"
            fontSize="12px"
            color="gray.500"
            mt="1px"
            textTransform="uppercase"
            mr={currentOption && currentOption.leftAddon ? '2' : ''}
          >
            {label ? label : 'Filter'}
          </Text>
          {currentOption ? (
            <>
              {currentOption.leftAddon && currentOption.leftAddon}
              <Text fontWeight="500" fontSize="17px" px="2">
                {currentOption.name}
              </Text>
            </>
          ) : (
            <Text fontWeight="500" fontSize="17px" px="2">
              All
            </Text>
          )}

          {isFiltered && canClearFilter && (
            <CloseIcon
              width="24px"
              onClick={(e) => {
                e.stopPropagation();
                onClearFilter();
                onClose();
              }}
            />
          )}

          {!canClearFilter &&
            (isOpen ? (
              <CaretUpIcon width="16px" />
            ) : (
              <CaretDownIcon width="16px" />
            ))}
        </Button>
      </ButtonGroup>
    );
  },
);

export interface DropdownFilterProps {
  label: string;
  options: Option[];
  onChange: (option?: Option) => void;
  value?: number | string | null;
  defaultValue?: number | string;
  zIndex?: number | string;
}

const DropdownFilter = ({
  label,
  value,
  defaultValue,
  zIndex,
  options,
  onChange,
}: DropdownFilterProps): JSX.Element => {
  const getOption = (val?: number | string | null) =>
    val ? options.find((o) => o.id === val) : undefined;

  const isControlled = !isUndefined(value);
  const canClearFilter = isUndefined(defaultValue);

  const currentOption = getOption(value);
  const defaultOption = getOption(defaultValue);

  const [_currentOption, _setCurrentOption] = useState<Option | undefined>(
    currentOption ? currentOption : defaultOption,
  );

  const setCurrentOption = (option?: Option) => {
    _setCurrentOption(option);
    onChange(option);
  };

  const onClearFilter = () => {
    setCurrentOption(defaultOption);
  };

  return (
    <Menu>
      {({ isOpen, onClose }) => (
        <>
          <DropdownButton
            label={label}
            isOpen={isOpen}
            currentOption={isControlled ? currentOption : _currentOption}
            canClearFilter={canClearFilter}
            onClearFilter={onClearFilter}
            onClose={onClose}
          />
          <MenuList zIndex={zIndex}>
            {options.map((option) => (
              <MenuItem
                key={option.id}
                onClick={() => {
                  setCurrentOption(option);
                }}
              >
                {option.leftAddon ? (
                  <HStack>
                    {option.leftAddon}
                    <Text>{option.name}</Text>
                  </HStack>
                ) : (
                  <Text>{option.name}</Text>
                )}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default DropdownFilter;
