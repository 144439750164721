import { useEffect } from 'react';
import { Redirect, RedirectProps, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';

const ReloadRedirect = (props: RedirectProps) => {
  useEffect(() => {
    window.location.reload();
  }, []);
  return <Redirect {...props} />;
};

type PrivateRouteProps = {
  whitelist: string | string[];
  value?: string;
} & RouteProps;

const PrivateRoute = ({
  whitelist,
  value,
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const isWhitelisted =
    value && (whitelist === value || whitelist.includes(value));

  return (
    <Route
      render={(props) =>
        isWhitelisted && Component ? <Component {...props} /> : <NotFoundPage />
      }
      {...rest}
    />
  );
};

type AppRouteProps = {
  isAuthenticated: boolean;
  isGuestRoute: boolean;
  isDisabled?: boolean;
  userTypeWhitelist?: string | string[];
} & RouteProps;

const AppRoute = ({
  isAuthenticated,
  isGuestRoute,
  isDisabled = false,
  userTypeWhitelist,
  ...routeProps
}: AppRouteProps) => {
  const { user } = useAuth();

  // Backwards compatability: Default to supplier as account_type is set on login
  const userType = user?.company?.account_type || 'supplier';

  if ((isGuestRoute && isAuthenticated) || isDisabled) {
    return <ReloadRedirect to="/orders" />;
  } else if (!isGuestRoute && !isAuthenticated) {
    return <ReloadRedirect to="/login" />;
  } else if (userTypeWhitelist && isAuthenticated) {
    return (
      <PrivateRoute
        whitelist={userTypeWhitelist}
        value={userType}
        {...routeProps}
      />
    );
  } else {
    return <Route {...routeProps} />;
  }
};

AppRoute.defaultProps = {
  isGuestRoute: false,
};

export default AppRoute;
