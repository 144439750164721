import { StyleProps, Tab, TabIndicator, TabList, Tabs } from '@chakra-ui/react';
export interface TabSwitchInputProps extends StyleProps {
  id: string;
  label?: string;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
}

export const TabSwitchInput = ({
  id,
  label,
  options,
  onChange,
  ...rest
}: TabSwitchInputProps) => {
  return (
    <Tabs
      id={id}
      onChange={(index) => onChange(options[index].value)}
      variant="fullTextSwitch"
      {...rest}
    >
      <TabList>
        {options.map((option) => (
          <Tab key={`${id}-option-${option.value}`} zIndex={1}>
            {option.label}
          </Tab>
        ))}
        <TabIndicator
          mt={0.5}
          mb={0.5}
          position="absolute"
          top={0}
          bottom={0}
          bg="white"
          borderRadius="lg"
          shadow="base"
        />
      </TabList>
    </Tabs>
  );
};

export default TabSwitchInput;
