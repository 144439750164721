const Radio = {
  variants: {
    darkHighlight: {
      container: {
        _hover: {
          backgroundColor: 'gray.150'
        }
      }
    },
    modalRow: {
      container: {
        _hover: {
          backgroundColor: 'gray.100'
        }
      }
    }
  },
};

export default Radio;
