import { observer } from 'mobx-react';
import { Box, Text, VStack, Select, HStack } from '@chakra-ui/react';

import { useCustomerGroups, useCustomers } from '../../../hooks/useStores';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';

import { Label, Subhead } from '../../../components/Typography/Typography';
import { Button } from '../../../components/Button/Button';
import { ArrowRightIcon } from '../../../components/Icons/IconsNew';
import { useHistory } from 'react-router-dom';
import useAccountingContact from '../../../hooks/useAccountingContact';

const CustomerGroupForm = ({ isEditable }: any) => {
  const history = useHistory();
  const { currentCustomer } = useCustomers();
  const { customerGroupSets } = useCustomerGroups();
  const { accountingMeta, mappedContactData } =
    useAccountingContact(currentCustomer);

  return (
    <VStack align="left" spacing="8" maxWidth="525px">
      <VStack align="left">
        <HStack>
          <Subhead fontWeight="600">Customer groups</Subhead>
          {!isEditable && customerGroupSets.length > 0 && (
            <Button
              variant="tertiary"
              rightIcon={<ArrowRightIcon width="16px" />}
              onClick={() => history.push('/customer-group-sets')}
            >
              Manage
            </Button>
          )}
        </HStack>
        {customerGroupSets.length === 0 && (
          <>
            <Subhead as="p">You have no customer groups set up.</Subhead>
            <Box>
              <Button mt="3" size="sm" href="/customer-group-sets">
                Manage customer groups
              </Button>
            </Box>
          </>
        )}
      </VStack>

      {customerGroupSets.length > 0 &&
        customerGroupSets.map((groupSet: any) => {
          const group = isEditable
            ? currentCustomer.formCustomerGroupsByGroupSetId[groupSet.id]
            : currentCustomer.customerGroupsByGroupSetId[groupSet.id];

          const isDisabled =
            (mappedContactData &&
              accountingMeta?.syncedCustomerFields.includes('pricing_tier') &&
              groupSet.set_type == 'pricing_tiers') ||
            false;

          return (
            <FormFieldWrapper
              key={groupSet.id}
              fieldName="phone"
              fieldLabel={<Label>{groupSet.name}</Label>}
              subLabel="Not visible to customers"
              width="100%"
            >
              {isEditable ? (
                <Select
                  defaultValue={
                    (group && group.customerGroupId) || 'placeholder-group-id'
                  }
                  onChange={(e) => {
                    const newGroup =
                      groupSet.groupsList[Number(e.target.value)];
                    if (newGroup) {
                      if (group) {
                        group.set({
                          ...newGroup,
                          customer_group_set_name: groupSet.name,
                        });
                      } else {
                        currentCustomer.addGroup({
                          customer_group_id: newGroup.id,
                          customer_group_name: newGroup.name,
                          customer_group_delivery_rules:
                            newGroup.delivery_rules,
                          customer_group_delivery_rules_enabled:
                            newGroup.delivery_rules_enabled,
                          customer_group_set_id: groupSet.id,
                          customer_group_set_name: groupSet.name,
                        });
                      }
                    } else {
                      if (group) {
                        group.unset();
                      }
                    }
                  }}
                  isDisabled={isDisabled}
                >
                  <option value="placeholder-group-id" color="gray.400">
                    {groupSet.unassignedGroupName}
                  </option>
                  {groupSet &&
                    groupSet.groups.map((group: any, i: number) => {
                      return (
                        <option key={`group-${i}`} value={group.id}>
                          {group.name}
                        </option>
                      );
                    })}
                </Select>
              ) : group ? (
                <Text>{group.customerGroupName}</Text>
              ) : (
                <Text color="gray.400">{groupSet.unassignedGroupName}</Text>
              )}
            </FormFieldWrapper>
          );
        })}
    </VStack>
  );
};

export default observer(CustomerGroupForm);
