import { types } from 'mobx-state-tree';

const PriceTier = types
  .model('PriceTier', {
    id: types.number,
    name: types.maybeNull(types.string),
    price: types.maybeNull(types.string)
  })
  .actions((self) => ({
    setId(newId: number) {
      self.id = newId;
    },
    setPrice(newPrice: string) {
      self.price = newPrice;
    },
  }));

export default PriceTier;
