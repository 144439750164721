import { isIE, isEdge } from 'react-device-detect';

export function base64ToUrl(base64: any) {
  const bytesArray = Uint8Array.from(atob(base64), (c) => c.charCodeAt(0));
  const localPdf = window.URL.createObjectURL(
    new window.Blob([bytesArray], { type: 'application/pdf' }),
  );
  return encodeURI(localPdf);
}

export function performPrint(params: any) {
  const { window } = params;
  try {
    window.focus();

    // If Edge or IE, try catch with execCommand
    if (isEdge || isIE) {
      try {
        window.document.execCommand('print', false, null);
      } catch (e) {
        window.print();
      }
    } else {
      // Other browsers
      window.print();
    }
  } catch (error) {
    params.onError(error);
  }
}

export function printInNewWindow(params: any) {
  const { url, onPopupBlocked } = params;
  const printWindow = window.open(url, '_blank');
  if (
    !printWindow ||
    printWindow.closed ||
    typeof printWindow.closed == 'undefined'
  ) {
    onPopupBlocked();
  } else {
    printWindow.addEventListener('load', () => {
      setTimeout(() => {
        performPrint({
          window: printWindow,
          ...params,
        });
      }, 50);
    });
  }
}
