import { types } from 'mobx-state-tree';

const Unit = types
  .model('Unit', {
    id: types.number,
    name: types.string,
    is_default: types.maybeNull(types.boolean), // Only for when ordering
    is_pricing_unit: types.maybeNull(types.boolean), // Only for when ordering
    min_order_quantity: types.maybeNull(types.string), // Only for when ordering
  })
  .actions((self) => ({
    setId(newId: number) {
      self.id = newId;
    },
  }));

export default Unit;
