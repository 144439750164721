import React, { FunctionComponent } from 'react';
import { Select, SelectProps } from '@chakra-ui/react';
import { Instance } from 'mobx-state-tree';
import Unit from '../../models/Unit';
import { observer } from 'mobx-react';

export interface SelectUnitProps extends Omit<SelectProps, 'onChange'> {
  unit: Instance<typeof Unit>;
  units: Array<Instance<typeof Unit>>;
  isInactive?: boolean;
  onChange: (unit: Instance<typeof Unit>) => void;
}

const SelectUnit: FunctionComponent<SelectUnitProps> = ({
  unit,
  units,
  isInactive = false,
  onChange,
  ...props
}) => {
  const onChangeHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    const unit = units.find(
      (u: Instance<typeof Unit>) => u.id === Number(value),
    );
    return unit ? onChange(unit) : null;
  };
  const allUnits = units.some((u) => u.id === unit.id)
    ? units
    : [...units, unit];
  return allUnits.length > 1 && !isInactive ? (
    <Select onChange={onChangeHandler} value={unit.id} {...props}>
      {allUnits.map((unit: any) => {
        return (
          <option
            key={`unit-${unit.id}`}
            value={unit.id}
            disabled={!units.some((u) => u.id === unit.id)}
          >
            {unit.name}
          </option>
        );
      })}
    </Select>
  ) : (
    <Select
      isDisabled={true}
      onChange={onChangeHandler}
      value={unit.id}
      {...props}
    >
      <option value={unit.id}>{unit.name}</option>
    </Select>
  );
};

export default observer(SelectUnit);
