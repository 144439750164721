import { observer } from 'mobx-react';

import {
  Button,
  Checkbox,
  FormControl,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
  Radio,
  RadioGroup,
  Select,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { find } from 'lodash';
import { SizedIcon } from '../../../components/Icons/IconsNew';

import { useCustomerGroups, useProducts } from '../../../hooks/useStores';
import useAccountingMeta from '../../../hooks/useAccountingMeta';
import AccountingProductBanner from './AccountingProductBanner';
import ProductVisibilityField from './ProductVisibilityField';
import ProductCodeWrapper from './ProductCodeWrapper';
import ProductCodeInput from './ProductCodeInput';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import MaxLengthInput from '../../../components/MaxLengthInput/MaxLengthInput';
import SourceCategoryForm from './SourceCategoryForm';
import { toJS } from 'mobx';
import { Instance } from 'mobx-state-tree';
import PricingForm from './PricingForm';
import Product from '../../../models/Product';
import ImageUploader from '../../../components/ImageUploader/ImageUploader';
import ProductPhoto from '../../../components/ProductPhoto/ProductPhoto';

const ProductForm = ({ product }: { product: Instance<typeof Product> }) => {
  const { categories, units } = useProducts();
  const { pricingTiersGroupSet } = useCustomerGroups();
  const { syncedProductFields } = useAccountingMeta(
    product?.accounting_product?.connector_type || 'none',
  );

  const usesPricingTiers = pricingTiersGroupSet;

  const hasError = (fieldName: string) => {
    return getErrorObject(fieldName) != undefined;
  };

  const getErrorMessage = (fieldName: string) => {
    return getErrorObject(fieldName)?.message;
  };

  const getErrorObject = (fieldName: string) => {
    return product?.fieldErrors?.find(
      (errorObj: any) => errorObj.fieldName == fieldName,
    );
  };

  const onSaveProductVisibilityField = ({
    customerIds,
    customerGroupIds,
    hideFromCustomers,
  }: {
    customerIds: string[];
    customerGroupIds: string[];
    hideFromCustomers: boolean;
  }) => {
    product?.setCompanyIds(customerIds);
    product?.setCustomerGroupIds(customerGroupIds);
    product?.setHideFromCustomers(hideFromCustomers);
  };

  const headingText = () => {
    if (!product) {
      return 'New product';
    } else {
      return '';
    }
  };

  const isSynced = product.is_synced;
  const disabledFields = isSynced ? syncedProductFields : [];

  return (
    <form style={{ backgroundColor: 'white', marginBottom: '80px' }}>
      <Heading size="md" my="10">
        {headingText()}
      </Heading>
      <VStack width="full" align="left" spacing="8">
        <AccountingProductBanner product={product} allowManualSync={false} />
        <FormFieldWrapper fieldName="photo" fieldLabel="Photo">
          <VStack alignItems={'left'}>
            {product.hasPhoto && (
              <ProductPhoto
                photo={product.photo_base64 || product.photo_large_url}
                height={180}
              />
            )}
            <ImageUploader
              buttonText={product.hasPhoto ? 'Replace photo' : 'Upload photo'}
              onSave={(base64Image: string) => {
                product.setPhotoBase64(base64Image);
              }}
            />

            {product.hasPhoto && (
              <Button
                size="sm"
                onClick={() => {
                  product.setPhotoDestroy();
                }}
                rightIcon={
                  <SizedIcon width="16px" height="16px" name={'CrossIcon'} />
                }
              >
                Remove photo
              </Button>
            )}
          </VStack>
        </FormFieldWrapper>
        <FormFieldWrapper fieldName="name" fieldLabel="Name">
          <Input
            type="text"
            defaultValue={product?.name || ''}
            onChange={(e) => product.setName(e.target.value)}
            maxLength={255}
            placeholder="eg. Asparagus (bunch)"
            isInvalid={hasError('name')}
            isDisabled={disabledFields.includes('name')}
          />
          {hasError('name') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('name')}
            </Text>
          )}
        </FormFieldWrapper>

        <FormFieldWrapper
          fieldName="description"
          fieldLabel="Description"
          subLabel="Optional"
        >
          <MaxLengthInput
            id="description"
            label="Description"
            value={product?.description || ''}
            maxLength={255}
            onChange={(value) => product.setDescription(value)}
            isDisabled={disabledFields.includes('description')}
          />
          {hasError('description') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('description')}
            </Text>
          )}
        </FormFieldWrapper>

        <FormFieldWrapper fieldName="category" fieldLabel="Category">
          <Select
            defaultValue={
              (product && product.category && product.category.id) ||
              'placeholder-category-id'
            }
            onChange={(e) => {
              const newCategory = find(
                categories,
                (c) => c.id === Number(e.target.value),
              );
              product.setCategory(newCategory);
            }}
            isInvalid={hasError('category_id')}
            isDisabled={disabledFields.includes('category_id')}
          >
            <option value="placeholder-category-id" disabled>
              Please select
            </option>
            {categories &&
              categories.map((category: any, i: number) => {
                return (
                  <option key={`category-${i}`} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
          </Select>
          {hasError('category_id') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('category_id')}
            </Text>
          )}
        </FormFieldWrapper>

        <FormFieldWrapper
          fieldName="units"
          fieldLabel="Units"
          helpContent="Select all units that customers can order. For example, if
          you select 'kg' and 'each', customers could order 3kg of
          bananas or 3 bananas."
        >
          <RadioGroup
            onChange={(value) => {
              const newUnit = find(units, (u) => u.id === parseInt(value, 10));
              product.setUnit(newUnit);
            }}
            value={
              (product && product.unit && product.unit.id.toString()) ||
              'placeholder-unit-id'
            }
            isDisabled={disabledFields.includes('units')}
          >
            <Table variant="ghost" size="xs">
              <Thead>
                <Tr>
                  <Th borderBottom="none">Allowed</Th>
                  <Th borderBottom="none">Default</Th>
                  <Th borderBottom="none">
                    <HStack spacing="1">
                      <Text>Minimum quantity</Text>
                      <Tooltip
                        label={
                          'Buyers will be unable to order less than the minimum quantity.'
                        }
                        hasArrow
                        placement="bottom"
                      >
                        <span>
                          <SizedIcon
                            width="16px"
                            height="16px"
                            name={'HelpIcon'}
                          />
                        </span>
                      </Tooltip>
                    </HStack>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {units &&
                  units.map((unit: any) => {
                    const isChecked = product.checkedUnits.some((item: any) => {
                      return item === unit.id;
                    });

                    // If it's checked, then create a unit setting if there isn't one
                    const unitSetting =
                      find(product.unit_settings, (unit_setting: any) => {
                        return unit_setting.unit_id === unit.id;
                      }) ||
                      (isChecked
                        ? product.addUnitSetting(unit.id, null)
                        : null);

                    return (
                      <Tr key={`unit-${unit.id}`}>
                        <Td>
                          <Checkbox
                            key={`allowedUnit-${unit.id}`}
                            isChecked={isChecked}
                            value={unit.id}
                            isDisabled={disabledFields.includes('units')}
                            onChange={() => product.toggleUnit(unit)}
                          >
                            {unit.name}
                          </Checkbox>
                        </Td>
                        <Td>
                          <Radio
                            backgroundColor="white"
                            key={`defaultUnit-${unit.id}`}
                            value={unit.id}
                            isDisabled={
                              disabledFields.includes('units') || !isChecked
                            }
                            mt="1"
                          />
                        </Td>
                        <Td>
                          <Input
                            type="number"
                            textAlign="right"
                            borderRadius="6px"
                            value={unitSetting?.min_order_quantity || ''}
                            onChange={(e) => {
                              unitSetting?.setMinOrderQuantity(e.target.value);
                            }}
                            isDisabled={
                              disabledFields.includes('units') || !isChecked
                            }
                            placeholder="0"
                          />
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </RadioGroup>

          {hasError('unit_id') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('unit_id')}
            </Text>
          )}
          {hasError('units') && (
            <Text mt="2" fontSize="sm" color="red">
              {getErrorMessage('units')}
            </Text>
          )}
        </FormFieldWrapper>

        <ProductCodeWrapper>
          <ProductCodeInput
            product={product}
            defaultValue={product?.product_code || ''}
            maxLength={255}
            isDisabled={disabledFields.includes('product_code')}
            onChange={(e) => product.setProductCode(e.target.value)}
            isInvalid={hasError('product_code')}
          />
        </ProductCodeWrapper>

        {!usesPricingTiers && (
          <FormFieldWrapper
            fieldLabel="Price"
            fieldName="price"
            subLabel="Optional"
          >
            <FormControl id="price">
              <HStack>
                <InputGroup w="132px">
                  <InputLeftAddon children="$"></InputLeftAddon>
                  <NumberInput
                    defaultValue={product?.price || undefined}
                    min={0}
                    onChange={(value) => product.setPrice(value)}
                    isDisabled={disabledFields.includes('price')}
                  >
                    <NumberInputField
                      borderLeftRadius="0"
                      borderRightRadius="md"
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </InputGroup>
                <Text>/</Text>
                <Select
                  w="116px"
                  defaultValue={
                    (product &&
                      product.pricing_unit &&
                      product.pricing_unit.id) ||
                    'price-unit-placeholder'
                  }
                  onChange={(e) => {
                    const newUnit = find(
                      units,
                      (u) => u.id === parseInt(e.target.value, 10),
                    );
                    product.setPricingUnit(toJS(newUnit));
                  }}
                  isInvalid={hasError('pricing_unit_id')}
                  isDisabled={disabledFields.includes('pricing_unit_id')}
                >
                  <option value="price-unit-placeholder" disabled>
                    Unit
                  </option>
                  {units &&
                    units.map((unit: any, i: number) => {
                      return (
                        <option key={i} value={unit.id}>
                          {unit.name}
                        </option>
                      );
                    })}
                </Select>
              </HStack>
            </FormControl>

            {hasError('pricing_unit_id') && (
              <Text mt="2" fontSize="sm" color="red">
                {getErrorMessage('pricing_unit_id')}
              </Text>
            )}
          </FormFieldWrapper>
        )}

        <FormFieldWrapper
          fieldLabel="Lead time (in hours)"
          fieldName="lead-time-hours"
          subLabel="Optional"
          helpContent={
            <VStack>
              <Text>
                Lead times are visible to customers when they are selecting
                their delivery date, though it wont limit the dates available to
                select.
              </Text>
              <Text>
                Any standing orders for a product with a lead time will be
                created earlier.
              </Text>
            </VStack>
          }
        >
          <InputGroup w="132px">
            <NumberInput
              defaultValue={product && product.lead_time_hours}
              min={0}
              onChange={(value) => product.setLeadTimeHours(Number(value))}
              isDisabled={disabledFields.includes('lead_time_hours')}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </FormFieldWrapper>

        <FormFieldWrapper
          fieldLabel="Wholesale supplier"
          fieldName="wholesale-supplier"
          subLabel="Optional, not visible to customers"
        >
          <Input
            type="text"
            defaultValue={product?.wholesale_supplier || ''}
            maxLength={255}
            onChange={(e) => product.setWholesaleSupplier(e.target.value)}
          />
        </FormFieldWrapper>

        <ProductVisibilityField
          customerIds={product.company_ids}
          customerGroupIds={product.customer_group_ids}
          visibleToCustomers={product.visible_to_customers}
          onSave={onSaveProductVisibilityField}
          isEditable={true}
        />

        {usesPricingTiers && (
          <PricingForm
            product={product}
            isDisabled={disabledFields.includes('price')}
          />
        )}

        <SourceCategoryForm
          currentProduct={product}
          isEditable={true}
          hasError={hasError}
          getErrorMessage={getErrorMessage}
        />
      </VStack>
    </form>
  );
};

export default observer(ProductForm);
