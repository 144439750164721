import { observer } from 'mobx-react';
import { useState, ChangeEvent, useEffect } from 'react';

import { Button, VStack, Select, Input } from '@chakra-ui/react';
import Recipe, { RecipeItem } from '../../../models/Recipe';
import { useSuccessToast } from '../../../components/toast';
import DetailView from '../../../components/DetailView/DetailView';
import { useRecipes } from '../../../hooks/useStores';
import { Instance } from 'mobx-state-tree';
import { useHistory } from 'react-router-dom';
import { H4, Label } from '../../../components/Typography/Typography';
import ItemTable from './ItemTable';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';

const EditRecipe = ({ recipe }: { recipe?: Instance<typeof Recipe> }) => {
  const { updateRecipe, createRecipe, getRecipes } = useRecipes();
  const successToast = useSuccessToast();
  const history = useHistory();
  const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);

  const [editableRecipe, setEditableRecipe] =
    useState<Instance<typeof Recipe> | undefined>();

  useEffect(() => {
    if (recipe) {
      setEditableRecipe(recipe);
    } else {
      setEditableRecipe(Recipe.create({ id: '', name: '', unit: 'kg' }));
    }
  }, [recipe]);

  const isNew = !recipe || recipe.id === '';

  if (!editableRecipe) {
    return <></>;
  }

  const handleSave = () => {
    setSaveButtonIsLoading(true);
    if (isNew) {
      console.log(
        'Creating editableRecipe.formState',
        editableRecipe.formState,
      );
      createRecipe(editableRecipe.formState).then((recipeData: any) => {
        getRecipes().then(() => {
          setSaveButtonIsLoading(false);
          successToast({
            description: `New recipe "${recipeData.name}" has been created.`,
          });
          history.push(`/recipes/${recipeData.id}`);
        });
      });
    } else {
      console.log('Updating recipe.formState', recipe.formState);
      updateRecipe(editableRecipe, editableRecipe.formState).then(
        (recipeData: any) => {
          getRecipes().then(() => {
            setSaveButtonIsLoading(false);
            successToast({
              description: `Recipe "${recipeData.name}" has been updated.`,
            });
            history.push(`/recipes/${recipeData.id}`);
          });
        },
      );
    }
  };

  return (
    <DetailView
      leftActions={<H4 my="3">{recipe ? recipe.name : 'New recipe'}</H4>}
      rightActions={
        <>
          <Button
            size="sm"
            variant="secondary"
            colorScheme="green"
            onClick={() => {
              window.location.href = `/recipes${
                recipe && !isNew ? `/${recipe.id}` : ''
              }`;
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="sm"
            variant="primary"
            colorScheme="green"
            isLoading={saveButtonIsLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </>
      }
    >
      <VStack width="full" align="left" spacing="8" my="10">
        <FormFieldWrapper fieldName="name">
          <Input
            type="text"
            value={editableRecipe.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              editableRecipe.setName(e.target.value)
            }
          />
        </FormFieldWrapper>
        <FormFieldWrapper fieldName="unit">
          <Select
            onChange={(e: ChangeEvent) => {
              const target = e.target as HTMLInputElement;
              // check if e.target.value is kg or L
              if (target.value === 'kg' || target.value === 'L') {
                editableRecipe.setUnit(target.value);
              }
            }}
            name="unit"
            value={editableRecipe.unit}
          >
            <option value="-" disabled>
              Please select
            </option>
            <option value="kg">kg</option>
            <option value="L">L</option>
          </Select>
        </FormFieldWrapper>
        <FormFieldWrapper fieldName="size">
          {editableRecipe.totalQuantity.toFixed(4)} {editableRecipe.unit}
        </FormFieldWrapper>
        {editableRecipe && (
          <ItemTable recipe={editableRecipe} isEditable={true} />
        )}
      </VStack>
    </DetailView>
  );
};

export default observer(EditRecipe);
