import { types } from 'mobx-state-tree';

const AccountingProduct = types
  .model('NewOne', {
    id: types.number,
    connector_type: types.string,
    connector_unique_id: types.string,
    name: types.string,
    product_code: types.string,
    syncing_enabled: types.boolean,
    sync_error: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setId(newId: number) {
      self.id = newId;
    },
  }));

export default AccountingProduct;
