import React from 'react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

import Tag from '../Tag/Tag';
import { TagProps } from '@chakra-ui/react';

const InactiveTag = ({ tooltip = true, ...tagProps }: { tooltip?: boolean } & TagProps) => (
  <Tag
    bg="red.200"
    color="red.900"
    tooltip={
      tooltip
        ? 'This product is inactive. It will still be added to orders'
        : undefined
    }
    tooltipIcon={<QuestionOutlineIcon />}
    size="sm"
    {...tagProps}
  >
    Inactive
  </Tag>
);

export default InactiveTag;
