import { AuthProvider } from './auth';
import { CurrentCompanyProvider } from './currentCompany';
import { AccountingProvider } from './accounting';
import { DeliveryProvider } from './delivery';
import { BatchOrdersProvider } from './batchOrders';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import {
  DndProvider,
  MouseTransition,
  TouchTransition,
} from 'react-dnd-multi-backend';

import { StoreProvider } from './store';
import DragAndDropPreview from './dragAndDropPreview';

export const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

function AppProviders({ children }: any) {
  return (
    <AuthProvider>
      <CurrentCompanyProvider>
        <AccountingProvider>
          <StoreProvider>
            <DeliveryProvider>
              <DndProvider options={HTML5toTouch}>
                <BatchOrdersProvider>{children}</BatchOrdersProvider>
                <DragAndDropPreview />
              </DndProvider>
            </DeliveryProvider>
          </StoreProvider>
        </AccountingProvider>
      </CurrentCompanyProvider>
    </AuthProvider>
  );
}
export default AppProviders;
