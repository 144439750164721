import { useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  ButtonGroup,
  HStack,
  Tooltip,
  IconButton,
  Flex,
} from '@chakra-ui/react';

import { useSourceCategories } from '../../../hooks/useStores';
import { Body2, Footnote } from '../../../components/Typography/Typography';
import { ArrowUpDownIcon, PlusIcon } from '../../../components/Icons/IconsNew';
import ListView from '../../../components/ListView/ListView';
import { pluralizeString } from '../../../utils';
import { useState } from 'react';
import ListRow from '../../../components/ListRow/ListRow';
import SourceCategoryRow from './SourceCategoryRow';
import { HamburgerIcon } from '@chakra-ui/icons';

const UnassignedRow = observer(({ count, isSorting, ...rest }: any) => {
  const history = useHistory();
  const match = useRouteMatch('/source-categories/unassigned');
  return (
    <ListRow
      isActive={false}
      isCurrent={match}
      onClick={() => history.push('/source-categories/unassigned')}
      py="18px"
      {...rest}
    >
      {isSorting && (
        <IconButton
          icon={<HamburgerIcon />}
          aria-label="Drag"
          isDisabled={true}
        />
      )}
      <Flex
        flex="1"
        justifyContent="space-between"
        direction={{ base: 'column', xl: 'row' }}
      >
        <Body2>Unassigned</Body2>
        <Footnote color="gray.500">
          {count} {pluralizeString('product', count)}
        </Footnote>
      </Flex>
    </ListRow>
  );
});

const SourceCategoriesList = () => {
  const history = useHistory();
  const {
    pagination: { totalCount },
    isLoading,
    unassignedProductsCount,
    sourceCategories,
    updateSortOrder,
    moveSourceCategory,
    findSourceCategoryById,
  } = useSourceCategories();
  const updateSortOrders = () => {
    sourceCategories.forEach((sourceCategory, index) => {
      sourceCategory.setSortOrder(index);
    });
    updateSortOrder();
  };

  const [isSorting, setIsSorting] = useState(false);
  const toggleSorting = () => setIsSorting(!isSorting);

  return (
    <ListView
      isLoading={isLoading}
      isEmpty={!isLoading && totalCount === 0}
      actionChildren={
        <HStack mx="24px" width="100%" justify="space-between">
          <Footnote>
            {sourceCategories.length}{' '}
            {pluralizeString('category', sourceCategories.length, 'categories')}
          </Footnote>
          <ButtonGroup variant="ghost" spacing="1">
            <Tooltip label={isSorting ? 'Stop sorting' : 'Start sorting'}>
              <IconButton
                onClick={toggleSorting}
                icon={<ArrowUpDownIcon width="24px" />}
                aria-label="Toggle sorting"
              />
            </Tooltip>
            <Tooltip label="Add new source category">
              <IconButton
                onClick={() => history.push('/source-categories/new')}
                icon={<PlusIcon width="24px" />}
                aria-label="Add new source category"
              />
            </Tooltip>
          </ButtonGroup>
        </HStack>
      }
      items={sourceCategories}
      renderItem={({ item: sourceCategory, extraProps }) => (
        <SourceCategoryRow
          key={`row-${sourceCategory.id}`}
          sourceCategory={sourceCategory}
          onDrop={updateSortOrders}
          findItem={findSourceCategoryById}
          moveItem={moveSourceCategory}
          canSort={isSorting}
          {...extraProps}
        />
      )}
      lastItem={(extraProps) => (
        <UnassignedRow
          count={unassignedProductsCount}
          isSorting={isSorting}
          {...extraProps}
        />
      )}
    />
  );
};

export default observer(SourceCategoriesList);
