import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { useCurrentUser } from '../../../hooks/useStores';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import ShowCompanyProfile from './ShowCompanyProfile';
import EditCompanyProfile from './EditCompanyProfile';

const CompanyProfileDetail = (): JSX.Element => {
  const { company } = useCurrentUser();

  return !company ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/profile/edit">
        <EditCompanyProfile company={company} />
      </Route>
      <Route path="/profile">
        <ShowCompanyProfile company={company} />
      </Route>
    </Switch>
  );
};

export default observer(CompanyProfileDetail);
