import { useState } from 'react';
import {
  ButtonGroup,
  FormControl,
  FormLabel,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import imageUrl from '../../../../images/cin7-core-logo.jpg';
import { Button } from '../../../../components/Button/Button';
import IntegrationListItemWrapper from '../IntegrationListItemWrapper';
import useAPI from '../../../../hooks/useAPI';
import { useSuccessToast, useErrorToast } from '../../../../components/toast';
import { useAccounting } from '../../../../contexts/accounting';

const Cin7CoreIntegrationListItem = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getCurrentAccountingConnection } = useAccounting();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const [accountId, setAccountId] = useState('');
  const [key, setKey] = useState('');

  const [callAPIPost, submitIsLoading] = useAPI({
    method: 'POST',
  });

  function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    callAPIPost('/v4/accounting_connectors/cin7_core/connect', {
      body: JSON.stringify({
        account_id: accountId,
        key: key,
      }),
    })
      .then(() => {
        successToast({
          description:
            'Cin7 Core is successfully set up and ready to receive Invoices.',
        });
        getCurrentAccountingConnection(() => {
          onClose();
        });
      })
      .catch(async (response: Response) => {
        const errorTitle = 'Error setting up Cin7 Core';
        if (response.status === 422) {
          const data = await response.json();
          errorToast({
            title: errorTitle,
            description: data.error,
          });
        } else {
          errorToast({
            title: errorTitle,
            description: 'The provided credentials are invalid.',
          });
        }
      });
  }

  return (
    <IntegrationListItemWrapper
      logoImageUrl={imageUrl}
      platformName="Cin7 Core"
    >
      <Button variant="primary" onClick={onOpen}>
        Connect
      </Button>

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalHeader>Connect to your Cin7 Core account</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack alignItems={'left'} spacing="8">
                <Text pb="2">
                  Go to the{' '}
                  <Link
                    isExternal
                    href="https://inventory.dearsystems.com/ExternalApi"
                  >
                    External API
                  </Link>{' '}
                  page of Cin7 Core. Click the plus button, giving the new
                  application a name (we suggest "HospoConnect"). Copy the{' '}
                  <strong>Account ID</strong> field into the Account ID field
                  below, and the <strong>Key</strong> field into the Key field
                  below.
                </Text>

                <FormControl id="c7-account-id">
                  <FormLabel>Account ID</FormLabel>
                  <Input
                    name="c7-account-id"
                    tabIndex={1}
                    autoComplete="off"
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                  />
                </FormControl>
                <FormControl id="c7-key">
                  <FormLabel>Key</FormLabel>
                  <Input
                    name="c7-key"
                    tabIndex={1}
                    autoComplete="off"
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                  />
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup colorScheme="green">
                <Button
                  variant="tertiary"
                  onClick={onClose}
                  isDisabled={submitIsLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  isLoading={submitIsLoading}
                  tabIndex={6}
                  isDisabled={accountId === '' || key === ''}
                  type="submit"
                >
                  Continue
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </IntegrationListItemWrapper>
  );
};

export default Cin7CoreIntegrationListItem;
