import {
  Box,
  HStack,
  VStack,
  ThemingProps,
  ResponsiveValue,
  useMultiStyleConfig,
  IconButton,
  Button,
  ButtonGroup,
} from '@chakra-ui/react';
import { Footnote, Subhead } from '../Typography/Typography';
import { BoxProps, Spacer } from '@chakra-ui/layout';
import { Fragment, ReactElement, ReactNode } from 'react';
import { CloseIcon } from '../Icons/IconsNew';
import { isString, isUndefined } from 'lodash';

export interface BannerProps extends BoxProps, ThemingProps<'Banner'> {
  title?: string;
  description: ReactNode;
  icon?: ReactNode;
  status?: ResponsiveValue<string>;
  onDismiss?: () => void;
  actions?: Array<ReactElement<typeof Button>>;
}

const Banner = ({
  title,
  description,
  icon,
  variant,
  status,
  actions,
  onDismiss,
  ...props
}: BannerProps): JSX.Element => {
  const styles = useMultiStyleConfig('Banner', { status, variant });
  const buttonScheme = () => {
    if (status === 'success') {
      return 'green';
    } else if (status === 'info') {
      return 'blue';
    } else {
      return 'gray';
    }
  };
  return (
    <Box __css={styles.banner} {...props}>
      <HStack spacing="8px" alignItems="flex-start">
        <Box __css={styles.icon}>{icon}</Box>
        <VStack alignItems="left" spacing="1">
          {title && <Subhead fontWeight="600">{title}</Subhead>}
          {isString(description) ? (
            <Footnote>{description}</Footnote>
          ) : (
            description
          )}
          {actions && (
            <ButtonGroup
              pt="10px"
              size="sm"
              spacing="24px"
              colorScheme={buttonScheme()}
            >
              {actions.map((action, index) => (
                <Fragment key={index}>{action}</Fragment>
              ))}
            </ButtonGroup>
          )}
        </VStack>
        {!isUndefined(onDismiss) && (
          <>
            <Spacer />
            <IconButton
              __css={styles.dismiss}
              aria-label="Dismiss"
              icon={<CloseIcon width="24px" />}
              isDisabled={false}
              onClick={onDismiss}
            />
          </>
        )}
      </HStack>
    </Box>
  );
};

export default Banner;
