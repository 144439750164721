import React from 'react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

import Tag from '../Tag/Tag';

const UnlinkedTag = ({ tooltip = true }: { tooltip?: boolean }) => (
  <Tag
    mt="0.5"
    tooltip={
      tooltip
        ? 'This profile was added by you or another staff member. It is not used by the customer.'
        : undefined
    }
    tooltipIcon={<QuestionOutlineIcon />}
  >
    Unlinked
  </Tag>
);

export default UnlinkedTag;
