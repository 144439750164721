import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import CustomersPageActions from './CustomersPageActions';
import CustomerDetail from './Detail/CustomerDetail';
import CustomersList from './List/CustomersList';

const CustomersPage = ({
  match: {
    params: { customerId },
  },
}: RouteComponentProps<{ customerId: string }>): JSX.Element => {
  return (
    <AppWrapper>
      <TwoUpWrapper
        headerChildren={<CustomersPageActions />}
        leftChildren={<CustomersList />}
        rightChildren={
          <>
            <CustomerDetail customerId={customerId} />
          </>
        }
      />
    </AppWrapper>
  );
};

export default observer(CustomersPage);
