import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { useStandingOrders } from '../../../hooks/useStores';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import EditStandingOrder from './EditStandingOrder';
import ShowStandingOrder from './ShowStandingOrder';
import CreateNow from './CreateNow';
import SelectCompanyModal from './SelectCompanyModal';

const StandingOrderDetail = ({
  standingOrderId = null,
  deliveryDate = null,
}: {
  standingOrderId: string | null;
  deliveryDate: string | null;
}): JSX.Element => {
  const { currentStandingOrder, getStandingOrder, setCurrentStandingOrder } =
    useStandingOrders();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (standingOrderId) {
      getStandingOrder(standingOrderId, () => {
        setLoading(false);
      });
    } else {
      setCurrentStandingOrder(undefined);
      setLoading(false);
    }
  }, [standingOrderId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/standing-orders/new">
        {!currentStandingOrder ? (
          <>
            <EmptyDetail />
            <SelectCompanyModal />
          </>
        ) : (
          <EditStandingOrder standingOrder={currentStandingOrder} />
        )}
      </Route>
      <Route path="/standing-orders/:standingOrderId/create-now/:deliveryDate">
        {currentStandingOrder && deliveryDate && (
          <CreateNow
            standingOrder={currentStandingOrder}
            deliveryDate={deliveryDate}
          />
        )}
      </Route>
      <Route path="/standing-orders/:standingOrderId/edit">
        {currentStandingOrder && (
          <EditStandingOrder standingOrder={currentStandingOrder} />
        )}
      </Route>
      <Route path="/standing-orders/:standingOrderId">
        {currentStandingOrder && (
          <ShowStandingOrder standingOrder={currentStandingOrder} />
        )}
      </Route>
      <Route path="/standing-orders">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(StandingOrderDetail);
