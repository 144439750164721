import { types, Instance, destroy, getParentOfType, hasParentOfType } from 'mobx-state-tree';
import Order from './Order';

import Product from './Product';
import Unit from './Unit';

const OrderItem = types
  .model('OrderItem', {
    id: types.maybeNull(types.number),
    _destroy: types.maybeNull(types.boolean),
    isValid: types.maybeNull(types.boolean),
    is_added: types.boolean,
    initial_amount: types.maybeNull(types.number),
    amount: types.maybeNull(types.number),
    notes: types.maybeNull(types.string),
    supplier_note: types.maybeNull(types.string),
    initial_unit: types.maybeNull(Unit),
    unit: types.maybeNull(Unit),
    price: types.maybeNull(types.number),
    pricing_unit: types.maybeNull(Unit),
    buyable: types.model({
      type: types.string,
      product: Product,
    }),
    isOrderItem: types.optional(types.literal(true), true),
  }).views((self) => ({
    get markedForDeletion() {
      if (self.id && !self.is_added) {
        return self.amount == 0;
      } else {
        return self._destroy;
      }
    },
    get meetsMinimumQuantityRequirements() {
      if (self.unit && self.unit.min_order_quantity && self.amount) {
        if (self.amount < Number(self.unit.min_order_quantity)) {
          return false;
        }
      }
      return true;
    }
  }))
  .actions((self) => ({
    setAmount(newAmount: number) {
      self.amount = newAmount;
    },
    setUnit(newUnit: Instance<typeof Unit>) {
      self.unit = { ...newUnit };
    },
    setNotes(newNotes: string) {
      self.notes = newNotes;
    },
    setSupplierNote(newSupplierNote: string) {
      self.supplier_note = newSupplierNote;
    },
    setProduct(product: Instance<typeof Product>) {
      self.buyable.product = product;
    },
    markForDeletion() {
      self.amount = 0;

      if (self.id && self.is_added) {
        self._destroy = true;
      } else if (!self.id) {
        destroy(self);
      }
    },
    restore() {
      if (self.initial_amount && self.initial_unit) {
        self.amount = self.initial_amount;
        self.unit = self.initial_unit;
      }
      self._destroy = false;
    },
    validate() {
      const parentOrder = hasParentOfType(self, Order) ? getParentOfType(self, Order) : null;

      if (self.markedForDeletion) {
        // We don't care about anything else if this is marked for destruction.
        self.isValid = true;
        return;
      }

      const isOrderSupplierAdded = parentOrder && parentOrder.isSupplierAdded;
      let errors = []
      if (!isOrderSupplierAdded && !self.meetsMinimumQuantityRequirements) {
        errors.push('Amount is below the minimum order quantity');
      }
      if (self.is_added && !self.amount) {
        errors.push('Must enter an amount');
      }

      self.isValid = errors.length == 0;
    },
  }));

export default OrderItem;
