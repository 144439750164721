import { Button } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Banner from '../Banner/Banner';
import { WarningFillIcon } from '../Icons/IconsNew';

export type ErrorMessageProps = {
  title?: string;
  description: ReactNode;
  resolveText?: string;
  onResolve?: () => void | null;
  onDismiss?: () => void;
  style?: any;
};

const ErrorMessage = ({
  title,
  description,
  resolveText,
  onResolve,
  onDismiss,
}: ErrorMessageProps): JSX.Element => {
  return (
    <Banner
      status="error"
      icon={<WarningFillIcon width="24px" />}
      title={title}
      description={description}
      onDismiss={onDismiss}
      actions={
        onResolve && [
          <Button variant="tertiary" fontSize="sm" onClick={onResolve}>
            {resolveText ? resolveText : 'Resolve'}
          </Button>,
        ]
      }
    />
  );
};

export default ErrorMessage;
