import { Dispatch, SetStateAction, useState } from 'react';

const useNavState = (): [
  string,
  string,
  Dispatch<SetStateAction<string>>,
  () => void,
] => {
  const slugs = {
    orders: ['orders'],
    products: ['products'],
    customers: ['customers'],
    'standing-orders': ['standing-orders'],
    settings: ['integrations', 'delivery-days', 'source-categories', 'customer-group-sets', 'product-group-sets', 'pricing-tiers'],
  };

  const getOpenNav = () => {
    const matches = window.location.pathname.match(/^\/([\w-]*)\/?/);
    const match = matches ? matches[1] : '';
    let openNav = '';
    (Object.keys(slugs) as (keyof typeof slugs)[]).forEach((key) => {
      const s = slugs[key];
      if (s.includes(match)) {
        openNav = key;
      }
    });
    return openNav;
  };
  const openNav = getOpenNav();
  const [selectedNav, setSelectedNav] = useState(openNav);
  const resetSelectedNav = () => setSelectedNav(openNav);

  return [openNav, selectedNav, setSelectedNav, resetSelectedNav];
};

export default useNavState;
