import React, { useState } from 'react';
import { useAuth } from '../contexts/auth';
import * as Sentry from '@sentry/browser';
import { xClientHeaders } from '../utils';

type DeliveryRules = {
  delivery_rules_enabled: boolean;
  delivery_rules: {
    [index: number]: {
      enabled: boolean;
      cutoff_time: string;
      cutoff_day: number;
    };
  };
  delivery_rules_message?: string;
};

type Context = {
  deliveryData?: DeliveryRules;
  setDeliveryData: (value: any) => void;
  deliveryDataError: boolean;
  setDeliveryDataError: (value: any) => void;
  getDeliveryData: (path: string, user: any) => void;
  updateDeliveryData: (
    formData: DeliveryRules,
    successCallback: () => any,
    errorCallback: () => any,
  ) => void;
  DeliveryProvider: () => void;
};

const DeliveryContext = React.createContext<Context>({
  deliveryData: undefined,
  setDeliveryData: () => {},
  deliveryDataError: false,
  setDeliveryDataError: () => {},
  getDeliveryData: () => {},
  updateDeliveryData: () => {},
  DeliveryProvider: () => {},
});

const api_url = `${process.env.REACT_APP_API_URL}/api`;

function DeliveryProvider(props: any) {
  const { user } = useAuth();
  const [deliveryData, setDeliveryData] = useState(undefined);
  const [deliveryDataError, setDeliveryDataError] = useState(false);

  const getDeliveryData = (path: any, user: any) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
      };

      const url = api_url + path;

      fetch(url, requestOptions)
        .then(async (response) => {
          if (response.status === 500) {
            setDeliveryDataError(true);
          } else {
            if (response.ok) {
              const data = await response.json();
              setDeliveryData(data);
              return data;
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          return error;
        });
    }
  };

  const updateDeliveryData = (
    formData: DeliveryRules,
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;

      const { delivery_rules_enabled, delivery_rules_message, delivery_rules } =
        formData;

      const requestOptions = {
        method: 'PUT',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
        body: JSON.stringify({
          delivery_rules_enabled,
          delivery_rules_message,
          delivery_rules,
        }),
      };

      const url = api_url + `/v3/companies/${user.company.id}`;
      fetch(url, requestOptions)
        .then(async (response) => {
          const data = await response;
          if (response.status !== 200) {
            errorCallback();
          } else {
            if (response.ok) {
              successCallback();
              return { data };
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          errorCallback();
          return error;
        });
    }
    return;
  };

  return (
    <DeliveryContext.Provider
      value={{
        deliveryData,
        setDeliveryData,
        deliveryDataError,
        setDeliveryDataError,
        getDeliveryData,
        updateDeliveryData,
      }}
      {...props}
    />
  );
}

const useDelivery = () => React.useContext(DeliveryContext);

export { DeliveryProvider, useDelivery };
