import { Flex, IconButton, Slide, useBreakpointValue } from '@chakra-ui/react';
import { Ref, RefObject, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { CloseIcon } from '../../../components/Icons/IconsNew';
import { useOrders } from '../../../hooks/useStores';
import { shouldPreventOnClick } from '../../../utils';
import OrderDetail from '../Detail/OrderDetail';

const OrderDetailPanel = ({
  orderId,
  dataGridRef,
}: {
  orderId: string;
  dataGridRef: RefObject<HTMLDivElement>;
}) => {
  const history = useHistory();
  const { setCurrentOrder } = useOrders();
  const isOpen = !!orderId;
  const onClose = () => {
    setCurrentOrder(undefined);
    history.push('/orders');
  };
  const selfRef = useRef<HTMLDivElement>(null);
  const width = useBreakpointValue({ base: 'calc(100% - 96px)', xl: '60%' });

  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      if (dataGridRef.current && selfRef.current) {
        let shouldClose = true;
        const target = e.target as Node;

        const inDataGrid = dataGridRef.current.contains(target);
        const inSelf = selfRef.current.contains(target);

        if (inDataGrid || inSelf) {
          shouldClose = false;
        }

        if (shouldClose) {
          const portals = document.getElementsByClassName('chakra-portal');
          Array.from(portals).forEach((el) => {
            if (el.contains(target)) {
              shouldClose = false;
            }
          });
        }

        if (shouldClose) {
          onClose();
        }
      }
    };

    document.addEventListener('mousedown', onClick);
    return () => document.removeEventListener('mousedown', onClick);
  }, [dataGridRef]);

  return (
    <Slide
      in={isOpen}
      ref={selfRef}
      style={{
        zIndex: 10,
        width: width,
        position: 'fixed',
        right: '0',
      }}
      direction="right"
    >
      <Flex
        bg="white"
        borderRight="1px"
        borderColor="gray.200"
        p={4}
        height="100vh"
        overflowY="auto"
        borderRadius="24px 0 0 24px"
        boxShadow="lg"
        width="100%"
        position="fixed"
        right="0"
      >
        <OrderDetail
          orderId={orderId}
          extraActions={
            <IconButton
              aria-label=" "
              icon={<CloseIcon width="24px" />}
              onClick={onClose}
              variant="ghost"
            />
          }
        />
      </Flex>
    </Slide>
  );
};

export default OrderDetailPanel;
