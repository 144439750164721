import { ForwardedRef } from 'react';
import { Field } from 'formik';

import {
  Tr,
  Td,
  Tooltip,
  FormControl,
  FormErrorMessage,
  IconButton,
  forwardRef,
  Table,
  Tbody,
} from '@chakra-ui/react';

import { CloseIcon, MenuIcon } from '../../../components/Icons/IconsNew';
import useDragAndDrop from '../../../hooks/useDragAndDrop';
import { DRAG_AND_DROP_TYPES } from '../../../constants';

interface EditProductRowProps {
  product: any;
  index: number;
  productErrorIds: number[];
  findItem: (id: string) => any;
  moveItem: (id: string, newIndex: number) => void;
  onDrop: (item: any) => void;
  removeProductErrorId: (id: number) => void;
}

const ForwardTr = forwardRef((props: any, ref: ForwardedRef<any>) => (
  <Tr ref={ref} {...props} />
));

const EditProductRow = ({
  product,
  index,
  productErrorIds,
  moveItem,
  findItem,
  onDrop,
  removeProductErrorId,
}: EditProductRowProps) => {
  const { opacity, handlerId, handleRef, dragRef, dropRef, ref } =
    useDragAndDrop<HTMLTableRowElement>({
      id: product.key,
      type: DRAG_AND_DROP_TYPES.CUSTOMER_GROUP_CUSTOMER_ROW,
      moveItem,
      findItem,
      onDrop,
      item: {
        name: product.name,
      },
    });

  const hasError = productErrorIds.includes(product.product_id);

  return product._destroy ? (
    <></>
  ) : (
    <Field name={`product_product_groups_attributes.${index}`}>
      {({ form, meta }: any) => {
        const showError = (!!meta.error && meta.touched) || hasError;
        return (
          <ForwardTr
            data-handler-id={handlerId}
            ref={dragRef(dropRef(ref))}
            opacity={opacity}
            backgroundColor={showError ? 'red.50' : 'inherit'}
          >
            <Td
              borderLeftWidth={showError ? '4px' : 'inherit'}
              borderLeftStyle={showError ? 'solid' : 'inherit'}
              borderLeftColor={showError ? 'red.500' : 'inherit'}
            >
              <FormControl isInvalid={showError}>
                <IconButton
                  ref={handleRef}
                  icon={<MenuIcon width="24px" />}
                  aria-label="Drag"
                  marginRight="4"
                />
                {product.name}
                <FormErrorMessage>
                  {meta.error ? meta.error : ''}
                </FormErrorMessage>
              </FormControl>
            </Td>
            <Td textAlign="end">
              <Tooltip label="Remove">
                <IconButton
                  aria-label="Remove"
                  colorScheme="red"
                  color="red.600"
                  _hover={{
                    backgroundColor: 'red.100',
                  }}
                  variant="ghost"
                  size="sm"
                  icon={<CloseIcon width="24px" />}
                  onClick={() => {
                    form.setFieldValue(
                      `product_product_groups_attributes.${index}._destroy`,
                      true,
                    );
                    removeProductErrorId(product.product_id);
                  }}
                />
              </Tooltip>
            </Td>
          </ForwardTr>
        );
      }}
    </Field>
  );
};

export const EditProductRowPreview = forwardRef(({ item, style }: any, ref) => {
  return (
    <div
      ref={ref}
      style={{
        ...style,
        width: item.dimensions.width,
        height: item.dimensions.height,
      }}
    >
      <Table>
        <Tbody>
          <Tr opacity={0.5} onClick={() => {}} {...item.extraProps}>
            <Td>
              <IconButton
                icon={<MenuIcon width="24px" />}
                aria-label="Drag"
                marginRight="4"
              />
              {item.name}
            </Td>
            <Td textAlign="end">
              <IconButton
                aria-label="Remove"
                colorScheme="red"
                color="red.600"
                variant="ghost"
                size="sm"
                icon={<CloseIcon width="24px" />}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
});

export default EditProductRow;
