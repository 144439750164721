import { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { IconButton, Tooltip } from '@chakra-ui/react';

import { useOrders } from '../../../hooks/useStores';
import { useBatchOrders } from '../../../contexts/batchOrders';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { useSuccessToast, useErrorToast } from '../../../components/toast';
import { RubbishIcon } from '../../../components/Icons/IconsNew';

const OrderDeleteAction = (): JSX.Element => {
  const { currentOrder } = useOrders();
  const { deleteBatchOrders } = useBatchOrders();
  const history = useHistory();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  let [buttonIsLoading, setButtonIsLoading] = useState(false);
  let [modalIsOpen, setModalIsOpen] = useState(false);

  const onConfirm = () => {
    setButtonIsLoading(true);
    setModalIsOpen(false);
    deleteBatchOrders([currentOrder.id], onSuccess, onFailure);
  };

  const onSuccess = () => {
    setButtonIsLoading(false);
    successToast({
      description: `The order has been deleted.`,
    });
    history.push('/orders');
  };

  const onFailure = () => {
    setButtonIsLoading(false);
    errorToast();
  };

  return (
    <>
      <Tooltip label="Delete order">
        <IconButton
          aria-label="Delete"
          onClick={() => setModalIsOpen(true)}
          icon={<RubbishIcon width="24px" height="24px" />}
          isLoading={buttonIsLoading}
        >
          Delete
        </IconButton>
      </Tooltip>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        titleText={'Are you sure you want to delete this order?'}
        confirmButtonText={'Delete this order'}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default observer(OrderDeleteAction);
