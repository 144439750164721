import { Center, Spinner } from '@chakra-ui/react';

function PageLoadSpinner() {
  return (
    <Center height="50%">
      <Spinner thickness="6px" size="xl" color="green" />
    </Center>
  );
}

export default PageLoadSpinner;
