import { action, computed, makeObservable, observable, remove } from 'mobx';
import * as Sentry from '@sentry/browser';
import update from 'immutability-helper';

import { api } from '../api';

import {
  CustomerGroupsStore,
} from '../stores/customerGroupsStore';
import { CustomerGroup, BaseCustomerGroupProps } from './CustomerGroup';
import { isUndefined } from 'lodash';

interface Customer {
  id: number;
  name: string;
  customer_groups: { customer_group_id: number }[];
}

export interface CustomerGroupSetProps {
  id: number;
  name: string;
  supplier_name: string;
  set_type: string;
  customer_groups: BaseCustomerGroupProps[];
  unassigned_customer_count?: number;
}

export class CustomerGroupSet {
  id: number;
  @observable store: CustomerGroupsStore;
  @observable name: string;
  @observable supplierName: string;
  @observable set_type: string;
  @observable _unassignedCustomerCount?: number;
  @observable unassignedCustomers?: Customer[];

  @observable groupsList: { [id: number]: CustomerGroup } = {};
  @observable groupsIndex: number[] = [];

  constructor(store: CustomerGroupsStore, props: CustomerGroupSetProps) {
    this.store = store;

    const {
      id,
      name,
      supplier_name,
      set_type,
    } = props;
    this.id = id;
    this.name = name;
    this.supplierName = supplier_name;
    this.set_type = set_type;

    this.addData(props)

    makeObservable(this);
  }

  @action
  addData = (props: CustomerGroupSetProps) => {
    const {
      name,
      supplier_name,
      customer_groups,
      set_type,
      unassigned_customer_count,
    } = props;

    this.name = name;
    this.supplierName = supplier_name;
    this.set_type = set_type;
    this._unassignedCustomerCount = unassigned_customer_count;

    customer_groups.forEach((group: any, index: number) => {
      if (!this.groupsList[group.id]) {
        this.groupsList[group.id] = new CustomerGroup(this, group);
      } else {
        this.groupsList[group.id].addData(group);
      }
      this.groupsIndex[index] = group.id;
    });
  }

  findCustomerGroupById = (findId: number) => {
    const id = this.groupsIndex.filter((g) => g === findId)[0];
    return {
      item: this.groupsList[id!],
      index: this.groupsIndex.indexOf(id),
    };
  };

  @computed
  get indexUri(): string {
    if (this.set_type === 'pricing_tiers') {
      return '/pricing-tiers';
    } else {
      return `/customer-group-sets`;
    }
  }

  @computed
  get baseUri(): string {
    if (this.set_type === 'pricing_tiers') {
      return '/pricing-tiers';
    } else {
      return `/customer-group-sets/${this.id}`;
    }
  }

  @computed
  get unassignedGroupName(): string {
    if (this.set_type === 'pricing_tiers') {
      return 'Default';
    } else {
      return 'Unassigned';
    }
  }

  @computed
  get groups(): CustomerGroup[] {
    return this.groupsIndex.map((key) => {
      return this.groupsList[key];
    });
  }

  @computed
  get unassignedCustomerCount(): number {
    if (!isUndefined(this.unassignedCustomers)) {
      return this.unassignedCustomers.length;
    }
    return this._unassignedCustomerCount || 0;
  }

  @computed
  get customerCount() {
    return this.groups.reduce(
      (accumulator, group) => accumulator + group.customerCount,
      0,
    );
  }

  @action
  moveGroup = (id: number, atIndex: number) => {
    const { index } = this.findCustomerGroupById(id);
    this.groupsIndex = update(this.groupsIndex, {
      $splice: [
        [index, 1],
        [atIndex, 0, id],
      ],
    });
  };

  @action
  private updateData(groupSet: any) {
    this.name = groupSet.name;
  }

  @action
  setUnassignedCustomers = (customers: Customer[]) => {
    this.unassignedCustomers = customers;
  };

  @action
  removeUnassignedCustomer = (customer: Customer) => {
    if (this.unassignedCustomers) {
      this.unassignedCustomers = this.unassignedCustomers.filter(
        (c) => c.id !== customer.id,
      );
    } else if (this._unassignedCustomerCount) {
      this._unassignedCustomerCount--;
    }
  };

  @action
  addUnassignedCustomer = (customer: Customer) => {
    if (this.unassignedCustomers) {
      this.unassignedCustomers.push(customer);
    } else if (this._unassignedCustomerCount) {
      this._unassignedCustomerCount++;
    }
  };

  @action
  getUnassignedCustomers = () => {
    return new Promise<Response>((resolve, reject) => {
      api
        .get(`/v4/customer_group_sets/${this.id}/unassigned_customers`)
        .then(async (response) => {
          const customers = await response.json();
          if (response.ok) {
            this.setUnassignedCustomers(customers);
            resolve(customers);
          }
          reject(customers);
        });
    });
  };

  @action
  createGroup = (formData: any) => {
    return new Promise<Response>((resolve, reject) => {
      api
        .post(
          `/v4/customer_groups`,
          JSON.stringify({
            customer_group: {
              sort_order: 0,
              customer_group_set_id: this.id,
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const group = await response.json();
          if (response.ok) {
            this.groupsList[group.id] = new CustomerGroup(this, group);
            this.groupsIndex.push(group.id);
            group.customers.forEach((customer: any) => {
              this.removeUnassignedCustomer(customer);
            });
            resolve(group);
          }
          reject(group);
        });
    });
  };

  @action
  update = (formData: any) => {
    return new Promise<Response>((resolve, reject) => {
      api
        .put(
          `/v4/customer_group_sets/${this.id}`,
          JSON.stringify({
            customer_group_set: {
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.updateData(data);
            resolve(data);
          }
          reject(data);
        });
    });
  };

  @action
  updateSortOrders = () => {
    this.groupsIndex.forEach((id, index) => {
      this.groupsList[id].setSortOrder(index);
    });
    return new Promise((resolve, reject) => {
      api
        .put(
          `/v4/customer_group_sets/${this.id}`,
          JSON.stringify({
            customer_group_set: {
              customer_groups_attributes: this.groups.map((g) => ({
                id: g.id,
                sort_order: g.sortOrder,
              })),
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action
  delete = () => {
    return new Promise<Response>((resolve, reject) => {
      api
        .delete(`/v4/customer_group_sets/${this.id}`)
        .then(async (response) => {
          if (response.ok) {
            const index = this.store.customerGroupSetsIndex.indexOf(this.id);
            if (index > -1) {
              this.store.pagination.removeFromCount();
              remove(this.store.customerGroupSetsIndex, `${index}`);
              remove(this.store.customerGroupSetsList, `${this.id}`);
              resolve(response);
            }
          }
          reject(response);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };
}
