import logoImage from '../../../../images/cin7-core-logo.jpg';

import DisconnectButton from '../DisconnectButton';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import Settings from '../Settings';
import { Subhead, Caption } from '../../../../components/Typography/Typography';
import SyncStatus from './SyncStatus';

import {
  Image,
  Box,
  HStack,
  Link,
  Spacer,
  Text,
  VStack,
  Divider,
} from '@chakra-ui/react';
import {
  CrossInCircleIcon,
  TickInCircleIcon,
  SettingsIcon,
} from '../../../../components/Icons/Icons';

type Cin7CoreConnectionDetailsProps = {
  connection: any;
};

const Cin7CoreConnectionDetails = ({
  connection,
}: Cin7CoreConnectionDetailsProps): JSX.Element => {
  return (
    <Box bg="white" p="4">
      {connection.connection_status === 'error' && (
        <ErrorMessage
          style={{ gridColumn: '1 / span 14', marginBottom: '16px' }}
          description={`There’s a problem with your Cin7 Core connection.`}
        />
      )}

      <VStack align="left" spacing="4">
        <HStack spacing="3">
          <Image src={logoImage} w="64px"></Image>

          <Box>
            <Subhead fontWeight="600">Cin7 Core</Subhead>
            <Caption>Connected to: {connection.tenant_name}</Caption>
          </Box>

          <Spacer />

          <Box>
            <VStack spacing="3">
              {connection.connection_status === 'active' && (
                <HStack color="green.600" fontWeight="600">
                  <Text>Connected</Text>
                  <TickInCircleIcon />
                </HStack>
              )}
              {connection.connection_status === 'setup_required' && (
                <HStack color="yellow.600" fontWeight="600">
                  <Text>Setup Required</Text>
                  <SettingsIcon />
                </HStack>
              )}
              {connection.connection_status === 'error' && (
                <HStack color="red.500" fontWeight="600">
                  <Text>Error</Text>
                  <CrossInCircleIcon />
                </HStack>
              )}

              <DisconnectButton
                platformName={'Cin7 Core'}
                additionalContent={
                  <Text>
                    We also recommend that you go to the{' '}
                    <Link
                      isExternal
                      href="https://inventory.dearsystems.com/ExternalApi"
                    >
                      External API
                    </Link>{' '}
                    page of Cin7 Core and deactivate the credentials you created
                    for HospoConnect.
                  </Text>
                }
              />
            </VStack>
          </Box>
        </HStack>

        <VStack align="left" spacing="6" maxW="600">
          <Divider />
          <SyncStatus
            connectorType="cin7_core"
            connectorStatus={connection.connection_status}
          />
          <Divider />
          <Settings
            currentSettings={connection.settings}
            accountingConnectionName={connection.label}
          />
        </VStack>
      </VStack>
    </Box>
  );
};

export default Cin7CoreConnectionDetails;
