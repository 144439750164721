import Product from '../../models/Product';
import { Instance } from 'mobx-state-tree';

import { Box, Image } from '@chakra-ui/react';

export type ProductPhotoProps = {
  photo: string | null;
  width?: number | null;
  height?: number | null;
  showPlaceholder?: boolean;
};

const ProductPhoto = ({
  photo,
  width = null,
  height = null,
  showPlaceholder = false,
}: ProductPhotoProps) => {
  const widthValue = width ? `${width}px` : 'inherit';
  const heightValue = height ? `${height}px` : 'inherit';

  if (photo) {
    return (
      <Image
        w={widthValue}
        h={heightValue}
        src={photo}
        borderRadius={'5px'}
      ></Image>
    );
  } else {
    return showPlaceholder ? (
      <Box
        w={widthValue}
        h={heightValue}
        bg="gray.100"
        border="1px solid"
        borderColor={'gray.200'}
        borderRadius={'5px'}
      ></Box>
    ) : (
      <></>
    );
  }
};

export default ProductPhoto;
