import { Box, Link, Text, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import ListView, { ListViewProps } from '../../../components/ListView/ListView';
import OrdersRow, { OrderRowProps } from './OrdersRow';
import OrdersListActions, {
  Action as OrderListAction,
} from './OrdersListActions';
import { Order } from '../../../stores/orders/order';
import { FunctionComponent } from 'react';

type OrdersListProps = {
  pagination: ListViewProps<any>['pagination'];
  orders: (Order | undefined)[];
  isLoading?: boolean;
  clearFilters: () => void;
  errorContainerRef?: any;
  rowComponent: FunctionComponent<OrderRowProps>;
  actions?: OrderListAction[];
  showActionBar?: boolean;
};

const OrdersList = ({
  errorContainerRef,
  orders,
  pagination,
  isLoading,
  clearFilters,
  rowComponent: RowComponent = OrdersRow,
  actions,
  showActionBar = true,
}: OrdersListProps) => {
  return (
    <ListView
      pagination={pagination}
      isLoading={isLoading}
      actionChildren={
        showActionBar ? (
          <OrdersListActions
            errorContainerRef={errorContainerRef}
            actions={actions}
          />
        ) : null
      }
      items={orders}
      renderItem={({ item: order, extraProps }) => (
        <RowComponent
          key={`orders-row-${order.id}`}
          order={order}
          {...extraProps}
        />
      )}
      emptyChildren={
        <Box mt="12" ml="6" maxWidth="360px" opacity={isLoading ? 0.6 : 1}>
          <VStack spacing="4" alignItems="left">
            <Text fontSize="lg" fontWeight="500">
              No orders found
            </Text>
            <Text>
              Use the filters above to view orders by order status, delivery
              date and date received.
            </Text>
            <Link
              fontWeight="400"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear filters
            </Link>
          </VStack>
        </Box>
      }
    />
  );
};

export default observer(OrdersList);
