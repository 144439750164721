import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import RecipeDetail from './Detail/RecipeDetail';
import RecipesList from './List/RecipesList';
import RecipesPageActions from './RecipesPageActions';

const RecipesPage = ({
  match: {
    params: { recipeId },
  },
}: RouteComponentProps<{
  recipeId: string;
}>): JSX.Element => {
  return (
    <AppWrapper>
      <TwoUpWrapper
        headerChildren={<RecipesPageActions />}
        leftChildren={<RecipesList />}
        rightChildren={<RecipeDetail recipeId={recipeId} />}
      />
    </AppWrapper>
  );
};

export default observer(RecipesPage);
