import { defineStyleConfig } from "@chakra-ui/react";

const HiddenScrollBox = defineStyleConfig({
  baseStyle: {
    overflowY: "scroll",
    height: "full",
    '&::-webkit-scrollbar': {
      opacity: 0,
    },
  },
});

export default HiddenScrollBox;
