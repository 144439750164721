import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

type RootRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

const RootRoute = ({ isAuthenticated }: RootRouteProps) => {
  if (isAuthenticated) {
    return <Redirect from="/" to="/orders" />;
  } else {
    return <Redirect from="/" to="/login" />;
  }
};

export default RootRoute;
