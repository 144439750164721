import { useEffect, useState } from 'react';
import OauthIntegrationListItem from './OauthIntegrationListItem';
import ManualIntegrationListItem from './ManualIntegrationListItem';
import UnleashedIntegrationListItem from './Unleashed/UnleashedIntegrationListItem';
import AccredoIntegrationListItem from './Accredo/AccredoIntegrationListItem';
import MountainStreamIntegrationListItem from './MountainStream/MountainStreamIntegrationListItem';
import Cin7CoreIntegrationListItem from './Cin7Core/Cin7CoreIntegrationListItem';

import { useAccounting } from '../../../contexts/accounting';

import xeroImage from '../../../images/xero.png';
import myobImage from '../../../images/myob.png';
import infusionImage from '../../../images/infusion.png';

import InfusionConnectionDetails from './Infusion/InfusionConnectionDetails';
import XeroConnectionDetails from './Xero/XeroConnectionDetails';
import MyobConnectionDetails from './Myob/MyobConnectionDetails';
import UnleashedConnectionDetails from './Unleashed/UnleashedConnectionDetails';
import AccredoConnectionDetails from './Accredo/AccredoConnectionDetails';
import MountainStreamConnectionDetails from './MountainStream/MountainStreamConnectionDetails';
import Cin7CoreConnectionDetails from './Cin7Core/Cin7CoreConnectionDetails';

import { Stack } from '@chakra-ui/layout';

const IntegrationList = () => {
  const { currentAccountingConnection, getCurrentAccountingConnection } =
    useAccounting();
  const [dataIsLoading, setDataIsLoading] = useState(true);

  useEffect(() => {
    getCurrentAccountingConnection(() => {
      setDataIsLoading(false);
    });
  }, []);

  return (
    <>
      {dataIsLoading && <p>Loading connection data...</p>}
      {!dataIsLoading && currentAccountingConnection && (
        <>
          {currentAccountingConnection.key === 'infusion' && (
            <InfusionConnectionDetails
              connection={currentAccountingConnection}
            />
          )}
          {currentAccountingConnection.key === 'xero' && (
            <XeroConnectionDetails connection={currentAccountingConnection} />
          )}
          {currentAccountingConnection.key === 'myob' && (
            <MyobConnectionDetails connection={currentAccountingConnection} />
          )}
          {currentAccountingConnection.key === 'unleashed' && (
            <UnleashedConnectionDetails
              connection={currentAccountingConnection}
            />
          )}
          {currentAccountingConnection.key === 'accredo' && (
            <AccredoConnectionDetails
              connection={currentAccountingConnection}
            />
          )}
          {currentAccountingConnection.key === 'mountain_stream' && (
            <MountainStreamConnectionDetails
              connection={currentAccountingConnection}
            />
          )}
          {currentAccountingConnection.key === 'cin7_core' && (
            <Cin7CoreConnectionDetails
              connection={currentAccountingConnection}
            />
          )}
        </>
      )}
      {!dataIsLoading && !currentAccountingConnection && (
        <Stack spacing="16px">
          <OauthIntegrationListItem
            logoImageUrl={xeroImage}
            platformName="Xero"
            platformKey="xero"
          />
          <OauthIntegrationListItem
            logoImageUrl={myobImage}
            platformName="MYOB"
            platformKey="myob"
          />
          <UnleashedIntegrationListItem />
          <Cin7CoreIntegrationListItem />
          <ManualIntegrationListItem
            logoImageUrl={infusionImage}
            platformName="Infusion"
          />
          <AccredoIntegrationListItem />
          <MountainStreamIntegrationListItem />
        </Stack>
      )}
    </>
  );
};

export default IntegrationList;
