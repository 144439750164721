import { FunctionComponent, ReactNode, RefObject } from 'react';
import { Table, Tbody, Box, VStack, Button } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import OneUpScrollPanel from '../../../components/Layouts/OneUpScrollPanel';
import { Body2, Headline } from '../../../components/Typography/Typography';
import LoadingToast from '../../../components/LoadingToast/LoadingToast';
import { Order } from '../../../stores/orders/order';

interface OrdersDataGridProps {
  dataGridRef: RefObject<HTMLTableElement>;
  orders: Order[];
  header: ReactNode;
  isLoading?: boolean;
  totalCount: number;
  clearFilters: () => void;
  renderItem: FunctionComponent<Order>;
}

const OrdersDataGrid = ({
  dataGridRef,
  orders,
  header,
  isLoading,
  totalCount,
  clearFilters,
  renderItem,
}: OrdersDataGridProps) => {
  return (
    <OneUpScrollPanel>
      <Table
        variant="datagrid"
        bg="background"
        ref={dataGridRef}
        overflowY="auto"
      >
        {header}
        {totalCount !== 0 && (
          <Tbody opacity={isLoading ? 0.6 : 1}>
            {orders.map((order) => renderItem(order))}
          </Tbody>
        )}
      </Table>
      {isLoading && (
        <VStack
          bg="background"
          width="100%"
          flexBasis="100%"
          color="gray.600"
          pt="24"
        >
          <LoadingToast isOpen={isLoading} />
        </VStack>
      )}
      {!isLoading && totalCount === 0 && (
        <VStack
          bg="background"
          width="100%"
          flexBasis="100%"
          color="gray.600"
          pt="24"
        >
          <Box textAlign="center" maxWidth="360px">
            <Headline fontWeight="600" mb="2">
              No orders found
            </Headline>
            <Body2 mb="6">
              Use the filters above to view orders by order status, delivery
              date and date received.
            </Body2>
            <Button
              fontWeight="400"
              fontSize="16px"
              colorScheme="green"
              variant="tertiary"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear filters
            </Button>
          </Box>
        </VStack>
      )}
    </OneUpScrollPanel>
  );
};

export default observer(OrdersDataGrid);
