import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useIngredients } from '../../../hooks/useStores';
import ListView from '../../../components/ListView/ListView';
import IngredientsRow from './IngredientsRow';

const IngredientsList = () => {
  const {
    ingredients,
    dataIsLoading,
    pagination: { totalCount, currentPage, totalPages },
    setCurrentPage,
    clearFilters,
  } = useIngredients();

  return (
    <ListView
      isLoading={dataIsLoading}
      isEmpty={!dataIsLoading && totalCount === 0}
      pagination={{
        handlePageChange: (pageNumber: number) => {
          setCurrentPage(pageNumber);
        },
        currentPage: currentPage,
        pageCount: totalPages || 0,
        totalCount: totalCount || 0,
        itemsPerPage: 20,
        itemsType: 'ingredients',
      }}
      actionChildren={''}
      items={ingredients}
      renderItem={({ item: ingredient, extraProps }) => (
        <IngredientsRow
          key={`ingredients-row-${ingredient.id}`}
          ingredient={ingredient}
          {...extraProps}
        />
      )}
      emptyChildren={
        <Box mt="12" ml="6" maxWidth="360px" opacity={dataIsLoading ? 0.6 : 1}>
          <VStack spacing="4" alignItems="left">
            <Text fontSize="lg" fontWeight="500">
              No ingredients found
            </Text>
            <Link
              fontWeight="400"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear filters
            </Link>
          </VStack>
        </Box>
      }
    />
  );
};

export default observer(IngredientsList);
