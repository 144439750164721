import { Box, Flex, HStack, Tooltip, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Body, Label } from '../../../../components/Typography/Typography';
import { QuestionOutlineIcon } from '@chakra-ui/icons';

export const DetailWrapper = (props: { children: ReactNode }) => {
  return <Flex>{props.children}</Flex>;
};

export const ReadOnlyDetailWrapper = (props: {
  label: string;
  content: string;
  subText?: string;
  children?: ReactNode;
  tooltip?: string;
}) => {
  return (
    <DetailWrapper>
      <Label flex={1} fontWeight="500" minWidth="180px" mr={4}>
        <HStack>
          <Text>{props.label}</Text>

          {props.tooltip && (
            <Tooltip label={props.tooltip} width="200px">
              <QuestionOutlineIcon />
            </Tooltip>
          )}
        </HStack>
      </Label>
      <Box as="span" flex={3}>
        <Body color="gray.700">{props.content}</Body>
        {props.subText && (
          <Body decoration="line-through" color="gray.500" mt={2}>
            {props.subText}
          </Body>
        )}
        {props.children}
      </Box>
    </DetailWrapper>
  );
};

export default DetailWrapper;
