import { useEffect, useState, useCallback } from 'react';
import {
  Text,
  HStack,
  VStack,
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Button as ChakraButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Spinner,
  Link,
} from '@chakra-ui/react';
import { AddIcon, CheckIcon, SearchIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';

import useAPI from '../../hooks/useAPI';
import { useAuth } from '../../contexts/auth';
import { useScroll } from '../../hooks/useScroll';
// import IngredientsListView from '../IngredientModalContent/IngredientsListView';
import { Button } from '../Button/Button';
import { IngredientProps } from '../../models/Ingredient';
import { Caption } from '../Typography/Typography';

const IngredientRow = ({ ingredient, initialIsAdded, onAdd }: any) => {
  const [isAdded, setIsAdded] = useState(initialIsAdded);
  const [hovered, setHovered] = useState(false);

  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <Box w="100%">
        <HStack>
          <Text>{ingredient.name}</Text>
          <Caption color={'gray.500'}>{ingredient.unit}</Caption>
        </HStack>
      </Box>
      {isAdded ? (
        <ChakraButton
          variant="outline"
          width="100px"
          disabled={true}
          leftIcon={<CheckIcon />}
        >
          Added
        </ChakraButton>
      ) : (
        <ChakraButton
          variant="outline"
          width="80px"
          leftIcon={<AddIcon />}
          onClick={() => {
            setIsAdded(true);
            onAdd(ingredient);
          }}
        >
          Add
        </ChakraButton>
      )}
    </HStack>
  );
};

type ingredientsResponse = {
  total_count: number;
  results: IngredientProps[];
};

const AddIngredientModal = ({
  isOpen,
  onClose,
  onAdd,
  addedIngredientsIds,
}: any) => {
  const { user } = useAuth();

  const limit = 20;
  const [page, setPage] = useState(1);
  const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useScroll<HTMLDivElement>(() => {
    setPage(page + 1);
  });

  const [getIngredients] = useAPI({
    method: 'GET',
  });
  const [ingredients, setIngredients] = useState<IngredientProps[]>([]);

  const clearResults = () => {
    setIngredients([]);
    setPage(1);
  };

  const clearFilters = () => {
    clearResults();
    setCurrentQueryFieldValue('');
    setCurrentQuery('');
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      clearResults();
      setCurrentQuery(value);
    }, 400),
    [],
  );

  useEffect(() => {
    const url = `/v4/ingredients?page=${page}&limit=${limit}${
      currentQuery && '&q=' + currentQuery
    }`;
    setIsLoading(true);

    getIngredients(url).then((data: ingredientsResponse) => {
      setResultCount(data.total_count);
      setIngredients((ingredients) => ingredients.concat(data.results));
      setIsLoading(false);
    });
  }, [page, currentQuery, currentCategory]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}} autoFocus={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={0} pb={0}>
          <Text px={6} mt={4} mb={6}>
            Add another ingredient
          </Text>

          <Box
            py={4}
            px={6}
            bg="gray.100"
            boxShadow="inset 0px 1px 0px #D1D5DB, inset 0px -1px 0px #D1D5DB"
          >
            <VStack spacing="3" alignItems="left">
              <InputGroup>
                <Input
                  variant="outline"
                  type="search"
                  onChange={(e) => {
                    setCurrentQueryFieldValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  value={currentQueryFieldValue}
                  placeholder="Search ingredients"
                  name="search"
                  bg="white"
                />
                <InputRightElement
                  pointerEvents="none"
                  color="gray.500"
                  children={<SearchIcon />}
                />
              </InputGroup>
            </VStack>
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          {ingredients.length > 0 ? (
            <Box ref={scrollRef} maxHeight="320px" overflowY="scroll">
              {ingredients.map((ingredient: IngredientProps) => {
                return (
                  <IngredientRow
                    key={ingredient.id}
                    ingredient={ingredient}
                    initialIsAdded={addedIngredientsIds.includes(ingredient.id)}
                    onAdd={onAdd}
                  />
                );
              })}
            </Box>
          ) : (
            !isLoading &&
            resultCount === 0 && (
              <VStack px={6} py={4} spacing="4" alignItems="left">
                <Text fontSize="lg" fontWeight="500">
                  No ingredients found
                </Text>
                <Link fontWeight="400" onClick={clearFilters}>
                  Clear filters
                </Link>
              </VStack>
            )
          )}
          {isLoading && (
            <Center height="100px">
              <Spinner thickness="6px" size="xl" color="green" />
            </Center>
          )}
        </ModalBody>
        <ModalFooter backgroundColor="gray.100" borderRadius="0 0 8px 8px">
          <Button variant="primary" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddIngredientModal;
