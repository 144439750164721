import React, { useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/browser';
import { api } from '../api';

// TODO update 'anys'
type Context = {
  setUser: (user: any) => void;
  setError: () => void;
  setAuthError: (authError: boolean) => void;
  logoutUser: () => void;
  ejectUser: () => void;
  loginUser: (
    payload: any,
    successCallback: (data: any) => any,
    errorCallback: () => any,
  ) => any;
  user: UserData;
  error: boolean;
  authError: boolean;
};

type UserData = {
  id: number;
  authentication_token: string;
  fullname: string;
  avatar_url: string;
  company: {
    id: number;
    name: string;
    logo_url: string;
    account_type: string;
  };
};

const AuthContext = React.createContext<Context>({
  setUser: () => {},
  setError: () => {},
  setAuthError: () => {},
  logoutUser: () => {},
  ejectUser: () => {},
  loginUser: () => {},
  authError: false,
  error: false,
  user: {} as UserData,
});

function AuthProvider(props: any) {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('userInfo') as string),
  );
  const [error, setError] = useState(false);
  const [authError, setAuthError] = useState(false);
  if (user) {
    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: user.id,
        companyId: user.company && user.company.id,
      });
    });
  }

  const loginUser = (
    payload: any,
    successCallback: (data: any) => any,
    errorCallback: () => any,
  ) => {
    api
      .post('/v2/sign_in', JSON.stringify(payload))
      .then(async (response) => {
        setAuthError(false);
        setError(false);
        const data = await response.json();
        if (response.ok) {
          successCallback(data);
          const userData = {
            id: data.id,
            authentication_token: data.authentication_token,
            fullname: data.fullname,
            company: {
              id: data.company.id,
              name: data.company.name,
              logo_url: data.company.logo_url,
              account_type: data.company.account_type,
            },
          };
          localStorage.setItem('userInfo', JSON.stringify(userData));
          setUser(userData);
          return data;
        } else {
          errorCallback();
          return Promise.reject(data);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        setError(true);
        return error;
      });
  };

  const logoutUser = () => {
    if (user) {
      api.delete('/v2/sign_out').then(ejectUser);
    }
  };

  const ejectUser = () => {
    localStorage.removeItem('userInfo');
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loginUser,
        logoutUser,
        error,
        setError,
        authError,
        setAuthError,
        setUser,
        ejectUser,
      }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
