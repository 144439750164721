import { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Input,
  HStack,
  Select,
  InputGroup,
  InputRightElement,
  Switch,
  FormControl,
  FormLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import TabSwitchInput from '../FullTextSwitchInput/TabSwitchInput';
import Banner from '../Banner/Banner';
import { useAuth } from '../../contexts/auth';

const ProductModalHeader = observer(
  ({
    setFilter,
    filterTab,
    setFilterTab = () => {},
    handleSearch,
    filters,
    categories,
  }: {
    setFilter: (id: string, value: any) => void;
    filterTab: string;
    setFilterTab?: (value: string) => void;
    handleSearch: (query: string) => void;
    filters: { [key: string]: any };
    categories?: any[];
  }) => {
    const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
    const { user } = useAuth();
    const isBuyer = user.company.account_type == 'restaurant';

    return (
      <>
        <VStack mb="5" spacing="3" alignItems={'left'}>
          <InputGroup>
            <Input
              variant="outline"
              type="search"
              onChange={(e) => {
                setCurrentQueryFieldValue(e.target.value);
                handleSearch(e.target.value);
              }}
              value={currentQueryFieldValue}
              placeholder="Search products"
              name="search"
              bg="white"
            />
            <InputRightElement
              pointerEvents="none"
              color="gray.500"
              children={<SearchIcon />}
            />
          </InputGroup>
          <TabSwitchInput
            id="filter-tabs"
            onChange={(value) => setFilterTab(value)}
            options={[
              {
                label: 'Categories',
                value: 'categories',
              },
              {
                label: 'Favourites',
                value: 'favourites',
              },
            ]}
          />
          {filters.hasOwnProperty('category_id') && (
            <Select
              onChange={(e) => {
                const value = e.target.value === 'all' ? '' : e.target.value;
                setFilter('category_id', value);
              }}
              name="category"
              value={filters.category_id === '' ? 'all' : filters.category_id}
            >
              <option value="all">All categories</option>
              {categories &&
                categories.map((category: any, i: number) => {
                  return (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </Select>
          )}
          {filterTab == 'favourites' && !isBuyer && (
            <Banner
              description={
                <HStack spacing="1">
                  <Text fontSize="sm" fontWeight="600">
                    Important:
                  </Text>
                  <Text fontSize="sm" fontWeight="400">
                    You and your customers share management of favourites.
                  </Text>
                </HStack>
              }
              status="info"
              variant="outline"
            />
          )}
          {filters.hasOwnProperty('status') && (
            <FormControl>
              <HStack>
                <Switch
                  id="show-inactive-products"
                  position="relative"
                  size="sm"
                  isChecked={filters.status !== 'active'}
                  onChange={(e) => {
                    setFilter('status', e.target.checked ? '' : 'active');
                  }}
                />
                <FormLabel
                  fontSize="xs"
                  fontWeight="500"
                  htmlFor="show-inactive-products"
                  cursor="pointer"
                >
                  Show inactive products
                </FormLabel>
              </HStack>
            </FormControl>
          )}
        </VStack>
      </>
    );
  },
);

export default ProductModalHeader;
