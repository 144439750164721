import { useEffect, useState } from 'react';
import { useAccounting } from '../contexts/accounting';
import useAPI from './useAPI';
import { Customer } from '../stores/customers/customer';
import { useSuccessToast } from '../components/toast';
import { useCustomers } from './useStores';
import useAccountingMeta from './useAccountingMeta';
import useJobStatus from './useJobStatus';

interface MappedContactData {
  mapped_contact_id: string;
  mapped_contact_name: string;
}

const useAccountingContact = (customer: Customer) => {
  const { getCustomer } = useCustomers();
  const { getCurrentAccountingConnection, currentAccountingConnection } =
    useAccounting();
  const accountingMeta = useAccountingMeta(currentAccountingConnection?.key);
  const [mappedContactData, setMappedContactData] =
    useState<MappedContactData | null>(null);
  const successToast = useSuccessToast();
  const [setJobId, isJobProcessing, jobStatus] = useJobStatus(1500, () => {
    getCustomer(customer.id.toString(), () => {
      successToast({
        title: 'Sync complete',
        description: `${customer.name} has been synced.`,
      });
    });
    fetchMappedContactData();
  });

  const [getAPI] = useAPI();
  const [syncNowRequest, syncNowIsLoading] = useAPI({
    method: 'PUT',
  });

  useEffect(() => {
    if (!currentAccountingConnection) {
      getCurrentAccountingConnection(() => {});
    } else {
      fetchMappedContactData();
    }
  }, []);

  useEffect(() => {
    if (currentAccountingConnection) {
      fetchMappedContactData();
    }
  }, [currentAccountingConnection]);

  const fetchMappedContactData = () => {
    getAPI(`/v4/accounting_connectors/contacts/${customer.id}`)
      .then((response: MappedContactData) => {
        setMappedContactData(response);
      })
      .catch((_error) => {
        setMappedContactData(null);
      });
  };

  const unMapContact = () => {
    getAPI(`/v4/accounting_connectors/contacts/${customer.id}`, {
      method: 'DELETE',
    })
      .then(() => {
        successToast({
          description: 'Customer unlinked',
        });
        setMappedContactData(null);
      })
      .catch((_error) => {
        // Do nothing
      });
  };

  const syncNow = () => {
    syncNowRequest('/v4/accounting_connectors/contacts/sync_now', {
      body: JSON.stringify({
        accounting_contact_ids: [currentAccountingConnection.id],
      }),
    })
      .then((data: any) => {
        setJobId(data.jid);
      })
      .catch(() => {});
  };

  return {
    currentAccountingConnection,
    accountingMeta,
    mappedContactData,
    fetchMappedContactData,
    unMapContact,
    syncNow,
    isSyncingNow:
      syncNowIsLoading || (isJobProcessing && jobStatus !== undefined),
  } as const;
};

export default useAccountingContact;
