import firebase from 'firebase/app';
import 'firebase/messaging';

let firebaseConfig = {};
if (window.location.hostname == 'app.hospoconnect.com') {
  firebaseConfig = {
    apiKey: 'AIzaSyDqTOM3iX6IXWck60m4UiYnMzYugPj6kaI',
    authDomain: 'hospoconnect-production.firebaseapp.com',
    projectId: 'hospoconnect-production',
    messagingSenderId: '349496549571',
    appId: '1:349496549571:web:c7c88edb5764b04e440276',
  };
} else {
  firebaseConfig = {
    apiKey: 'AIzaSyCi9FsdO9RkYZOQaBCn2ArFE0rYplI9Cws',
    authDomain: 'hospoconnect-staging.firebaseapp.com',
    projectId: 'hospoconnect-staging',
    messagingSenderId: '112712486643',
    appId: '1:112712486643:web:03e2e95ae1add15bc97186',
  };
}

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : {};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });
