import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Button } from '../../../components/Button/Button';
import IntegrationListItemWrapper from './IntegrationListItemWrapper';

type ManualIntegrationListItemProps = {
  logoImageUrl: string;
  platformName: string;
};

const ManualIntegrationListItem = ({
  logoImageUrl,
  platformName,
}: ManualIntegrationListItemProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <IntegrationListItemWrapper
      logoImageUrl={logoImageUrl}
      platformName={platformName}
    >
      <Button variant="primary" onClick={onOpen}>
        Connect
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{platformName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Connecting your HospoConnect account to {platformName} is a manual
            process. Please contact us to discuss.
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Ok</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </IntegrationListItemWrapper>
  );
};

export default ManualIntegrationListItem;
