const baseStyle = {
  global: {
    body: {
      bg: 'offwhite',
      color: 'gray.900',
    },
  },
};

export default baseStyle;
