import { Box } from '@chakra-ui/react';
import TwoUpRightScrollPanel from '../Layouts/TwoUpRightScrollPanel';

const DetailScrollAreaWrapper = ({ children }: any): JSX.Element => {
  return (
    <TwoUpRightScrollPanel>
      <Box height="100%" px="40px">
        {children}
      </Box>
    </TwoUpRightScrollPanel>
  );
};

export default DetailScrollAreaWrapper;
