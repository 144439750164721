const colors = {
  background: '#F3F4F6',
  gray: {
    1000: '#000000',
    900: '#111827',
    800: '#1F2937',
    700: '#374151',
    600: '#4B5563',
    500: '#6B7280',
    400: '#9CA3AF',
    300: '#D1D5DB',
    200: '#E5E7EB',
    150: '#F0F2F4',
    100: '#F6F7F8',
    50: '#F9FAFB',
    0: '#FFFFFF',
  },
  green: {
    900: '#02311A',
    800: '#034223',
    700: '#046335',
    600: '#0B8843',
    500: '#07A549',
    400: '#47C362',
    300: '#74D88A',
    200: '#AAEBB9',
    100: '#CEF9D8',
    50: '#ECFEF0',
  },
  blue: {
    900: '#1A365D',
    800: '#2A4365',
    700: '#2C5282',
    600: '#2B6CB0',
    500: '#3182CE',
    400: '#4299E1',
    300: '#63B3ED',
    200: '#90CDF4',
    100: '#CAECFE',
    50: '#EBF8FF',
  },
  red: {
    700: '#A41E1E',
    500: '#EA3434',
    100: '#FED7D7',
    50: '#FFF5F5',
  },
  orange: {
    700: '#983816',
    500: '#F37623',
    100: '#FEE1CB',
    50: '#FFF4EB',
  },
  yellow: {
    700: '#984E03',
    500: '#FFC800',
    100: '#FEF0C3',
    50: '#FFFBF0',
  },
  teal: {
    700: '#1A6870',
    500: '#2BB6B3',
    100: '#D2F9F3',
    50: '#F0FFFC',
  },
  purple: {
    700: '#4D3989',
    500: '#8E57E7',
    100: '#EFE2FE',
    50: '#FAF5FF',
  },
  pink: {
    700: '#90236F',
    500: '#E43A91',
    100: '#FEE6F1',
    50: '#FFF5F9',
  },
  error: {
    red: '#DE0101',
    amber: '#F59700',
  },
};

export default colors;

/**
 * All colours being migrated from src/styles/globals.scss

$colour-green: #08b254;
$colour-faintgreen: #dce8dc;
$color-lightgreen: #89dbae;
$colour-mintgreen: #5cb85c;
$colour-darkmintgreen: #45ae45;
$colour-darkgreen: #019c46;
$colour-pastelgreen: #cef9d8;
$colour-orange: #f0ad4e;
$colour-lightorange: #fbcb88;
$colour-brightorange: #ffb9b9;
$colour-white: #ffffff;
$colour-offwhite: #f4f4f4;
$colour-offwhiteSecond: #fbfbfb;
$colour-darkwhite: #eff8ef;
$colour-lightergrey: #eeeeee;
$colour-lightgrey: #e5e5e5;
$colour-medgrey: #cccccc;
$colour-grey: #d8d8d8;
$colour-table-grey: #a9a9a9;
$colour-light-bluegrey: #e5e7eb;
$colour-grayishblue: #4b5563;
$colour-yellow: #fef5a7;

$colour-darkgrey: #555555;
$colour-darkergrey: #313131;
$colour-black: #000000;
$colour-lightblack: #373a3c;
$colour-dark-blueblack: #111827;
$colour-lightred: #fff4f4;
$colour-red: #ffaba7;
$colour-black-overlay: rgba(0, 0, 0, 0.7);
$colour-blue: #0275d8;
$colour-darkerblue: #004fb9;
$colour-skyblue: #1ab4d7;
$colour-brightblue: #a6e9ff;
$colour-red: #d9534f;
$colour-darkred: #a94442;
$colour-darkred: #d1d5db;

// Card
$colour-card-lightgrey: #ebebeb;

$colour-table-grey: #c4c4c4;
$colour-modal-grey: #bfbfbf;

 */
