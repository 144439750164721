import { VStack, StackProps, BoxProps, Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const PricingTiersTable = ({
  containerProps,
  header,
  rows,
}: {
  containerProps?: StackProps;
  header?: ReactNode;
  rows?: ReactNode[];
}) => (
  <VStack
    borderRadius="8px"
    borderWidth="1px"
    borderColor="gray.200"
    borderBottom="none"
    maxW="509px"
    overflow="hidden"
    spacing={0}
    {...containerProps}
  >
    {header && <HeaderRow>{header}</HeaderRow>}
    {rows}
  </VStack>
);

const rowProps: BoxProps = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDir: 'row',
  alignItems: 'center',
  paddingY: '3',
  paddingX: '4',
};

const HeaderRow = (props: BoxProps) => (
  <Box
    {...rowProps}
    backgroundColor="white"
    color="gray.900"
    paddingY={5}
    borderBottomWidth="1px"
    borderColor="gray.200"
    fontSize="sm"
    fontWeight={500}
    {...props}
  />
);

export const PricingTiersTableRow = (props: BoxProps) => (
  <Box
    {...rowProps}
    borderBottomWidth="1px"
    borderColor="gray.200"
    {...props}
  />
);
