import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Avatar, Flex } from '@chakra-ui/react';
import ListRow from '../../../components/ListRow/ListRow';
import { Caption } from '../../../components/Typography/Typography';
import { useCurrentUser } from '../../../hooks/useStores';

const StandingOrdersRow = ({ standingOrder }: any): JSX.Element => {
  let history = useHistory();
  const { isBuyer } = useCurrentUser();

  const otherCompany = isBuyer
    ? standingOrder.supplier
    : standingOrder.customer;

  return (
    <ListRow
      key={`table-row-${standingOrder.id}`}
      isCurrent={standingOrder.isCurrent}
      isChecked={standingOrder.isChecked}
      onClick={() => {
        history.push(`/standing-orders/${standingOrder.id}`);
      }}
      onCheckboxChange={standingOrder.toggleChecked}
    >
      <Flex flex="1" align="center" gap="4">
        <Avatar size="md" src={otherCompany.logo_url} />
        <Flex direction="column" alignItems="flex-start">
          <Caption fontWeight="600" color="gray.900">
            {otherCompany.name}
          </Caption>
        </Flex>
      </Flex>
    </ListRow>
  );
};

export default observer(StandingOrdersRow);
