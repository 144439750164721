import { action, computed, makeObservable, observable } from 'mobx';
import * as Sentry from '@sentry/browser';

import { api } from '../api';
import { PaginationStore } from './paginationStore';

import { ProductGroupSet } from '../models/ProductGroupSet';
import { ProductGroup, ProductGroupProps } from '../models/ProductGroup';

export interface ProductProps {
  id: number;
  name: string;
  product_id: number;
  sort_order: number;
}

export class Product {
  id: number;
  @observable name: string;
  @observable productId: number;
  @observable sortOrder: number;
  @observable group: ProductGroup;

  constructor(group: ProductGroup, props: ProductProps) {
    this.group = group;

    const { id, name, product_id, sort_order } = props;
    this.id = id;
    this.name = name;
    this.productId = product_id;
    this.sortOrder = sort_order;
  }

  @computed
  get toFormInitialValues() {
    return {
      id: this.id,
      key: this.id,
      product_id: this.productId,
      name: this.name,
      sort_order: this.sortOrder,
      _destroy: false,
    };
  }

  @action
  addData = (props: ProductProps) => {
    const { id, name, product_id, sort_order } = props;
    this.id = id;
    this.name = name;
    this.productId = product_id;
    this.sortOrder = sort_order;
  };
}

export class ProductGroupsStore {
  user: any = [];
  @observable productGroupSetsList: { [id: number]: ProductGroupSet } = {};
  @observable productGroupSetsIndex: number[] = [];
  @observable pagination = new PaginationStore();
  @observable currentGroupId?: number;
  @observable currentGroupSetId?: number;

  constructor(user?: any) {
    this.user = user;
    makeObservable(this);
  }

  @computed
  get productGroupSets() {
    return this.productGroupSetsIndex.map((key) => {
      return this.productGroupSetsList[key];
    });
  }

  @computed
  get currentGroup(): any {
    if (this.currentGroupSetId && this.currentGroupId) {
      return this.productGroupSetsList[this.currentGroupSetId].groupsList[
        this.currentGroupId
      ];
    }
  }

  @computed
  get pricingTiersGroupSet(): any {
    return Object.values(this.productGroupSetsList)?.find(
      (group) => group.set_type === 'pricing_tiers',
    );
  }

  @computed
  get currentGroupSet(): any {
    if (this.currentGroupSetId) {
      return this.productGroupSetsList[this.currentGroupSetId];
    }
  }

  @action
  setCurrentGroupSet = (groupSetId: number) => {
    this.currentGroupSetId = groupSetId;
  };

  @action
  setProductGroupSets = (groupSets: any) => {
    this.pagination.setFromResponse(groupSets);
    groupSets.results.forEach((groupSet: any, index: number) => {
      if (!this.productGroupSetsList[groupSet.id]) {
        this.productGroupSetsList[groupSet.id] = new ProductGroupSet(
          this,
          groupSet,
        );
      } else {
        this.productGroupSetsList[groupSet.id].addData(groupSet);
      }
      this.productGroupSetsIndex[index] = groupSet.id;
    });
  };

  @action
  setCurrentGroup = (group?: ProductGroupProps) => {
    if (this.currentGroup) {
      this.currentGroup.setIsCurrent(false);
    }

    if (group) {
      const groupSetObject =
        this.productGroupSetsList[group.product_group_set_id];
      if (groupSetObject) {
        const groupObject = groupSetObject?.groupsList[group.id];
        groupObject.addData(group);
        groupObject.setIsCurrent(true);
      }

      this.currentGroupId = group.id;
      this.currentGroupSetId = group.product_group_set_id;
    } else {
      this.currentGroupId = undefined;
    }
  };

  @action
  addProductGroupSet = (data: any) => {
    const groupSet = new ProductGroupSet(this, data);
    this.productGroupSetsList[groupSet.id] = groupSet;
    this.productGroupSetsIndex[this.productGroupSetsIndex.length] =
      groupSet.id;
  };

  @action
  createProductGroupSet = (formData: any) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          '/v4/product_group_sets/?include_product_count=true',
          JSON.stringify({
            product_group_set: {
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.addProductGroupSet(data);
            this.pagination.addToCount();
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  @action
  getProductGroupSets = (withFullProductGroupData = false, withDeliveryRules = false) => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/v4/product_group_sets?include_product_count=true&include_full_product_groups=${withFullProductGroupData}&include_delivery_rules=${withDeliveryRules}`,
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setProductGroupSets(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  @action
  getProductGroup = (groupId: string) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v4/product_groups/${groupId}`)
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setCurrentGroup(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };
}
