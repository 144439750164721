import { FunctionComponent, useState, useEffect } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import isDeliveryDateAvailable from '../../utils/isDeliveryDateAvailable';
import {
  FormControl,
  FormLabel,
  FormLabelProps,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  StyleProps,
} from '@chakra-ui/react';
import { useDeliveryRules } from '../../hooks/useStores';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './react-date-theme.scss'; // theme css file
import { isEmpty } from 'lodash';

import { Calendar } from 'react-date-range';
import { CaretDownIcon } from '../Icons/IconsNew';

export interface DateInputProps extends StyleProps {
  id: string;
  label: string;
  labelProps: FormLabelProps;
  value?: Date | null;
  onChange: (date: Date) => void;
  fontSize?: string;
  enforceCutoffTimes?: boolean;
  allowPastDates?: boolean;
  isDisabled?: boolean;
}

export type PureDateInputProps = {
  deliveryRulesEnabled?: boolean;
  deliveryRules?: any;
  leadTime?: number;
} & DateInputProps;

const DateInput: FunctionComponent<DateInputProps> = ({ ...props }) => {
  const { deliveryRules, deliveryRulesEnabled, getDeliveryRules } =
    useDeliveryRules();

  useEffect(() => {
    if (isEmpty(deliveryRules)) {
      getDeliveryRules();
    }
  }, []);

  return (
    <PureDateInput
      deliveryRules={deliveryRules}
      deliveryRulesEnabled={deliveryRulesEnabled}
      {...props}
    />
  );
};

export const PureDateInput: FunctionComponent<PureDateInputProps> = ({
  id,
  label,
  labelProps,
  onChange,
  allowPastDates,
  deliveryRules,
  deliveryRulesEnabled,
  enforceCutoffTimes = false,
  leadTime,
  value,
  fontSize = 'md',
  isDisabled = false,
  ...props
}) => {
  const [currentDate, setCurrentDate] = useState(value);

  const shouldUseDeliveryRules =
    deliveryRulesEnabled && deliveryRules !== undefined;

  const minDate = allowPastDates
    ? new Date(new Date().getFullYear() - 1, 0, 1)
    : new Date();
  const maxDate = new Date(new Date().getFullYear() + 1, 11, 31);

  return (
    <Popover placement="bottom-start">
      {({ isOpen, onClose }) => (
        <>
          <FormControl {...props}>
            <FormLabel {...labelProps}>{label}</FormLabel>
            <PopoverTrigger>
              <InputGroup>
                <Input
                  cursor="pointer"
                  placeholder={label}
                  value={
                    currentDate
                      ? format(currentDate, 'EEEE d MMMM y') // Friday 20 May 2022
                      : undefined
                  }
                  isDisabled={isDisabled}
                  onChange={() => {}}
                />
                <InputRightElement
                  pointerEvents="none"
                  children={<CaretDownIcon width="12px" color="gray.700" />}
                ></InputRightElement>
              </InputGroup>
            </PopoverTrigger>
          </FormControl>
          <PopoverContent>
            <Calendar
              date={currentDate ? currentDate : undefined}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(date) => {
                onChange(date);
                setCurrentDate(date);
                onClose();
              }}
              disabledDay={(date: Date) => {
                if (shouldUseDeliveryRules) {
                  if (enforceCutoffTimes) {
                    return !isDeliveryDateAvailable(
                      date,
                      moment(),
                      deliveryRules,
                      deliveryRulesEnabled,
                      leadTime,
                    );
                  } else {
                    return !deliveryRules[date.getDay()]?.enabled;
                  }
                } else {
                  return false;
                }
              }}
              weekdayDisplayFormat="EEEEE"
              weekStartsOn={1}
              color="#07a549" // green.500
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default observer(DateInput);
