import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { useProductGroups } from '../../../hooks/useStores';
import EditProductGroupSetModal from './EditProductGroupSetModal';
import ShowProductGroup from './ShowProductGroup';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EditProductGroup from './EditProductGroup';
import UnassignedProducts from './UnassignedProducts';

const ProductGroupDetail = ({
  groupId,
  groupSetId,
}: {
  groupId: any;
  groupSetId: string;
}): JSX.Element => {
  const {
    currentGroup,
    currentGroupSet,
    getProductGroup,
    setCurrentGroup,
    setCurrentGroupSet,
  } = useProductGroups();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (groupSetId && !groupId) {
      setCurrentGroup(undefined);
      setCurrentGroupSet(Number(groupSetId));
    }

    if (groupId) {
      getProductGroup(groupId).then(() => {
        setLoading(false);
      });
    } else {
      setCurrentGroup(undefined);
      setLoading(false);
    }
  }, [groupId, groupSetId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/product-group-sets/:groupSetId/product-groups/new">
        {!currentGroup && currentGroupSet && (
          <EditProductGroup
            key={currentGroupSet.id}
            groupSet={currentGroupSet}
          />
        )}
      </Route>
      <Route path="/product-group-sets/:groupSetId/product-groups/unassigned">
        {!currentGroup && currentGroupSet && (
          <UnassignedProducts
            key={currentGroupSet.id}
            groupSet={currentGroupSet}
          />
        )}
      </Route>
      <Route path="/product-group-sets/:groupSetId/edit">
        {currentGroupSet && (
          <>
            <EditProductGroupSetModal key={currentGroupSet.id} />
            <EmptyDetail />
          </>
        )}
      </Route>
      <Route path="/product-group-sets/:groupSetId/product-groups/:groupId/edit">
        {currentGroup && currentGroupSet && (
          <EditProductGroup
            key={currentGroup.id}
            group={currentGroup}
            groupSet={currentGroupSet}
          />
        )}
      </Route>
      <Route path="/product-group-sets/:groupSetId/product-groups/:groupId">
        {currentGroup && (
          <ShowProductGroup
            group={currentGroup}
            groupSet={currentGroupSet}
            confirmationDialogTitleText={`Delete “${currentGroup.name}” group?`}
            confirmationDialogBodyContent="Deleting a group does not delete the product records. This action cannot be undone."
          />
        )}
      </Route>
      <Route path="/product-group-sets">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(ProductGroupDetail);
