import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { ButtonGroup } from '@chakra-ui/react';

import { Button } from '../../components/Button/Button';
import { PlusIcon } from '../../components/Icons/IconsNew';
import ActionBar from '../../components/ActionBar/ActionBar';

const PricingTierPageActions = ({
  isReadOnly = false,
}: {
  isReadOnly?: boolean;
}) => {
  const history = useHistory();
  const showBanner = localStorage.getItem('hidePricingTiersBanner') !== 'true';

  const subTitle = showBanner
    ? 'These settings are private so customers won’t know they’re in a tier.'
    : 'Show different prices to different customers. These settings are private so customers won’t know they’re in a tier.';

  return (
    <ActionBar
      title="Pricing tiers"
      subTitle={subTitle}
      rightChildren={
        <ButtonGroup>
          <Button
            variant="primary"
            leftIcon={<PlusIcon width="24px" />}
            onClick={() => history.push('/pricing-tiers/customer-groups/new')}
            isDisabled={isReadOnly}
          >
            New tier
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default observer(PricingTierPageActions);
