import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Image } from '@chakra-ui/react';
import Banner from '../../components/Banner/Banner';
import { useAccounting } from '../../contexts/accounting';
import useAccountingMeta from '../../hooks/useAccountingMeta';

const AccountingPricingTiersBanner = () => {
  const { currentAccountingConnection, getCurrentAccountingConnection } =
    useAccounting();
  const { accountingName, accountingLogo, syncedObjects } = useAccountingMeta(
    currentAccountingConnection?.key,
  );

  useEffect(() => {
    if (!currentAccountingConnection) {
      getCurrentAccountingConnection(() => {});
    }
  }, []);

  if (currentAccountingConnection && syncedObjects.includes('pricing_tiers')) {
    return (
      <Banner
        icon={<Image src={accountingLogo} w="64px" pr="1" />}
        title={`Connected to ${accountingName}`}
        description={`Your pricing tiers are being synced from ${accountingName}. This section is uneditable.`}
        variant="outline"
        status="info"
        bg="white"
        mx="4"
        mb="4"
      />
    );
  } else {
    return <></>;
  }
};

export default observer(AccountingPricingTiersBanner);
