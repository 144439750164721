import { useState, useEffect, useCallback, ReactNode } from 'react';
import debounce from 'lodash/debounce';
import useSWRInfinite from 'swr/infinite';
import { observer } from 'mobx-react';
import { Instance, SnapshotIn } from 'mobx-state-tree';
import { ModalContent, ModalHeader, ModalBody } from '@chakra-ui/react';

import useAPI from '../../hooks/useAPI';
import Product from '../../models/Product';
import ProductsModalList from './ProductsModalList';
import Order from '../../models/Order';
import ProductModalHeader from './ProductModalHeader';
import { omit } from 'lodash';
import { useCurrentUser } from '../../hooks/useStores';

type ProductFilters = {
  category_id?: string;
  status?: '' | 'active' | 'inactive';
};

const supplierFilters = {
  category_id: '',
  status: '',
};

const buyerFilters = {
  category_id: '',
};

type ProductsAPIData = {
  results: SnapshotIn<typeof Product>[];
  total_count: number;
};

const ProductModalContent = observer(
  ({
    order,
    defaultStatusFilter = '',
    headerUpperChildren,
    footerChildren,
  }: {
    order: Instance<typeof Order>;
    defaultStatusFilter?: '' | 'active';
    headerUpperChildren: ReactNode;
    footerChildren: ReactNode;
  }) => {
    const { isBuyer } = useCurrentUser();

    const baseFilters = isBuyer
      ? buyerFilters
      : { ...supplierFilters, status: defaultStatusFilter };

    const limit = 20;
    const [currentQuery, setCurrentQuery] = useState('');
    const [filters, setFilters] = useState<ProductFilters>(baseFilters);
    const [categories, setCategories] = useState([]);
    const [filterTab, setFilterTab] = useState('categories');

    const [callAPI] = useAPI({
      method: 'GET',
    });

    const filterString = Object.entries(filters)
      .map(([key, value]) => {
        if (key === 'category_id' && filterTab !== 'categories') {
          return '';
        } else {
          return value ? `&${key}=${value}` : '';
        }
      })
      .join('');

    const customerString = order.customer?.id
      ? `&customer_id=${order.customer?.id}`
      : '';

    const favouritesString =
      filterTab === 'favourites' ? '&favourites_only=true' : '';

    const getUrl = (pageIndex: number) =>
      `/v4/products?page=${pageIndex + 1}&limit=${limit}${
        currentQuery ? '&q=' + currentQuery : ''
      }${customerString}${filterString}${favouritesString}${
        order.supplier?.id ? '&supplier_id=' + order.supplier?.id : ''
      }`;

    const { data, setSize, size, isLoading } = useSWRInfinite<ProductsAPIData>(
      getUrl,
      callAPI,
    );
    const isLoadingMore =
      isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined');

    const products =
      data?.reduce(
        (
          prev: SnapshotIn<typeof Product>[],
          pageResult: { results: SnapshotIn<typeof Product>[] },
        ) => [...prev, ...pageResult.results],
        [],
      ) || [];
    const resultCount = data ? data[0].total_count : 0;

    const loadMore = () => {
      if (data && size * limit < data[0].total_count) {
        setSize(size + 1);
      }
    };

    const clearResults = () => {
      setSize(1);
    };

    const clearFilters = () => {
      clearResults();
      setCurrentQuery('');
      setFilters(baseFilters);
    };

    const handleSearch = useCallback(
      debounce((value: string) => {
        clearResults();
        setCurrentQuery(value);
      }, 400),
      [],
    );

    const handleSetFilterTab = (value: string) => {
      setFilterTab(value);
      clearResults();
    };

    useEffect(() => {
      callAPI(`/v2/companies/${order.supplier?.id}/categories`).then(
        (data: any) => {
          setCategories(data);
        },
      );
    }, []);

    return (
      <ModalContent>
        <ModalHeader
          px={6}
          pb={0}
          bg="gray.100"
          borderTopRadius="var(--chakra-radii-md)"
          boxShadow="inset 0px -1px 0px #D1D5DB"
        >
          {headerUpperChildren}
          <ProductModalHeader
            setFilter={(key, value) => {
              setFilters({ ...filters, [key]: value });
            }}
            handleSearch={handleSearch}
            categories={categories}
            filters={
              filterTab === 'categories'
                ? filters
                : omit(filters, 'category_id')
            }
            filterTab={filterTab}
            setFilterTab={handleSetFilterTab}
          />
        </ModalHeader>
        <ModalBody p={0}>
          <ProductsModalList
            order={order}
            products={products}
            isLoading={isLoadingMore}
            resultCount={resultCount}
            loadMore={loadMore}
            clearFilters={clearFilters}
          />
        </ModalBody>

        {footerChildren}
      </ModalContent>
    );
  },
);

export default ProductModalContent;
