import { useState } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {
  HStack,
  Link,
  Text,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  VStack,
} from '@chakra-ui/react';

import { useCurrentUser, useOrders } from '../../../hooks/useStores';
import { useBatchOrders } from '../../../contexts/batchOrders';
import { capitalizeString, pluralizeString } from '../../../utils';
import SelectAllCheckbox from '../../../components/SelectAllCheckbox/SelectAllCheckbox';
import StatusDot from '../../../components/Orders/StatusDot';
import { ClipboardIcon } from '../../../components/Icons/Icons';
import ProcessingModal from '../../../components/ProcessingModal/ProcessingModal';

import OrdersPrintAction from '../OrdersPrintAction';
import OrdersDownloadAction from '../OrdersDownloadAction';
import OrdersDeleteAction from './OrdersDeleteAction';
import OrdersExportAction from './OrdersExportAction';
import { useSuccessToast, useErrorToast } from '../../../components/toast';

export type Action =
  | 'download'
  | 'change-status'
  | 'print'
  | 'delete'
  | 'export';

const OrdersListActions = ({
  errorContainerRef,
  actions = ['download', 'change-status', 'print', 'delete', 'export'],
}: {
  errorContainerRef?: any;
  actions?: Action[];
}) => {
  const {
    currentOrder,
    orders,
    getOrders,
    getAllOrdersNoLimit,
    currentBulkIds,
    setCurrentBulkIds,
    totalPages,
    totalCount,
  } = useOrders();
  const { isBuyer } = useCurrentUser();
  const [isSelectingAll, setIsSelectingAll] = useState(false);
  const { updateBatchOrders } = useBatchOrders();
  const [changeStatusButtonisLoading, setChangeStatusButtonisLoading] =
    useState(false);
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const currentPageIds = orders
    ? orders.map((object: any) => {
        return object.id;
      })
    : null;
  // We either use the checked items, or the currently selected item if there is one.
  const currentBatchActionableIDs = currentBulkIds.length
    ? currentBulkIds
    : currentOrder
    ? [currentOrder.id]
    : [];

  const handleChangeStatus = (status: string) => {
    setChangeStatusButtonisLoading(true);
    const data = {
      order_ids: currentBatchActionableIDs,
      attributes: {
        status: status,
      },
    };
    updateBatchOrders(
      data,
      () => {
        getOrders(
          () => {
            setCurrentBulkIds([]);
            setChangeStatusButtonisLoading(false);
            successToast({
              description: `Order status updated to ${capitalizeString(
                status,
              )}. (${currentBatchActionableIDs.length} ${pluralizeString(
                'order',
                currentBatchActionableIDs.length,
              )})`,
            });
          },
          () => {
            setChangeStatusButtonisLoading(false);
            errorToast();
          },
          isBuyer ? 'outgoing' : 'incoming',
        );
      },
      () => {},
    );
  };

  const allRecordsOnPageSelected = _.isEqual(
    _.sortBy(currentPageIds),
    _.sortBy(currentBulkIds),
  );

  const selectAllThatMatchSearch = () => {
    const direction = isBuyer ? 'outgoing' : 'incoming';
    setIsSelectingAll(true);
    getAllOrdersNoLimit(
      (data: any) => {
        // Now select all the ids.
        // GraphQL would make this a lot nicer, wouldn't it?
        setCurrentBulkIds(
          data.results.map((object: any) => {
            return object.id;
          }),
        );
        // Now fetch the orders again, otherwise the page is potentially full of thousands of orders.
        getOrders(
          () => {
            setIsSelectingAll(false);
          },
          () => {
            setIsSelectingAll(false);
          },
          direction,
        );
        return;
      },
      () => {
        setIsSelectingAll(false);
      },
      direction,
    );
  };

  return (
    <>
      <SelectAllCheckbox
        selectedIds={currentBulkIds}
        setSelectedIds={setCurrentBulkIds}
        currentPageIds={currentPageIds}
      />

      <ProcessingModal isOpen={isSelectingAll}>
        <VStack alignItems="center" spacing="6" width="100%">
          <Text align="center">
            Selecting all {totalCount}{' '}
            {pluralizeString('order', currentBulkIds.length)} that match your
            search criteria
          </Text>
        </VStack>
      </ProcessingModal>

      <ButtonGroup
        variant="ghost"
        spacing="1"
        isDisabled={!currentBatchActionableIDs.length}
      >
        {actions.includes('change-status') && (
          <Menu>
            <Tooltip hasArrow label="Change status">
              <MenuButton
                isLoading={changeStatusButtonisLoading}
                as={IconButton}
                icon={<ClipboardIcon />}
              ></MenuButton>
            </Tooltip>

            <MenuList fontSize="sm" zIndex="dropdown">
              <MenuItem onClick={() => handleChangeStatus('new')}>
                <StatusDot mr="2" status={'new'} />
                New
              </MenuItem>
              <MenuItem onClick={() => handleChangeStatus('processing')}>
                <StatusDot mr="2" status={'processing'} />
                Processing
              </MenuItem>
              <MenuItem onClick={() => handleChangeStatus('invoiced')}>
                <StatusDot mr="2" status={'invoiced'} />
                Invoiced
              </MenuItem>
              <MenuItem onClick={() => handleChangeStatus('complete')}>
                <StatusDot mr="2" status={'complete'} />
                Complete
              </MenuItem>
            </MenuList>
          </Menu>
        )}

        {actions.includes('print') && (
          <OrdersPrintAction
            orderIds={currentBatchActionableIDs}
            useIconButton={true}
            tooltipText={'Print orders'}
          />
        )}
        {actions.includes('download') && (
          <OrdersDownloadAction
            orderIds={currentBatchActionableIDs}
            tooltipText={'Download orders'}
            useIconButton={true}
          />
        )}
        {actions.includes('export') && (
          <OrdersExportAction
            orderIds={currentBatchActionableIDs}
            errorContainerRef={errorContainerRef}
          />
        )}
        {actions.includes('delete') && (
          <OrdersDeleteAction orderIds={currentBatchActionableIDs} />
        )}
      </ButtonGroup>

      {allRecordsOnPageSelected && totalPages > 1 && (
        <HStack
          bg="yellow.200"
          py="1"
          px="3"
          borderRadius={'10px'}
          fontSize={'sm'}
        >
          <Text fontWeight="500" color="yellow.900">
            All <strong>{currentBulkIds.length} </strong>
            {pluralizeString('order', currentBulkIds.length)} on this page are
            selected.
          </Text>
          <Link onClick={selectAllThatMatchSearch} color="yellow.900">
            Select all {pluralizeString('order', currentBulkIds.length)} that
            match your search criteria
          </Link>
        </HStack>
      )}
    </>
  );
};

export default observer(OrdersListActions);
