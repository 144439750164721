import { observable, action, computed, makeObservable, remove } from 'mobx';
import * as Sentry from '@sentry/browser';

import { Customer, BaseCustomerProps, CustomerProps } from './customer';
import { api } from '../../api';
import { RootStore } from '../rootStore';
import { PaginationStore } from '../paginationStore';

interface CustomersListInterface {
  [id: number]: Customer;
}

export class CustomerStore {
  user: any = [];
  pageLimit = 20;

  @observable rootStore?: RootStore;

  @observable customersAreLoading: boolean = true;
  @observable currentSearchQuery: string = '';
  @observable currentPage: number = 1;
  @observable currentGroupIdsForGroupSets: { [id: number]: number } = {};

  @observable pagination = new PaginationStore();
  @observable totalCount?: number;
  @observable totalPages: number = 0;
  @observable nextPage?: number;
  @observable previousPage?: number;

  @observable customersList: CustomersListInterface = {};
  @observable customersOrder: (number | null)[] = [];
  @observable currentCustomerId?: number;

  @observable saveButtonIsLoading: boolean = false;

  constructor(rootStore?: RootStore, user?: any) {
    if (rootStore) {
      this.rootStore = rootStore;
    }
    this.user = user;
    makeObservable(this);
  }

  @computed
  get customers() {
    return this.customersOrder.map((key) => {
      if (typeof key === 'number') return this.customersList[key];
    });
  }

  @computed
  get currentCustomer(): any {
    if (this.currentCustomerId) {
      return this.customersList[this.currentCustomerId];
    }
  }

  @computed
  get currentGroupIds() {
    return Object.values(this.currentGroupIdsForGroupSets);
  }

  @computed
  get currentGroupIdsQueryString() {
    return (
      '&' +
      this.currentGroupIds.map((id) => `customer_group_ids[]=${id}`).join('&')
    );
  }

  @action setCustomers = (customers: any) => {
    this.totalCount = customers.total_count;
    this.totalPages = customers.total_pages;
    this.nextPage = customers.next_page;
    this.previousPage = customers.previous_page;
    this.customersOrder = [];
    customers.results.forEach((customer: BaseCustomerProps, index: number) => {
      // Only create new instance if customer doesn't exist. But should we merge new data with existing instances?
      if (!this.customersList[customer.id]) {
        this.customersList[customer.id] = new Customer(this, customer);
      }
      this.customersOrder[index] = customer.id;
    });
  };
  @action setCurrentCustomer = (customer?: CustomerProps) => {
    if (customer) {
      if (
        this.currentCustomerId &&
        this.customersList[this.currentCustomerId]
      ) {
        this.customersList[this.currentCustomerId].setIsCurrentCustomer(false);
      }
      const customerObject = this.customersList[customer.id];
      if (customerObject) {
        customerObject.addData(customer);
        customerObject.setIsCurrentCustomer(true);
      } else {
        this.customersList[customer.id] = new Customer(this, customer);
      }
      this.currentCustomerId = customer.id;
    }
  };
  @action setCustomersAreLoading = (customersAreLoading: boolean) =>
    (this.customersAreLoading = customersAreLoading);
  @action setCurrentSearchQuery = (query: string) => {
    this.currentSearchQuery = query;
  };
  @action setCurrentPage = (value: number) => (this.currentPage = value);
  @action setSaveButtonIsLoading = (value: boolean) =>
    (this.saveButtonIsLoading = value);
  @action clearFilters = () => {
    this.currentSearchQuery = '';
    this.currentGroupIdsForGroupSets = {};
  };

  @action setGroupIdForGroupSet = (id: number, groupSetId: number) => {
    this.currentGroupIdsForGroupSets[groupSetId] = id;
  };
  @action unsetGroupIdForGroupSet = (groupSetId: number) => {
    remove(this.currentGroupIdsForGroupSets, groupSetId.toString());
  };

  @action getCustomers = (
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    const searchParam =
      (this.currentSearchQuery && `&q=${this.currentSearchQuery}`) || '';
    const customerGroupIdsParam =
      this.currentGroupIds.length > 0 ? this.currentGroupIdsQueryString : '';
    const path = `/v4/customers?limit=${this.pageLimit}&page=${this.currentPage}${searchParam}${customerGroupIdsParam}`;

    this.setCustomersAreLoading(true);
    api.get(path).then(async (response) => {
      if (response.status === 401) {
      } else {
        const data = await response.json();
        if (response.ok) {
          this.setCustomers(data);
          if (successCallback) {
            successCallback();
          }
          this.setCustomersAreLoading(false);
          return data;
        } else {
          if (errorCallback) {
            errorCallback();
          }
          this.setCustomersAreLoading(false);
          return Promise.reject(data);
        }
      }
      this.setCustomersAreLoading(false);
    });
    return;
  };

  @action getCustomer = (customerId: string, successCallback: () => any) => {
    api
      .get(`/v4/customers/${customerId}`)
      .then(async (response) => {
        if (response.status === 500) {
        } else {
          if (response.ok) {
            const data = await response.json();
            this.setCurrentCustomer(data);
            if (successCallback) {
              successCallback();
            }
            return data;
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return error;
      });
  };

  @action updateCustomer = (
    formData: any,
    customerId: number,
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    api
      .put(`/v4/customers/${customerId}`, JSON.stringify({ ...formData }))
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          this.setCurrentCustomer(data);
          successCallback();
          return data;
        } else {
          errorCallback();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        errorCallback();
        return error;
      });
  };
}

export default new CustomerStore();
