import { action, observable } from 'mobx';

export class PaginationStore {
  @observable currentPage: number = 1;

  @observable totalCount?: number;
  @observable totalPages?: number;
  @observable nextPage?: number;
  @observable previousPage?: number;

  @action
  addToCount = () => {
    this.totalCount = this.totalCount ? this.totalCount + 1 : 1;
  };

  @action
  removeFromCount = () => {
    this.totalCount = this.totalCount ? this.totalCount - 1 : 0;
  };

  @action
  setCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  @action
  setFromResponse = (resp: {
    total_count: number;
    total_pages: number;
    next_page: number;
    previous_page: number;
  }) => {
    this.totalCount = resp.total_count;
    this.totalPages = resp.total_pages;
    this.nextPage = resp.next_page;
    this.previousPage = resp.previous_page;
  };
}
