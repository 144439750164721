import { Box, BoxProps } from '@chakra-ui/react';

const NoteBox = (props: BoxProps): JSX.Element => {
  return (
    <Box
      bg="gray.100"
      p="6px 10px"
      borderRadius="16px"
      fontSize="xs"
      width="fit-content"
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default NoteBox;
