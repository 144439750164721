import React, { useState } from 'react';

import { useAuth } from './auth';
import addOrderStore, { AddOrderStore } from '../stores/addOrder/addOrderStore';

const AddOrderContext = React.createContext<AddOrderStore>(addOrderStore);

const AddOrderProvider = (props: any) => {
  const { user } = useAuth();
  const [store, _] = useState(new AddOrderStore(user));
  const { children } = props;
  return (
    <AddOrderContext.Provider value={store}>
      {children}
    </AddOrderContext.Provider>
  );
};
const useAddOrder = () => React.useContext(AddOrderContext);
export { AddOrderProvider, useAddOrder };
