import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalOverlay } from '@chakra-ui/react';

import { useStandingOrders } from '../../../hooks/useStores';
import SelectCompanyScreen from '../../../components/SelectCompanyScreen/SelectCompanyScreen';

import useAPI from '../../../hooks/useAPI';
import { useCurrentUser } from '../../../hooks/useStores';

const SelectCompanyModal = () => {
  const history = useHistory();
  const { setCurrentStandingOrder } = useStandingOrders();
  const { company, isBuyer } = useCurrentUser();
  const [getRequest] = useAPI({
    method: 'GET',
  });

  const handleClose = () => {
    history.goBack();
  };

  const handleCompanySelect = (selectedCompany: any) => {
    const supplier = isBuyer ? selectedCompany : company;
    const customer = isBuyer ? company : selectedCompany;

    getRequest(
      `/v4/suppliers/${supplier?.id}/delivery_rules?customer_id=${customer.id}`,
    ).then((response: any) => {
      setCurrentStandingOrder({
        id: 'new',
        supplier: supplier,
        customer: customer,
        standing_order_items: [],
        paused_from: null,
        paused_to: null,
        delivery_rules: response.delivery_rules,
        delivery_rules_enabled: response.delivery_rules_enabled,
      });
    });
  };

  return (
    <>
      <Modal
        isOpen={true}
        onClose={handleClose}
        size="xl"
        closeOnOverlayClick={false}
        autoFocus={false}
      >
        <ModalOverlay />
        <SelectCompanyScreen
          onCompanySelect={handleCompanySelect}
          extraRequestParams={{ has_standing_orders: false }}
          headerText="Add new standing order"
        />
      </Modal>
    </>
  );
};

export default observer(SelectCompanyModal);
