import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
  'banner',
  'icon',
  'actions',
  'dismiss',
]);

const variantOutline = (props) => {
  const { status } = props;
  if (status) {
    let color = '';
    if (status === 'error') {
      color = 'error.red';
    } else if (status === 'warning') {
      color = 'error.amber';
    } else if (status === 'success') {
      color = 'green.500';
    } else if (status === 'info') {
      color = 'blue.500';
    }
    return {
      banner: {
        backgroundColor: 'gray.0',
        borderColor: color,
        borderWidth: '0.5px',
        borderLeftWidth: '4px',
      },
      icon: {
        color: color,
      },
      dismiss: {
        color: color,
      },
    };
  }
};

const variantSolid = (props) => {
  const { status } = props;
  if (status) {
    let color = '';
    let bg = '';
    if (status === 'error') {
      color = 'error.red';
      bg = 'red.50';
    } else if (status === 'warning') {
      color = 'error.amber';
      bg = 'yellow.50';
    } else if (status === 'success') {
      color = 'green.500';
      bg = 'green.50';
    } else if (status === 'info') {
      color = 'blue.500';
      bg = 'blue.50';
    }
    return {
      banner: {
        backgroundColor: bg,
        borderColor: color,
        borderWidth: '0.5px',
        borderLeftWidth: '4px',
      },
      icon: {
        color: color,
      },
      dismiss: {
        color: color,
      },
    };
  }
};

export default helpers.defineMultiStyleConfig({
  // Styles for the base style
  baseStyle: {
    banner: {
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
      borderRadius: '8px',
      p: '16px',
    },
    dismiss: {
      color: 'green.500',
    },
    actions: {
      color: 'green.500',
      spacing: '24px',
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    outline: variantOutline,
    solid: variantSolid,
  },
  // The default `size` or `variant` values
  defaultProps: {
    variant: 'outline',
  },
});
