import React from 'react';
import { observer } from 'mobx-react';
import { groupBy, orderBy, keys } from 'lodash';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/react';
import NoteBox from '../../../components/NoteBox/NoteBox';
import Tag from '../../../components/Tag/Tag';
import { useEditableOrder } from '../../../contexts/editableOrder';
import EditableOrderItemRow from './EditableOrderItemRow';
import ProductPhoto from '../../../components/ProductPhoto/ProductPhoto';

const NotesRow = ({
  notes,
  supplierNotes,
  colSpan = 4,
}: {
  notes?: string;
  supplierNotes?: string;
  colSpan?: number;
}) => {
  return (
    <Tr>
      <Td colSpan={colSpan} p="0 16px 16px">
        {notes && <NoteBox>"{notes}"</NoteBox>}
        {supplierNotes && (
          <NoteBox mt={notes ? '8px' : ''}>Supplier: "{supplierNotes}"</NoteBox>
        )}
      </Td>
    </Tr>
  );
};

type OrderItemRowProps = {
  orderItem: any;
  showPriceColumn: boolean;
};

const ReadOnlyOrderItemRowEdited = observer(
  ({ orderItem, showPriceColumn }: OrderItemRowProps): JSX.Element => {
    const product = orderItem.buyable.product;
    const isDeleted = orderItem.amount == 0;
    const textDecoration = isDeleted ? 'line-through' : 'none';
    const hasNotes = !!orderItem.notes || !!orderItem.supplier_note;

    return (
      <>
        <Tr bg={isDeleted ? 'gray.50' : 'inherit'}>
          <Td
            padding="16px"
            verticalAlign="middle"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            <HStack>
              <ProductPhoto photo={product.photo_url} height={40} />
              <Text
                lineHeight="5"
                fontSize="sm"
                fontWeight="500"
                textColor="gray.900"
                textDecoration={textDecoration}
              >
                {product.name}
              </Text>
            </HStack>
          </Td>
          <Td
            verticalAlign="middle"
            textAlign="left"
            px="0"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            <Text
              fontSize="xs"
              fontWeight="500"
              lineHeight="4"
              color="gray.500"
              letterSpacing="wider"
              textDecoration={textDecoration}
            >
              {product.product_code}
            </Text>
          </Td>
          {showPriceColumn && (
            <Td
              verticalAlign="middle"
              textAlign="right"
              textDecoration={textDecoration}
              color="gray.500"
              borderBottom={hasNotes ? 'transparent' : ''}
              pr="16px"
            >
              <Text fontSize="sm">
                {orderItem.price && orderItem.pricing_unit ? (
                  <>
                    ${Number(orderItem.price).toFixed(2)}/
                    {orderItem.pricing_unit.name}
                  </>
                ) : (
                  <>-</>
                )}
              </Text>
            </Td>
          )}
          <Td
            p="16px 16px 16px 0"
            verticalAlign="middle"
            textAlign="right"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            {orderItem.is_added ? (
              <Tag bg="yellow.300" color="yellow.900">
                Added
              </Tag>
            ) : (
              <Text
                fontSize="sm"
                lineHeight="20px"
                color="gray.500"
                textDecoration={textDecoration}
              >
                {orderItem.initial_amount} {orderItem.initial_unit.name}
              </Text>
            )}
          </Td>
          <Td
            verticalAlign="middle"
            textAlign="right"
            pl="0"
            pr="16px"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            <Text fontSize="sm">
              {parseFloat(orderItem.amount)} {orderItem.unit.name}
            </Text>
          </Td>
        </Tr>
        {hasNotes && (
          <NotesRow
            notes={orderItem.notes}
            supplierNotes={orderItem.supplier_note}
            colSpan={showPriceColumn ? 5 : 4}
          />
        )}
      </>
    );
  },
);

const ReadOnlyOrderItemRowUnedited = observer(
  ({ orderItem, showPriceColumn }: OrderItemRowProps): JSX.Element => {
    const product = orderItem.buyable.product;
    const isDeleted = orderItem.amount == 0;
    const textDecoration = isDeleted ? 'line-through' : 'none';
    const hasNotes = !!orderItem.notes || !!orderItem.supplier_note;

    return (
      <>
        <Tr bg={isDeleted ? 'gray.50' : 'inherit'}>
          <Td
            padding="16px"
            verticalAlign="middle"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            <HStack>
              <ProductPhoto photo={product.photo_url} height={40} />
              <Text
                lineHeight="5"
                fontSize="sm"
                fontWeight="500"
                textColor="gray.900"
                textDecoration={textDecoration}
              >
                {product.name}
              </Text>
            </HStack>
          </Td>
          <Td
            verticalAlign="middle"
            textAlign="left"
            px="0"
            borderBottom={hasNotes ? 'transparent' : ''}
          >
            <Text
              fontSize="xs"
              fontWeight="500"
              lineHeight="4"
              color="gray.500"
              letterSpacing="wider"
              pt="2px"
              textDecoration={textDecoration}
            >
              {product.product_code}
            </Text>
          </Td>
          {showPriceColumn && (
            <Td
              verticalAlign="middle"
              textAlign="right"
              textDecoration={textDecoration}
              color="gray.500"
              borderBottom={hasNotes ? 'transparent' : ''}
              pr="16px"
            >
              <Text fontSize="sm">
                {orderItem.price && orderItem.pricing_unit ? (
                  <>
                    ${Number(orderItem.price).toFixed(2)}/
                    {orderItem.pricing_unit.name}
                  </>
                ) : (
                  <>-</>
                )}
              </Text>
            </Td>
          )}
          <Td
            verticalAlign="middle"
            textAlign="right"
            textDecoration={textDecoration}
            borderBottom={hasNotes ? 'transparent' : ''}
            pr="16px"
          >
            <Text fontSize="sm">
              {parseFloat(orderItem.amount)} {orderItem.unit.name}
            </Text>
          </Td>
        </Tr>
        {hasNotes && (
          <NotesRow
            notes={orderItem.notes}
            supplierNotes={orderItem.supplier_note}
            colSpan={showPriceColumn ? 5 : 4}
          />
        )}
      </>
    );
  },
);

const OrderItemsTable = ({ orderItems, isEditable }: any): JSX.Element => {
  const { editableOrder } = useEditableOrder();
  const visibleOrderItems = orderItems.filter((orderItem: any) => {
    return !orderItem._destroy;
  });
  const groupedOrderItems = groupBy(visibleOrderItems, (orderItem: any) => {
    return orderItem.buyable.product.category.name;
  });
  const orderedCategoryNames = orderBy(keys(groupedOrderItems), [], 'asc');

  const isEdited =
    orderItems.filter((orderItem: any) => {
      // orderItemis not always an instance of OrderItem, so we cant run this on the model
      return (
        orderItem.is_added ||
        (orderItem.initial_amount &&
          orderItem.initial_unit &&
          (Number(orderItem.initial_amount) != Number(orderItem.amount) ||
            orderItem.initial_unit.name !== orderItem.unit.name))
      );
    }).length > 0;
  const showPriceColumn =
    orderItems.filter((orderItem: any) => {
      return (
        orderItem.price && orderItem.pricing_unit && orderItem.pricing_unit.name
      );
    }).length > 0;
  const showEditedHeadings = isEditable || isEdited;

  return (
    <Table variant="orderitems" width="100%" boxShadow="base">
      <Thead>
        {showEditedHeadings ? (
          <Tr>
            <Th px="16px" width={isEditable ? '226px' : '358px'}>
              Product
            </Th>
            <Th pl="0" pr="16px" width="144px">
              Code
            </Th>
            {showPriceColumn && (
              <Th pl="0" pr="16px" width="88px" textAlign="right">
                Price
              </Th>
            )}
            <Th pl="0" pr="16px" width="88px" textAlign="right">
              Ordered
            </Th>
            <Th
              pl="0"
              pr="16px"
              width={isEditable ? '204px' : 'auto'}
              textAlign="left"
            >
              Supplied
            </Th>
          </Tr>
        ) : (
          <Tr>
            <Th px="16px" width="414px">
              Product
            </Th>
            <Th pl="0" pr="16px" width="144px">
              Code
            </Th>
            {showPriceColumn && (
              <Th pl="0" pr="16px" width="144px" textAlign="right">
                Price
              </Th>
            )}
            <Th pl="0" pr="16px" width="88px" textAlign="right">
              Quantity
            </Th>
          </Tr>
        )}
      </Thead>
      <Tbody>
        {orderedCategoryNames.map((categoryName: string) => {
          return groupedOrderItems[categoryName].map(
            (orderItem: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  {key == 0 && (
                    <CategoryHeaderRow
                      categoryName={categoryName}
                      colSpan={showPriceColumn ? 5 : 4}
                    />
                  )}
                  {isEditable && (
                    <EditableOrderItemRow
                      orderItem={orderItem}
                      onDelete={() => {
                        if (editableOrder) {
                          editableOrder.removeOrderItem(orderItem);
                        }
                      }}
                    />
                  )}
                  {!isEditable && (
                    <>
                      {isEdited ? (
                        <ReadOnlyOrderItemRowEdited
                          orderItem={orderItem}
                          showPriceColumn={showPriceColumn}
                        />
                      ) : (
                        <ReadOnlyOrderItemRowUnedited
                          orderItem={orderItem}
                          showPriceColumn={showPriceColumn}
                        />
                      )}
                    </>
                  )}
                </React.Fragment>
              );
            },
          );
        })}
      </Tbody>
    </Table>
  );
};

const CategoryHeaderRow = ({
  categoryName,
  colSpan = 4,
}: {
  categoryName: string;
  colSpan?: number;
}) => {
  return (
    <Tr bg="gray.100">
      <Td
        colSpan={colSpan}
        pl="16px"
        fontSize="xs"
        color="gray.700"
        fontWeight="500"
        py="2"
        letterSpacing="wider"
        textTransform="uppercase"
      >
        {categoryName}
      </Td>
    </Tr>
  );
};

export default observer(OrderItemsTable);
