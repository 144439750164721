import { useEffect, useState } from 'react';
import useAPI from '../../../../hooks/useAPI';
import useJobStatus from '../../../../hooks/useJobStatus';
import moment from 'moment';
import ProcessingModal from '../../../../components/ProcessingModal/ProcessingModal';
import { useHistory } from 'react-router-dom';

import {
  Alert,
  AlertIcon,
  Box,
  Heading,
  ButtonGroup,
  Text,
  useDisclosure,
  VStack,
  Spinner,
  Progress,
} from '@chakra-ui/react';
import { Button } from '../../../../components/Button/Button';
import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import { ChevronRightIcon } from '../../../../components/Icons/IconsNew';

const ProductSyncStatus = ({ platformName }: any): JSX.Element => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [setJobId, isJobProcessing, jobStatus, jobData] = useJobStatus(
    1500,
    fetchStatus,
  );
  const [statusData, setStatusData] = useState<any | null>(null);
  const [putSyncAllNowRequest, putSyncAllNowIsLoading] = useAPI({
    method: 'PUT',
  });
  const [getStatusRequest, getStatusIsLoading] = useAPI({
    method: 'GET',
  });
  const [importProductsRequest, importProductsLoading] = useAPI({
    method: 'PUT',
  });

  useEffect(() => {
    fetchStatus();
  }, []);

  const lastSyncedAt =
    statusData && statusData.sync_ended_at
      ? moment(statusData.sync_ended_at)
      : null;

  function fetchStatus() {
    setStatusData(null);
    getStatusRequest('/v4/accounting/products/status').then((data: any) => {
      setStatusData(data);
    });
  }

  const enableAutoSync = () => {
    importProductsRequest('/v4/accounting/products', {
      body: JSON.stringify({
        sync_strategy: 'auto',
      }),
    }).then(() => {
      syncNow();
      fetchStatus();
      onClose();
    });
  };

  const syncNow = () => {
    putSyncAllNowRequest('/v4/accounting/products/sync_now')
      .then((data: any) => {
        setJobId(data.jid);
      })
      .catch(() => {});
  };

  const percentageComplete =
    jobData && jobData.num_total && jobData.num_synced && jobData.num_errors
      ? ((Number(jobData.num_synced) + Number(jobData.num_errors)) /
          Number(jobData.num_total)) *
        100
      : undefined;

  return (
    <Box>
      <Heading as="h3" size="md">
        Products
      </Heading>

      <Box pt="6">
        {statusData ? (
          <VStack spacing="6" alignItems="left">
            {getStatusIsLoading ? (
              <Spinner size="sm" color="green" />
            ) : (
              <VStack alignItems="left">
                {statusData.sync_strategy == 'off' ? (
                  <Text>
                    Import and sync your products from {platformName}.
                  </Text>
                ) : (
                  <>
                    <Text>Auto-syncing is enabled.</Text>
                    {lastSyncedAt && (
                      <Text fontSize="12">
                        Last full sync completed{' '}
                        {lastSyncedAt && lastSyncedAt.fromNow()}
                      </Text>
                    )}
                  </>
                )}
              </VStack>
            )}

            <ButtonGroup>
              {statusData && statusData.sync_strategy == 'off' && (
                <Button onClick={onOpen} colorScheme="blue">
                  Enable syncing
                </Button>
              )}

              {statusData && statusData.sync_strategy == 'auto' && (
                <>
                  <Button
                    onClick={() => {
                      syncNow();
                    }}
                    colorScheme="green"
                    isLoading={putSyncAllNowIsLoading || getStatusIsLoading}
                  >
                    Sync all now
                  </Button>

                  {statusData.num_errors > 0 && (
                    <Button
                      colorScheme="red"
                      onClick={() =>
                        history.push('/settings/integrations/sync-errors')
                      }
                      rightIcon={<ChevronRightIcon boxSize={4} />}
                    >
                      View {statusData.num_errors} errors
                    </Button>
                  )}
                </>
              )}
            </ButtonGroup>
          </VStack>
        ) : (
          <Spinner size="lg" thickness="3px" color="green" />
        )}
      </Box>

      <ConfirmationDialog
        marginTop="7.5rem"
        isOpen={isOpen}
        onCancel={onClose}
        titleText={`Enable automatic product syncing?`}
        bodyContent="Syncing your products will overwrite any existing products in HospoConnect. Are you sure you want to continue?"
        confirmButtonText={'Enable Automatic Syncing'}
        onConfirm={enableAutoSync}
      />

      <ProcessingModal isOpen={isJobProcessing && jobStatus}>
        <VStack alignItems="center" spacing="6" width="100%">
          <Text>
            {jobStatus == 'queued' && `Products are waiting to begin syncing`}
            {jobStatus == 'working' &&
              (jobData.num_total
                ? `Syncing ${jobData.num_total} products from ${platformName}`
                : `Pulling products from ${platformName}`)}
            {jobStatus == 'complete' && `Finished!`}
          </Text>
          <Progress
            width="100%"
            colorScheme="green"
            size="sm"
            isIndeterminate={percentageComplete === undefined}
            value={percentageComplete}
          />
        </VStack>
      </ProcessingModal>
    </Box>
  );
};

export default ProductSyncStatus;
