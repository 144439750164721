import { MenuItem, HTMLChakraProps, Button } from '@chakra-ui/react';

import NavItem, { NavItemProps } from '../NavItem/NavItem';
import { Footnote } from '../Typography/Typography';
import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';

interface SidebarNavItemComponentProps extends NavItemProps {
  id: string;
  openNav: string;
  selectedNav: string;
  setSelected: (value: string) => void;
  resetSelected?: () => void;
}

export type SidebarNavItem = {
  id?: string;
  label: string;
  link?: string;
  icon: FunctionComponent<HTMLChakraProps<'svg'>>;
  onClick?: MouseEventHandler;
  subitems?: SidebarNavItem[];
};

export type SidebarNavItemProps = {
  label: string | ReactNode;
} & Omit<SidebarNavItem, 'label'>;

export const SidebarSubItem = ({
  label,
  icon: IconComponent,
  link,
  onClick,
}: SidebarNavItemProps) => {
  return (
    <MenuItem
      as="a"
      href={link}
      onClick={onClick}
      cursor="pointer"
      icon={<IconComponent color="green.500" width="24px" height="24px" />}
      pr="24px"
    >
      {typeof label === 'string' ? <Footnote>{label}</Footnote> : label}
    </MenuItem>
  );
};

export const SidebarNavItem = ({
  id,
  openNav,
  selectedNav,
  setSelected,
  resetSelected,
  ...rest
}: SidebarNavItemComponentProps) => {
  const isOpen = id === openNav;
  const isSelected = id === selectedNav;
  return (
    <NavItem
      isOpen={isOpen}
      isSelected={isSelected}
      onOpen={() => setSelected(id)}
      onClose={resetSelected}
      {...rest}
    />
  );
};

export default SidebarNavItem;
