import { observer } from 'mobx-react-lite';
import useRealtimeData, {
  RealtimeDataEventPayloadType,
} from '../../../hooks/useRealtimeData';
import { useOrders } from '../../../hooks/useStores';
import OrdersList from './OrdersList';
import OrdersRow from './OrdersRow';

const SupplierOrdersList = ({ errorContainerRef }: any) => {
  const {
    orders,
    ordersAreLoading,
    pageLimit,
    currentStatusFilter,
    setCurrentPage,
    getOrders,
    totalCount,
    totalPages,
    currentPage,
    clearFilters,
    uncheckOrdersIfHidden,
  } = useOrders();

  const onReceived = (payload: RealtimeDataEventPayloadType) => {
    if (payload.entityType == 'order') {
      if (currentStatusFilter != 'new' && payload.eventType == 'create') {
        /** Don't bother refreshing for a create event unless we are on the new tab */
        return;
      }

      getOrders(
        () => {
          uncheckOrdersIfHidden(payload.entityIds);
        },
        () => {},
        'incoming',
      );
    }
  };
  const onTabReactivate = () => {
    getOrders(
      () => {},
      () => {},
      'incoming',
    );
  };
  useRealtimeData(
    'DataFeedChannel',
    onReceived,
    [currentStatusFilter],
    onTabReactivate,
  );

  return (
    <OrdersList
      pagination={{
        handlePageChange: (pageNumber: number) => {
          setCurrentPage(pageNumber);
        },
        currentPage: currentPage,
        pageCount: totalPages,
        totalCount: totalCount || 0,
        itemsPerPage: pageLimit,
        itemsType: 'orders',
      }}
      orders={orders}
      isLoading={ordersAreLoading}
      clearFilters={clearFilters}
      errorContainerRef={errorContainerRef}
      rowComponent={OrdersRow}
    />
  );
};

export default observer(SupplierOrdersList);
