import { useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { RouteComponentProps } from 'react-router-dom';
import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import { useCurrentUser } from '../../hooks/useStores';
import OrderDetail from './Detail/OrderDetail';

import OrderDetailPanel from './DataGrid/OrderDetailPanel';
import { useOrders } from '../../hooks/useStores';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react';
import OrdersDataGridActions from './DataGrid/OrdersDataGridActions';
import OneUpWrapper from '../../components/Layouts/OneUpWrapper';
import BuyersOrdersList from './List/BuyersOrdersList';
import SupplierOrdersList from './List/SupplierOrdersList';
import OrdersPageActionBar from './OrdersPageActionBar';
import SuppliersOrdersDataGrid from './DataGrid/SuppliersOrdersDataGrid';
import BuyersOrdersDataGrid from './DataGrid/BuyersOrdersDataGrid';

const OrdersPage = ({
  match: {
    params: { orderId },
  },
}: RouteComponentProps<{ orderId: string }>): JSX.Element => {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const { isBuyer } = useCurrentUser();
  const dataGridRef = useRef<HTMLTableElement>(null);
  const { tabularView } = useFlags();
  const { showOrdersTabularView: showOrdersTabularViewPreference } =
    useOrders();
  const showOrdersTabularView =
    tabularView && !isUndefined(showOrdersTabularViewPreference)
      ? showOrdersTabularViewPreference
      : tabularView;

  return (
    <AppWrapper>
      {isBuyer ? (
        <OneUpWrapper
          headerChildren={
            <OrdersDataGridActions errorContainerRef={errorContainerRef} />
          }
          wrapperProps={{
            pt: '4',
          }}
        >
          <BuyersOrdersDataGrid dataGridRef={dataGridRef} />
          <OrderDetailPanel orderId={orderId} dataGridRef={dataGridRef} />
        </OneUpWrapper>
      ) : showOrdersTabularView ? (
        <OneUpWrapper
          headerChildren={
            <OrdersDataGridActions errorContainerRef={errorContainerRef} />
          }
          wrapperProps={{
            pt: '4',
          }}
        >
          <SuppliersOrdersDataGrid dataGridRef={dataGridRef} />
          <OrderDetailPanel orderId={orderId} dataGridRef={dataGridRef} />
        </OneUpWrapper>
      ) : (
        <TwoUpWrapper
          headerChildren={
            <OrdersPageActionBar errorContainerRef={errorContainerRef} />
          }
          leftChildren={
            <SupplierOrdersList errorContainerRef={errorContainerRef} />
          }
          rightChildren={<OrderDetail orderId={orderId} />}
        />
      )}
    </AppWrapper>
  );
};

export default observer(OrdersPage);
