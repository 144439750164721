import { ForwardedRef } from 'react';
import { forwardRef, IconButton, Tooltip } from '@chakra-ui/react';

const NoteEditorIconButton = forwardRef(
  (
    { noteEditorLabel, iconForButton, ...rest }: any,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <Tooltip label={noteEditorLabel}>
      <IconButton
        aria-label={noteEditorLabel}
        minWidth="24px"
        height="24px"
        variant="transparentGhost"
        color="grey"
        ref={ref}
        icon={iconForButton}
        {...rest}
      />
    </Tooltip>
  ),
);

export default NoteEditorIconButton;
