import { useEffect, useState } from 'react';
import { Progress, Text, VStack } from '@chakra-ui/react';
import ProcessingModal from '../ProcessingModal/ProcessingModal';

export type FakeProgressModalProps = {
  mainText?: string | null;
  isOpen: boolean;
  isDone: boolean;
  timePerItemMs: number;
  totalItems: number;
  bottomChildren?: any;
};

const FakeProgressModal = ({
  mainText,
  isOpen,
  isDone,
  timePerItemMs,
  totalItems,
  bottomChildren,
}: FakeProgressModalProps): JSX.Element => {
  const [isInitialising, setIsInitialising] = useState(true);
  const [numComplete, setNumComplete] = useState(0);
  var progressInterval: any;

  useEffect(() => {
    if (isDone) {
      // It's already finished, so set to 100%
      setNumComplete(totalItems);
      clearInterval(progressInterval);
    } else {
      setNumComplete(0);
      clearInterval(progressInterval);
      setIsInitialising(true);
      if (isOpen) {
        setTimeout(() => {
          setIsInitialising(false);
          progressInterval = setInterval(() => {
            setNumComplete((prevNum) => prevNum + 1);
          }, timePerItemMs);
        }, 1000);
      }
    }

    return () => clearInterval(progressInterval);
  }, [isOpen, isDone]);

  return (
    <ProcessingModal isOpen={isOpen}>
      <VStack alignItems="center" spacing="6" width="100%">
        {mainText && <Text>{mainText}</Text>}
        <Progress
          width="100%"
          colorScheme="green"
          size="sm"
          isIndeterminate={isInitialising}
          value={(100 * numComplete) / totalItems}
        />
        {bottomChildren}
      </VStack>
    </ProcessingModal>
  );
};

export default FakeProgressModal;
