import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Flex } from '@chakra-ui/react';
import ListRow from '../../../components/ListRow/ListRow';
import { Caption } from '../../../components/Typography/Typography';

const RecipesRow = ({ recipe }: any): JSX.Element => {
  let history = useHistory();

  return (
    <ListRow
      key={`table-row-${recipe.id}`}
      isCurrent={recipe.isCurrent}
      isChecked={recipe.isChecked}
      onClick={() => {
        history.push(`/recipes/${recipe.id}`);
      }}
      onCheckboxChange={recipe.toggleChecked}
    >
      <Flex flex="1" align="center" gap="4">
        <Flex direction="row" gap="2" alignItems="flex-start">
          <Caption fontWeight="600" color="gray.900">
            {recipe.name}
          </Caption>
          {/* <Caption fontWeight="500" color="gray.500">
            This is all TODO.
          </Caption> */}
        </Flex>
      </Flex>
    </ListRow>
  );
};

export default observer(RecipesRow);
