import React, { useState } from 'react';
import { useSuccessToast, useErrorToast } from '../../components/toast';
import * as Sentry from '@sentry/browser';
import { xClientHeaders } from '../../utils';

import {
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';

const ForgotPasswordForm = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState(false);
  //const [isSuccessful, setIsSuccessful] = useState(false);
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setSubmitButtonIsLoading(true);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...xClientHeaders },
      body: JSON.stringify({ email: email }),
    };

    const url = `${process.env.REACT_APP_API_URL}/api/v4/passwords`;
    fetch(url, requestOptions)
      .then(async (response) => {
        setSubmitButtonIsLoading(false);
        const data = await response.json();
        if (response.ok) {
          successToast({
            description: data.message,
          });
          return data;
        } else {
          return Promise.reject(data);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (error.message) {
          errorToast({ description: error.message });
        } else {
          errorToast();
        }
        setSubmitButtonIsLoading(false);
        return error;
      });
  }

  return (
    <chakra.form onSubmit={handleSubmit}>
      <Stack spacing="6">
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            name="email"
            type="email"
            autoComplete="email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        <ButtonGroup>
          <Button
            type="submit"
            colorScheme="green"
            size="lg"
            fontSize="md"
            isLoading={submitButtonIsLoading}
          >
            Send email instructions
          </Button>
          <Button
            as="a"
            href="/login"
            size="lg"
            fontSize="md"
            variant="outline"
          >
            Cancel
          </Button>
        </ButtonGroup>
      </Stack>
    </chakra.form>
  );
};

export default ForgotPasswordForm;
