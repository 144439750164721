import * as Constants from './constants';
import { xClientHeaders } from './utils';

export const api = {
  client(url: string, options?: {}): Promise<Response> {
    const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
    const authToken = userInfo?.authentication_token;
    const token = `Bearer ${authToken}`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(!!userInfo && { Authorization: token }),
        ...xClientHeaders
      },
      ...options,
    };

    return new Promise((resolve, reject) =>
      fetch(`${Constants.API_URL}${url}`, requestOptions)
        .then((data) => resolve(data))
        .catch((err) => reject(err)),
    );
  },
  get(url: string) {
    return this.client(url);
  },
  post(url: string, body: any) {
    return this.client(url, {
      method: 'POST',
      body,
    });
  },
  put(url: string, body: any) {
    return this.client(url, {
      method: 'PUT',
      body,
    });
  },
  delete(url: string) {
    return this.client(url, {
      method: 'DELETE',
    });
  },
};
