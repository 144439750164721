import { observer } from 'mobx-react-lite';

import { useProductGroups } from '../../../hooks/useStores';
import ProductGroupSetBox from './ProductGroupSetBox';

const ProductGroupsList = (): JSX.Element => {
  const { productGroupSets } = useProductGroups();

  return (
    <>
      {productGroupSets.length !== 0 &&
        productGroupSets.map((groupSet: any) => (
          <ProductGroupSetBox key={groupSet.id} groupSet={groupSet} />
        ))}
    </>
  );
};

export default observer(ProductGroupsList);
