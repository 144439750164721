import { useState } from 'react';
import { observer } from 'mobx-react';
import { isAndroid } from 'react-device-detect';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Box,
  Tooltip,
  useDisclosure,
  Button as ChakraButton,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
} from '@chakra-ui/react';
import Recipe from '../../../models/Recipe';
import { Instance } from 'mobx-state-tree';
import { PrintIcon } from '../../../components/Icons/Icons';
import useAPI from '../../../hooks/useAPI';
import FakeProgressModal from '../../../components/FakeProgressModal/FakeProgressModal';
import { base64ToUrl, printInNewWindow } from '../../../print';
import OrdersOpenDocumentModal from '../../OrdersPage/OrdersOpenDocumentModal';
import { useErrorToast } from '../../../components/toast';
import { Button } from '../../../components/Button/Button';

const PrintAction = ({
  recipe,
}: {
  recipe: Instance<typeof Recipe>;
}): JSX.Element => {
  let [processingIsDone, setProcessingIsDone] = useState<boolean>(false);
  let [modalIsOpen, setModalIsOpen] = useState(false);
  let [quantityModalIsOpen, setQuantityModalIsOpen] = useState(false);
  let [quantity, setQuantity] = useState(recipe.totalQuantity);
  const errorToast = useErrorToast();

  const [popupsBlocked, setPopupsBlocked] = useState(false);

  const [postRequest] = useAPI({
    method: 'POST',
  });

  const onClickPrint = () => {
    setModalIsOpen(true);
    postRequest(
      `/v4/recipes/${recipe.id}/document`,
      {
        body: JSON.stringify({
          quantity: quantity,
        }),
      },
      true,
    )
      .then(printBase64)
      .catch(handleError);
  };

  const afterProcessing = () => {
    setModalIsOpen(false);
    setQuantityModalIsOpen(false);
    setProcessingIsDone(false);
  };

  const printBase64 = async (response: any) => {
    setProcessingIsDone(true);
    if (typeof window === 'undefined') {
      return '';
    }

    const data = await response.text();
    const pdfUrl = base64ToUrl(data);
    setPrintUrl(pdfUrl);
    printDialogOnOpen();
    print(pdfUrl);
  };

  const handleError = () => {
    setModalIsOpen(false);
    setProcessingIsDone(false);
    errorToast();
  };

  const {
    isOpen: printDialogIsOpen,
    onOpen: printDialogOnOpen,
    onClose: printDialogOnClose,
  } = useDisclosure();
  const [printUrl, setPrintUrl] = useState('');

  const print = (url: any) => {
    printInNewWindow({
      url,
      onPopupBlocked: () => {
        setPopupsBlocked(true);
      },
    });
  };

  const showAndroidWarning = isAndroid;

  return (
    <>
      <Tooltip label="Print recipe">
        <ChakraButton
          onClick={() => {
            setQuantityModalIsOpen(true);
          }}
          leftIcon={<PrintIcon width="24px" />}
        >
          Print
        </ChakraButton>
      </Tooltip>

      <Modal
        isOpen={quantityModalIsOpen}
        onClose={() => {
          setQuantityModalIsOpen(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose quantity</ModalHeader>
          <ModalBody>
            <InputGroup>
              <Input
                type="number"
                min={0}
                value={quantity}
                onFocus={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  target.select();
                }}
                onChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  setQuantity(Number(target.value));
                }}
                textAlign={'right'}
                borderRadius="6px"
                placeholder="0"
                minWidth="75px"
              />
              <InputRightAddon children={recipe.unit} />
            </InputGroup>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup spacing="4">
              <Button
                variant="tertiary"
                onClick={() => {
                  setQuantityModalIsOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={onClickPrint}>
                Print
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <FakeProgressModal
        mainText={`Preparing recipe for printing`}
        isOpen={modalIsOpen}
        isDone={processingIsDone}
        totalItems={10}
        timePerItemMs={150}
        bottomChildren={
          showAndroidWarning ? (
            <Alert status="warning" size="sm">
              <AlertIcon />
              <Box>
                <AlertTitle>Issues while using an Android device?</AlertTitle>
                <AlertDescription>
                  If printing isn't working, try another browser or use your
                  laptop / desktop computer.
                </AlertDescription>
              </Box>
            </Alert>
          ) : (
            ''
          )
        }
      />

      <OrdersOpenDocumentModal
        isOpen={printDialogIsOpen}
        arePopupsBlocked={popupsBlocked}
        onClose={() => {
          printDialogOnClose();
          afterProcessing();
        }}
        onClick={() => print(printUrl)}
      />
    </>
  );
};

export default observer(PrintAction);
