import { useEffect, useState } from 'react';
import useAPI from '../../../../hooks/useAPI';
import useJobStatus from '../../../../hooks/useJobStatus';
import moment from 'moment';
import ProcessingModal from '../../../../components/ProcessingModal/ProcessingModal';
import { without } from 'lodash';

import {
  Box,
  ButtonGroup,
  Center,
  Text,
  VStack,
  Spinner,
  Progress,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { Button } from '../../../../components/Button/Button';
import { Subhead } from '../../../../components/Typography/Typography';
import useAccountingMeta from '../../../../hooks/useAccountingMeta';
import SelectModalBody from '../../../../components/SelectCustomersModal/SelectModalBody';

const SyncStatus = ({
  connectorType,
  connectorStatus,
}: {
  connectorType: string;
  connectorStatus: string;
}): JSX.Element => {
  const {
    isOpen: isProductsSelectOpen,
    onOpen: onProductsSelectOpen,
    onClose: onProductsSelectClose,
  } = useDisclosure();
  const { accountingName } = useAccountingMeta(connectorType);
  const [setJobId, isJobProcessing, jobStatus, jobData] = useJobStatus(
    500,
    fetchStatus,
  );
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [statusData, setStatusData] = useState<any | null>(null);
  const [products, setProducts] = useState<any | null>(null);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [setupStep, setSetupStep] = useState<string | null>(null);

  const {
    isOpen: isSetupModalOpen,
    onOpen: onSetupModalOpen,
    onClose: onSetupModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (connectorStatus == 'setup_required') {
      setSetupStep('confirmation_required');
    }
  }, []);

  const setupSteps = [
    'confirmation_required',
    'initial_sync',
    'select_products',
    'complete',
  ];
  const nextStep = () => {
    if (setupStep != null) {
      const currentIndex = setupSteps.indexOf(setupStep);
      if (currentIndex < setupSteps.length - 1) {
        setSetupStep(setupSteps[currentIndex + 1]);
      }
    }
  };

  useEffect(() => {
    switch (setupStep) {
      case 'confirmation_required':
        onSetupModalOpen();
        break;
      case 'initial_sync':
        onSetupModalClose();
        // onImportProducts sets to manual sync, and triggers a syncNow after.
        onImportProducts();
        break;
      case 'select_products':
        onOpenProductsSelect();
        break;
      case 'complete':
        finaliseSetup();
        break;
    }
  }, [setupStep]);

  const [putSyncAllNowRequest, putSyncAllNowIsLoading] = useAPI({
    method: 'PUT',
  });
  const [getStatusRequest, getStatusIsLoading] = useAPI({
    method: 'GET',
  });
  const [getProductsRequest, getProductsLoading] = useAPI({
    method: 'GET',
  });
  const [importProductsRequest, importProductsLoading] = useAPI({
    method: 'PUT',
  });
  const [putSetupRequest, putSetupRequestIsLoading] = useAPI({
    method: 'PUT',
  });

  useEffect(() => {
    if (isJobProcessing && jobStatus && !isProgressModalOpen) {
      setIsProgressModalOpen(true);
    }

    if (!isJobProcessing && isProgressModalOpen) {
      setTimeout(() => {
        setIsProgressModalOpen(false);
        nextStep();
      }, 2000);
    }
  }, [isJobProcessing]);

  useEffect(() => {
    fetchStatus();
  }, []);

  const lastSyncedAt =
    statusData && statusData.sync_ended_at
      ? moment(statusData.sync_ended_at)
      : null;

  function fetchStatus() {
    setStatusData(null);
    getStatusRequest('/v4/accounting/sync/status').then((data: any) => {
      setStatusData(data);
    });
  }

  function fetchProducts() {
    getProductsRequest('/v4/accounting/products').then((data: any) => {
      let newSelectedIds: string[] = [];
      data.forEach((product: any) => {
        product.id = String(product.id);
        if (product.syncing_enabled) {
          newSelectedIds.push(product.id);
        }
      });
      setSelectedProductIds(newSelectedIds);
      setProducts(data);
    });
  }

  const onImportProducts = () => {
    onProductsSelectClose();
    importProductsRequest('/v4/accounting/products', {
      body: JSON.stringify({
        accounting_product_ids: selectedProductIds,
        sync_strategy: 'manual',
      }),
    }).then(() => {
      syncNow();
    });
  };

  const syncNow = () => {
    putSyncAllNowRequest('/v4/accounting/sync/sync_all_now')
      .then((data: any) => {
        setJobId(data.jid);
      })
      .catch(() => {});
  };

  const finaliseSetup = () => {
    putSetupRequest('/v4/accounting_connectors/cin7_core/setup').then(() => {
      window.location.reload();
    });
  };

  const onSelectAll = (value: boolean) => {
    console.log('onSelectAll value', value);
    if (value) {
      setSelectedProductIds(products.map((product: any) => product.id));
    } else {
      setSelectedProductIds([]);
    }
  };

  const percentageComplete =
    jobData && jobData.num_total && jobData.num_synced && jobData.num_errors
      ? ((Number(jobData.num_synced) + Number(jobData.num_errors)) /
          Number(jobData.num_total)) *
        100
      : undefined;

  const onOpenProductsSelect = () => {
    fetchProducts();
    onProductsSelectOpen();
  };

  const workingTextByJobData = (jobData: any) => {
    switch (jobData.sync_service) {
      case 'contact':
        return 'Getting customer data...';
      case 'product':
        return 'Getting product data...';
      case 'pricing_tier':
        return 'Getting pricing tier data...';
      default:
        return 'Analysing data...';
    }
  };

  return (
    <Box>
      <Subhead fontWeight={600}>Synced Data</Subhead>

      <Box pt="6">
        {statusData ? (
          <VStack spacing="6" alignItems="left">
            {getStatusIsLoading ? (
              <Spinner size="sm" color="green" />
            ) : (
              <VStack alignItems="left">
                <Text>
                  HospoConnect will pull through your pricing tiers, any
                  products you select, including their prices per pricing tier,
                  and any mapped customers will be put into the correct pricing
                  tier for you.
                </Text>
                <Text>
                  Once your products are synced for the first time, subsequent
                  syncs should be faster, as it will only pull through new or
                  changed data.
                </Text>
                {lastSyncedAt && (
                  <Text fontSize="12">
                    Last full sync completed{' '}
                    {lastSyncedAt && lastSyncedAt.fromNow()}
                  </Text>
                )}
              </VStack>
            )}

            <ButtonGroup isDisabled={connectorStatus == 'setup_required'}>
              {statusData && (
                <Button
                  onClick={() => {
                    syncNow();
                  }}
                  colorScheme="green"
                  isLoading={putSyncAllNowIsLoading || getStatusIsLoading}
                >
                  Sync all now
                </Button>
              )}
              {statusData && (
                <Button
                  onClick={onOpenProductsSelect}
                  colorScheme="blue"
                  isLoading={putSyncAllNowIsLoading || getStatusIsLoading}
                >
                  Manage synced products
                </Button>
              )}
            </ButtonGroup>
          </VStack>
        ) : (
          <Spinner size="lg" thickness="3px" color="green" />
        )}
      </Box>

      <ProcessingModal isOpen={isProgressModalOpen}>
        <VStack alignItems="center" spacing="6" width="100%">
          <Text>
            {jobStatus == 'retrying' &&
              `Something went wrong... trying again soon.`}
            {jobStatus == 'queued' && `Data is waiting to begin syncing...`}
            {jobStatus == 'working' && workingTextByJobData(jobData)}
            {jobStatus == 'complete' && `Finished!`}
          </Text>
          <Progress
            width="100%"
            colorScheme="green"
            size="sm"
            isIndeterminate={
              jobStatus == 'retrying' || percentageComplete === undefined
            }
            value={percentageComplete}
          />

          {setupStep && (
            <Text fontSize="sm" color="gray.600">
              {setupStep == 'initial_sync' &&
                'This is an initial shallow sync of your data'}
              {setupStep == 'select_products' &&
                'This is a deeper sync, to collect data specific to your chosen products'}
            </Text>
          )}
        </VStack>
      </ProcessingModal>

      <Modal isOpen={isSetupModalOpen} onClose={onSetupModalClose} size="xl">
        <ModalOverlay />
        <ModalContent maxH="80vh">
          <ModalCloseButton />
          <ModalHeader>Setup required</ModalHeader>
          <ModalBody
            position="relative"
            overflow="hidden"
            display="flex"
            flexDirection="column"
          >
            <Text>
              In order to get started, we need to pull in data on your pricing
              tiers, customers and products.
            </Text>
          </ModalBody>
          <ModalFooter backgroundColor="gray.100" borderRadius="0 0 8px 8px">
            <ButtonGroup>
              <Button variant="secondary" onClick={onSetupModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={nextStep}>
                Let's go!
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isProductsSelectOpen}
        onClose={onProductsSelectClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent maxH="80vh">
          <ModalCloseButton />
          <ModalHeader>Manage synced products</ModalHeader>
          <ModalBody
            position="relative"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            px={0}
            pb={0}
          >
            {getProductsLoading && (
              <Center>
                <Spinner size="lg" color="green" />
              </Center>
            )}
            {!getProductsLoading && products && (
              <SelectModalBody
                search
                onSelectAll={onSelectAll}
                onSelect={(productId, checked) => {
                  if (checked) {
                    setSelectedProductIds(
                      selectedProductIds.concat([productId]),
                    );
                  } else {
                    setSelectedProductIds(
                      without(selectedProductIds, productId),
                    );
                  }
                }}
                options={products.map((product: any) => {
                  return {
                    value: product.id,
                    label: product.name,
                    selected: selectedProductIds.includes(product.id),
                    subtext: product.product_code,
                    inputId: `product-${product.id}`,
                  };
                })}
              />
            )}
          </ModalBody>
          <ModalFooter backgroundColor="gray.100" borderRadius="0 0 8px 8px">
            <ButtonGroup>
              <Button variant="secondary" onClick={onProductsSelectClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                isDisabled={selectedProductIds.length == 0}
                onClick={onImportProducts}
                isLoading={importProductsLoading}
              >
                Sync {selectedProductIds.length} products
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SyncStatus;
