import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useStores';
import {
  Day,
  StandingOrder,
  StandingOrderItem,
} from '../../stores/standingOrders/standingOrder';
import {
  Alert,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button as ChakraButton,
  IconButton,
  HStack,
  VStack,
  Box,
  Tooltip,
  Spacer,
  Input,
  Link,
  AlertIcon,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
} from '@chakra-ui/react';
import { TimeIcon } from '@chakra-ui/icons';
import { Caption, Subhead } from '../Typography/Typography';
import InactiveTag from '../InactiveTag/InactiveTag';
import { AddIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';

import useAPI from '../../hooks/useAPI';
import AddProductModal from '../AddProductModal/AddProductModal';
import SelectUnit from '../SelectUnit/SelectUnit';
import { CrossIcon } from '../Icons/Icons';
import { Button } from '../Button/Button';
import { getMinUnits } from '../../models/Product';

import { MessageIcon16x16, EditIcon } from '../../components/Icons/Icons';
import NoteEditorWrapper from '../../components/NoteEditor/NoteEditorWrapper';
import NoteBox from '../../components/NoteBox/NoteBox';

const StandingOrderCell = observer(
  ({ item, amount, hasAmount, onChange, isEditable, isDisabled }: any) => {
    return isDisabled ? (
      <Td textAlign="center" color="gray.400">
        -
      </Td>
    ) : isEditable ? (
      <Td>
        <Input
          type="number"
          min={0}
          disabled={isDisabled}
          defaultValue={item[amount]}
          placeholder="0"
          onChange={onChange}
          textAlign="right"
          width="100%"
        />
      </Td>
    ) : hasAmount ? (
      <Td textAlign="center">{item[amount]}</Td>
    ) : (
      <Td textAlign="center" color="gray.400">
        0
      </Td>
    );
  },
);

const ProductDetail = ({ product }: any) => {
  const isInactive = product.active === false;
  const minUnits = getMinUnits(product.units);

  return (
    <VStack w="100%" spacing="1" alignItems="left">
      {isInactive && (
        <Box mb={1}>
          <InactiveTag />
        </Box>
      )}
      <Box>
        <Text color="gray.700" fontWeight="400">
          {product.name}{' '}
          {product.product_code && (
            <Text as="span" fontWeight="500">
              {product.product_code}
            </Text>
          )}
        </Text>
      </Box>
      {product.lead_time_hours > 0 && (
        <HStack spacing="1">
          <TimeIcon color="error.amber" />
          <Caption fontWeight="semibold" color="error.amber">
            {product.lead_time_hours} hour lead time
          </Caption>
        </HStack>
      )}
      <Box as="span" display="flex" w="100%" position="relative">
        <Caption color="gray.500">
          <HStack>
            <Box>
              {minUnits.length > 0 && (
                <Text as="span" fontWeight="semibold">
                  Minimum&nbsp;
                  {minUnits.map((unit: any, i: number) => (
                    <span key={unit.unit_name}>
                      {Number(unit.quantity)}
                      {unit.unit_name}
                      {i < minUnits.length - 1 ? ', ' : ''}
                    </span>
                  ))}
                </Text>
              )}
            </Box>
          </HStack>
        </Caption>
      </Box>
    </VStack>
  );
};

const StandingOrderRow = observer(
  ({
    item,
    standingOrder,
    isEditable,
  }: {
    item: StandingOrderItem;
    standingOrder: StandingOrder;
    isEditable: boolean;
  }) => {
    const {
      buyable: { product },
      unit,
    } = item;
    const [selectedUnit, setSelectedUnit] = useState(unit);

    const isInactive = product.active === false;
    const onClickRemove = () => {
      item.remove();
    };

    let total = 0;

    if (item.destroy) return <></>;

    return isEditable ? (
      <Tr>
        <Td textAlign="start">
          <VStack alignItems={'left'}>
            <HStack>
              <Tooltip label="Remove">
                <IconButton
                  aria-label="Remove"
                  colorScheme="red"
                  color="red.600"
                  _hover={{ backgroundColor: 'red.100' }}
                  variant="ghost"
                  size="sm"
                  icon={<CrossIcon />}
                  onClick={onClickRemove}
                />
              </Tooltip>
              <Box ml="8">
                <ProductDetail product={product} />
              </Box>
            </HStack>

            {item.notes && (
              <NoteBox border="1px solid" borderColor="gray.200">
                "{item.notes}"
                <NoteEditorWrapper
                  onSave={(newValue: string) => {
                    item.setNotes(newValue);
                  }}
                  initialValue={item.notes || ''}
                  noteEditorLabel={item.notes ? 'Edit note' : 'Add note'}
                  iconForButton={<EditIcon w="16px" />}
                />
              </NoteBox>
            )}
          </VStack>
        </Td>
        <Td>
          <NoteEditorWrapper
            onSave={(newValue: string) => {
              item.setNotes(newValue);
            }}
            initialValue={item.notes || ''}
            noteEditorLabel={item.notes ? 'Edit note' : 'Add note'}
            iconForButton={<MessageIcon16x16 />}
          />
        </Td>
        <Td>
          <SelectUnit
            units={product.units}
            unit={selectedUnit}
            isInactive={isInactive}
            onChange={(unit) => {
              setSelectedUnit(unit);
              item.setUnit(unit.id);
            }}
          />
        </Td>
        {standingOrder.days.map((day: Day) => {
          const isDisabled = !day.enabled;
          const hasAmount = !!item[day.key];
          if (!isDisabled) {
            total = total + (item[day.key] || 0);
          }
          const standingOrderCell = (
            <StandingOrderCell
              key={day.key}
              item={item}
              amount={day.key}
              onChange={(e: any) => {
                item.setAmount(day.key, Number(e.target.value));
              }}
              hasAmount={hasAmount}
              isEditable={isEditable}
              isDisabled={isDisabled}
            />
          );
          return standingOrderCell;
        })}
        <Td textAlign="right">
          <Text>{total ? `${total}${selectedUnit.name}` : '-'}</Text>
        </Td>
      </Tr>
    ) : (
      <Tr>
        <Td textAlign="start">
          <VStack alignItems={'left'}>
            <ProductDetail product={product} />

            {item.notes && (
              <NoteBox border="1px solid" borderColor="gray.200">
                "{item.notes}"
              </NoteBox>
            )}
          </VStack>
        </Td>
        <Td>
          <Text>{selectedUnit.name}</Text>
        </Td>
        {standingOrder.days.map((day: Day) => {
          const isDisabled = !day.enabled;
          const hasAmount = !!item[day.key];
          if (!isDisabled) {
            total = total + (item[day.key] || 0);
          }
          const standingOrderCell = (
            <StandingOrderCell
              key={day.key}
              item={item}
              amount={day.key}
              hasAmount={hasAmount}
              isEditable={isEditable}
              isDisabled={isDisabled}
            />
          );
          return standingOrderCell;
        })}
        <Td textAlign="right">
          <Text>{total ? `${total}${selectedUnit.name}` : '-'}</Text>
        </Td>
      </Tr>
    );
  },
);

function StandingOrderTable({
  standingOrder,
  isEditable,
}: {
  standingOrder: StandingOrder | undefined;
  isEditable: boolean;
}) {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getRequest] = useAPI({
    method: 'GET',
  });
  const { isBuyer } = useCurrentUser();

  useEffect(() => {
    if (
      isEditable &&
      standingOrder &&
      !standingOrder.id &&
      !standingOrder.hasProducts
    ) {
      setIsModalOpen(true);
    }
  }, []);

  if (!standingOrder) return <></>;

  const hasLeadTimes = standingOrder.items.some((item: any) => {
    return item.buyable.product.lead_time_hours > 0 && !item.destroy;
  });

  const showDeliveryRulesError =
    isEmpty(standingOrder.deliveryRules) && !standingOrder.deliveryRulesEnabled;

  return (
    <VStack alignItems="start">
      <Subhead fontWeight={600}>Products</Subhead>

      {standingOrder.hasProducts ? (
        <Table
          variant="standingorder"
          className={isEditable ? 'editing' : undefined}
        >
          <Thead>
            <Tr>
              <Th textAlign="start" minWidth={{ base: '100px', xl: '300px' }}>
                <VStack alignItems={'left'}>
                  <Text>Deliver on</Text>
                </VStack>
              </Th>
              {isEditable && <Th maxW="20px" />}
              <Th minW="100px" maxW="100px" />
              {standingOrder.days.map((day: Day) => {
                const hasAmounts = standingOrder.dayHasAmounts(day);
                const dayData = standingOrder.scheduleDataForIsoWeekday(
                  day.isoWeekday,
                );
                const nextDate = moment(
                  dayData?.next_delivery_date,
                  'YYYY-MM-DD',
                );
                const cutOff = moment(dayData?.cutoff, 'YYYY-MM-DD HH:mm:ss');
                const generatesAt = moment(
                  dayData?.generates_at,
                  'YYYY-MM-DD HH:mm:ss',
                );
                const productIdsForDay = standingOrder.productIdsForDay(day);
                const alreadyOrderedProductIds =
                  dayData?.already_ordered_product_ids || [];
                const remainingProductIds = productIdsForDay.filter(
                  (x) => x && !alreadyOrderedProductIds.includes(x),
                );
                const alreadyFullyOrdered = remainingProductIds.length === 0;
                return (
                  <Th
                    key={day.key}
                    minWidth={{ base: '58px', xl: '68px' }}
                    textAlign={isEditable ? 'center' : 'right'}
                  >
                    {dayData ? (
                      <Popover trigger={!isEditable ? 'hover' : undefined}>
                        <PopoverTrigger>
                          <VStack>
                            <Text
                              color={day.enabled ? 'default' : 'grey.400'}
                              cursor={!isEditable ? 'pointer' : 'default'}
                            >
                              {day.name}
                            </Text>
                          </VStack>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverHeader bgColor={'gray.50'}>
                              <strong>
                                Next up: {nextDate.format('dddd DD MMMM')}
                              </strong>
                            </PopoverHeader>
                            <PopoverBody>
                              {hasAmounts ? (
                                <>
                                  {!isBuyer && (
                                    <Text fontSize={'sm'}>
                                      <strong>Order cut-off:</strong> <br />{' '}
                                      {cutOff.format('h:mma dddd DD MMMM')}
                                    </Text>
                                  )}
                                  <Text fontSize={'sm'}>
                                    <strong>
                                      {isBuyer
                                        ? 'You can make edits up until:'
                                        : 'Auto-generates at approximately:'}
                                    </strong>{' '}
                                    <br />{' '}
                                    {generatesAt.format('h:mma dddd DD MMMM')}
                                  </Text>
                                  <br />
                                  {alreadyOrderedProductIds.length > 0 ? (
                                    <>
                                      {alreadyFullyOrdered ? (
                                        <Text
                                          fontSize={'sm'}
                                          color="yellow.600"
                                        >
                                          All products already ordered.
                                        </Text>
                                      ) : (
                                        <Text
                                          fontSize={'sm'}
                                          color="yellow.600"
                                        >
                                          Some items have already been ordered.
                                        </Text>
                                      )}
                                    </>
                                  ) : (
                                    <Text fontSize={'sm'}>
                                      To make one-off edits to the order for
                                      this date, use the link below.
                                    </Text>
                                  )}
                                </>
                              ) : (
                                <Text fontSize={'sm'}>
                                  No products selected for{' '}
                                  {nextDate.format('dddd')}s.
                                </Text>
                              )}
                            </PopoverBody>
                            <PopoverFooter bgColor={'gray.50'}>
                              <ChakraButton
                                variant="tertiary"
                                size={'sm'}
                                colorScheme="green"
                                isDisabled={
                                  !day.enabled ||
                                  !hasAmounts ||
                                  alreadyFullyOrdered
                                }
                                onClick={() => {
                                  history.push(
                                    `/standing-orders/${
                                      standingOrder.id
                                    }/create-now/${nextDate.format(
                                      'YYYY-MM-DD',
                                    )}`,
                                  );
                                }}
                              >
                                {alreadyOrderedProductIds.length > 0
                                  ? 'Order remaining products now'
                                  : 'Edit and place order now'}
                              </ChakraButton>
                            </PopoverFooter>
                          </PopoverContent>
                        </Portal>
                      </Popover>
                    ) : (
                      <VStack>
                        <Text
                          color={day.enabled ? 'default' : 'grey.400'}
                          textDecoration={
                            !day.enabled ? 'line-through' : 'default'
                          }
                        >
                          {day.name}
                        </Text>
                      </VStack>
                    )}
                  </Th>
                );
              })}
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {standingOrder.sortedItems.map((item: StandingOrderItem) => (
              <StandingOrderRow
                key={item.id}
                item={item}
                isEditable={isEditable}
                standingOrder={standingOrder}
              />
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text>No products added yet</Text>
      )}
      {isEditable && (
        <Button
          leftIcon={<AddIcon />}
          marginTop="4"
          onClick={() => setIsModalOpen(true)}
        >
          {standingOrder.hasProducts ? 'Add another product' : 'Add a product'}
        </Button>
      )}

      <AddProductModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        showPrices={false}
        showMinimums={false}
        showLeadTimes={false}
        showDescription={false}
        addedProducts={standingOrder.currentProducts}
        onAdd={(product: any) => {
          getRequest(
            `/v4/products/${product.id}?customer_id=${standingOrder.customer.id}&supplier_id=${standingOrder.supplier.id}`,
          ).then((data) => {
            standingOrder.addProduct(data);
          });
        }}
        customerId={standingOrder.customer.id}
        supplierId={standingOrder.supplier.id}
      />

      {showDeliveryRulesError ? (
        <Alert status="error" borderRadius="6px">
          <AlertIcon />
          <AlertDescription>
            You have not set up delivery days and cut-off times. We recommend
            setting this up in Settings so that standing orders are received
            within your cut-off times.{' '}
            <Link color="gray.900" href="/settings/delivery-days">
              Set up now
            </Link>
          </AlertDescription>
        </Alert>
      ) : (
        <Caption color="gray.600" fontSize="xs">
          Standing orders will appear in your Orders before the cut-off time.
          <br />
          <Link
            color="gray.900"
            fontWeight={400}
            href="/settings/delivery-days"
          >
            Set up delivery days &amp; cut-off times
          </Link>{' '}
          in Settings.
          {hasLeadTimes && (
            <>
              <br />
              <Text color="error.amber">
                Note: Products with lead times will be created earlier as a
                separate order, according to their lead time.
              </Text>
            </>
          )}
        </Caption>
      )}
    </VStack>
  );
}

export default observer(StandingOrderTable);
