import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  ButtonGroup,
  useDisclosure,
  Select,
  FormControl,
  Flex,
  Box,
  FormLabel,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import DetailView from '../../../components/DetailView/DetailView';
import { ChevronRightIcon } from '../../../components/Icons/IconsNew';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import {
  Footnote,
  H4,
  Headline,
  Label,
} from '../../../components/Typography/Typography';
import { useProductGroups } from '../../../hooks/useStores';
import { ProductGroupSet } from '../../../models/ProductGroupSet';

interface Product {
  id: number;
  name: string;
}

const FieldWrapper = ({ label, children, direction = 'row' }: any) => {
  return (
    <FormControl>
      <Flex flexDirection={direction}>
        <Box width="184px" minWidth="184px" pr="12px">
          <FormLabel as={Label} color="gray.600" fontWeight="500">
            {label}
          </FormLabel>
        </Box>
        <Box>{children}</Box>
      </Flex>
    </FormControl>
  );
};

const AssignProductModal = ({ product, setProduct, isOpen, onClose }: any) => {
  const { currentGroupSet } = useProductGroups();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const [currentGroupId, setCurrentGroupId] = useState<number | null>(null);

  const selectGroup = (e: any) => {
    setCurrentGroupId(Number(e.target.value));
  };
  const handleClose = () => {
    setProduct(null);
    setCurrentGroupId(null);
    onClose();
  };
  const handleAssign = () => {
    if (product && currentGroupId) {
      const group = currentGroupSet.groupsList[currentGroupId];
      group
        .assignProduct(product)
        .then(() => {
          handleClose();
          successToast({
            description: `‘${product.name}’ assigned to ${currentGroupSet.name}: ${group.name}`,
          });
        })
        .catch(() => {
          handleClose();
          errorToast({
            description: `Something went wrong and ‘${product.name}’ was not assigned to ${currentGroupSet.name}: ${group.name}. Please try again`,
          });
        });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Headline fontWeight="600">Assign {`‘${product.name}’`}</Headline>
        </ModalHeader>
        <ModalBody mt="28px" mb="24px">
          <FieldWrapper label={currentGroupSet.name} direction="column">
            <Select onChange={selectGroup}>
              <option value="placeholder-group-id" color="gray.400">
                {currentGroupSet.unassignedGroupName}
              </option>
              {currentGroupSet &&
                currentGroupSet.groups.map((group: any, i: number) => {
                  return (
                    <option key={`group-${i}`} value={group.id}>
                      {group.name}
                    </option>
                  );
                })}
            </Select>
          </FieldWrapper>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup colorScheme="green">
            <Button onClick={handleClose} variant="tertiary">
              Cancel
            </Button>
            <Button
              onClick={handleAssign}
              variant="primary"
              isDisabled={!currentGroupId}
            >
              Assign
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface UnassignedProductsProps {
  groupSet: ProductGroupSet;
}
const UnassignedProducts = ({ groupSet }: UnassignedProductsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentProduct, setCurrentProduct] = useState<Product | null>(null);

  const selectProductToAssign = (product: Product) => {
    setCurrentProduct(product);
    onOpen();
  };

  useEffect(() => {
    groupSet.getUnassignedProducts().then(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingDetail />;
  }
  return (
    <>
      {currentProduct && (
        <AssignProductModal
          product={currentProduct}
          setProduct={setCurrentProduct}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <DetailView leftActions={<H4 my="3">{groupSet.unassignedGroupName}</H4>}>
        {groupSet.unassignedProducts &&
        groupSet.unassignedProducts.length > 0 ? (
          <Table variant="orderitems" maxWidth="758px" width="100%" my="10">
            <Thead>
              <Tr>
                <Th>Products</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {groupSet.unassignedProducts.map((product: any) => (
                <Tr key={product.id}>
                  <Td>
                    <Footnote>{product.name}</Footnote>
                  </Td>
                  <Td textAlign="right">
                    {groupSet.groups.length > 0 && (
                      <IconButton
                        aria-label="Assign product to group"
                        variant="ghost"
                        icon={
                          <ChevronRightIcon color="gray.400" width="16px" />
                        }
                        onClick={() => selectProductToAssign(product)}
                      />
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Box py="24px">
            <H4 fontWeight="500">
              Sweet! All products are assigned to a product group.
            </H4>
          </Box>
        )}
      </DetailView>
    </>
  );
};

export default UnassignedProducts;
