import React, { ForwardedRef } from 'react';
import { getColourSchemeByStatus } from '../../utils';
import { BoxProps } from '@chakra-ui/layout';

import { Box, forwardRef } from '@chakra-ui/react';

type StatusDotProps = {
  status: string;
};

const StatusDot = (props: BoxProps & StatusDotProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
  const { status, ...rest } = props;
  return (
    <Box
      backgroundColor={`${getColourSchemeByStatus(status)}.400`}
      w="10px"
      h="10px"
      borderRadius="full"
      display="inline-block"
      ref={ref}
      {...rest}
    />
  );
};

export default forwardRef(StatusDot);
