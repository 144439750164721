import React from 'react';
import { FlexProps } from '@chakra-ui/layout';
import { Divider, Flex } from '@chakra-ui/react';

const ActionsWrapper = (props: FlexProps): JSX.Element => {
  return (
    <>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        justify="space-between"
        py="2"
        px="40px"
        bg="white"
        minHeight="56px"
        {...props}
      >
        {props.children}
      </Flex>
      <Divider />
    </>
  );
};

export default ActionsWrapper;
