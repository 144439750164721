import { types } from 'mobx-state-tree';

const Customer = types
  .model('Customer', {
    id: types.number,
    name: types.string,
    logo_url: types.maybeNull(types.string),
    default_address: types.maybeNull(types.string),
    default_instructions: types.maybeNull(types.string)
  })
  .views((self) => ({
    get logo() {
      if (self.logo_url) return self.logo_url;
      return undefined;
    },
  }));

export default Customer;
