import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Heading,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { Instance, SnapshotOrInstance } from 'mobx-state-tree';

import DetailView from '../../../components/DetailView/DetailView';
import {
  UserCompany,
  UserCompanyEditFormValues,
} from '../../../models/UserCompany';
import CompanyProfileContactFields from './CompanyProfileContactFields';
import CompanyProfileFields from './CompanyProfileFields';
import { Formik, FormikHelpers } from 'formik';
import CompanyProfileAddressFields from './CompanyProfileAddressFields';
import Address, { AddressFieldComponents } from '../../../models/Address';
import * as yup from 'yup';
import { Button } from '../../../components/Button/Button';

const addressValidationSchema = yup.object().shape({
  unit: yup.string().nullable(),
  street: yup.string().nullable().required('Street is required'),
  suburb: yup.string().nullable(),
  city: yup.string().nullable().required('City is required'),
  post_code: yup.string().nullable().required('Postcode is required'),
  instructions: yup.string().nullable(),
});

const profileValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  about: yup.string().nullable(),
  phone: yup.string().nullable(),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  addresses: yup.array().of(addressValidationSchema),
});

const EditCompanyProfile = ({
  company,
}: {
  company: Instance<typeof UserCompany>;
}) => {
  const history = useHistory();

  const onCancel = () => {
    history.push(`/profile`);
  };

  const onSave = (
    values: UserCompanyEditFormValues,
    formikBag: FormikHelpers<UserCompanyEditFormValues>,
  ) => {
    const { addresses, ...companyValues } = values;
    company
      .updateAddresses(addresses || [])
      .then(() => {
        company.updateCompany(
          companyValues,
          () => {
            history.push(`/profile`);
          },
          () => {
            formikBag.setSubmitting(false);
          },
        );
      })
      .catch((error) => {
        console.log(error);
        formikBag.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={company.editFormValues}
      validationSchema={profileValidationSchema}
      onSubmit={(values, formikHelpers) => {
        onSave(values, formikHelpers);
      }}
    >
      {({
        values,
        handleChange,
        submitForm,
        isSubmitting,
        setFieldValue,
        errors,
      }) => (
        <DetailView
          leftActions={
            <Heading size="md" as="h4">
              {company?.name}
            </Heading>
          }
          rightActions={
            <>
              <Button size="sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={submitForm}
                isLoading={isSubmitting}
                isDisabled={isSubmitting || Object.keys(errors).length > 0}
              >
                Save
              </Button>
            </>
          }
        >
          <Box style={{ backgroundColor: 'white', marginBottom: '80px' }}>
            {company ? (
              <>
                <VStack align="left" spacing="8" mt="16">
                  <CompanyProfileFields
                    company={values}
                    onChange={handleChange}
                    isEditable={true}
                  />
                  <Divider />
                  <CompanyProfileContactFields
                    company={values}
                    onChange={handleChange}
                    isEditable={true}
                  />
                  {company.isBuyer && (
                    <>
                      <Divider />
                      <CompanyProfileAddressFields
                        company={values}
                        onChange={(
                          index: number,
                          address: SnapshotOrInstance<typeof Address>,
                        ) => {
                          setFieldValue(`addresses[${index}]`, address);
                        }}
                        isEditable={true}
                      />
                    </>
                  )}
                </VStack>
              </>
            ) : (
              <>Oh no! Something went wrong!</>
            )}
          </Box>
        </DetailView>
      )}
    </Formik>
  );
};
export default observer(EditCompanyProfile);
