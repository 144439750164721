import { Fragment, ReactElement, ReactNode } from 'react';
import { Box, Center, Stack, VStack } from '@chakra-ui/react';

import TwoUpLeftScrollPanel from '../Layouts/TwoUpLeftScrollPanel';
import ListRow from '../ListRow/ListRow';
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';
import Pagination from '../Pagination/Pagination';
import { Footnote } from '../Typography/Typography';
import LoadingToast from '../LoadingToast/LoadingToast';
import { isUndefined } from 'lodash';
import { observer } from 'mobx-react';

export interface ListViewProps<ListItem> {
  actionChildren: ReactNode;
  items: Array<ListItem>;
  renderItem: (props: {
    item: ListItem;
    index: number;
    extraProps?: any;
  }) => ReactElement<typeof ListRow>;
  lastItem?: (extraProps?: any) => ReactNode;
  isLoading?: boolean;
  isCard?: boolean;
  isEmpty?: boolean;
  emptyChildren?: ReactNode;
  pagination?: {
    handlePageChange: (val: number) => void;
    currentPage: number;
    pageCount: number;
    totalCount: number;
    itemsPerPage: number;
    itemsType: string;
  };
}

const ListViewWrapper = ({
  isCard,
  children,
}: {
  isCard: boolean;
  children: ReactNode;
}) =>
  isCard ? (
    <Box
      mx="16px"
      mb="24px"
      borderRadius="8px"
      borderWidth="1px"
      borderColor="gray.200"
      position="relative"
      zIndex="docked"
      boxShadow="base"
      children={children}
    />
  ) : (
    <Fragment children={children} />
  );

const ListView = ({
  actionChildren,
  items,
  renderItem,
  isLoading = false,
  isCard = false,
  isEmpty,
  pagination,
  emptyChildren,
  lastItem,
}: ListViewProps<any>) => {
  const hasData = items.length !== 0;
  const hasLoadedData = isUndefined(isEmpty) ? hasData : !isEmpty;
  const showEmptyState = !isLoading && !isCard && !hasLoadedData;
  return (
    <ListViewWrapper isCard={isCard}>
      {actionChildren && <Stack
        spacing="6"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
        py="2"
        pr="2"
        minHeight="56px"
        color="gray"
        bg="gray.200"
      >
        {actionChildren}
      </Stack>}
      {isLoading && (
        <>
          <LoadingToast isOpen={isLoading} />
          {!hasData && (
            <Center height="100%" py="4">
              <PageLoadSpinner />
            </Center>
          )}
        </>
      )}
      <TwoUpLeftScrollPanel opacity={isLoading ? 0.6 : 1}>
        {showEmptyState &&
          (emptyChildren ? (
            <>{emptyChildren}</>
          ) : (
            <Box mt="12" ml="6" maxWidth="360px">
              <VStack spacing="4" alignItems="left">
                <Footnote fontSize="lg" fontWeight="500">
                  No items found
                </Footnote>
              </VStack>
            </Box>
          ))}
        {items.map((item: any, index: number) => {
          if (isCard && index === items.length - 1 && !lastItem) {
            // Applies 8px bottom border radius to the last element if this list is a card
            const extraProps = { borderBottomRadius: '8px' };
            return renderItem({ item, index, extraProps });
          }
          return renderItem({ item, index });
        })}

        {lastItem &&
          hasLoadedData &&
          (isCard ? lastItem({ borderBottomRadius: '8px' }) : lastItem())}

        {pagination && hasLoadedData && (
          <Pagination
            handlePageChange={(pageNumber: number) => {
              pagination.handlePageChange(pageNumber);
            }}
            currentPage={pagination.currentPage - 1}
            pageCount={pagination.pageCount}
            totalCount={pagination.totalCount}
            itemsPerPage={pagination.itemsPerPage}
            itemsType={pagination.itemsType}
          />
        )}
      </TwoUpLeftScrollPanel>
    </ListViewWrapper>
  );
};

export default observer(ListView);
