import React from 'react';
import { BoxProps } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';

const EmptyBox = (props: BoxProps): JSX.Element => {
  return (
    <Box
      bg="gray.200"
      height="100%"
      width="100%"
      overflow="hidden"
      boxShadow="inset 0px 0px 20px -8px rgba(0, 0, 0, 0.25);"
      {...props}
    >
      {props.children}
    </Box>
  );
};

export default EmptyBox;
