import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import DetailView from '../../../components/DetailView/DetailView';
import { StandingOrder } from '../../../stores/standingOrders/standingOrder';
import StandingOrderTable from '../../../components/StandingOrders/StandingOrderTable';
import { useCurrentUser } from '../../../hooks/useStores';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import UnlinkedTag from '../../../components/UnlinkedTag/UnlinkedTag';
import { EditBoxIcon } from '../../../components/Icons/IconsNew';
import PauseSection from '../../../components/StandingOrders/PauseSection';
import NoteBox from '../../../components/NoteBox/NoteBox';

const ShowStandingOrder = ({
  standingOrder,
}: {
  standingOrder: StandingOrder;
}) => {
  const history = useHistory();
  const { isBuyer } = useCurrentUser();

  const otherCompany = isBuyer
    ? standingOrder.supplier
    : standingOrder.customer;

  return (
    <DetailView
      rightActions={
        <>
          <Tooltip label="Edit standing order">
            <Button
              onClick={() => {
                history.push(`/standing-orders/${standingOrder.id}/edit`);
              }}
              leftIcon={<EditBoxIcon width="24px" />}
            >
              Edit
            </Button>
          </Tooltip>
        </>
      }
    >
      <form style={{ backgroundColor: 'white', marginBottom: '80px' }}>
        <Flex direction="row" py="6">
          <Box>
            <Avatar size="xl" src={otherCompany.logo_url} />
          </Box>
          <Box pl="5" pt="8">
            <Heading size="md" as="h4">
              {otherCompany.name}
            </Heading>
            {otherCompany.is_unlinked && <UnlinkedTag />}
          </Box>
        </Flex>

        <VStack align="left" spacing="8" mt="16" maxWidth="680px">
          <StandingOrderTable
            isEditable={false}
            standingOrder={standingOrder}
          />

          <Divider />

          <PauseSection standingOrder={standingOrder} isEditable={false} />
        </VStack>
      </form>
    </DetailView>
  );
};

export default observer(ShowStandingOrder);
