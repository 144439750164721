import { SnapshotIn, types } from 'mobx-state-tree';
import { api } from '../api';
import SourceCategoryProduct from './SourceCategoryProduct';

export interface SourceCategoryBaseProps {
  id: number;
  name: string;
  sort_order: number;
  unit: 'kg' | 'L';
  amounts_required: boolean;
  products_count: number;
}

export interface SourceCategoryProps extends SourceCategoryBaseProps {
  source_category_products: Array<SnapshotIn<typeof SourceCategoryProduct>>;
}

const SourceCategory = types
  .model('SourceCategory', {
    id: types.identifierNumber,
    name: types.string,
    unit: types.optional(
      types.union(types.literal('kg'), types.literal('L')),
      'kg',
    ),
    amountsRequired: true,
    sortOrder: types.number,
    products: types.array(SourceCategoryProduct),
    isCurrent: false,
    hasAddedData: false,
    _productsCount: 0,
  })
  .views((self) => ({
    get productsCount() {
      if (self.hasAddedData) {
        return self.products.length;
      }
      return self._productsCount;
    },
    get toFormInitialValues() {
      return {
        name: self.name,
        unit: self.unit,
        amounts_required: self.amountsRequired,
        sort_order: self.sortOrder,
        source_category_products_attributes: self.products.map(
          (scp) => scp.toFormInitialValues,
        ),
      };
    },
  }))
  .actions((self) => ({
    setIsCurrent(isCurrent: boolean) {
      self.isCurrent = isCurrent;
    },
    setSortOrder(sortOrder: number) {
      self.sortOrder = sortOrder;
    },
    addProduct(product: any) {
      if (self.hasAddedData) {
        self.products.push(SourceCategoryProduct.create(product));
      } else {
        self._productsCount += 1;
      }
    },
    addData({
      id,
      name,
      unit,
      sort_order,
      amounts_required,
      source_category_products,
    }: SourceCategoryProps) {
      self.id = id;
      self.name = name;
      self.unit = unit;
      self.sortOrder = sort_order;
      self.amountsRequired = amounts_required;

      self.hasAddedData = true;
      self.products.clear();
      source_category_products.forEach((pcp: any) => {
        self.products.push(
          SourceCategoryProduct.create({
            sortOrder: pcp.sort_order,
            ...pcp,
          }),
        );
      });
    },
  }))
  .actions((self) => ({
    assignProduct(product: any, amount?: string) {
      return new Promise<Response>((resolve, reject) => {
        api
          .put(
            `/v4/products/${product.id}`,
            JSON.stringify({
              product: {
                source_category_products_attributes: [
                  {
                    source_category_id: self.id,
                    amount: amount,
                    sort_order: 0,
                  },
                ],
              },
            }),
          )
          .then(async (response) => {
            const product = await response.json();
            if (response.ok) {
              self.addProduct(product);
              resolve(product);
            }
            reject(response);
          });
      });
    },
  }));

export default SourceCategory;
