import { VStack, Textarea, Input } from '@chakra-ui/react';
import EditableFormWrapper from '../../../components/Form/EditableFormWrapper';
import { Subhead } from '../../../components/Typography/Typography';
import { UserCompany, UserCompanyEditFormValues } from '../../../models/UserCompany';
import { SnapshotOrInstance } from 'mobx-state-tree';
import FormikEditableFormWrapper from '../../../components/Form/FormikEditableFormWrapper';

const CompanyProfileContactFields = ({
  company,
  isEditable = false,
  onChange = () => { }
}: {
  company: SnapshotOrInstance<typeof UserCompany> | UserCompanyEditFormValues;
  isEditable?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}) => {

  return (
    <VStack align="left" spacing="8" maxWidth="525px">
      <Subhead fontWeight="600">Contact</Subhead>
      <FormikEditableFormWrapper
        value={company.phone || ''}
        EditComponent={Input}
        href={`tel:${company.phone}`}
        fieldName="phone"
        fieldLabel="Phone"
        isEditable={isEditable}
        onChange={onChange}
      />
      <FormikEditableFormWrapper
        value={company.email || ''}
        EditComponent={Input}
        href={`mailto:${company.email}`}
        fieldName="email"
        fieldLabel="Email"
        isEditable={isEditable}
        onChange={onChange}
      />
      <FormikEditableFormWrapper
        value={company.website || ''}
        EditComponent={Input}
        href={company.website || ''}
        fieldName="website"
        fieldLabel="Website"
        isEditable={isEditable}
        onChange={onChange}
      />
    </VStack>
  );
};

export default CompanyProfileContactFields;
