import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Flex } from '@chakra-ui/react';

import { Body2, Footnote } from '../../../components/Typography/Typography';
import { pluralizeString } from '../../../utils';
import DraggableListRow from '../../../components/DraggableListRow/DraggableListRow';

const ProductGroupRow = observer(
  ({ group, groupSet, canSort, ...rest }: any) => {
    const history = useHistory();
    return (
      <DraggableListRow
        key={`group-${group.id}`}
        id={group.id}
        type={`DraggableListRow-${groupSet.id}`}
        canSort={canSort}
        isCurrent={group.isCurrent}
        findItem={groupSet.findProductGroupById}
        moveItem={groupSet.moveGroup}
        onDrop={groupSet.updateSortOrders}
        onClick={() => {
          history.push(`${groupSet.baseUri}/product-groups/${group.id}`);
        }}
        {...rest}
      >
        <Flex
          flex="1"
          justifyContent="space-between"
          direction={{ base: 'column', xl: 'row' }}
        >
          <Body2>{group.name}</Body2>
          <Footnote color="gray.500">
            {group.productCount}{' '}
            {pluralizeString('product', group.productCount)}
          </Footnote>
        </Flex>
      </DraggableListRow>
    );
  },
);

export default ProductGroupRow;
