import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useProducts } from '../../../hooks/useStores';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { useSuccessToast, useErrorToast } from '../../../components/toast';

import { IconButton, Tooltip } from '@chakra-ui/react';
import { DeleteIcon } from '../../../components/Icons/Icons';
import { useHistory } from 'react-router-dom';

const ProductsDeleteAction = ({
  productIds,
  confirmationDialogModayBodyContent,
}: any): JSX.Element => {
  const history = useHistory();
  const {
    currentProductId,
    setCurrentBulkIds,
    getProductsList,
    deleteBatchProducts,
  } = useProducts();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  let [buttonIsLoading, setButtonIsLoading] = useState(false);
  let [modalIsOpen, setModalIsOpen] = useState(false);

  const onConfirm = () => {
    setButtonIsLoading(true);
    setModalIsOpen(false);
    deleteBatchProducts(productIds, onSuccess, onFailure);
  };

  const onSuccess = () => {
    getProductsList(
      () => {
        if (productIds.indexOf(currentProductId) > -1) {
          history.push('/products');
        }
        setCurrentBulkIds([]);
        setButtonIsLoading(false);
        successToast({
          description: `We deleted ${productIds.length} products.`,
        });
      },
      () => {},
    );
  };

  const onFailure = () => {
    setButtonIsLoading(false);
    errorToast();
  };

  return (
    <>
      <Tooltip label="Delete products">
        <IconButton
          onClick={() => setModalIsOpen(true)}
          icon={<DeleteIcon />}
          isLoading={buttonIsLoading}
          aria-label="Delete products"
        />
      </Tooltip>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        titleText={`Delete ${productIds.length} products?`}
        bodyContent={confirmationDialogModayBodyContent}
        confirmButtonText={`Delete ${productIds.length} products`}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default observer(ProductsDeleteAction);
