import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  Table,
  Td,
  Tr,
  Th,
  Thead,
  Tbody,
  Input,
  VStack,
  HStack,
  Select,
  Text,
  Box,
  Tooltip,
  ButtonGroup,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { CrossIcon16x16 } from '../../../components/Icons/Icons';
import { observer } from 'mobx-react';
import Recipe from '../../../models/Recipe';
import { IngredientProps } from '../../../models/Ingredient';
import { Instance } from 'mobx-state-tree';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import prettyPrintQuantity from '../../../utils/prettyPrintQuantity';
import { PlusIcon } from '../../../components/Icons/IconsNew';
import AddIngredientModal from '../../../components/AddIngredientModal/AddIngredientModal';

const ItemTable = ({
  isEditable = false,
  recipe,
}: {
  isEditable: boolean;
  recipe: Instance<typeof Recipe>;
}): JSX.Element => {
  const [goalQuantity, setGoalQuantity] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDebugging, setIsDebugging] = useState(false);

  useHotkeys('ctrl+d', () => {
    setIsDebugging(!isDebugging);
  });

  return (
    <VStack spacing="6" alignItems="left">
      <Table minWidth="500px" variant="orderitems" boxShadow="base">
        <Thead>
          <Tr>
            <Th w="350px">Ingredient</Th>
            <Th w="160px" textAlign={'right'}>
              Quantity
            </Th>
            <Th>Unit</Th>
            {isDebugging && (
              <>
                <Th></Th>
                <Th>Coefficient</Th>
              </>
            )}
            {isEditable && <Th></Th>}
          </Tr>
        </Thead>
        <Tbody>
          {recipe.currentRecipeItems.map((item) => {
            return (
              <Tr key={`item-maintable-${item.id}`}>
                <Td>{item.ingredient.name}</Td>
                <Td textAlign={'right'}>
                  {!isEditable && item.quantity}
                  {isEditable && (
                    <Input
                      type="number"
                      min={0}
                      value={item.quantity || ''}
                      onFocus={(e: React.SyntheticEvent) => {
                        const target = e.target as HTMLInputElement;
                        target.select();
                      }}
                      onChange={(e: React.SyntheticEvent) => {
                        const target = e.target as HTMLInputElement;
                        item.setQuantity(Number(target.value));
                      }}
                      textAlign={'right'}
                      borderRadius="6px"
                      placeholder="0"
                      minWidth="75px"
                    />
                  )}
                </Td>
                <Td>{item.ingredient.unit}</Td>
                {isDebugging && (
                  <>
                    <Td w="20px">
                      <Text fontSize="sm" textColor={'gray.500'}>
                        {item.ingredient.convertedQuantity(
                          item.quantity,
                          item.ingredient.unit,
                          recipe.unit,
                        )}
                        &nbsp;
                        {recipe.unit}
                      </Text>
                    </Td>
                    <Td>{recipe.coefficientFor(item).toFixed(4)}</Td>
                  </>
                )}
                {isEditable && (
                  <Td textAlign={'right'}>
                    <ButtonGroup
                      variant="transparentGhost"
                      color="gray"
                      verticalAlign="middle"
                      py="12px"
                      pl="4px"
                      spacing="1"
                    >
                      <Tooltip label={'Remove'}>
                        <IconButton
                          data-testid="toggleButton"
                          aria-label={'Remove'}
                          minWidth="24px"
                          height="24px"
                          icon={<CrossIcon16x16 />}
                          onClick={() => {
                            recipe.removeItem(item);
                          }}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {isEditable && (
        <ButtonGroup colorScheme="green">
          <Button
            variant="secondary"
            leftIcon={<PlusIcon width="24px" />}
            onClick={() => setIsModalOpen(true)}
          >
            Add ingredient
          </Button>
        </ButtonGroup>
      )}

      <AddIngredientModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        addedIngredientsIds={recipe.recipeItems.map(
          (item) => item.ingredient.id,
        )}
        onAdd={(ingredientData: IngredientProps) => {
          recipe.addIngredient(ingredientData);
        }}
      />

      {recipe.currentRecipeItems.length > 0 && isDebugging && (
        <Box
          p="6"
          borderRadius={5}
          boxShadow="inset 0px -1px 0px #E5E7EB"
          border={'1px solid'}
          w="350px"
        >
          <VStack alignItems={'left'}>
            <Box>
              To make{' '}
              <Input
                type="number"
                min={0}
                w="30px"
                value={goalQuantity}
                onFocus={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  target.select();
                }}
                onChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  setGoalQuantity(Number(target.value));
                }}
                borderRadius="6px"
                placeholder="0"
                minWidth="75px"
              />{' '}
              {recipe.unit} of {recipe.name}, you will need:
            </Box>
            <Table>
              <Tbody>
                {recipe.currentRecipeItems.map((item) => {
                  return (
                    <Tr key={`item-previewtable-${item.id}`}>
                      <Td> {item.ingredient.name}</Td>
                      <Td>
                        {prettyPrintQuantity(
                          recipe.quantityFor(item, goalQuantity),
                          item.ingredient.unit,
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </VStack>
        </Box>
      )}
    </VStack>
  );
};

export default observer(ItemTable);
