const Tabs = {
  variants: {
    fullTextSwitch: {
      tablist: {
        position: 'relative',
        borderRadius: 'lg',
        backgroundColor: 'gray.150',
        width: '100%',
        display: 'flex',
      },
      tab: {
        borderRadius: 'lg',
        margin: 0.5,
        flex: 1,
        color: 'gray.500',
        fontSize: '16px',
        lineHeight: '20.8px',
        _selected: {
          color: 'gray.900',
          fontWeight: 'semibold',
        },
        _hover: {
          backgroundColor: 'gray.100',
        }
      },
    },
  },
};

export default Tabs;
