import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useStandingOrders } from '../../../hooks/useStores';
import { pluralizeString } from '../../../utils';
import ListView from '../../../components/ListView/ListView';
import { Footnote } from '../../../components/Typography/Typography';
import StandingOrdersRow from './StandingOrdersRow';

const StandingOrdersList = () => {
  const {
    standingOrders,
    dataIsLoading,
    pageLimit,
    totalCount,
    totalPages,
    currentPage,
    setCurrentPage,
    clearFilters,
  } = useStandingOrders();

  return (
    <ListView
      isLoading={dataIsLoading}
      isEmpty={!dataIsLoading && totalCount === 0}
      pagination={{
        handlePageChange: (pageNumber: number) => {
          setCurrentPage(pageNumber);
        },
        currentPage: currentPage,
        pageCount: totalPages || 0,
        totalCount: totalCount || 0,
        itemsPerPage: pageLimit,
        itemsType: 'Standing orders',
      }}
      actionChildren={
        <HStack mx="24px" width="100%" justify="space-between">
          {totalCount && (
            <Footnote>
              {totalCount} {pluralizeString('Standing order', totalCount)}
            </Footnote>
          )}
        </HStack>
      }
      items={standingOrders}
      renderItem={({ item: standingOrder, extraProps }) => (
        <StandingOrdersRow
          key={standingOrder.id}
          standingOrder={standingOrder}
          {...extraProps}
        />
      )}
      emptyChildren={
        <Box mt="12" ml="6" maxWidth="360px" opacity={dataIsLoading ? 0.6 : 1}>
          <VStack spacing="4" alignItems="left">
            <Text fontSize="lg" fontWeight="500">
              No standing orders found
            </Text>
            <Link
              fontWeight="400"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear filters
            </Link>
          </VStack>
        </Box>
      }
    />
  );
};

export default observer(StandingOrdersList);
