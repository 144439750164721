import { Flex } from '@chakra-ui/react';

function TwoUpRightScrollPanel({ children }: any) {
  return (
    <Flex flexDirection="column" flex="2" overflowY="auto">
      <Flex flexDirection="column" bg="white">
        {children}
      </Flex>
    </Flex>
  );
}

export default TwoUpRightScrollPanel;
