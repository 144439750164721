import { HamburgerIcon } from '@chakra-ui/icons';
import { forwardRef, IconButton } from '@chakra-ui/react';

import { DRAG_AND_DROP_TYPES } from '../../constants';
import ListRow, { ListRowProps } from '../ListRow/ListRow';
import useDragAndDrop from '../../hooks/useDragAndDrop';

export interface DraggableListRowProps extends Omit<ListRowProps, 'id'> {
  id: string;
  canSort: boolean;
  type?: string;
  findItem: (id: string) => any;
  moveItem: (id: string, newIndex: number) => void;
  onDrop: (item: any) => void;
}

const DraggableListRow = ({
  id,
  moveItem,
  findItem,
  onDrop,
  children,
  canSort,
  type,
  ...rest
}: DraggableListRowProps): JSX.Element => {
  const { opacity, handlerId, handleRef, dragRef, dropRef, ref } =
    useDragAndDrop({
      id: id,
      type: type ? type : DRAG_AND_DROP_TYPES.LIST_ROW,
      moveItem,
      findItem,
      onDrop,
      item: {
        children,
        extraProps: rest,
      },
    });

  return (
    <ListRow
      data-handler-id={handlerId}
      ref={dragRef(dropRef(ref))}
      opacity={opacity}
      {...rest}
    >
      {canSort && (
        <IconButton
          ref={handleRef}
          icon={<HamburgerIcon />}
          aria-label="Drag"
        />
      )}
      {children}
    </ListRow>
  );
};

export const DraggableListRowPreview = forwardRef(
  ({ item, style }: any, ref) => {
    return (
      <div
        ref={ref}
        style={{
          ...style,
          width: item.dimensions.width,
          height: item.dimensions.height,
        }}
      >
        <ListRow opacity={0.5} onClick={() => {}} {...item.extraProps}>
          <IconButton icon={<HamburgerIcon />} aria-label="Drag" />
          {item.children}
        </ListRow>
      </div>
    );
  },
);

export default DraggableListRow;
