import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useCustomers } from '../../hooks/useStores';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import CreateNewCustomerModalScreen from '../../components/CreateNewCustomerModalScreen/CreateNewCustomerModalScreen';

const AddCustomerModal = () => {
  const history = useHistory();
  const { getCustomers } = useCustomers();

  const handleClose = () => {
    history.push('/customers');
  };

  const onSubmit = (data: any) => {
    getCustomers(
      () => {},
      () => {},
    );
    history.push(`/customers/${data.id}`);
  };

  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
      size="xl"
      closeOnOverlayClick={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <CreateNewCustomerModalScreen
        onSubmit={onSubmit}
        submitText="Save"
        onCancel={handleClose}
      />
    </Modal>
  );
};

export default observer(AddCustomerModal);
