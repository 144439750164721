import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { MixpanelProvider } from 'react-mixpanel-browser';
import { ActionCableProvider } from './contexts/actionCable';

import './styles/index.scss';

import App from './components/App';
import AppProviders from './contexts/context';
import theme from './theme';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import ErrorPage from './pages/ErrorPage/ErrorPage';
// import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

(async () => {
  const user = JSON.parse(localStorage.getItem('userInfo') as string);
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID
      ? process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID
      : '',
    context:
      user && user.id
        ? {
            kind: 'user',
            key: user.company ? `${user.id}-${user.company.id}` : `${user.id}`,
            company: user.company.name, // For some reason you need to pass extra attributes here or it cant be used in a custom context rule
            company_id: user.company.id,
          }
        : {
            kind: 'user',
            anonymous: true,
          },
  });

  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <LDProvider>
      <ChakraProvider theme={theme}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <MixpanelProvider>
            <ActionCableProvider>
              <BrowserRouter>
                <AppProviders>
                  <App />
                </AppProviders>
              </BrowserRouter>
            </ActionCableProvider>
          </MixpanelProvider>
        </Sentry.ErrorBoundary>
      </ChakraProvider>
    </LDProvider>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
