import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useRecipes } from '../../hooks/useStores';
import debounce from 'lodash/debounce';
import { PlusIcon } from '../../components/Icons/IconsNew';

import {
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  ButtonGroup,
} from '@chakra-ui/react';
import { Button } from '../../components/Button/Button';
import { SearchIcon } from '@chakra-ui/icons';
import ActionBar from '../../components/ActionBar/ActionBar';

const RecipesPageActions = () => {
  const {
    getRecipes,
    currentSearchQuery,
    setCurrentSearchQuery,
    currentPage,
    setCurrentPage,
  } = useRecipes();

  const handleSearch = useCallback(
    debounce((e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      setCurrentPage(1);
      setCurrentSearchQuery(target.value);
    }, 500),
    [],
  );

  useEffect(() => {
    getRecipes().then((data: any) => {
      console.log('Recipes loaded', data);
    });
  }, [currentSearchQuery, currentPage]);

  return (
    <ActionBar
      title="Recipes"
      rightChildren={
        <HStack>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              variant="outline"
              type="search"
              onChange={handleSearch}
              placeholder="Search"
              name="search"
              bg="white"
            />
          </InputGroup>
          <ButtonGroup>
            <Button
              variant="primary"
              leftIcon={<PlusIcon width="24px" />}
              href="/recipes/new"
            >
              Add recipe
            </Button>
          </ButtonGroup>
        </HStack>
      }
    ></ActionBar>
  );
};

export default observer(RecipesPageActions);
