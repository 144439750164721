import { observable, action, computed, makeObservable } from 'mobx';
import * as Sentry from '@sentry/browser';

import { StandingOrder, BaseStandingOrderProps, StandingOrderProps } from './standingOrder';
import { api } from '../../api';
import { RootStore } from '../rootStore';
import { PaginationStore } from '../paginationStore';

interface StandingOrdersListInterface {
  [id: string | number]: StandingOrder;
}

export class StandingOrderStore {
  user: any = [];
  pageLimit = 20;

  @observable rootStore?: RootStore;

  @observable dataIsLoading: boolean = true;
  @observable currentSearchQuery: string = '';
  @observable currentPage: number = 1;

  @observable pagination = new PaginationStore();
  @observable totalCount?: number;
  @observable totalPages: number = 0;
  @observable nextPage?: number;
  @observable previousPage?: number;

  @observable standingOrdersList: StandingOrdersListInterface = {};
  @observable standingOrdersIndex: (string | number | null)[] = [];
  @observable currentStandingOrderId?: string | number;

  @observable saveButtonIsLoading: boolean = false;

  constructor(rootStore?: RootStore, user?: any) {
    if (rootStore) {
      this.rootStore = rootStore;
    }
    this.user = user;
    makeObservable(this);
  }

  @computed
  get standingOrders() {
    return this.standingOrdersIndex.map((key) => {
      if (typeof key === 'number') return this.standingOrdersList[key];
    });
  }

  @computed
  get currentStandingOrder(): any {
    if (this.currentStandingOrderId) {
      return this.standingOrdersList[this.currentStandingOrderId];
    } else {
      return null;
    }
  }

  @action setStandingOrders = (standingOrders: any) => {
    this.totalCount = standingOrders.total_count;
    this.totalPages = standingOrders.total_pages;
    this.nextPage = standingOrders.next_page;
    this.previousPage = standingOrders.previous_page;
    this.standingOrdersIndex = [];
    standingOrders.results.forEach((standingOrder: BaseStandingOrderProps, index: number) => {
      // Only create new instance if it doesn't exist yet. But should we merge new data with existing instances?
      if (!this.standingOrdersList[standingOrder.id]) {
        this.standingOrdersList[standingOrder.id] = new StandingOrder(this, standingOrder);
      }
      this.standingOrdersIndex[index] = standingOrder.id;
    });
  };
  @action setCurrentStandingOrder = (standingOrder?: StandingOrderProps) => {
    // First clear the existing standing order, if there is one.
    if (
      this.currentStandingOrderId &&
      this.standingOrdersList[this.currentStandingOrderId]
    ) {
      this.standingOrdersList[this.currentStandingOrderId].setIsCurrent(false);
      this.currentStandingOrderId = undefined;
    }
  
    // Now set the new standing order, if there is one to set.
    if (standingOrder && standingOrder.id === 'new') {
      this.standingOrdersList[standingOrder.id] = new StandingOrder(this, standingOrder);
      this.currentStandingOrderId = 'new';
    } else if (standingOrder) {
      const standingOrderObject = this.standingOrdersList[standingOrder.id];
      if (standingOrderObject) {
        standingOrderObject.addData(standingOrder);
        standingOrderObject.setIsCurrent(true);
      } else {
        this.standingOrdersList[standingOrder.id] = new StandingOrder(this, standingOrder);
      }
      this.currentStandingOrderId = standingOrder.id;
    }
  };
  @action setdataIsLoading = (dataIsLoading: boolean) =>
    (this.dataIsLoading = dataIsLoading);
  @action setCurrentSearchQuery = (query: string) => {
    this.currentSearchQuery = query;
  };
  @action setCurrentPage = (value: number) => (this.currentPage = value);
  @action setSaveButtonIsLoading = (value: boolean) =>
    (this.saveButtonIsLoading = value);

  @action clearFilters = () => {
    this.currentSearchQuery = '';
  };

  @action getStandingOrders = (
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    const searchParam =
      (this.currentSearchQuery && `&q=${this.currentSearchQuery}`) || '';
    const path = `/v4/standing_orders?limit=${this.pageLimit}&page=${this.currentPage}${searchParam}`;

    this.setdataIsLoading(true);
    api.get(path).then(async (response) => {
      if (response.status === 401) {
      } else {
        const data = await response.json();
        if (response.ok) {
          this.setStandingOrders(data);
          if (successCallback) {
            successCallback();
          }
          this.setdataIsLoading(false);
          return data;
        } else {
          if (errorCallback) {
            errorCallback();
          }
          this.setdataIsLoading(false);
          return Promise.reject(data);
        }
      }
      this.setdataIsLoading(false);
    });
    return;
  };

  @action getStandingOrder = (id: number | string, successCallback: () => any) => {
    api
      .get(`/v4/standing_orders/${id}`)
      .then(async (response) => {
        if (response.status === 500) {
        } else {
          if (response.ok) {
            const data = await response.json();
            this.setCurrentStandingOrder(data);
            if (successCallback) {
              successCallback();
            }
            return data;
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return error;
      });
  };

  @action createStandingOrder = (
    formData: any,
    successCallback: (data: any) => any,
    errorCallback: () => any,
  ) => {
    api
      .post('/v4/standing_orders', JSON.stringify({ ...formData }))
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          this.setCurrentStandingOrder(data);
          successCallback(data);
          return data;
        } else {
          errorCallback();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        errorCallback();
        return error;
      });
  };

  @action updateStandingOrder = (
    formData: any,
    id: number | string,
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    api
      .put(`/v4/standing_orders/${id}`, JSON.stringify({ ...formData }))
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          this.setCurrentStandingOrder(data);
          successCallback();
          return data;
        } else {
          errorCallback();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        errorCallback();
        return error;
      });
  };
}

export default new StandingOrderStore();
