import {
  Checkbox,
  CheckboxProps,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Body2, Caption } from '../Typography/Typography';

const CheckboxRow = ({
  label,
  iconUrl,
  checked,
  value,
  inputId,
  style,
  onChange,
  subtext,
  captionProps,
  ...checkboxProps
}: CheckboxProps & {
  label?: string;
  iconUrl?: string;
  checked?: boolean;
  value: string | number;
  subtext?: string | ReactNode;
  inputId?: string;
  style?: React.CSSProperties;
  captionProps?: TextProps;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <FormControl key={inputId} style={{ ...style, marginTop: 0 }}>
    <Flex>
      <Checkbox
        id={inputId}
        flex={1}
        pt={3}
        pb={3}
        isChecked={checked}
        value={value}
        onChange={onChange}
        {...checkboxProps}
      >
        <FormLabel
          alignItems="center"
          cursor="pointer"
          htmlFor={inputId}
          mb="0"
          ml={1}
        >
          <HStack>
            <Body2 color="gray.700" fontWeight="normal">
              {label}
            </Body2>
          </HStack>
          {subtext && (
            <Caption
              as="p"
              color="gray.500"
              fontWeight="normal"
              mt={1}
              {...captionProps}
            >
              {subtext}
            </Caption>
          )}
        </FormLabel>
      </Checkbox>
    </Flex>
  </FormControl>
);

export default CheckboxRow;
