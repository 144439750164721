import { ReactNode, useState } from 'react';

import {
  Box,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  IconButton,
  ModalOverlay,
  Modal,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
} from '@chakra-ui/react';
import { HiX } from 'react-icons/hi';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isString } from 'lodash';
import { useMixpanel } from 'react-mixpanel-browser';

import tabularViewImage from '../../images/OrdersTabularTip.png';
import { SparkleIcon } from '../Icons/Icons';
import { Body, Body2 } from '../Typography/Typography';
import { useCurrentUser } from '../../hooks/useStores';

/**
 * hideBanner001 (26/10/2021 - 02/11/2021) was about the upcoming app release.
 * hideBanner002 (02/11/2021 - 09/05/2022) is after the aforementioned release.
 * hideBanner003 (09/05/2022 - 28/06/2022) is about the standing orders.
 * addOrdersBanner (28/06/2022 - 12/07/2022) is about the add orders feature.
 * editOrdersBanner (15/08/2022 - 16/10/2022) is about the edit orders feature.
 * tabletOptimisations (08/02/2023 - 28/02/2023) webapp face lift and optimisation for tablets.
 * orderFulfilment (08/05/2023 - 26/06/2023) the order fulfilment wider release.
 * ordersTabularView (???? - ????) the first iteration of tabular view for orders.
 */

export const CurrentBanner = {
  key: 'ordersTabularView',
  startDate: moment('05/06/2023', 'DD/MM/YYYY'),
  endDate: moment('28/07/2023', 'DD/MM/YYYY'),
  description: (
    <>
      <b>New layout coming soon!</b>
      &nbsp;&nbsp; Orders will be shown in a sortable table layout to make it
      easier to navigate.{' '}
    </>
  ),
  launchDarklyKey: '',
  link: ({ onClose, setIsHidden }: any) => {
    const handleClose = () => {
      localStorage.setItem(CurrentBanner.key, 'true');
      setIsHidden(true);
      onClose();
    };
    return (
      <Modal isOpen={true} size="4xl" onClose={handleClose}>
        <ModalOverlay />
        <ModalContent pt="16px" color="gray.700">
          <ModalBody>
            <Body fontWeight="600" mb="8px">
              New layout for Orders coming soon!
            </Body>
            <Body2 mb="24px">
              Orders will be shown in a table view so you can easily sort them
              by name or date, and find what you’re looking for more easily.
              Everything else will work the same. You’ll see this change within
              the next week or so.
            </Body2>
            <Image src={tabularViewImage} w="full" />
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" colorScheme="green" onClick={handleClose}>
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  },
  linkText: 'Get a sneak peek',
};

const TopBannerLink = ({
  link,
  linkText,
  setIsHidden,
}: {
  link: string | ((props: any) => JSX.Element);
  linkText: string;
  setIsHidden?: (isHidden: boolean) => void;
}) => {
  const mixpanel = useMixpanel();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // TODO: This only works with the modal, not plain text link
  const openLink = () => {
    mixpanel.track(`banners.${CurrentBanner.key}.readMore`);
    onOpen();
  };

  return isString(link) ? (
    <Link color="white" href={link} isExternal>
      {linkText ? linkText : 'Read more'}
    </Link>
  ) : (
    <>
      <Link color="white" onClick={openLink}>
        {linkText ? linkText : 'Read more'}
      </Link>
      {isOpen && link({ isOpen, onOpen, onClose, setIsHidden })}
    </>
  );
};

const TopBanner = (): JSX.Element => {
  const today = moment();
  const mixpanel = useMixpanel();
  const { isBuyer } = useCurrentUser();
  const [isHidden, setIsHidden] = useState(
    localStorage.getItem(CurrentBanner.key) === 'true' ||
      today.isBefore(CurrentBanner.startDate) ||
      (CurrentBanner.endDate ? today.isAfter(CurrentBanner.endDate) : false),
  );
  const flags = useFlags();

  // NOTE: Temporary exit for if the user is a buyer or the tabular view is already enabled
  if (isBuyer || flags['tabularView']) {
    return <></>;
  }

  // If the launch darkly key is set, and the return value is false, exit early
  if (
    (CurrentBanner.launchDarklyKey && !flags[CurrentBanner.launchDarklyKey]) ||
    flags[CurrentBanner.launchDarklyKey] == 'off'
  ) {
    return <></>;
  }

  function onClose() {
    mixpanel.track(`banners.${CurrentBanner.key}.closeBanner`);
    localStorage.setItem(CurrentBanner.key, 'true');
    setIsHidden(true);
  }

  return (
    <>
      {!isHidden && (
        <Box
          display={!isHidden ? 'block' : 'none'}
          bg="blue.500"
          color="white"
          py="3"
          px={{ base: '3', md: '6', lg: '8' }}
        >
          <HStack spacing="3">
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justifyContent="center"
              alignItems="center"
              spacing={{ base: '3', md: '6' }}
              width="full"
            >
              <Icon as={SparkleIcon} fontSize="2xl" h="10" />
              <Text fontWeight="medium" marginEnd="2">
                {CurrentBanner.description}
                {CurrentBanner.link && (
                  <TopBannerLink
                    link={CurrentBanner.link}
                    linkText={CurrentBanner.linkText}
                    setIsHidden={setIsHidden}
                  />
                )}
              </Text>
            </Stack>
            <IconButton
              fontSize="1.5em"
              variant="ghost"
              colorScheme="blue.500"
              icon={<HiX />}
              alignSelf={{ base: 'self-start', sm: 'initial' }}
              aria-label="Close banner"
              onClick={onClose}
            />
          </HStack>
        </Box>
      )}
    </>
  );
};

export default TopBanner;
