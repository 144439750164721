import { observer } from 'mobx-react';
import DetailView from '../../../components/DetailView/DetailView';
import { StandingOrder } from '../../../stores/standingOrders/standingOrder';
import { Heading } from '@chakra-ui/react';
import { AddOrderProvider } from '../../../contexts/addOrder';
import AddStandingOrderModal from './AddStandingOrderModal';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';

const EditCustomer = ({
  standingOrder,
  deliveryDate,
}: {
  standingOrder: StandingOrder;
  deliveryDate: string;
}) => {
  return (
    <DetailView>
      <EmptyDetail />
      <AddOrderProvider>
        <AddStandingOrderModal
          standingOrder={standingOrder}
          deliveryDate={deliveryDate}
        />
      </AddOrderProvider>
    </DetailView>
  );
};

export default observer(EditCustomer);
