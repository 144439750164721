import React from 'react';
import logoImage from '../../../../images/infusion.png';
import {
  ClipboardIcon,
  TickInCircleIcon,
} from '../../../../components/Icons/Icons';
import Settings from '../Settings';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';

type InfusionConnectionDetailsProps = {
  connection: any;
};

const InfusionConnectionDetails = ({
  connection,
}: InfusionConnectionDetailsProps): JSX.Element => {
  return (
    <Box bg="white" p="4">
      <VStack align="left" spacing="4">
        <HStack spacing="4">
          <Avatar src={logoImage} size="lg"></Avatar>
          <Text>Infusion</Text>

          <Spacer />

          <Box>
            <VStack spacing="3">
              {connection.connection_status === 'setup_required' && (
                <HStack color="green.600" fontWeight="600">
                  <Text>Setup required</Text>
                  <ClipboardIcon />
                </HStack>
              )}
              {connection.connection_status === 'active' &&
                !connection.is_connection_stale && (
                  <HStack color="green.600" fontWeight="600">
                    <Text>Connected</Text>
                    <TickInCircleIcon />
                  </HStack>
                )}
            </VStack>
          </Box>
        </HStack>

        {connection.connection_status === 'active' &&
          connection.is_connection_stale && (
            <Alert status="error" fontSize="sm">
              <AlertIcon />
              <AlertDescription>
                Your integration is offline. Please check the server is running,
                then refresh this page and try again
              </AlertDescription>
            </Alert>
          )}

        {connection.connection_status === 'setup_required' && (
          <Alert status="error" fontSize="sm">
            <AlertIcon />
            <AlertDescription>
              Our developers are setting up your Infusion connection. Please
              wait.
            </AlertDescription>
          </Alert>
        )}

        <Settings
          currentSettings={connection.settings}
          accountingConnectionName={connection.label}
        />
      </VStack>
    </Box>
  );
};

export default InfusionConnectionDetails;
