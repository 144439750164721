import { cssVar } from "@chakra-ui/theme-tools";
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';

ChakraTooltip.defaultProps = {
  hasArrow: true,
};
const Tooltip = {
  baseStyle: {
    color: 'white',
    bg: 'gray.900',
    [cssVar("popper-arrow-bg").variable]: 'colors.gray.900',
    borderRadius: 'base',
    padding: '8px',
    textAlign: 'center'
  }
}

export default Tooltip;
