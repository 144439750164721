import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const NotFoundPage = (): JSX.Element => {
  return (
    <Box bg="gray.100" minH="100vh">
      <Box pt="20vh" textAlign="center">
        <Heading size="md">Uh oh! That page doesn't exist.</Heading>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
