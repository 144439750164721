import { action, computed, makeObservable, observable, remove } from 'mobx';
import {
  Instance
} from 'mobx-state-tree';
import * as Sentry from '@sentry/browser';

import { api } from '../api';

import { IModel } from '../stores/Model';
import { ProductGroupSet } from '../models/ProductGroupSet';
import { Product } from '../stores/productGroupsStore';
import DeliveryRules, { DeliveryRule, DeliveryRuleProps, DeliveryRulesProps } from './DeliveryRules';

export interface BaseProductGroupProps {
  id: number;
  name: string;
  sort_order: number;
  product_count: number;
}

export interface ProductGroupProps extends BaseProductGroupProps {
  products: any[];
  product_group_set_id: number;
  product_group_set_name: string;
  delivery_rules: DeliveryRulesProps;
  delivery_rules_enabled: boolean;
}

export class ProductGroup implements IModel {
  id: number;
  @observable name: string;
  @observable sortOrder: number;

  @observable product_group_set_name: string | null = null;
  @observable product_group_set_id: number | null = null;

  @observable delivery_rules: Instance<typeof DeliveryRules> | null = null;
  @observable delivery_rules_enabled: boolean = false;

  @observable _productCount: number;

  @observable isFullyLoaded: boolean = false;
  @observable isCurrent: boolean = false;
  @observable groupSet: ProductGroupSet;

  @observable productsList: { [id: number]: Product } = {};
  @observable productsIndex: (number | null)[] = [];

  constructor(
    groupSet: ProductGroupSet,
    props: BaseProductGroupProps | ProductGroupProps,
  ) {
    this.groupSet = groupSet;

    const { id, name, product_count, sort_order } = props;
    this.id = id;
    this.name = name;
    this.sortOrder = sort_order;
    this._productCount = product_count || 0;

    if ('delivery_rules_enabled' in props && 'delivery_rules' in props) {
      console.log('Adding props', props);
      const { delivery_rules_enabled, delivery_rules } = props;
      this.delivery_rules_enabled = delivery_rules_enabled;
      this.setDeliveryRules(delivery_rules);
    }

    if ('products' in props) {
      this.addData(props);
    }

    makeObservable(this);
  }

  @computed
  get productCount() {
    if (this.isFullyLoaded) {
      return this.products.length;
    }
    return this._productCount;
  }

  @computed
  get products(): Product[] {
    return this.productsIndex
      .filter((key): key is number => key !== null && typeof key === 'number')
      .map((key: number) => {
        return this.productsList[key];
      });
  }

  @computed
  get toFormInitialValues() {
    return {
      name: this.name,
      product_product_groups_attributes: this.products.map(
        (product: any) => product.toFormInitialValues,
      ),
    };
  }

  @action
  addData = (props: ProductGroupProps) => {
    const {
      id,
      name,
      sort_order,
      product_group_set_name,
      product_group_set_id,
      delivery_rules_enabled,
      delivery_rules,
      products,
    } = props;
    this.id = id;
    this.name = name;
    this.product_group_set_name = product_group_set_name;
    this.product_group_set_id = product_group_set_id;
    this.sortOrder = sort_order;

    this.delivery_rules_enabled = delivery_rules_enabled;
    if (delivery_rules) { this.setDeliveryRules(delivery_rules); }

    this.isFullyLoaded = true;

    if (products) {
      products.forEach((product: any, index: number) => {
        if (!this.productsList[product.id]) {
          this.productsList[product.id] = new Product(this, product);
        } else {
          this.productsList[product.id].addData(product);
        }
        this.productsIndex[index] = product.id;
      });
    }
  };

  @action
  assignProduct = (product: Product) => {
    return new Promise<Response>((resolve, reject) => {
      api
        .put(
          `/v4/product_groups/${this.id}/add_products`,
          JSON.stringify({
            product_ids: [product.id],
          }),
        )
        .then(async (response) => {
          const result = await response.json();
          if (response.ok) {
            this.addProduct(product);
            resolve(result);
          }
          reject(result);
        });
    });
  };

  @action
  addProduct = (product: any) => {
    this.groupSet.removeUnassignedProduct(product);
    if (this.isFullyLoaded) {
      this.productsList[product.id] = new Product(this, product);
      this.productsIndex[this.productsIndex.length] = product.id;
    } else {
      this._productCount += 1;
    }
  };

  @action
  setSortOrder = (value: number) => {
    this.sortOrder = value;
  };

  @action
  setIsCurrent = (value: boolean) => {
    this.isCurrent = value;
  };

  @action
  setDeliveryRulesEnabled(value: boolean) {
    this.delivery_rules_enabled = value;
  };

  @action
  clearDeliveryRules() {
    this.delivery_rules = null;
  };

  @action
  setDeliveryRules(value: DeliveryRulesProps | null) {
    this.delivery_rules = value ? DeliveryRules.create({
      '0': DeliveryRule.create(value['0']),
      '1': DeliveryRule.create(value['1']),
      '2': DeliveryRule.create(value['2']),
      '3': DeliveryRule.create(value['3']),
      '4': DeliveryRule.create(value['4']),
      '5': DeliveryRule.create(value['5']),
      '6': DeliveryRule.create(value['6'])
    }): null;
  };

  @action
  populateDefaultDeliveryRules() {
    this.setDeliveryRules({
      '0': { enabled: false, cutoff_day: 6, cutoff_time: '9:00 AM' },
      '1': { enabled: false, cutoff_day: 0, cutoff_time: '9:00 AM' },
      '2': { enabled: false, cutoff_day: 1, cutoff_time: '9:00 AM' },
      '3': { enabled: false, cutoff_day: 2, cutoff_time: '9:00 AM' },
      '4': { enabled: false, cutoff_day: 3, cutoff_time: '9:00 AM' },
      '5': { enabled: false, cutoff_day: 4, cutoff_time: '9:00 AM' },
      '6': { enabled: false, cutoff_day: 5, cutoff_time: '9:00 AM' },
    });
  };

  @action
  private updateData(group: any) {
    this.name = group.name;

    const cachedProducts = this.products;

    this.productsList = {};
    this.productsIndex = [];
    group.products.forEach((product: any, index: number) => {
      if (!this.productsList[product.id]) {
        this.productsList[product.id] = new Product(this, product);
      }
      this.productsIndex[index] = product.id;
    });

    const addedProducts = this.products.filter(
      (c) => !cachedProducts.find((cc) => cc.productId === c.productId),
    );
    const removedProducts = cachedProducts.filter(
      (c) => !this.products.find((cc) => cc.productId === c.productId),
    );

    addedProducts.forEach((c: any) =>
      this.groupSet.removeUnassignedProduct(c),
    );
    removedProducts.forEach((c: any) =>
      this.groupSet.addUnassignedProduct(c),
    );
  }

  @action
  update = (formData: any) => {
    return new Promise<Response>((resolve, reject) => {
      api
        .put(
          `/v4/product_groups/${this.id}`,
          JSON.stringify({
            product_group: {
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.updateData(data);
            resolve(data);
          }
          reject(data);
        });
    });
  };

  @action
  delete = () => {
    return new Promise<Response>((resolve, reject) => {
      api
        .delete(`/v4/product_groups/${this.id}`)
        .then(async (response) => {
          if (response.ok) {
            const index = this.groupSet.groupsIndex.indexOf(this.id);
            if (index > -1) {
              this.products.forEach((c: any) =>
                this.groupSet.addUnassignedProduct(c),
              );
              remove(this.groupSet.groupsIndex, `${index}`);
              remove(this.groupSet.groupsList, `${this.id}`);
              resolve(response);
            }
          }
          reject(response);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };
}
