import { Tr, Td, HStack, Checkbox } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { shouldPreventOnClick } from '../../../utils';
import StatusDot from '../../../components/Orders/StatusDot';
import Tag from '../../../components/Tag/Tag';
import DateText from '../../../components/Orders/DateText';
import DataGridCell from './OrdersDataGridCell';
import { Order } from '../../../stores/orders/order';
import { useOrders } from '../../../hooks/useStores';
import { ellipseString } from '../../../utils';

const SuppliersOrdersDataGridRow = ({ order }: { order: Order }) => {
  const { setCurrentOrder } = useOrders();
  const history = useHistory();
  const className = `${order.isCurrentOrder ? 'current' : ''} ${
    order.isChecked ? 'selected' : ''
  }`;
  return (
    <Tr
      key={order.id}
      className={className}
      boxShadow={
        order.isCurrentOrder
          ? 'inset 3px 0 0 #3182CE, inset 0 -1px 0 #3182CE'
          : ''
      }
      cursor="pointer"
      onClick={(e: React.SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        if (!shouldPreventOnClick(target)) {
          if (order.isCurrentOrder) {
            history.push(`/orders`);
            setCurrentOrder(undefined);
          } else {
            history.push(`/orders/${order.id}`);
          }
        }
      }}
    >
      <Td p="0" pl="8px" width="1%" minWidth="56px">
        <Checkbox
          isChecked={order.isChecked}
          onChange={order.toggleChecked}
          variant="table"
        />
      </Td>
      <Td width="1%" minWidth="58px">
        <StatusDot status={order.status} />
      </Td>
      <DataGridCell id="customer" width="294px">
        {order.customer_company.name}
      </DataGridCell>
      <DataGridCell id="orderedBy" width="294px">
        {order.source === 'standing_order' ? (
          <Tag>Standing</Tag>
        ) : order.source === 'supplier_created' ? (
          <HStack>
            <span>{order.customer?.fullname}</span>
            <Tag
              tooltip={`Added on behalf of customer by ${order.customer.fullname}`}
            >
              Added
            </Tag>
          </HStack>
        ) : (
          order.customer?.fullname
        )}
      </DataGridCell>
      <DataGridCell id="deliveryDate" width="184px">
        {order.delivery_date && (
          <DateText date={order.delivery_date} showTime={false} />
        )}
      </DataGridCell>
      <DataGridCell id="date" width="224px">
        <DateText date={order.date} />
      </DataGridCell>
      <DataGridCell id="orderNumber" width="124px">
        #{order.order_number}
      </DataGridCell>
      <DataGridCell id="customerPO" width="184px" wordBreak="break-all">
        {order.purchase_order_number}
      </DataGridCell>
      <DataGridCell id="orderMessage" width="58px">
        {ellipseString(order.message || '', 20)}
      </DataGridCell>
    </Tr>
  );
};

export default observer(SuppliersOrdersDataGridRow);
