import { useEffect, useState, useCallback } from 'react';
import {
  Text,
  HStack,
  VStack,
  Box,
  Input,
  Button as ChakraButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Spinner,
  Link,
} from '@chakra-ui/react';
import { debounce } from 'lodash';

import useAPI from '../../hooks/useAPI';
import { useScroll } from '../../hooks/useScroll';
import { Button } from '../Button/Button';
import { CloseIcon, PlusIcon, TickIcon } from '../Icons/IconsNew';
import { isUndefined } from 'lodash';

const CustomerRow = ({ customer, initialIsAdded, onAdd, onRemove }: any) => {
  const [isAdded, setIsAdded] = useState(initialIsAdded);
  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
    >
      <Box>
        <Text color="gray.700">{customer.name}</Text>
      </Box>
      {isAdded ? (
        isUndefined(onRemove) ? (
          <Button
            width="100px"
            disabled={true}
            leftIcon={<TickIcon width="24px" />}
          >
            Added
          </Button>
        ) : (
          <Button
            width="120px"
            colorScheme="red"
            color="red.400"
            borderColor="red.400"
            leftIcon={<CloseIcon width="24px" />}
            onClick={() => {
              setIsAdded(false);
              onRemove(customer);
            }}
          >
            Remove
          </Button>
        )
      ) : (
        <Button
          width="80px"
          leftIcon={<PlusIcon width="24px" />}
          onClick={() => {
            setIsAdded(true);
            onAdd(customer);
          }}
        >
          Add
        </Button>
      )}
    </HStack>
  );
};

const AddCustomerModal = ({
  isOpen,
  onClose,
  onAdd,
  onRemove,
  addedCustomers,
  includeCustomerGroups = false,
}: any) => {
  const limit = 20;
  const [page, setPage] = useState(1);
  const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useScroll<HTMLDivElement>(() => {
    setPage(page + 1);
  });

  const [getCustomers] = useAPI({
    method: 'GET',
  });
  const [customers, setCustomers] = useState([]);

  const clearResults = () => {
    setCustomers([]);
    setPage(1);
  };

  const clearFilters = () => {
    clearResults();
    setCurrentQueryFieldValue('');
    setCurrentQuery('');
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      clearResults();
      setCurrentQuery(value);
    }, 400),
    [],
  );

  useEffect(() => {
    const url = `/v4/customers?page=${page}&limit=${limit}${
      currentQuery && '&q=' + currentQuery
    }${includeCustomerGroups && '&include_customer_groups=true'}`;
    setIsLoading(true);

    getCustomers(url).then((data: any) => {
      setResultCount(data.total_count);
      setCustomers((customers) => customers.concat(data.results));
      setIsLoading(false);
    });
  }, [page, currentQuery]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}} autoFocus={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={0} pb={0}>
          <Text px={6} mt={4} mb={6}>
            Add another customer
          </Text>

          <Box
            py={4}
            px={6}
            bg="gray.100"
            boxShadow="inset 0px 1px 0px #D1D5DB, inset 0px -1px 0px #D1D5DB"
          >
            <Input
              variant="outline"
              type="search"
              onChange={(e) => {
                setCurrentQueryFieldValue(e.target.value);
                handleSearch(e.target.value);
              }}
              value={currentQueryFieldValue}
              placeholder="Search"
              name="search"
              bg="white"
              mb={3}
            />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          {customers.length > 0 ? (
            <Box ref={scrollRef} maxHeight="320px" overflowY="scroll">
              {customers.map((customer: any) => {
                return (
                  <CustomerRow
                    key={customer.id}
                    customer={customer}
                    onAdd={onAdd}
                    onRemove={onRemove}
                    initialIsAdded={
                      addedCustomers && addedCustomers.includes(customer.id)
                    }
                  />
                );
              })}
            </Box>
          ) : (
            !isLoading &&
            resultCount == 0 && (
              <VStack px={6} py={4} spacing="4" alignItems="left">
                <Text fontSize="lg" fontWeight="500">
                  No customers found
                </Text>
                <Link fontWeight="400" onClick={clearFilters}>
                  Clear filters
                </Link>
              </VStack>
            )
          )}
          {isLoading && (
            <Center height="100px">
              <Spinner thickness="6px" size="xl" color="green" />
            </Center>
          )}
        </ModalBody>
        <ModalFooter backgroundColor="gray.100" borderRadius="0 0 8px 8px">
          <ChakraButton onClick={onClose} colorScheme="green">
            Done
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCustomerModal;
