import { useState, useEffect } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useOrders, useDocumentTemplates } from './useStores';
import useAPI from './useAPI';
import { useErrorToast } from '../components/toast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { pluralizeString } from '../utils';

type DocumentMeta = {
  timePerItemMs: number;
  nameForModalText: string;
  pluralizeName?: boolean;
  nameForMixPanelEvent?: string;
  skipUpdateStatusModal?: boolean;
};

const documentMeta: Record<string, DocumentMeta> = {
  legacy_pick_sheet: {
    timePerItemMs: 100,
    nameForModalText: 'Legacy Pick Sheet',
    pluralizeName: true,
    nameForMixPanelEvent: 'Pick Sheet',
    /**
     * For data consistency we're leaving the Mixpanel event name as "Print Pick Sheet" and have
     * rewritten the display name in the MixPanel Lexicon to "Print Legacy Pick Sheet"
     * */
  },
  pick_sheet: {
    timePerItemMs: 400,
    nameForModalText: 'Pick Sheet',
    pluralizeName: true,
    nameForMixPanelEvent: 'New Pick Sheet',
    /** "Print New Pick Sheet" should be renamed in the Mixpanel Lexicon to "Print Pick Sheet" */
  },
  pick_all_sheet: {
    timePerItemMs: 400,
    nameForModalText: 'Pick-all Sheet',
  },
  production_sheet: {
    timePerItemMs: 400,
    nameForModalText: 'Production Sheet',
  },
  packing_slip: {
    timePerItemMs: 400,
    nameForModalText: 'Packing Slip',
    pluralizeName: true,
  },
  order_summary_report: {
    timePerItemMs: 10,
    nameForModalText: 'Order Summary report',
  },
  delivery_run_sheet: {
    timePerItemMs: 2,
    nameForModalText: 'Delivery Run Sheet',
    skipUpdateStatusModal: true,
  },
  delivery_label: {
    timePerItemMs: 20,
    nameForModalText: 'Delivery Label',
    skipUpdateStatusModal: true,
    pluralizeName: true,
  },
  sales_report: {
    timePerItemMs: 10,
    nameForModalText: 'Sales report',
  },
  quantity_report: {
    timePerItemMs: 100,
    nameForModalText: 'Quantity Report',
    nameForMixPanelEvent: 'Quantity Report',
    skipUpdateStatusModal: true,
  },
  products_report: {
    timePerItemMs: 10,
    nameForModalText: 'Products report',
    nameForMixPanelEvent: 'Products report',
  },
};

const useDocuments = (
  actionText: string,
  futureActionText: string,
  orderIds: any,
  afterRequestPdf: any,
) => {
  const mixpanel = useMixpanel();
  let [modalIsOpen, setModalIsOpen] = useState(false);
  let [modalText, setModalText] = useState('');
  const flags = useFlags();
  const showLegacyPickSheet =
    flags['orderFulfilmentMvpSupplierView'] == 'on-include-legacy';
  const showProductsReport = flags['productsReport'] == true;
  const showDispatchReport = flags['dispatchReport'] == true;
  const errorToast = useErrorToast();
  const { ordersList, currentBulkIds, setCurrentBulkIds } = useOrders();
  const { getDocumentTemplates, documentTemplates } = useDocumentTemplates();
  let [processingIsDone, setProcessingIsDone] = useState<boolean>(false);
  let [currentDocument, setCurrentDocument] = useState<string | null>(null);
  let [newOrderIds, setNewOrderIds] = useState<number[]>([]);
  let [updateStatusModalIsOpen, setUpdateStatusModalIsOpen] = useState(false);
  let [timePerItemMs, setTimePerItemMs] = useState<number>(2500);
  let [selectProductModalIsOpen, setSelectProductModalIsOpen] =
    useState<boolean>(false);
  let [selectedProductIds, setSelectedProductIds] = useState<any[]>([]);

  const [postRequest] = useAPI({
    method: 'POST',
  });

  useEffect(() => {
    getDocumentTemplates();
  }, []);

  const onClickDocument = (documentKey: string, extraParams?: any) => {
    setCurrentDocument(documentKey);

    if (documentKey == 'quantity_report') {
      setSelectProductModalIsOpen(true);
    } else {
      prepareDocument(documentKey, extraParams);
    }
  };

  const prepareDocument = (documentKey: string, extraParams?: any) => {
    const docMeta = documentMeta[documentKey];
    const nameForModalText = docMeta.pluralizeName
      ? pluralizeString(docMeta.nameForModalText, orderIds.length)
      : docMeta.nameForModalText;

    mixpanel.track(
      `${actionText} ${docMeta.nameForMixPanelEvent || nameForModalText}`,
      {
        numOrders: orderIds.length,
      },
    );
    setTimePerItemMs(docMeta.timePerItemMs);
    setModalText(`Preparing ${nameForModalText} for ${futureActionText}.`);
    requestDocument({ type: documentKey, ...extraParams });
  };

  const onClickDocumentTemplate = (documentTemplate: any) => {
    setCurrentDocument('document_template');
    prepareDocumentTemplate(documentTemplate);
  };

  const prepareDocumentTemplate = (documentTemplate: any) => {
    const docMeta = {
      ...documentMeta[documentTemplate.base_type],
      pluralizeName: false,
      nameForModalText: documentTemplate.name,
    };
    const nameForModalText = docMeta.pluralizeName
      ? pluralizeString(docMeta.nameForModalText, orderIds.length)
      : docMeta.nameForModalText;

    mixpanel.track(`${actionText} Document Template`, {
      numOrders: orderIds.length,
      documentTemplateId: documentTemplate.id,
      documentTemplateBaseType: documentTemplate.base_type,
      documentTemplateName: documentTemplate.name,
    });
    setTimePerItemMs(docMeta.timePerItemMs);
    setModalText(`Preparing ${nameForModalText} for ${futureActionText}.`);
    requestDocument({
      document_template_id: documentTemplate.id,
    });
  };

  const requestDocument = (params: any = {}) => {
    setModalIsOpen(true);
    postRequest(
      `/v4/order_documents`,
      {
        body: JSON.stringify({
          order_ids: orderIds.join(','),
          ...params,
        }),
      },
      true,
    )
      .then(afterRequestPdf)
      .catch(handleError);
  };

  const conditionallyShowUpdateStatusModal = () => {
    if (!currentDocument) {
      return;
    }

    const docMeta = documentMeta[currentDocument];
    if (docMeta && docMeta['skipUpdateStatusModal']) {
      resetState();
      return;
    }

    const newOrderIds = orderIds.filter((orderId: any) => {
      return ordersList[orderId].status == 'new';
    });

    if (newOrderIds.length) {
      setNewOrderIds(newOrderIds);
      setUpdateStatusModalIsOpen(true);
    } else {
      resetState();
    }
  };

  const resetState = () => {
    clearCheckboxes();
    setCurrentDocument(null);
  };

  const clearCheckboxes = () => {
    if (currentBulkIds == orderIds) {
      setCurrentBulkIds([]);
    }
  };

  const handleError = () => {
    setModalIsOpen(false);
    setProcessingIsDone(false);
    errorToast();
  };

  const onSelectProduct = (productIds: any) => {
    if (typeof productIds === 'string') {
      setSelectedProductIds([productIds]);
    } else {
      setSelectedProductIds(productIds);
    }
  };

  const onClearProducts = () => {
    setSelectedProductIds([]);
  };

  const onConfirmProducts = () => {
    setSelectProductModalIsOpen(false);
    prepareDocument('quantity_report', {
      product_ids: String(selectedProductIds.join(',')),
    });
  };

  const afterProcessing = () => {
    setModalIsOpen(false);
    setProcessingIsDone(false);
    onClearProducts();
    conditionallyShowUpdateStatusModal();
  };

  return {
    showLegacyPickSheet,
    showProductsReport,
    showDispatchReport,
    modalIsOpen,
    modalText,
    updateStatusModalIsOpen,
    setUpdateStatusModalIsOpen,
    timePerItemMs,
    processingIsDone,
    setProcessingIsDone,
    newOrderIds,
    resetState,
    onClickDocument,
    selectProductModalIsOpen,
    setSelectProductModalIsOpen,
    onSelectProduct,
    selectedProductIds,
    onClearProducts,
    onConfirmProducts,
    afterProcessing,
    currentDocument,
    setCurrentDocument,
    documentTemplates,
    onClickDocumentTemplate,
  } as const;
};

export default useDocuments;
