import { Td, Tr } from '@chakra-ui/react';

const EmptyTableRow = ({ colSpan }: { colSpan: number }) => (
  <Tr>
    <Td
      colSpan={colSpan}
      width="100%"
      height="28"
      bg="gray.50"
      boxShadow="inset 0px 4px 5px rgba(0, 0, 0, 0.07), inset 0px 14px 20px -8px rgba(50, 50, 93, 0.1)"
    />
  </Tr>
);

export default EmptyTableRow;
