const lessKerning = {
  letterSpacing: '-0.02em',
};

const Heading = {
  sizes: {
    md: lessKerning,
    lg: lessKerning,
    xl: lessKerning,
  },
};

export default Heading;
