import { observer } from 'mobx-react';

import { Button, Select, Input, VStack } from '@chakra-ui/react';
import Ingredient from '../../../models/Ingredient';
import { useSuccessToast } from '../../../components/toast';
import DetailView from '../../../components/DetailView/DetailView';
import { useIngredients } from '../../../hooks/useStores';
import { Instance } from 'mobx-state-tree';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import FormField from '../../../components/FormField/FormField';
import { H4, Caption } from '../../../components/Typography/Typography';

export const conversionText = (conversionFactor: number, unit: string) => {
  if (unit === 'kg') {
    return `1 kg = ${conversionFactor} L`;
  } else if (unit === 'L') {
    return `1 L = ${conversionFactor} kg`;
  } else {
    return 'Please select a unit';
  }
};

const EditIngredient = ({
  ingredient,
}: {
  ingredient?: Instance<typeof Ingredient>;
}) => {
  const { updateIngredient, createIngredient, getIngredients } =
    useIngredients();
  const successToast = useSuccessToast();
  const history = useHistory();

  return (
    <Formik
      initialValues={
        ingredient
          ? ingredient.toFormInitialValues
          : {
              name: '',
              unit: 'kg',
              conversion_factor: 1,
            }
      }
      validationSchema={Yup.object({
        name: Yup.string()
          .required('Required')
          .max(40, 'Name must be 40 characters or less'),
        unit: Yup.string(),
      })}
      onSubmit={async (values) =>
        ingredient
          ? await updateIngredient(ingredient, values).then(
              (ingredient: any) => {
                getIngredients().then(() => {
                  successToast({
                    description: `Ingredient "${ingredient.name}" has been updated.`,
                  });
                  history.push(`/ingredients/${ingredient.id}`);
                });
              },
            )
          : createIngredient(values).then((ingredient: any) => {
              getIngredients().then(() => {
                successToast({
                  description: `New ingredient "${ingredient.name}" has been created.`,
                });
                history.push(`/ingredients/${ingredient.id}`);
              });
            })
      }
    >
      {({ isSubmitting, isValid, handleReset, values }) => {
        return (
          <DetailView
            as={Form}
            leftActions={
              <H4 my="3">{ingredient ? ingredient.name : 'New ingredient'}</H4>
            }
            rightActions={
              <>
                <Button
                  size="sm"
                  variant="secondary"
                  colorScheme="green"
                  onClick={() => {
                    handleReset();
                    ingredient
                      ? history.push(`/ingredients/${ingredient.id}`)
                      : history.push(`/ingredients`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  size="sm"
                  variant="primary"
                  colorScheme="green"
                  disabled={isSubmitting || !isValid}
                >
                  Save
                </Button>
              </>
            }
          >
            <VStack width="full" align="left" spacing="8" my="10">
              <FormField name="name" label="Name" />
              <FormField name="unit" label="Unit">
                {({ field }) => (
                  <Select {...field}>
                    <option value="placeholder-unit-id" disabled>
                      Please select
                    </option>
                    <option value="kg">kg</option>
                    <option value="L">L</option>
                  </Select>
                )}
              </FormField>

              <FormField
                name="conversion_factor"
                label="Conversion Factor"
                subLabel="For converting between units"
              >
                {({ field }) => (
                  <>
                    <Input
                      {...field}
                      type="number"
                      step="0.01"
                      placeholder="Conversion Factor"
                    />

                    {values.conversion_factor && (
                      <Caption>
                        {conversionText(values.conversion_factor, values.unit)}
                      </Caption>
                    )}
                  </>
                )}
              </FormField>
            </VStack>
          </DetailView>
        );
      }}
    </Formik>
  );
};

export default observer(EditIngredient);
