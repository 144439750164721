import { useState } from 'react';
import { observer } from 'mobx-react';

import useAPI from '../../hooks/useAPI';
import { xClientHeaders } from '../../utils';
import { useAddOrder } from '../../contexts/addOrder';

import SelectCompanyScreen from '../SelectCompanyScreen/SelectCompanyScreen';
import SelectProductsScreen from './SelectProductsScreen';
import ReviewOrderScreen from './ReviewOrderScreen';
import CreateNewCustomerModalScreen from '../CreateNewCustomerModalScreen/CreateNewCustomerModalScreen';
import { useCurrentUser } from '../../hooks/useStores';
import { SnapshotOrInstance } from 'mobx-state-tree';
import Customer from '../../models/Customer';
import UserCompany from '../../models/UserCompany';
import Supplier from '../../models/Supplier';
import { Instance } from 'mobx-state-tree/dist/internal';

export type OrderModalScreen =
  | 'select-company'
  | 'select-products'
  | 'review-order'
  | 'new-customer';

export type OrderFieldName = 'customer' | 'supplier' | 'deliveryDate';

const AddOrderModalContent = ({
  initialScreen = 'select-company',
  disabledFields = [],
  submitOrder,
  isSubmitting,
}: {
  initialScreen?: OrderModalScreen;
  disabledFields?: OrderFieldName[];
  submitOrder: () => void;
  isSubmitting: boolean;
}) => {
  const { order } = useAddOrder();
  const { company: userCompany, authToken, isBuyer } = useCurrentUser();

  const token = `Bearer ${authToken}`;

  const [getSupplierInfo] = useAPI({
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      ...xClientHeaders,
    },
  });

  const [modalScreen, setModalScreen] =
    useState<OrderModalScreen>(initialScreen);

  const handleCompanySelect = ({
    customer,
    supplier = userCompany,
  }: {
    customer:
      | SnapshotOrInstance<typeof Customer>
      | Instance<typeof UserCompany>;
    supplier:
      | SnapshotOrInstance<typeof Supplier>
      | Instance<typeof UserCompany>
      | undefined;
  }) => {
    if (order?.customer?.id !== customer.id) {
      order.reset();
    }

    order.setCustomer(customer);
    if (isBuyer && supplier) {
      getSupplierInfo(`/v4/suppliers/${supplier.id}`).then((data) => {
        order.setSupplierCompany(data);
        setModalScreen('select-products');
      });
      if (userCompany?.default_address_obj) {
        order.setAddress({
          id: userCompany.default_address_obj.id,
          full_address: userCompany.default_address_obj.full_address,
          instructions: userCompany.default_address_obj.instructions,
        });
      }
    } else {
      if (supplier) {
        order.setSupplierCompany(supplier);
        order.setIsSupplierAdded(true);
      }
      setModalScreen('select-products');
    }
  };

  switch (modalScreen) {
    case 'select-company':
      return (
        <SelectCompanyScreen
          onCompanySelect={(selectedCompany) => {
            if (userCompany) {
              handleCompanySelect({
                customer: isBuyer ? userCompany : selectedCompany,
                supplier: isBuyer ? selectedCompany : userCompany,
              });
            }
          }}
          headerText={isBuyer ? 'New order' : 'Add new order'}
          onStartNewCustomer={
            !isBuyer ? () => setModalScreen('new-customer') : undefined
          }
        />
      );

    case 'select-products':
      return (
        <SelectProductsScreen
          onBack={() => {
            order.clearCustomer();
            setModalScreen('select-company');
          }}
          onSubmit={() => setModalScreen('review-order')}
          disabledFields={disabledFields}
        />
      );

    case 'review-order':
      return (
        <ReviewOrderScreen
          onBack={() => setModalScreen('select-products')}
          onSubmit={submitOrder}
          isLoading={isSubmitting}
          disabledFields={disabledFields}
          initialiseEditing={initialScreen === 'review-order'}
        />
      );

    case 'new-customer':
      return (
        <CreateNewCustomerModalScreen
          onSubmit={() => setModalScreen('select-products')}
        />
      );
  }
};

export default observer(AddOrderModalContent);
