// See https://chakra-ui.com/docs/theming/customize-theme

import { extendTheme } from '@chakra-ui/react';

import breakpoints from './breakpoints';
import colors from './colors';
import fonts from './fonts';
import styles from './styles';

// Component style overrides
import Badge from './components/badge';
import Banner from './components/banner';
import Button from './components/button';
import Checkbox from './components/checkbox';
import Heading from './components/heading';
import HiddenScrollBox from './components/hiddenScrollBox';
import Input from './components/input';
import Radio from './components/radio';
import Link from './components/link';
import Menu from './components/menu';
import NumberInput from './components/numberInput';
import Select from './components/select';
import Table from './components/table';
import Textarea from './components/textarea';
import Tooltip from './components/tooltip';
import Tabs from './components/tabs';

const overrides = {
  breakpoints,
  colors,
  components: {
    Badge,
    Banner,
    Button,
    Checkbox,
    Heading,
    HiddenScrollBox,
    Input,
    Radio,
    Link,
    Menu,
    NumberInput,
    Select,
    Table,
    Textarea,
    Tooltip,
    Tabs,
  },
  fonts,
  styles,
};

export default extendTheme(overrides);
