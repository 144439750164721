import React from 'react';
import { useAuth } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';

import LoginForm from './LoginForm';
import AuthWrapper from '../../components/Layouts/AuthWrapper';

import { Heading, Link, Text } from '@chakra-ui/react';

const LoginPage = (): JSX.Element => {
  const { user } = useAuth();
  let history = useHistory();

  if (user) {
    history.push('/orders');
  }

  return (
    <AuthWrapper
      outterChildren={
        <>
          <Heading color="white" textAlign="center" size="xl" fontWeight="700">
            Log in to HospoConnect
          </Heading>
          <Text
            color="white"
            mt="4"
            mb="8"
            align="center"
            maxW="md"
            fontWeight="500"
          >
            <Text as="span">Don&apos;t have an account?</Text>
            <Link
              color="white"
              ml="1"
              href="https://www.hospoconnect.co.nz/register"
            >
              Register for free
            </Link>
          </Text>
        </>
      }
      innerChildren={<LoginForm />}
    />
  );
};

export default LoginPage;
