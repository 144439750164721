import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import UnlinkedTag from '../../../components/UnlinkedTag/UnlinkedTag';
import { Avatar, Flex } from '@chakra-ui/react';
import ListRow from '../../../components/ListRow/ListRow';
import { Caption } from '../../../components/Typography/Typography';

const CustomersRow = ({ customer }: any): JSX.Element => {
  let history = useHistory();

  return (
    <ListRow
      key={`table-row-${customer.id}`}
      isCurrent={customer.isCurrentCustomer}
      isChecked={customer.isChecked}
      onClick={() => {
        history.push(`/customers/${customer.id}`);
      }}
      onCheckboxChange={customer.toggleChecked}
    >
      <Flex flex="1" align="center" gap="4">
        <Avatar size="md" src={customer.logo_url} />
        <Flex direction="column" alignItems="flex-start">
          <Caption fontWeight="600" color="gray.900">
            {customer.name}
          </Caption>
          {customer.is_unlinked && <UnlinkedTag tooltip={false} />}
        </Flex>
      </Flex>
    </ListRow>
  );
};

export default observer(CustomersRow);
