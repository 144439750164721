import { ReactNode, FunctionComponent } from 'react';
import { FormikFormProps } from 'formik';
import { Box, Stack, ChakraComponent } from '@chakra-ui/react';

import ActionsButtonGroup from '../Detail/ActionsButtonGroup';
import ActionsWrapper from '../Detail/ActionsWrapper';
import DetailScrollAreaWrapper from '../Detail/DetailScrollAreaWrapper';

interface DetailViewProps {
  rightActions?: ReactNode;
  leftActions?: ReactNode;
  children?: ReactNode;
  as?: FunctionComponent<FormikFormProps> | ChakraComponent<'form'>;
}

const DetailView = ({
  children,
  rightActions,
  leftActions,
  as,
}: DetailViewProps): JSX.Element => {
  return (
    <Box
      as={as}
      display="flex"
      flexDirection="column"
      flex={{ base: '2', xl: '3' }}
      bg="white"
      borderColor="gray.200"
    >
      <ActionsWrapper flexWrap="wrap">
        {leftActions ? leftActions : <Stack />}
        <ActionsButtonGroup>{rightActions}</ActionsButtonGroup>
      </ActionsWrapper>
      <DetailScrollAreaWrapper>{children}</DetailScrollAreaWrapper>
    </Box>
  );
};

export default DetailView;
