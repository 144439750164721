import { useEffect, useState, useCallback } from 'react';
import {
  Text,
  HStack,
  VStack,
  Box,
  Select,
  Input,
  IconButton,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Spinner,
  Link,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Radio,
  Spacer,
} from '@chakra-ui/react';
import { debounce } from 'lodash';
import { CrossIcon } from '../Icons/Icons';
import useAPI from '../../hooks/useAPI';
import { useAuth } from '../../contexts/auth';
import { useScroll } from '../../hooks/useScroll';
import { Button } from '../Button/Button';
import { Caption } from '../Typography/Typography';
import { pluralizeString } from '../../utils';

const ProductRow = ({
  product,
  allowMultiple,
}: {
  product: any;
  allowMultiple: boolean;
}) => {
  const clickableContent = (
    <Box ml="4" w="100%">
      <Box
        w="100%"
        flex={1}
        display="flex"
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Text color="gray.700" fontWeight="400" noOfLines={2}>
            {product.name}
          </Text>
          <Box lineHeight="24px" w="100%">
            {' '}
            <Box as="span" display="flex" w="100%" position="relative">
              <Caption color="gray.500">
                <HStack>
                  {product.price && product.pricing_unit && (
                    <Text as="span" fontWeight="semibold">
                      ${Number(product.price).toFixed(2)}/
                      {product.pricing_unit.name}
                    </Text>
                  )}
                  {product.product_code && (
                    <Text as="span">{product.product_code}</Text>
                  )}
                </HStack>
              </Caption>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
    >
      {allowMultiple ? (
        <Checkbox w="100%" value={String(product.id)}>
          {clickableContent}
        </Checkbox>
      ) : (
        <Radio w="100%" value={String(product.id)}>
          {clickableContent}
        </Radio>
      )}
    </HStack>
  );
};

const SelectProductModal = ({
  isOpen,
  onClose,
  onSelect,
  onConfirm,
  onClear,
  confirmButtonText = 'Done',
  allowMultiple = false,
  selectedProductIds = [],
}: any) => {
  const { user } = useAuth();

  const limit = 20;
  const [page, setPage] = useState(1);
  const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useScroll<HTMLDivElement>(() => {
    setPage(page + 1);
  });

  const [getProducts] = useAPI({
    method: 'GET',
  });
  const [getCategories] = useAPI({
    method: 'GET',
  });
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const hasFilters = currentQuery || currentCategory;

  const clearResults = () => {
    setProducts([]);
    setPage(1);
  };

  const clearFilters = () => {
    if (hasFilters) {
      clearResults();
    }
    setCurrentCategory('');
    setCurrentQuery('');
    setCurrentQueryFieldValue('');
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      clearResults();
      setCurrentQuery(value);
    }, 400),
    [],
  );

  const handleCategory = (value: string) => {
    clearResults();

    if (value === 'all') {
      setCurrentCategory('');
    } else {
      setCurrentCategory(value);
    }
  };

  useEffect(() => {
    const url = `/v4/products?page=${page}&limit=${limit}${
      currentQuery && '&q=' + currentQuery
    }${currentCategory && '&category_id=' + currentCategory}`;
    setIsLoading(true);

    getProducts(url).then((data: any) => {
      setResultCount(data.total_count);
      setProducts((products) => products.concat(data.results));
      setIsLoading(false);
    });
  }, [page, currentQuery, currentCategory]);

  useEffect(() => {
    getCategories(`/v2/companies/${user.company.id}/categories`).then(
      (data: any) => {
        setCategories(data);
      },
    );
  }, [user?.company?.id]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}} autoFocus={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={0} pb={0}>
          <Text px={6} mt={4} mb={6}>
            Select product
          </Text>

          <Box
            py={4}
            px={6}
            bg="gray.100"
            boxShadow="inset 0px 1px 0px #D1D5DB, inset 0px -1px 0px #D1D5DB"
          >
            <Input
              variant="outline"
              type="search"
              onChange={(e) => {
                setCurrentQueryFieldValue(e.target.value);
                handleSearch(e.target.value);
              }}
              value={currentQueryFieldValue}
              placeholder="Search"
              name="search"
              bg="white"
              mb={3}
            />
            <Select
              onChange={(e) => handleCategory(e.target.value)}
              name="category"
              value={currentCategory === '' ? 'All' : currentCategory}
            >
              <option value="all">All</option>
              {categories &&
                categories.map((category: any, i: number) => {
                  return (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </Select>
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          {products.length > 0 ? (
            !allowMultiple ? (
              <RadioGroup onChange={onSelect} value={selectedProductIds[0]}>
                <Box ref={scrollRef} maxHeight="320px" overflowY="scroll">
                  {products.map((product: any) => {
                    return (
                      <ProductRow
                        key={product.id}
                        product={product}
                        allowMultiple={allowMultiple}
                      />
                    );
                  })}
                </Box>
              </RadioGroup>
            ) : (
              <CheckboxGroup onChange={onSelect} value={selectedProductIds}>
                <Box ref={scrollRef} maxHeight="320px" overflowY="scroll">
                  {products.map((product: any) => {
                    return (
                      <ProductRow
                        key={product.id}
                        product={product}
                        allowMultiple={allowMultiple}
                      />
                    );
                  })}
                </Box>
              </CheckboxGroup>
            )
          ) : (
            !isLoading &&
            resultCount == 0 && (
              <VStack px={6} py={4} spacing="4" alignItems="left">
                <Text fontSize="lg" fontWeight="500">
                  No products found
                </Text>
                <Link fontWeight="400" onClick={clearFilters}>
                  Clear filters
                </Link>
              </VStack>
            )
          )}
          {isLoading && (
            <Center height="100px">
              <Spinner thickness="6px" size="xl" color="green" />
            </Center>
          )}
        </ModalBody>
        <ModalFooter backgroundColor="gray.100" borderRadius="0 0 8px 8px">
          <HStack w="100%">
            {allowMultiple && selectedProductIds.length > 0 && (
              <Button
                rightIcon={<CrossIcon h="8px" w="8px" />}
                onClick={onClear}
                size="xs"
                colorScheme="blue"
              >
                {selectedProductIds.length}{' '}
                {pluralizeString('product', selectedProductIds.length)} selected
              </Button>
            )}
            <Spacer />

            <ButtonGroup colorScheme="green" spacing="4">
              <Button
                variant="tertiary"
                onClick={() => {
                  onClose();
                  onClear();
                  clearFilters();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onConfirm}
                isDisabled={!selectedProductIds[0]}
              >
                {confirmButtonText}
              </Button>
            </ButtonGroup>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectProductModal;
