import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Input,
  Text,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  ModalFooter,
  FormErrorMessage,
  ButtonGroup,
} from '@chakra-ui/react';

import useAPI from '../../hooks/useAPI';
import { xClientHeaders } from '../../utils';
import { useAddOrder } from '../../contexts/addOrder';
import { useErrorToast, useSuccessToast } from '../toast';
import { Button } from '../Button/Button';
import { useCurrentUser } from '../../hooks/useStores';

const CreateNewCustomerModalScreen = observer(
  ({
    onSubmit,
    submitText = 'Next',
    onCancel,
  }: {
    onSubmit: (data: any) => void;
    submitText?: string;
    onCancel?: () => void;
  }) => {
    const { order } = useAddOrder();
    const { authToken, company } = useCurrentUser();
    const successToast = useSuccessToast();
    const errorToast = useErrorToast();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [notes, setNotes] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');

    const [fieldErrors, setFieldErrors] = useState<
      { fieldName: string; message: string }[]
    >([]);

    const validateForm = () => {
      let newFieldErrors = [];

      // Name
      if (!name) {
        newFieldErrors.push({
          fieldName: 'name',
          message: 'Name is a required field.',
        });
      }

      if (newFieldErrors.length > 0) {
        return setFieldErrors(newFieldErrors);
      } else {
        return true;
      }
    };

    const token = `Bearer ${authToken}`;

    const [createCustomer, isLoading] = useAPI({
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
        ...xClientHeaders,
      },
    });

    const handleSubmit = () => {
      if (validateForm()) {
        const formState = {
          company: {
            name,
            email,
          },
          customer: {
            notes,
            supplier_provided_address: deliveryAddress,
          },
        };

        createCustomer('/v4/customers', {
          body: JSON.stringify(formState),
        })
          .then((data: any) => {
            order.reset();
            order.setCustomer(data);
            if (company) {
              order.setSupplierCompany(company);
            }
            onSubmit(data);
            successToast({
              description: `New customer "${order.customer?.name}" has been added.`,
            });
          })
          .catch(() => {
            errorToast({
              description:
                "Something went wrong and we couldn't save the customer.",
            });
          });
      }
    };

    const hasError = (fieldName: string) => {
      return getErrorObject(fieldName) != undefined;
    };

    const getErrorMessage = (fieldName: string) => {
      return getErrorObject(fieldName)?.message;
    };

    const getErrorObject = (fieldName: string) => {
      return fieldErrors.find(
        (errorObj: any) => errorObj.fieldName == fieldName,
      );
    };

    const clearError = (fieldName: string) => {
      const errorObj = getErrorObject(fieldName);
      if (errorObj) {
        setFieldErrors(fieldErrors.filter((item) => item !== errorObj));
      }
    };

    return (
      <ModalContent>
        <ModalHeader
          px={0}
          pb={0}
          bg="gray.100"
          borderTopRadius="var(--chakra-radii-md)"
          boxShadow="inset 0px -1px 0px #D1D5DB"
        >
          <Text px={6} mt={4} mb={6}>
            Add new customer
          </Text>
          <ModalCloseButton top={4} right={4} />
        </ModalHeader>
        <ModalBody p={0}>
          <FormControl
            id="name"
            isInvalid={hasError('name')}
            px={6}
            mb={8}
            mt={8}
          >
            <FormLabel>Company name</FormLabel>
            <Input
              defaultValue={name}
              onChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                clearError('name');
                setName(target.value);
              }}
              placeholder="Doe Bakery"
            />
            {hasError('name') ? (
              <FormErrorMessage>{getErrorMessage('name')}</FormErrorMessage>
            ) : (
              <FormHelperText>Required</FormHelperText>
            )}
          </FormControl>

          <FormControl id="email" isInvalid={hasError('email')} px={6} mb={8}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              defaultValue={email}
              onChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                clearError('email');
                setEmail(target.value);
              }}
              placeholder="example@email.co.nz"
            />
            {hasError('email') && (
              <FormErrorMessage>{getErrorMessage('email')}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            id="delivery_address"
            isInvalid={hasError('delivery_address')}
            px={6}
            mb={8}
          >
            <FormLabel>Delivery Address</FormLabel>
            <Input
              defaultValue={deliveryAddress}
              onChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                clearError('delivery_address');
                setDeliveryAddress(target.value);
              }}
              placeholder="123 Streety Street, Streetsville, Citytown"
            />
            {hasError('delivery_address') && (
              <FormErrorMessage>
                {getErrorMessage('delivery_address')}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl id="customer_note" px={6} mb={6}>
            <FormLabel>Customer note</FormLabel>
            <Input
              defaultValue={notes}
              onChange={(e: React.SyntheticEvent) => {
                const target = e.target as HTMLInputElement;
                setNotes(target.value);
              }}
              placeholder="Eg; minimal packaging"
            />
            <FormHelperText>
              Customer note appears on orders from this customer, including
              downloads and print outs. Not visible to the customer.
            </FormHelperText>
          </FormControl>
        </ModalBody>
        <ModalFooter backgroundColor="gray.100" borderRadius="0 0 8px 8px">
          <ButtonGroup>
            {onCancel && (
              <Button variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              variant="primary"
              isDisabled={name === ''}
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              {submitText}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    );
  },
);

export default CreateNewCustomerModalScreen;
