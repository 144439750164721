import { useField } from 'formik';
import EditableFormWrapper, {
  EditableFormWrapperProps,
} from './EditableFormWrapper';

const FormikEditableFormWrapper = ({
  onBlur = () => { },
  ...editableFormWrapperProps
}: EditableFormWrapperProps) => {
  const [field, meta] = useField(editableFormWrapperProps.fieldName);

  return (
    <EditableFormWrapper
      error={meta.touched && meta.error ? meta.error : undefined}
      onBlur={(e) => {
        onBlur(e);
        field.onBlur(e);
      }}
      {...editableFormWrapperProps}
    />
  );
};

// Only apply formik field props if isEditable is true
const SafeFormikEditableFormWrapper = ({
  isEditable,
  ...editableFormWrapperProps
}: EditableFormWrapperProps) => {
  if (!isEditable) {
    return <EditableFormWrapper isEditable={isEditable} {...editableFormWrapperProps} />;
  }
  return <FormikEditableFormWrapper isEditable={isEditable} {...editableFormWrapperProps} />;
  
};

export default SafeFormikEditableFormWrapper;
