import { api } from '../api';
import * as Sentry from '@sentry/browser';

export const loginUser = async (payload: any) =>
  api.post('/v2/sign_in', JSON.stringify(payload)).then(
    async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    },
    (error) => {
      Sentry.captureException(error);
      return error;
    },
  );

export const logoutUser = async () =>
  api.delete('/v2/sign_out').catch((error) => {
    Sentry.captureException(error);
  });

export const getCurrentUser = async () => {
  return api.get(`/v4/current_user`).then(
    async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    },
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    },
  );
}

const AuthApi = {
  loginUser,
  logoutUser,
  getCurrentUser
}

export default AuthApi;