import { observer } from 'mobx-react-lite';

import { useCustomerGroups } from '../../../hooks/useStores';
import CustomerGroupSetBox from '../../../components/CustomerGroups/List/CustomerGroupSetBox';

const CustomerGroupsList = (): JSX.Element => {
  const { defaultGroupSets } = useCustomerGroups();

  return (
    <>
      {defaultGroupSets.length !== 0 &&
        defaultGroupSets.map((groupSet: any) => (
          <CustomerGroupSetBox key={groupSet.id} groupSet={groupSet} />
        ))}
    </>
  );
};

export default observer(CustomerGroupsList);
