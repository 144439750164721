import { useState } from 'react';
import { useAuth } from '../../../contexts/auth';

import { Box } from '@chakra-ui/react';
import { Button } from '../../../components/Button/Button';
import IntegrationListItemWrapper from './IntegrationListItemWrapper';

type OauthIntegrationListItemProps = {
  logoImageUrl: string;
  platformKey: string;
  platformName: string;
};

const OauthIntegrationListItem = ({
  logoImageUrl,
  platformKey,
  platformName,
}: OauthIntegrationListItemProps): JSX.Element => {
  const { user } = useAuth();
  const [connectButtonIsLoading, setConnectButtonIsLoading] = useState(false);

  const authToken = user && user.authentication_token;

  const connectFormIdPrefix = `connectForm-${platformKey}`;
  const formId = `${connectFormIdPrefix}-form`;
  const authFieldId = `${connectFormIdPrefix}-authentication_token_field`;
  const returnUrlFieldId = `${connectFormIdPrefix}-return_url`;

  const connectUrl = `${process.env.REACT_APP_API_URL}/auth/${platformKey}`;
  return (
    <IntegrationListItemWrapper
      logoImageUrl={logoImageUrl}
      platformName={platformName}
    >
      <Box>
        <form id={formId} method="post" name="connectForm" action={connectUrl}>
          <input name="authentication_token" id={authFieldId} type="hidden" />
          <input
            name="return_url"
            id={returnUrlFieldId}
            type="hidden"
            value={window.location.href.split('?')[0]}
          />
        </form>
        <Button
          variant="primary"
          isLoading={connectButtonIsLoading}
          onClick={() => {
            setConnectButtonIsLoading(true);
            let authenticationTokenField = document.getElementById(authFieldId);
            authenticationTokenField!.setAttribute('value', authToken);
            let form = document.forms.namedItem(formId);
            form!.submit();
          }}
        >
          Connect
        </Button>
      </Box>
    </IntegrationListItemWrapper>
  );
};

export default OauthIntegrationListItem;
