import { set } from 'lodash';
import { types } from 'mobx-state-tree';

export interface IngredientProps {
  id: number;
  name: string;
  unit: 'kg' | 'L';
  conversion_factor: string;
  recipes: {
    id: number;
    name: string;
  }[];
}

export const MicroRecipe = types.model('MicroRecipe', {
  id: types.number,
  name: types.string,
});

const Ingredient = types
  .model('Ingredient', {
    id: types.identifierNumber,
    name: types.string,
    unit: types.optional(
      types.union(types.literal('kg'), types.literal('L')),
      'kg',
    ),
    conversion_factor: types.optional(types.number, 1),
    recipes: types.array(MicroRecipe),
    isCurrent: false
  })
  .views((self) => ({
    get toFormInitialValues() {
      return {
        name: self.name,
        unit: self.unit,
        conversion_factor: self.conversion_factor,
      };
    },
  }))
  .actions((self) => ({
    setIsCurrent(isCurrent: boolean) {
      self.isCurrent = isCurrent;
    },
    setUnit(unit: 'kg' | 'L') {
      self.unit = unit;
    },
    setConversionFactor(conversionFactor: number) {
      self.conversion_factor = conversionFactor;
    },
    setName(name: string) {
      self.name = name;
    },
    convertedQuantity(quantity: number, baseUnit: 'kg' | 'L', desiredUnit: 'kg' | 'L') {
      if (baseUnit === desiredUnit) {
        return quantity;
      } else {
        if (desiredUnit === self.unit) {
          return quantity / self.conversion_factor;
        } else {
          return quantity * self.conversion_factor;
        }
      }
    },
    addData({
      id,
      name,
      unit,
      conversion_factor,
      recipes
    }: IngredientProps) {
      self.id = id;
      self.name = name;
      self.unit = unit;
      self.conversion_factor = parseFloat(conversion_factor) || 1;
      recipes.forEach((recipe) => 
        self.recipes.push({
          id: recipe.id,
          name: recipe.name
        }
      ));
    },
  }))

export default Ingredient;
