import { observer } from 'mobx-react';
import { useErrorToast, useSuccessToast } from '../../components/toast';
import { useCurrentUser, useOrders } from '../../hooks/useStores';
import { pluralizeString, capitalizeString } from '../../utils';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { useBatchOrders } from '../../contexts/batchOrders';
import { useAuth } from '../../contexts/auth';

export type OrdersUpdateStatusModalProps = {
  orderIds: number[];
  status: string;
  isOpen: boolean;
  onClose: () => void;
};

const OrdersUpdateStatusModal = ({
  orderIds,
  status,
  isOpen,
  onClose,
}: OrdersUpdateStatusModalProps): JSX.Element => {
  const errorToast = useErrorToast();
  const { ordersList, getOrders } = useOrders();
  const { isBuyer } = useCurrentUser();
  const { updateBatchOrders } = useBatchOrders();
  const successToast = useSuccessToast();

  const onConfirm = () => {
    updateBatchOrders(
      {
        order_ids: orderIds,
        attributes: {
          status: status,
        },
      },
      onSuccess,
      onError,
    );
  };

  const onSuccess = () => {
    let shouldRefreshOrdersList = false;

    orderIds.forEach((orderId) => {
      if (ordersList[orderId]) {
        shouldRefreshOrdersList = true;
        ordersList[orderId].setStatus(status);
      }
    });

    if (shouldRefreshOrdersList) {
      getOrders(
        () => {},
        () => {},
        isBuyer ? 'outgoing' : 'incoming',
      );
    }

    successToast({
      description: `Order status updated to ${capitalizeString(status)}. (${
        orderIds.length
      } ${pluralizeString('order', orderIds.length)})`,
    });

    onClose();
  };

  const onError = () => {
    errorToast();
    onClose();
  };

  const orderAmountSummaryText = () => {
    return orderIds.length > 1
      ? `${orderIds.length} ${pluralizeString('order', orderIds.length)}`
      : 'order';
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={onClose}
      titleText={`Update ${orderAmountSummaryText()} to ${capitalizeString(
        status,
      )}?`}
      bodyContent={`You can find these orders in the ${capitalizeString(
        status,
      )} tab.`}
      confirmButtonText={`Update ${orderAmountSummaryText()}`}
      confirmButtonColorScheme="green"
      onConfirm={onConfirm}
    />
  );
};

export default observer(OrdersUpdateStatusModal);
