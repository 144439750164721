import { IconButton, ScaleFade, StyleProps } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import { useState } from 'react';
import useAPI from '../../hooks/useAPI';
import Product from '../../models/Product';
import { StarIcon } from '../Icons/IconsNew';

const FavouriteButton = ({
  product,
  show = true,
  customerId = null,
  ...chakraProps
}: {
  product: Instance<typeof Product>;
  show?: boolean;
  customerId?: string | null;
} & StyleProps) => {
  const [isFavourite, setIsFavourite] = useState(!!product.favourite);
  const [addFavourite] = useAPI({
    method: 'POST',
  });
  const [removeFavourite] = useAPI({
    method: 'DELETE',
  });

  // Sometimes the Product is a snapshot and doesn't have the setFavourite method
  const setFavourite = (value: boolean) => {
    setIsFavourite(value);

    if (
      product.hasOwnProperty('setFavourite') &&
      typeof product.setFavourite === 'function'
    ) {
      product.setFavourite(value);
    } else {
      product.favourite = value;
    }
  };

  const handleFavourite = () => {
    const url =
      `/v4/products/${product.id}/favourite` +
      (customerId ? `?customer_id=${customerId}` : '');
    if (isFavourite) {
      removeFavourite(url).then(() => {
        setFavourite(false);
      });
    } else {
      addFavourite(url).then(() => {
        setFavourite(true);
      });
    }
  };

  return (
    <ScaleFade initialScale={0.5} in={show}>
      <IconButton
        aria-label="Favourite"
        icon={
          <StarIcon w="5" fill={isFavourite ? 'green.500' : 'transparent'} />
        }
        variant="ghost"
        color={isFavourite ? 'green.500' : 'gray.400'}
        onClick={handleFavourite}
        {...chakraProps}
      />
    </ScaleFade>
  );
};

export default observer(FavouriteButton);
