import { ReactElement } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';

import {
  VStack,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tooltip,
  useDisclosure,
  HStack,
  Button as ChakraButton,
} from '@chakra-ui/react';

import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import { Body2, Footnote, H4 } from '../../../components/Typography/Typography';
import { Product } from '../../../stores/productGroupsStore';
import { ProductGroup } from '../../../models/ProductGroup';
import { ProductGroupSet } from '../../../models/ProductGroupSet';
import {
  ArrowRightIcon,
  EditBoxIcon,
  RubbishIcon,
} from '../../../components/Icons/IconsNew';
import DetailView from '../../../components/DetailView/DetailView';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { Button } from '../../../components/Button/Button';

export interface ShowProductGroupProps {
  group: ProductGroup;
  groupSet: ProductGroupSet;
  showGroupSetNameInTitle?: boolean;
  confirmationDialogTitleText: string;
  confirmationDialogBodyContent: string | ReactElement<any, any>;
}

const ShowProductGroup = ({
  group,
  groupSet,
  showGroupSetNameInTitle = true,
  confirmationDialogTitleText,
  confirmationDialogBodyContent,
}: ShowProductGroupProps) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const onConfirm = () => {
    setIsLoading(true);
    group.delete().then(() => {
      setIsLoading(false);
      history.push(group.groupSet.indexUri);
    });
  };

  const startEdit = () => {
    history.push(`${group.groupSet.baseUri}/product-groups/${group.id}/edit`);
  };

  return (
    <DetailView
      leftActions={
        <HStack>
          {showGroupSetNameInTitle && (
            <>
              <H4 fontWeight="600">{group.groupSet.name}</H4>
              <ArrowRightIcon width="24px" color="gray.400" />
            </>
          )}
          <H4 fontWeight="600" as="h5">
            {group.name}
          </H4>
        </HStack>
      }
      rightActions={
        <>
          <Tooltip label="Delete group">
            <ChakraButton
              onClick={onOpen}
              leftIcon={<RubbishIcon width="24px" />}
              isLoading={isLoading}
            >
              Delete
            </ChakraButton>
          </Tooltip>
          <Tooltip label="Edit group">
            <ChakraButton
              onClick={() => {
                history.push(
                  `${group.groupSet.baseUri}/product-groups/${group.id}/edit`,
                );
              }}
              leftIcon={<EditBoxIcon width="24px" />}
            >
              Edit
            </ChakraButton>
          </Tooltip>
          <ConfirmationDialog
            isOpen={isOpen}
            onCancel={onClose}
            titleText={confirmationDialogTitleText}
            bodyContent={confirmationDialogBodyContent}
            confirmButtonText={'Delete'}
            onConfirm={onConfirm}
          />
        </>
      }
    >
      <VStack width="full" align="left" spacing="8" my="10">
        <FormFieldWrapper
          fieldName="name"
          fieldLabel={<Footnote color="gray.600">Name</Footnote>}
        >
          <Body2>{group.name}</Body2>
        </FormFieldWrapper>

        {group.products.length > 0 ? (
          <Table variant="orderitems" width="100%">
            <Thead>
              <Tr>
                <Th>Products</Th>
              </Tr>
            </Thead>
            <Tbody>
              {group.products.map((product: Product) => (
                <Tr key={product.id}>
                  <Td>{product.name}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <FormFieldWrapper
            fieldName="products"
            fieldLabel={<Footnote color="gray.600">Products</Footnote>}
          >
            <Button onClick={startEdit}>Add products</Button>
          </FormFieldWrapper>
        )}
      </VStack>
    </DetailView>
  );
};

export default observer(ShowProductGroup);
