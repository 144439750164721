import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { VStack, Text } from '@chakra-ui/react';
import { useCustomerGroups } from '../../../hooks/useStores';
import ShowCustomerGroup from '../../../components/CustomerGroups/Detail/ShowCustomerGroup';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EditCustomerGroup from '../../../components/CustomerGroups/Detail/EditCustomerGroup';
import UnassignedCustomers from '../../../components/CustomerGroups/Detail/UnassignedCustomers';

const PricingTierDetail = ({
  groupId,
  groupSetId,
  isReadOnly = false,
}: {
  groupId: any;
  groupSetId: string;
  isReadOnly?: boolean;
}): JSX.Element => {
  const {
    currentGroup,
    pricingTiersGroupSet,
    getCustomerGroup,
    setCurrentGroup,
    setCurrentGroupSet,
  } = useCustomerGroups();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (!groupId) {
      setCurrentGroup(undefined);
    }

    if (groupId) {
      getCustomerGroup(groupId).then(() => {
        setLoading(false);
      });
    } else {
      setCurrentGroup(undefined);
      setLoading(false);
    }
  }, [groupId, groupSetId]);

  useEffect(() => {
    if (pricingTiersGroupSet) {
      setCurrentGroupSet(pricingTiersGroupSet.id);
    }
  }, [pricingTiersGroupSet]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/pricing-tiers/customer-groups/new">
        {!currentGroup && pricingTiersGroupSet && !isReadOnly && (
          <EditCustomerGroup
            key={pricingTiersGroupSet.id}
            groupSet={pricingTiersGroupSet}
          />
        )}
      </Route>
      <Route path="/pricing-tiers/customer-groups/unassigned">
        {!currentGroup && pricingTiersGroupSet && (
          <UnassignedCustomers
            key={pricingTiersGroupSet.id}
            groupSet={pricingTiersGroupSet}
          />
        )}
      </Route>
      <Route path="/pricing-tiers/customer-groups/:groupId/edit">
        {currentGroup && pricingTiersGroupSet && !isReadOnly && (
          <EditCustomerGroup
            key={currentGroup.id}
            group={currentGroup}
            groupSet={pricingTiersGroupSet}
          />
        )}
      </Route>
      <Route path="/pricing-tiers/customer-groups/:groupId">
        {currentGroup && (
          <ShowCustomerGroup
            key={currentGroup.id}
            group={currentGroup}
            groupSet={pricingTiersGroupSet}
            showGroupSetNameInTitle={false}
            isReadOnly={isReadOnly}
            confirmationDialogTitleText={'Delete Pricing tier?'}
            confirmationDialogBodyContent={
              <VStack alignItems="left">
                <Text>
                  Deleting this tier will remove all discounts and custom
                  pricing added to product settings for this tier.All customers
                  in this tier will revert back to standard pricing.
                </Text>
                <Text>This action cannot be undone.</Text>
              </VStack>
            }
          />
        )}
      </Route>
      <Route path="/pricing-tiers">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(PricingTierDetail);
