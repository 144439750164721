import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useAuth } from '../../contexts/auth';
import { ExportIcon, UserInCircleIcon } from '../Icons/IconsNew';
import { SidebarSubItem } from '../Sidebar/SidebarNavItem';
import { Footnote } from '../Typography/Typography';

export const UserNav = () => {
  const mixpanel = useMixpanel();
  const { user, logoutUser } = useAuth();
  return (
    <Menu placement="top">
      <MenuButton
        as={IconButton}
        borderRadius="22px"
        w="44px"
        h="44px"
        border="none"
        bg="transparent"
        _hover={{
          border: '2px white',
          boxShadow: 'dark-lg',
        }}
        _expanded={{
          border: '2px white',
          boxShadow: 'dark-lg',
        }}
        icon={
          <Avatar
            size="sm"
            w="40px"
            h="40px"
            name={user.fullname}
            src={user.avatar_url}
          />
        }
      />
      <Portal>
        <MenuList position="relative" zIndex="dropdown" left="2">
          <SidebarSubItem
            link="/profile"
            icon={UserInCircleIcon}
            label={
              <>
                <Footnote>{user.fullname}</Footnote>
                {user.company && (
                  <Footnote fontSize="xs">{user.company.name}</Footnote>
                )}
              </>
            }
          />
          <SidebarSubItem
            onClick={() => {
              logoutUser();
              mixpanel.track('Log out');
            }}
            icon={ExportIcon}
            label="Log out"
          />
        </MenuList>
      </Portal>
    </Menu>
  );
};
