import { useRef, useEffect } from 'react';
import { debounce } from 'lodash';

/**
 * Adds an on scroll event listener to the `scrollRef` element, calling the passed `callback` function
 * when the bottom of the `scrollRef` element is reached.
 *
 * Returns a `ref` to be applied within the component using this hook.
 *
 * @param callback - Callback function to be called when the bottom of the element is scrolled to.
 * @returns scrollRef - Ref to be applied to the right component.
 */
function useScroll<ElementType extends HTMLElement>(callback: any) {
  const scrollRef = useRef<ElementType>(null);
  useEffect(() => {
    const marginOfError = 1;
    const handleScrollBottom = debounce((event: Event) => {
      if (!scrollRef.current) {
        return;
      }

      const containerHeight =
        scrollRef.current.scrollHeight - scrollRef.current.offsetHeight;
      const currentDistanceFromBottom =
        scrollRef.current.scrollTop - containerHeight;
      const hasHitTheBottom =
        Math.abs(currentDistanceFromBottom) <= marginOfError;

      if (hasHitTheBottom) {
        callback(event);
      }
    }, 100);

    scrollRef.current?.addEventListener('scroll', handleScrollBottom);

    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScrollBottom);
    };
  });

  return scrollRef;
}

export { useScroll };
