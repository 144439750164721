import { useState } from 'react';
import {
  ButtonGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { PasswordField } from '../../../../components/Auth/PasswordField';
import imageUrl from '../../../../images/accredo.png';
import { Button } from '../../../../components/Button/Button';
import IntegrationListItemWrapper from '../IntegrationListItemWrapper';
import useAPI from '../../../../hooks/useAPI';
import { useSuccessToast, useErrorToast } from '../../../../components/toast';
import { useAccounting } from '../../../../contexts/accounting';

const AccredoIntegrationListItem = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getCurrentAccountingConnection } = useAccounting();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [clientId, setClientId] = useState('');
  const [host, setHost] = useState('');
  const [systemType, setSystemType] = useState('saturn');
  const [companyCode, setCompanyCode] = useState('');

  const [callAPIPost, submitIsLoading] = useAPI({
    method: 'POST',
  });

  function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    callAPIPost('/v4/accounting_connectors/accredo/connect', {
      body: JSON.stringify({
        username: username,
        password: password,
        client_id: clientId,
        host: host,
        system_type: systemType,
        company_code: companyCode,
      }),
    })
      .then(() => {
        successToast({
          description:
            'Accredo is successfully set up and ready to receive Invoices.',
        });
        getCurrentAccountingConnection(() => {
          onClose();
        });
      })
      .catch(async (response: Response) => {
        if (response.status === 422) {
          const data = await response.json();
          errorToast({
            title: 'Error connecting to Accredo',
            description: data.error,
          });
        } else {
          errorToast({
            title: 'Error connecting to Accredo',
            description: 'The provided credentials are invalid.',
          });
        }
      });
  }

  return (
    <IntegrationListItemWrapper logoImageUrl={imageUrl} platformName="Accredo">
      <Button variant="primary" onClick={onOpen}>
        Connect
      </Button>

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalHeader>Connect to your Accredo account</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack alignItems={'left'} spacing="8">
                <Text pb="2">
                  Your Accredo QSP can assist you in getting the correct
                  information to complete this setup.
                </Text>

                <FormControl id="accredo-host">
                  <FormLabel>Host</FormLabel>
                  <Input
                    name="accredo-host"
                    tabIndex={1}
                    autoComplete="off"
                    value={host}
                    onChange={(e) => setHost(e.target.value)}
                  />
                </FormControl>
                <FormControl id="accredo-system-type">
                  <FormLabel>System</FormLabel>
                  <Select
                    name="accredo-system-type"
                    tabIndex={2}
                    onChange={(e) => setSystemType(e.target.value)}
                    value={systemType}
                  >
                    <option value={'saturn'}>SATURN</option>
                    <option value={'mercury'}>MERCURY</option>
                  </Select>
                </FormControl>
                <FormControl id="accredo-company-code">
                  <FormLabel>Company Code</FormLabel>
                  <Input
                    name="accredo-company-code"
                    tabIndex={3}
                    autoComplete="off"
                    value={companyCode}
                    onChange={(e) => setCompanyCode(e.target.value)}
                  />
                </FormControl>
                <FormControl id="accredo-client-id">
                  <FormLabel>Client Id</FormLabel>
                  <Input
                    name="accredo-client-id"
                    tabIndex={4}
                    autoComplete="off"
                    value={clientId}
                    onChange={(e) => setClientId(e.target.value)}
                  />
                </FormControl>
                <FormControl id="accredo-username">
                  <FormLabel>Username</FormLabel>
                  <Input
                    name="accredo-username"
                    tabIndex={5}
                    autoComplete="off"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </FormControl>
                <FormControl id="accredo-password">
                  <FormLabel>Password</FormLabel>
                  <PasswordField
                    name="accredo-password"
                    tabIndex={6}
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup colorScheme="green">
                <Button
                  variant="tertiary"
                  onClick={onClose}
                  isDisabled={submitIsLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  isLoading={submitIsLoading}
                  tabIndex={6}
                  type="submit"
                >
                  Continue
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </IntegrationListItemWrapper>
  );
};

export default AccredoIntegrationListItem;
