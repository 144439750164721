
// This one doesn't work for some reason. I tried Checkbox too.
const CheckboxGroup = {
  baseStyle: {
    control: {
      bg: "white",
      backgroundColor: "white"
    }
  },
  variants: {
    table: {
      container: {
        padding: '1em',
        borderRadius: '100%',
        _hover: {
          bg: 'blackAlpha.200',
          backgroundColor: 'blackAlpha.200'
        }
      },
      control: {
        borderColor: "gray.500"
      }
    }
  }
}

export default CheckboxGroup;