import {
  Box,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { Label, Subhead } from '../../../components/Typography/Typography';
import { useProductGroups } from '../../../hooks/useStores';
import { ProductGroupSetProps } from '../../../models/ProductGroupSet';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import FormField from '../../../components/FormField/FormField';

const EditProductGroupSetModal = () => {
  const history = useHistory();
  const { productGroupSets, currentGroupSet } = useProductGroups();

  const { isOpen, onOpen, onClose: onConfirmClose } = useDisclosure();
  const onConfirm = () => {
    currentGroupSet.delete().then(() => {
      history.push(`/product-group-sets`);
    });
  };

  const onClose = () => {
    history.push('/product-group-sets');
  };

  return (
    <>
      <ConfirmationDialog
        marginTop="7.5rem"
        isOpen={isOpen}
        onCancel={onConfirmClose}
        titleText={`Delete “${currentGroupSet.name}” set?`}
        bodyContent="Deleting a set does not delete the product records. This action cannot be undone."
        confirmButtonText={'Delete'}
        onConfirm={onConfirm}
      />
      <Modal isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            px={6}
            bg="gray.100"
            borderTopRadius="var(--chakra-radii-md)"
            boxShadow="inset 0px -1px 0px #D1D5DB"
          >
            Edit set
          </ModalHeader>
          <ModalCloseButton />
          <Formik
            initialValues={{
              name: currentGroupSet.name,
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .required('Required')
                .max(40, 'Name must be 40 characters or less')
                .test(
                  'unique',
                  'Another product group set already exists with this name',
                  (value) => {
                    const duplicate = productGroupSets.some(
                      (set) => set.name === value,
                    );
                    return !duplicate;
                  },
                ),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              currentGroupSet
                .update(values)
                .then((groupSet: ProductGroupSetProps) => {
                  setSubmitting(false);
                  history.push('/product-group-sets');
                });
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <ModalBody>
                  <VStack my="4" spacing="8">
                    <FormField
                      name="name"
                      label="Set name"
                      direction="column"
                    />
                    <VStack alignItems="left">
                      <Label>Delete set?</Label>
                      <Box>
                        <Button onClick={onOpen} colorScheme="red">
                          Delete set
                        </Button>
                      </Box>
                      <Subhead pb="8" color="gray.600">
                        Deleting a set does not delete the product records. This
                        action cannot be undone.
                      </Subhead>
                    </VStack>
                  </VStack>
                </ModalBody>
                <ModalFooter
                  backgroundColor="gray.100"
                  borderRadius="0 0 8px 8px"
                >
                  <ButtonGroup>
                    <Button variant="secondary" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      isLoading={isSubmitting}
                      isDisabled={!isValid}
                    >
                      Done
                    </Button>
                  </ButtonGroup>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(EditProductGroupSetModal);
