import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import NoteEditor from './NoteEditor';
import NoteEditorIconButton from './NoteEditorIconButton';

export interface NoteEditorWrapperProps {
  initialValue: string;
  onSave: (newValue: string) => void;
  iconForButton: ReactNode;
  noteEditorLabel: string;
}

const NoteEditorWrapper = observer(
  ({
    initialValue,
    onSave,
    noteEditorLabel,
    iconForButton,
  }: NoteEditorWrapperProps): JSX.Element => {
    return (
      <NoteEditor
        initialValue={initialValue}
        onSave={onSave}
        headerText={noteEditorLabel}
      >
        <NoteEditorIconButton
          noteEditorLabel={noteEditorLabel}
          iconForButton={iconForButton}
        />
      </NoteEditor>
    );
  },
);

export default NoteEditorWrapper;
