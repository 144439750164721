import { CaretDownIcon } from "../../components/Icons/IconsNew";
import { Select as ChakraSelect } from "@chakra-ui/react";

ChakraSelect.defaultProps = {
  icon: <CaretDownIcon width="12px" />,
};
const Select = {
  baseStyle: {
    field: {
      bg: 'white',
      backgroundColor: 'white',
    },
    icon: {
      width: '12px',
      mx: '4px',
      color: 'gray.700',
    },
  },
  variants: {
    unitSelector: {
      field: {
        bg: 'gray.100',
        backgroundColor: 'gray.100',
        borderWidth: '1px',
        borderColor: 'gray.300',
        minWidth: '62px',
      },
    },
  },
};

export default Select;
