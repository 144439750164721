import { useRef, useState } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Caption } from '../Typography/Typography';
import 'cropperjs/dist/cropper.css';
import { SizedIcon } from '../Icons/IconsNew';
import { fileToBase64 } from '../../utils';

export type ImageUploaderProps = {
  buttonText: string;
  croppingEnabled?: boolean;
  onSave: (base64Image: string) => void;
};

const ImageUploader = ({
  buttonText,
  croppingEnabled = false,
  onSave,
}: ImageUploaderProps): JSX.Element => {
  const [image, setImage] = useState<string | null>(null);
  const [isCropping, setIsCropping] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cropperRef = useRef<ReactCropperElement>(null);

  const reset = () => {
    onClose();
    setImage(null);
    setIsCropping(false);
  };

  const onClickCancel = () => {
    reset();
  };

  const onClickDone = () => {
    const cropper = cropperRef.current?.cropper;
    // @ts-ignore
    onSave(cropper.getCroppedCanvas().toDataURL());
    reset();
  };

  const onDrop = (acceptedFiles: File[]) => {
    fileToBase64(acceptedFiles[0]).then((base64) => {
      if (croppingEnabled) {
        setImage(base64);
        setIsCropping(true);
      } else {
        onSave(base64);
        reset();
      }
    });
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: onDrop,
      maxFiles: 1,
      accept: { 'image/jpeg': [], 'image/png': [] },
    });

  return (
    <>
      <Button
        size="sm"
        onClick={onOpen}
        rightIcon={<SizedIcon width="16px" height="16px" name={'UploadIcon'} />}
      >
        {buttonText}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {image && isCropping ? <>Crop photo</> : <>Upload photo</>}
          </ModalHeader>
          <ModalBody>
            {!image && (
              <VStack>
                <Box
                  borderWidth={isDragAccept || isDragReject ? '3px' : '2px'}
                  borderStyle={'dashed'}
                  borderColor={
                    isDragAccept ? 'green' : isDragReject ? 'red' : 'gray.500'
                  }
                  boxSizing="content-box"
                  py="8"
                  px="6"
                  cursor="pointer"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <Text color="gray.600">
                    Drag and drop, or click here to upload an image.
                  </Text>
                </Box>

                <Caption color="gray.600">
                  For best results, upload images that are landscape
                  orientation, with the focal point in the center. JPG/PNG files
                  only.
                </Caption>
              </VStack>
            )}

            {image && croppingEnabled && isCropping && (
              <>
                <Cropper
                  src={image}
                  zoomOnWheel={false}
                  style={{ width: '100%' }}
                  aspectRatio={1}
                  guides={true}
                  ref={cropperRef}
                />
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button variant="secondary" onClick={onClickCancel}>
                Cancel
              </Button>
              {image && croppingEnabled && isCropping && (
                <Button variant="primary" onClick={onClickDone}>
                  Done
                </Button>
              )}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageUploader;
