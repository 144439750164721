import { types, Instance, destroy } from 'mobx-state-tree';
import Ingredient, { IngredientProps } from './Ingredient';
import { setNestedObjectValues } from 'formik';
import { set } from 'lodash';

type idType = string | number;

export interface RecipeProps {
  id: idType;
  name: string;
  unit: 'kg' | 'L';
  recipe_items: Array<RecipeItemProps>;
}

export interface RecipeItemProps {
  id: idType;
  quantity: string;
  ingredient: IngredientProps;
}

export const RecipeItem = types.model('RecipeItem', {
  id: types.union(types.number, types.string),
  ingredient: Ingredient,
  quantity: types.number,
  _destroy: types.optional(types.boolean, false),
})
  .views((self) => ({
    get formState() {
      return {
        id: self.id,
        quantity: self.quantity,
        ingredient_id: self.ingredient.id,
        _destroy: self._destroy,
      };
    },
  }))
.actions((self) => ({
  setQuantity(quantity: number) {
    self.quantity = quantity;
  },
  markForDeletion() {
    if (self.id) {
      self._destroy = true;
    } else {
      destroy(self);
    }
  },
  restore() {
    self._destroy = false;
  },
}));

const Recipe = types
  .model('Recipe', {
    id: types.union(types.number, types.string),
    name: types.string,
    unit: types.optional(
      types.union(types.literal('kg'), types.literal('L')),
      'kg',
    ),
    isCurrent: false,
    recipeItems: types.array(RecipeItem),
  })
  .views((self) => ({
    get formState() {
      return {
        name: self.name,
        unit: self.unit,
        recipe_items_attributes: self.recipeItems.map((item) => item.formState),
      };
    },
    get currentRecipeItems() {
      return self.recipeItems.filter((item) => !item._destroy);
    }
  }))
  .views((self) => ({
    get totalQuantity() {
      return self.currentRecipeItems.reduce(
        (acc, item) => acc + item.quantity,
        0,
      );
    },
  }))
  .actions((self) => ({
    setIsCurrent(isCurrent: boolean) {
      self.isCurrent = isCurrent;
    },
    setName(name: string) {
      self.name = name;
    },
    setUnit(unit: 'kg' | 'L') {
      self.unit = unit;
    },
    addData({
      id,
      name,
      unit,
      recipe_items,
    }: RecipeProps) {
      self.id = id;
      self.name = name;
      self.unit = unit;
      self.recipeItems.clear();

      recipe_items.forEach((item) => 
        self.recipeItems.push({
          id: item.id,
          quantity: parseFloat(item.quantity),
          ingredient: {
            id: item.ingredient.id,
            name: item.ingredient.name,
            unit: item.ingredient.unit,
            conversion_factor: parseFloat(item.ingredient.conversion_factor),
          }
        }
      ));
    },
    removeItem(item: Instance<typeof RecipeItem>) {
      item.markForDeletion();
    },
    coefficientFor(item: Instance<typeof RecipeItem>) {
      return item.ingredient.convertedQuantity(item.quantity, item.ingredient.unit, self.unit) / self.totalQuantity;
    },
    addIngredient(ingredientData: IngredientProps) {
      self.recipeItems.push({
        id: '',
        ingredient: {
          id: ingredientData.id,
          name: ingredientData.name,
          unit: ingredientData.unit,
          conversion_factor: parseFloat(ingredientData.conversion_factor),
        },
        quantity: 1
      });
    }
  }))
  .actions((self) => ({
    quantityFor(item: Instance<typeof RecipeItem>, goalQuantity: number) {
      return item.ingredient.convertedQuantity(self.coefficientFor(item), self.unit, item.ingredient.unit) * goalQuantity;
    }
  }));

export default Recipe;
