import { useEffect, useState, useCallback } from 'react';
import {
  Text,
  HStack,
  VStack,
  Box,
  Select,
  Input,
  InputGroup,
  InputRightElement,
  Button as ChakraButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
  Spinner,
  Link,
} from '@chakra-ui/react';
import { AddIcon, CheckIcon, SearchIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';
import TabSwitchInput from '../FullTextSwitchInput/TabSwitchInput';

import useAPI from '../../hooks/useAPI';
import { useAuth } from '../../contexts/auth';
import { useScroll } from '../../hooks/useScroll';
import ProductsListView from '../ProductModalContent/ProductsListView';
import { Button } from '../Button/Button';
import Banner from '../Banner/Banner';

const ProductRow = ({
  product,
  initialIsAdded,
  onAdd,
  customerId = null,
  showPrices = true,
  showMinimums = true,
  showLeadTimes = true,
  showDescription = true,
}: any) => {
  const [isAdded, setIsAdded] = useState(initialIsAdded);
  const [hovered, setHovered] = useState(false);

  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <Box w="100%">
        <ProductsListView
          product={product}
          customerId={customerId}
          showFavourites={hovered}
          showPrices={showPrices}
          showMinimums={showMinimums}
          showLeadTimes={showLeadTimes}
          showDescription={showDescription}
        />
      </Box>
      {isAdded ? (
        <ChakraButton
          variant="outline"
          width="100px"
          disabled={true}
          leftIcon={<CheckIcon />}
        >
          Added
        </ChakraButton>
      ) : (
        <ChakraButton
          variant="outline"
          width="80px"
          leftIcon={<AddIcon />}
          onClick={() => {
            setIsAdded(true);
            onAdd(product);
          }}
        >
          Add
        </ChakraButton>
      )}
    </HStack>
  );
};

const AddProductModal = ({
  isOpen,
  onClose,
  onAdd,
  addedProducts,
  customerId = null,
  supplierId = null,
  showPrices = true,
  showMinimums = true,
  showLeadTimes = true,
  showDescription = true,
}: any) => {
  const { user } = useAuth();
  const isBuyer = user.company.account_type === 'restaurant';

  const limit = 20;
  const [page, setPage] = useState(1);
  const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterTab, setFilterTab] = useState('categories');
  const scrollRef = useScroll<HTMLDivElement>(() => {
    setPage(page + 1);
  });

  const [getProducts] = useAPI({
    method: 'GET',
  });
  const [getCategories] = useAPI({
    method: 'GET',
  });
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const clearResults = () => {
    setProducts([]);
    setPage(1);
  };

  const clearFilters = () => {
    clearResults();
    setCurrentQueryFieldValue('');
    setCurrentQuery('');
    setCurrentCategory('');
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      clearResults();
      setCurrentQuery(value);
    }, 400),
    [],
  );

  const handleCategory = (value: string) => {
    clearResults();

    if (value === 'all') {
      setCurrentCategory('');
    } else {
      setCurrentCategory(value);
    }
  };

  useEffect(() => {
    const url = `/v4/products?page=${page}&limit=${limit}${
      currentQuery && '&q=' + currentQuery
    }${
      filterTab === 'categories' && currentCategory
        ? '&category_id=' + currentCategory
        : ''
    }${supplierId ? '&supplier_id=' + supplierId : ''}${
      customerId && '&customer_id=' + customerId
    }${filterTab === 'favourites' ? '&favourites_only=true' : ''}`;
    setIsLoading(true);

    getProducts(url).then((data: any) => {
      setResultCount(data.total_count);
      setProducts((products) => products.concat(data.results));
      setIsLoading(false);
    });
  }, [page, currentQuery, currentCategory, filterTab]);

  useEffect(() => {
    getCategories(`/v2/companies/${user.company.id}/categories`).then(
      (data: any) => {
        setCategories(data);
      },
    );
  }, [user?.company?.id]);

  return (
    <Modal isOpen={isOpen} onClose={() => {}} autoFocus={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={0} pb={0}>
          <Text px={6} mt={4} mb={6}>
            Add another product
          </Text>

          <Box
            py={4}
            px={6}
            bg="gray.100"
            boxShadow="inset 0px 1px 0px #D1D5DB, inset 0px -1px 0px #D1D5DB"
          >
            <VStack spacing="3" alignItems="left">
              <InputGroup>
                <Input
                  variant="outline"
                  type="search"
                  onChange={(e) => {
                    setCurrentQueryFieldValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  value={currentQueryFieldValue}
                  placeholder="Search products"
                  name="search"
                  bg="white"
                />
                <InputRightElement
                  pointerEvents="none"
                  color="gray.500"
                  children={<SearchIcon />}
                />
              </InputGroup>

              <TabSwitchInput
                id="filter-tabs"
                onChange={(value) => {
                  clearResults();
                  setFilterTab(value);
                }}
                options={[
                  {
                    label: 'Categories',
                    value: 'categories',
                  },
                  {
                    label: 'Favourites',
                    value: 'favourites',
                  },
                ]}
                mb={3}
              />

              {filterTab === 'categories' && (
                <Select
                  onChange={(e) => handleCategory(e.target.value)}
                  name="category"
                  value={currentCategory === '' ? 'All' : currentCategory}
                >
                  <option value="all">All</option>
                  {categories &&
                    categories.map((category: any, i: number) => {
                      return (
                        <option key={i} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                </Select>
              )}

              {filterTab === 'favourites' && !isBuyer && (
                <Banner
                  description={
                    <HStack spacing="1">
                      <Text fontSize="sm" fontWeight="600">
                        Important:
                      </Text>
                      <Text fontSize="sm" fontWeight="400">
                        You and your customers share management of favourites.
                      </Text>
                    </HStack>
                  }
                  status="info"
                  variant="outline"
                />
              )}
            </VStack>
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          {products.length > 0 ? (
            <Box ref={scrollRef} maxHeight="320px" overflowY="scroll">
              {products.map((product: any) => {
                return (
                  <ProductRow
                    key={product.id}
                    product={product}
                    initialIsAdded={addedProducts.includes(product.id)}
                    onAdd={onAdd}
                    customerId={customerId}
                    showPrices={showPrices}
                    showMinimums={showMinimums}
                    showLeadTimes={showLeadTimes}
                    showDescription={showDescription}
                  />
                );
              })}
            </Box>
          ) : (
            !isLoading &&
            resultCount === 0 && (
              <VStack px={6} py={4} spacing="4" alignItems="left">
                <Text fontSize="lg" fontWeight="500">
                  No products found
                </Text>
                <Link fontWeight="400" onClick={clearFilters}>
                  Clear filters
                </Link>
              </VStack>
            )
          )}
          {isLoading && (
            <Center height="100px">
              <Spinner thickness="6px" size="xl" color="green" />
            </Center>
          )}
        </ModalBody>
        <ModalFooter backgroundColor="gray.100" borderRadius="0 0 8px 8px">
          <Button variant="primary" onClick={onClose}>
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProductModal;
