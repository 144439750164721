import { types } from 'mobx-state-tree';


export type DeliveryRulesProps = {
  0: DeliveryRuleProps;
  1: DeliveryRuleProps;
  2: DeliveryRuleProps;
  3: DeliveryRuleProps;
  4: DeliveryRuleProps;
  5: DeliveryRuleProps;
  6: DeliveryRuleProps;
};

export type DeliveryRuleProps = {
  enabled: boolean;
  cutoff_day: number;
  cutoff_time: string;
};

export const DeliveryRule = types.model({
  enabled: types.boolean,
  cutoff_day: types.number,
  cutoff_time: types.string
}).views((self) => ({
  toJSON() {
    return {
      enabled: self.enabled,
      cutoff_day: self.cutoff_day,
      cutoff_time: self.cutoff_time
    }
  }
})).actions((self) => ({
  setEnabled(newEnabled: boolean) {
    self.enabled = newEnabled;
  },
  setCutoffDay(newCutoffDay: number) {
    self.cutoff_day = newCutoffDay;
  },
  setCutoffTime(newCutoffTime: string) {
    self.cutoff_time = newCutoffTime;
  }
}));

export const DeliveryRules = types.model({
  '0': DeliveryRule,
  '1': DeliveryRule,
  '2': DeliveryRule,
  '3': DeliveryRule,
  '4': DeliveryRule,
  '5': DeliveryRule,
  '6': DeliveryRule
}).views((self) => ({
  toJSON() {
    return {
      '0': self['0'].toJSON(),
      '1': self['1'].toJSON(),
      '2': self['2'].toJSON(),
      '3': self['3'].toJSON(),
      '4': self['4'].toJSON(),
      '5': self['5'].toJSON(),
      '6': self['6'].toJSON()
    }
  }
}));

export default DeliveryRules;