import { observer } from 'mobx-react';
import { Box, Divider, VStack, Input, Text, Button } from '@chakra-ui/react';
import EditableFormWrapper from '../../../components/Form/EditableFormWrapper';
import { Subhead } from '../../../components/Typography/Typography';
import { Customer } from '../../../stores/customers/customer';
import useAccountingContact from '../../../hooks/useAccountingContact';
import { useState } from 'react';
import MatchContactsModal from '../../../components/MatchContactsModal/MatchContactsModal';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import { LinkIcon } from '../../../components/Icons/IconsNew';
import ProcessingModal from '../../../components/ProcessingModal/ProcessingModal';
import { useSuccessToast } from '../../../components/toast';

const CustomerConnectorFields = ({
  customer,
  isEditable = false,
}: {
  customer: Customer;
  isEditable?: boolean;
}) => {
  const canEditCompanyFields = customer.can_edit_company_fields;
  const {
    currentAccountingConnection,
    mappedContactData,
    fetchMappedContactData,
    unMapContact,
    syncNow,
    isSyncingNow,
  } = useAccountingContact(customer);
  const [matchContactModal, setMatchContactModal] = useState(false);
  const successToast = useSuccessToast();

  if (currentAccountingConnection) {
    return (
      <>
        <Divider />
        <VStack align="left" spacing="8" maxWidth="525px">
          <Subhead fontWeight="600">
            {currentAccountingConnection.label}
          </Subhead>

          <FormFieldWrapper
            fieldName="linked_contact"
            fieldLabel="Linked Contact"
            subLabel={'Not visible to customers'}
          >
            {mappedContactData ? (
              <VStack alignItems={'left'}>
                <Box>
                  <Button
                    variant="tertiary"
                    leftIcon={<LinkIcon width="16px" />}
                    colorScheme="red"
                    onClick={() => {
                      unMapContact();
                    }}
                  >
                    Unlink customer
                  </Button>
                </Box>

                {mappedContactData.mapped_contact_name && (
                  <Text color="gray.500" fontSize="xs">
                    Linked with '{mappedContactData.mapped_contact_name}'
                  </Text>
                )}
              </VStack>
            ) : (
              <VStack>
                <Button
                  variant="secondary"
                  leftIcon={<LinkIcon width="16px" />}
                  colorScheme="green"
                  onClick={() => {
                    setMatchContactModal(true);
                  }}
                >
                  Link customer
                </Button>
              </VStack>
            )}

            <MatchContactsModal
              isOpen={matchContactModal}
              onClose={() => {
                setMatchContactModal(false);
              }}
              onSuccess={() => {
                successToast({
                  description: 'Customer linked',
                });
                if (currentAccountingConnection.key === 'cin7_core') {
                  syncNow();
                } else {
                  fetchMappedContactData();
                }
              }}
              contactId={customer.id}
              contactName={customer.name}
            />

            <ProcessingModal isOpen={isSyncingNow}>
              <VStack alignItems="center" spacing="6" width="100%">
                <Text>
                  Getting latest customer data from{' '}
                  {currentAccountingConnection.label}
                </Text>
              </VStack>
            </ProcessingModal>
          </FormFieldWrapper>

          {currentAccountingConnection.key === 'mountain_stream' && (
            <EditableFormWrapper
              value={customer.connectorFieldsDefaultAddressCode}
              editableValue={customer.formConnectorFieldsDefaultAddressCode}
              onChange={(e) =>
                customer.setFormConnectorFieldsDefaultAddressCode(
                  e.target.value,
                )
              }
              EditComponent={Input}
              fieldName="address_code"
              fieldLabel="Default Address Code"
              isEditable={isEditable && canEditCompanyFields}
            >
              <Text mt="10px" color="gray.600" fontSize="xs">
                The default address code to send with each of this customer's
                orders. This can be overridden on a per-order basis if required.
              </Text>
            </EditableFormWrapper>
          )}
        </VStack>
      </>
    );
  } else {
    return null;
  }
};

export default observer(CustomerConnectorFields);
