import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-dom';

import { useCustomerGroups } from '../../hooks/useStores';
import PageLoadSpinner from '../../components/PageLoadSpinner/PageLoadSpinner';
import LoadingToast from '../../components/LoadingToast/LoadingToast';
import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import TwoUpLeftScrollPanel from '../../components/Layouts/TwoUpLeftScrollPanel';
import PricingTierList from './List/PricingTierList';
import PricingTierDetail from './Detail/PricingTierDetail';
import PricingTiersPageActions from './PricingTiersPageActions';
import PricingTiersBanner from './PricingTiersBanner';
import AccountingPricingTiersBanner from './AccountingPricingTiersBanner';
import { useAccounting } from '../../contexts/accounting';
import useAccountingMeta from '../../hooks/useAccountingMeta';

const PricingTiersPage = ({
  match: {
    params: { groupId, groupSetId },
  },
}: RouteComponentProps<{
  groupId: string;
  groupSetId: string;
}>): JSX.Element => {
  const {
    pagination: { totalCount },
    getCustomerGroupSets,
  } = useCustomerGroups();
  const [isLoading, setIsLoading] = useState(false);
  const { currentAccountingConnection, getCurrentAccountingConnection } =
    useAccounting();
  const { syncedObjects } = useAccountingMeta(currentAccountingConnection?.key);

  useEffect(() => {
    if (!currentAccountingConnection) {
      getCurrentAccountingConnection(() => {});
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getCustomerGroupSets(false, false, 'pricing_tiers')
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const isReadOnly = syncedObjects.includes('pricing_tiers');

  return (
    <AppWrapper>
      {isLoading ? (
        <>
          <PageLoadSpinner />
          <LoadingToast isOpen={isLoading} />
        </>
      ) : (
        <TwoUpWrapper
          headerChildren={<PricingTiersPageActions isReadOnly={isReadOnly} />}
          leftChildren={
            <>
              <TwoUpLeftScrollPanel>
                <PricingTiersBanner />
                <AccountingPricingTiersBanner />
                <PricingTierList isReadOnly={isReadOnly} />
              </TwoUpLeftScrollPanel>
            </>
          }
          rightChildren={
            <>
              <PricingTierDetail
                groupId={groupId}
                groupSetId={groupSetId}
                isReadOnly={isReadOnly}
              />
            </>
          }
        />
      )}
    </AppWrapper>
  );
};

export default observer(PricingTiersPage);
