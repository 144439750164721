import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ReactElement, ReactNode } from 'react';
import { Label } from '../Typography/Typography';

export interface FormFieldProps {
  name: string;
  label: string;
  subLabel?: string | ReactElement<any, any>;
  type?: string;
  direction?: 'row' | 'column';
  children?: ({ args }: any) => ReactNode;
}

const FormFieldType = ({ type, ...rest }: { type?: string }) => {
  switch (type) {
    case 'textarea':
      return <Textarea {...rest} />;
    default:
      return <Input {...rest} />;
  }
};

const FormField = ({
  name,
  label,
  subLabel,
  type,
  direction = 'row',
  children,
}: FormFieldProps) => {
  const [field, meta] = useField(name);
  const isRow = direction === 'row';
  return (
    <FormControl isInvalid={meta.touched && !!meta.error}>
      <Flex flexDirection={direction}>
        <Box width="184px" minWidth="184px" pr="12px">
          <FormLabel
            as={Label}
            color={isRow ? 'gray.600' : ''}
            fontWeight={isRow ? '400' : ''}
            mb={subLabel && '0'}
          >
            {label}
          </FormLabel>
          {subLabel && (
            <Text fontWeight="400" color="gray.600" fontSize="xs">
              {subLabel}
            </Text>
          )}
        </Box>
        <Box>
          {children ? (
            children({ field })
          ) : (
            <FormFieldType data-testid={name} type={type} {...field} />
          )}
          <FormErrorMessage data-testid={`${name}-error`} color="red.700">
            {meta.error}
          </FormErrorMessage>
        </Box>
      </Flex>
    </FormControl>
  );
};

export default FormField;
