import ReactPaginate from 'react-paginate';
import './pagination.scss';

import { Text, HStack } from '@chakra-ui/layout';
import { CaretLeftIcon, CaretRightIcon } from '../Icons/Icons';

const Pagination = ({
  handlePageChange,
  currentPage,
  pageCount,
  totalCount,
  itemsPerPage,
  itemsType,
}: any) => {
  const handlePageClick = (nextPage: any) => {
    const nextPageNumber = nextPage.selected + 1;
    handlePageChange(nextPageNumber);
  };
  const startCount = currentPage * itemsPerPage + 1;
  let endCount = (currentPage + 1) * itemsPerPage;
  if (endCount > totalCount) endCount = totalCount;

  return (
    <HStack ml={6}>
      <ReactPaginate
        forcePage={currentPage}
        previousLabel={<CaretLeftIcon />}
        nextLabel={<CaretRightIcon />}
        pageCount={pageCount}
        marginPagesDisplayed={0}
        pageRangeDisplayed={0}
        onPageChange={handlePageClick}
        previousLinkClassName={'pagination__prev-link'}
        nextLinkClassName={'pagination__next-link'}
        containerClassName={'pagination'}
        pageClassName={'pagination__button'}
        breakClassName={'pagination__button pagination__button-break'}
        breakLinkClassName={'pagination__link'}
        previousClassName={'pagination__button'}
        nextClassName={'pagination__button'}
        pageLinkClassName={'pagination__link'}
        activeClassName={'pagination__button pagination__button-active'}
      />
      <Text fontSize="xs" color="gray.600" fontWeight={500}>
        {startCount} – {endCount} of {totalCount} {itemsType}
      </Text>
    </HStack>
  );
};

export default Pagination;
