import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import DetailView from '../../../components/DetailView/DetailView';
import Ingredient from '../../../models/Ingredient';
import {
  Box,
  Button,
  Flex,
  Heading,
  Tooltip,
  VStack,
  Text,
} from '@chakra-ui/react';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import { EditBoxIcon } from '../../../components/Icons/IconsNew';
import { Instance } from 'mobx-state-tree';
import { H4 } from '../../../components/Typography/Typography';
import { conversionText } from './EditIngredient';
import IngredientDeleteAction from './IngredientDeleteAction';

const ShowIngredient = ({
  ingredient,
}: {
  ingredient: Instance<typeof Ingredient>;
}) => {
  const history = useHistory();

  return (
    <DetailView
      leftActions={
        <H4 my="3">{ingredient ? ingredient.name : 'New ingredient'}</H4>
      }
      rightActions={
        <>
          <IngredientDeleteAction ingredient={ingredient} />
          <Tooltip label="Edit ingredient">
            <Button
              onClick={() => {
                history.push(`/ingredients/${ingredient.id}/edit`);
              }}
              leftIcon={<EditBoxIcon width="24px" />}
            >
              Edit
            </Button>
          </Tooltip>
        </>
      }
    >
      <VStack width="full" align="left" spacing="8" my="10">
        <FormFieldWrapper fieldName="unit" fieldLabel="Unit">
          <Text>
            {ingredient.unit && ingredient.unit}
            {!ingredient.unit && '-'}
          </Text>
        </FormFieldWrapper>
        <FormFieldWrapper
          fieldName="converstionFactor"
          fieldLabel="Conversion Factor"
          subLabel="For converting between units. For example, if 1 litre of oil weighs 1.1 kg."
        >
          <Text>
            {conversionText(ingredient.conversion_factor, ingredient.unit)}
          </Text>
        </FormFieldWrapper>
      </VStack>
    </DetailView>
  );
};

export default observer(ShowIngredient);
