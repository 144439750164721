import { observable, action, makeObservable } from 'mobx';
import { Instance } from 'mobx-state-tree';
import * as Sentry from '@sentry/browser';
import { api } from '../../api';
import DeliveryRules from '../../models/DeliveryRules';

export class DeliveryRulesStore {
  user: any = [];

  @observable deliveryRulesEnabled: boolean = false;
  @observable deliveryRules: Instance<typeof DeliveryRules> | null = null;

  constructor(user?: any) {
    this.user = user;
    makeObservable(this);
  }

  @action setDeliveryRules = (value: Instance<typeof DeliveryRules>) => {
    this.deliveryRules = value;
  };
  @action setDeliveryRulesEnabled = (value: boolean) =>
    (this.deliveryRulesEnabled = value);

  @action getDeliveryRules = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v3/companies/${this.user.company.id}`)
        .then(async (response) => {
          if (response.status === 500) {
            reject(response);
          } else {
            if (response.ok) {
              const data = await response.json();
              this.setDeliveryRulesEnabled(data['delivery_rules_enabled']);
              this.setDeliveryRules(data['delivery_rules']);
              resolve(data);
            }
            reject(response);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };
}

export default new DeliveryRulesStore();
