import { Box, BoxProps, useToken } from '@chakra-ui/react';

interface TriangleProps extends BoxProps {
  color: string;
  width: number;
  direction: 'up' | 'down' | 'right' | 'left';
  ratio: number;
}

const Triangle = ({
  color: colorProp,
  width,
  direction = 'up',
  ratio = 0.866,
  ...rest
}: TriangleProps) => {
  const [color] = useToken('colors', [colorProp]);
  const height = Math.round((width / ratio / 2) * 100) / 100;

  const borderBase = `${width}px solid ${color}`;
  const borderEdge = `${height}px solid transparent`;

  const borderBottom =
    direction === 'up' ? borderBase : direction === 'down' ? '' : borderEdge;
  const borderTop =
    direction === 'down' ? borderBase : direction === 'up' ? '' : borderEdge;
  const borderLeft =
    direction === 'right' ? borderBase : direction === 'left' ? '' : borderEdge;
  const borderRight =
    direction === 'left' ? borderBase : direction === 'right' ? '' : borderEdge;

  return (
    <Box
      bg="transparent"
      width={0}
      height={0}
      borderBottom={borderBottom}
      borderTop={borderTop}
      borderLeft={borderLeft}
      borderRight={borderRight}
      {...rest}
    />
  );
};

export default Triangle;
