import { Box, Button, ButtonProps, Flex, HStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { CaretRightIcon } from '../Icons/Icons';
import { Body2, Caption } from '../Typography/Typography';

// Note will pull this out once design is locked
const ButtonRow = ({
  onClick,
  text,
  subtext,
  children,
  leftIcon,
  ...btnProps
}: { text?: string; subtext?: string | null } & ButtonProps) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <Button
      as={motion.button}
      rightIcon={
        <Box
          as={motion.span}
          initial={false}
          animate={{
            x: isHovered ? 4 : 0,
            scale: 1,
          }}
        >
          <CaretRightIcon color="green" boxSize={6} />
        </Box>
      }
      border="none"
      color="gray.700"
      fontWeight="normal"
      pt={3}
      pb={3}
      display="flex"
      boxSizing="border-box"
      paddingInline={6}
      w="100%"
      h="100%"
      borderRadius={0}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      backgroundColor="transparent"
      _hover={{ backgroundColor: 'gray.150' }}
      {...btnProps}
    >
      <Box as="span">{leftIcon}</Box>
      <Box textAlign="left" flex={1} overflow="hidden">
        <Body2
          color="gray.700"
          fontWeight="normal"
          isTruncated
        >
          {text}
        </Body2>
        {!!subtext && (
          <Caption color="gray.500" fontWeight="normal">
            {subtext}
          </Caption>
        )}
        {children}
      </Box>
    </Button>
  );
};

export default ButtonRow;
