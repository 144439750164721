import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export interface DragAndDropProps {
  id: string;
  type: string;
  findItem: (id: string) => any;
  moveItem: (id: string, newIndex: number) => void;
  onDrop: (item: any) => void;
  item?: Object;
}

interface DragItem {
  id: string;
}

const useDragAndDrop = <T extends HTMLElement>({
  id,
  type,
  moveItem,
  findItem,
  onDrop,
  item,
}: DragAndDropProps) => {
  const ref = useRef<T>(null);
  const originalIndex = findItem(id).index;
  const [dimensions, setDimensions] = useState({ width: 400, height: 0 });

  const [{ opacity }, handleRef, dragRef] = useDrag(
    () => ({
      type,
      item: { id, originalIndex, dimensions, ...item },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppedId, originalIndex);
        } else {
          const { item: realItem } = findItem(id);
          onDrop(realItem);
        }
      },
    }),
    [id, originalIndex, moveItem],
  );

  const [{ handlerId }, dropRef] = useDrop(
    () => ({
      accept: type,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item) {
        const { id: draggedId } = item as DragItem;
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          moveItem(draggedId, overIndex);
        }
      },
    }),
    [findItem, moveItem],
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const updateDimensions = () => {
      const rect = ref.current!.getBoundingClientRect();
      setDimensions({ width: rect.width, height: rect.height });
    };

    const resizeObserver = new ResizeObserver(() => updateDimensions());
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref.current]);

  return { opacity, handlerId, handleRef, dragRef, dropRef, ref };
};

export default useDragAndDrop;
