import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

import { useRecipes } from '../../../hooks/useStores';
import ListView from '../../../components/ListView/ListView';
import RecipesRow from './RecipesRow';

const RecipesList = () => {
  const {
    recipes,
    dataIsLoading,
    pagination: { totalCount, currentPage, totalPages },
    setCurrentPage,
    clearFilters,
  } = useRecipes();

  return (
    <ListView
      isLoading={dataIsLoading}
      isEmpty={!dataIsLoading && totalCount === 0}
      pagination={{
        handlePageChange: (pageNumber: number) => {
          setCurrentPage(pageNumber);
        },
        currentPage: currentPage,
        pageCount: totalPages || 0,
        totalCount: totalCount || 0,
        itemsPerPage: 20,
        itemsType: 'recipes',
      }}
      actionChildren={''}
      items={recipes}
      renderItem={({ item: recipe, extraProps }) => (
        <RecipesRow
          key={`recipes-row-${recipe.id}`}
          recipe={recipe}
          {...extraProps}
        />
      )}
      emptyChildren={
        <Box mt="12" ml="6" maxWidth="360px" opacity={dataIsLoading ? 0.6 : 1}>
          <VStack spacing="4" alignItems="left">
            <Text fontSize="lg" fontWeight="500">
              No recipes found
            </Text>
            <Link
              fontWeight="400"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear filters
            </Link>
          </VStack>
        </Box>
      }
    />
  );
};

export default observer(RecipesList);
