import { useEffect, useState } from 'react';
import {
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  InputGroup,
  NumberInput,
  NumberInputField,
  InputRightAddon,
  FormControl,
  Flex,
  Box,
  FormLabel,
  VStack,
} from '@chakra-ui/react';

import DetailView from '../../../components/DetailView/DetailView';
import { H4, Footnote, Label } from '../../../components/Typography/Typography';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import { ChevronRightIcon } from '../../../components/Icons/IconsNew';
import { useSourceCategories } from '../../../hooks/useStores';

import { Headline } from '../../../components/Typography/Typography';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import { useHistory } from 'react-router-dom';
import EmptyTableRow from '../../../components/Table/EmptyTableRow';

const FieldWrapper = ({ label, children, direction = 'row' }: any) => {
  return (
    <FormControl>
      <Flex flexDirection={direction}>
        <Box width="184px" minWidth="184px" pr="12px">
          <FormLabel as={Label} color="gray.600" fontWeight="500">
            {label}
          </FormLabel>
        </Box>
        <Box>{children}</Box>
      </Flex>
    </FormControl>
  );
};

const AssignProductModal = ({ product, setProduct, isOpen, onClose }: any) => {
  const { sourceCategories, sourceCategoriesList, removeUnassignedProduct } =
    useSourceCategories();
  const history = useHistory();
  const [hasUnitError, setHasUnitError] = useState(false);
  const [currentSourceCategoryId, setCurrentSourceCategoryId] =
    useState<number | null>(null);
  const [currentAmount, setCurrentAmount] = useState<string>();
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const currentCategory = currentSourceCategoryId
    ? sourceCategoriesList[currentSourceCategoryId]
    : null;

  const selectSourceCategory = (e: any) => {
    setCurrentSourceCategoryId(Number(e.target.value));
    setHasUnitError(false);
  };
  const handleClose = () => {
    setProduct(null);
    setCurrentSourceCategoryId(null);
    setHasUnitError(false);
    onClose();
  };
  const handleAssign = () => {
    if (product && currentCategory) {
      currentCategory
        .assignProduct(product, currentAmount)
        .then(() => {
          handleClose();
          removeUnassignedProduct(product);
          successToast({
            description: `‘${product.name}’ assigned to ${currentCategory.name}`,
          });
        })
        .catch((e) => {
          if (e.status === 422) {
            setHasUnitError(true);
          } else {
            handleClose();
            errorToast({
              description: `Something went wrong and ‘${product.name}’ was not assigned to ${currentCategory.name}. Please try again`,
            });
          }
        });
    }
  };

  const isDisabled =
    !currentCategory || (currentCategory.amountsRequired && !currentAmount);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Headline fontWeight="600">Assign {`‘${product.name}’`}</Headline>
        </ModalHeader>
        <ModalBody>
          <VStack spacing="36px" mt="28px" mb="24px">
            {currentCategory && hasUnitError && (
              <ErrorMessage
                title="Unit Error"
                description={`Product has multiple units so it cannot be assigned to a source category which requires item sizes: ${currentCategory.name}`}
                onResolve={() => history.push(`/products/${product.id}`)}
              />
            )}
            <FieldWrapper label="Source category" direction="column">
              <Select onChange={selectSourceCategory}>
                <option value="placeholder-source-category-id" color="gray.400">
                  Unassigned
                </option>
                {sourceCategories &&
                  sourceCategories.map((sc: any, i: number) => {
                    return (
                      <option key={`source-category-${i}`} value={sc.id}>
                        {sc.name}
                      </option>
                    );
                  })}
              </Select>
            </FieldWrapper>
            {currentCategory && currentCategory.amountsRequired && (
              <FieldWrapper label="Item size" direction="column">
                <InputGroup>
                  <NumberInput
                    width="100%"
                    onChange={(value) => {
                      setCurrentAmount(value);
                    }}
                  >
                    <NumberInputField
                      textAlign="right"
                      placeholder="0.00"
                      borderRightRadius={0}
                      pr={3}
                    />
                  </NumberInput>
                  <InputRightAddon>
                    {currentCategory?.unit || 'kg'}
                  </InputRightAddon>
                </InputGroup>
              </FieldWrapper>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup colorScheme="green" spacing="4">
            <Button onClick={handleClose} variant="tertiary">
              Cancel
            </Button>
            <Button
              onClick={handleAssign}
              variant="primary"
              isDisabled={isDisabled}
            >
              Assign
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const UnassignedProducts = () => {
  const { getUnassignedProducts, unassignedProducts } = useSourceCategories();
  const [isLoading, setIsLoading] = useState(true);
  const [currentProduct, setCurrentProduct] = useState<any>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectProductToAssign = (product: any) => {
    setCurrentProduct(product);
    onOpen();
  };

  useEffect(() => {
    getUnassignedProducts().then((data: any) => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingDetail />;
  }
  return (
    <>
      {currentProduct && (
        <AssignProductModal
          product={currentProduct}
          setProduct={setCurrentProduct}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <DetailView leftActions={<H4 my="3">Unassigned</H4>}>
        {unassignedProducts && unassignedProducts.length > 0 ? (
          <Table variant="orderitems" maxWidth="758px" width="100%" my="10">
            <Thead>
              <Tr>
                <Th>Products</Th>
                <Th>Code</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {unassignedProducts ? (
                unassignedProducts.map((product: any) => (
                  <Tr key={product.id}>
                    <Td>
                      <Footnote as="span">{product.name}</Footnote>
                    </Td>
                    <Td>
                      <Footnote as="span">{product.product_code}</Footnote>
                    </Td>
                    <Td textAlign="right">
                      <IconButton
                        aria-label="Assign product to source category"
                        variant="ghost"
                        icon={
                          <ChevronRightIcon color="gray.400" width="16px" />
                        }
                        onClick={() => selectProductToAssign(product)}
                      />
                    </Td>
                  </Tr>
                ))
              ) : (
                <EmptyTableRow colSpan={3} />
              )}
            </Tbody>
          </Table>
        ) : (
          <Box py="24px">
            <H4 fontWeight="500">
              Sweet! All products are assigned to a source category.
            </H4>
          </Box>
        )}
      </DetailView>
    </>
  );
};

export default UnassignedProducts;
