import React, { ReactElement } from 'react';

import {
  Button,
  ButtonGroup,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
  ModalContentProps,
} from '@chakra-ui/react';

export interface ConfirmationDialogProps extends ModalContentProps {
  isOpen: boolean;
  titleText?: string;
  bodyContent?: string | ReactElement<any, any>;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColorScheme?: string;
  onCancel: () => any;
  onConfirm: () => any;
}

const ConfirmationDialog = ({
  isOpen,
  titleText,
  bodyContent,
  cancelButtonText,
  confirmButtonText,
  confirmButtonColorScheme,
  onCancel,
  onConfirm,
  ...props
}: ConfirmationDialogProps): JSX.Element => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <AlertDialogOverlay />
      <AlertDialogContent {...props}>
        <AlertDialogHeader>
          {titleText ? titleText : 'Careful!'}
        </AlertDialogHeader>
        <AlertDialogBody>
          {bodyContent ? (
            bodyContent
          ) : (
            <Text>You cannot undo this action.</Text>
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <ButtonGroup>
            <Button ref={cancelRef} onClick={onCancel}>
              {cancelButtonText ? cancelButtonText : 'Cancel'}
            </Button>
            <Button
              colorScheme={
                confirmButtonColorScheme ? confirmButtonColorScheme : 'red'
              }
              onClick={onConfirm}
            >
              {confirmButtonText ? confirmButtonText : 'Yes'}
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmationDialog;
