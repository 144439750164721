import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { useOrders } from '../../hooks/useStores';
import * as Constants from '../../constants';
import {
  requestFirebaseNotificationPermission,
  onMessageListener,
} from '../../firebaseInit';
import * as Sentry from '@sentry/browser';
import { xClientHeaders } from '../../utils';

const NotificationHandler = (): JSX.Element => {
  const { user } = useAuth();
  const { getOrders } = useOrders();
  const api_url = Constants.API_URL;

  useEffect(() => {
    if (user) {
      requestFirebaseNotificationPermission()
        .then((firebaseToken: any) => {
          const token = `Bearer ${user.authentication_token}`;
          const requestOptions = {
            method: 'PUT',
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
              ...xClientHeaders,
            },
            body: JSON.stringify({
              fcm_token: firebaseToken,
            }),
          };
          fetch(api_url + '/v2/register_fcm_token', requestOptions).then(
            async (response) => {
              const data = await response;
              if (response.ok) {
                return data;
              } else {
                return Promise.reject(data);
              }
            },
          );

          return firebaseToken;
        })
        .catch((error) => {
          if (error.code == 'messaging/permission-blocked') {
            // It is very common for messages to be blocked and is not worth recording in Sentry.
            return;
          } else {
            Sentry.captureException(error);
          }
        });

      onMessageListener()
        .then((payload: any) => {
          const { data } = payload;
          if (data.type == 'new_order') {
            getOrders(
              () => {},
              () => {},
            );
          }
        })
        .catch((error: any) => {
          Sentry.captureException(error);
        });
    }
  }, [user]);

  return <div className="notification-nub"></div>;
};

export default NotificationHandler;
