import React from 'react';
import { ButtonGroupProps } from '@chakra-ui/button';
import { ButtonGroup } from '@chakra-ui/react';

const ActionsButtonGroup = (props: ButtonGroupProps): JSX.Element => {
  return (
    <ButtonGroup
      variant="ghost"
      spacing="1"
      color="gray.600"
      mt="6px"
      size="sm"
    >
      {props.children}
    </ButtonGroup>
  );
};

export default ActionsButtonGroup;
