const Input = {
  baseStyle: {
    field: {
      bg: 'white',
      backgroundColor: 'white',
    },
  },
  variants: {
    address: {
      field: {
        marginLeft: 10
      }
    }
  }
};

export default Input;
