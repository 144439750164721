import {
  ChevronRightIcon,
  ChevronDownIcon,
  CrossFillIcon,
  WarningFillIcon,
} from '../Icons/IconsNew';

import {
  Box,
  Button,
  ButtonGroup,
  Code,
  Collapse,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Text,
  VStack,
  HStack,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';

interface ErrorItem {
  error_key: string;
  error_messages: string[] | null;
  error_raw: string | null;
}

export interface AccountingErrorModalProps {
  accountingConnectorName: string;
  errorItem: ErrorItem;
  showErrorRaw?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const AccountingErrorModal = ({
  accountingConnectorName,
  errorItem,
  showErrorRaw = false,
  isOpen,
  onClose,
}: AccountingErrorModalProps): JSX.Element => {
  const { isOpen: isRawErrorOpen, onToggle: onRawErrorToggle } =
    useDisclosure();
  const hasErrorMessages =
    errorItem.error_messages && errorItem.error_messages.length > 0;

  function headerText() {
    switch (errorItem.error_key) {
      case 'address_code_required':
        return 'Address code required';
      case 'product_codes_required':
        return 'Product codes required';
      case 'create_invoice_failed':
      case 'product_data_issue':
        if (hasErrorMessages) {
          return `Failed to send to ${accountingConnectorName}`;
        } else {
          return `Check your ${accountingConnectorName} data`;
        }
      case 'rate_limited':
        return `Rate limited by ${accountingConnectorName}`;
      case 'unexpected_error':
      default:
        return `${accountingConnectorName} returned an unexpected error`;
    }
  }

  function bodyText() {
    switch (errorItem.error_key) {
      case 'address_code_required':
        return 'Either add an Address code to this order, or add a Default address code for the customer.';
      case 'product_codes_required':
        return 'Please ensure that the products have product codes.';
      case 'create_invoice_failed':
      case 'product_data_issue':
        if (hasErrorMessages) {
          return `${accountingConnectorName} returned the following error${
            errorItem.error_messages && errorItem.error_messages.length > 1
              ? 's'
              : ''
          }:`;
        } else {
          switch (accountingConnectorName) {
            case 'MYOB':
              return 'Check your HospoConnect product codes match item codes in MYOB, and that all MYOB products have a set price, unit, and tax code.';
            default:
              return `Check your HospoConnect product codes match item codes in ${accountingConnectorName}, then try again.`;
          }
        }
      case 'rate_limited':
        return `Please wait a minute, and/or try sending less orders at once. If you keep getting this error, then please call us.`;
      case 'unexpected_error':
      default:
        return 'Our developers have been alerted. Please try again later.';
    }
  }

  if (errorItem) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack alignItems={'top'}>
              <Box pt="1">
                <WarningFillIcon color="red.500" w="24px" />
              </Box>
              <Text>{headerText()}</Text>
            </HStack>
          </ModalHeader>
          <ModalBody>
            <VStack alignItems={'left'} fontSize="16px">
              <Text>{bodyText()}</Text>

              {hasErrorMessages && errorItem.error_key !== 'unexpected_error' && (
                <List ml="4">
                  {errorItem.error_messages &&
                    errorItem.error_messages.map((error: any) => {
                      return (
                        <ListItem>
                          <ListIcon
                            as={CrossFillIcon}
                            color="red.500"
                            mb="1px"
                          />
                          {error}
                        </ListItem>
                      );
                    })}
                </List>
              )}
            </VStack>

            {errorItem.error_raw && showErrorRaw && (
              <Box pt={4}>
                <VStack alignItems={'left'}>
                  <Link color={'red.400'} onClick={onRawErrorToggle}>
                    <HStack>
                      <Box fontSize="xs">
                        I'm brave, show me the error straight from the{' '}
                        {accountingConnectorName} API
                      </Box>
                      {isRawErrorOpen && <ChevronDownIcon width="16px" />}
                      {!isRawErrorOpen && <ChevronRightIcon width="16px" />}
                    </HStack>
                  </Link>

                  <Collapse in={isRawErrorOpen}>
                    <Code fontSize="13px" p={3} colorScheme="red">
                      {errorItem.error_raw}
                    </Code>
                  </Collapse>
                </VStack>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button variant="primary" onClick={onClose}>
                Ok
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default AccountingErrorModal;
