import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { ButtonGroup } from '@chakra-ui/react';

import { Button } from '../../components/Button/Button';
import { PlusIcon } from '../../components/Icons/IconsNew';
import ActionBar from '../../components/ActionBar/ActionBar';

const ProductGroupsPageActions = (): JSX.Element => {
  const history = useHistory();
  return (
    <ActionBar
      title="Product groups"
      rightChildren={
        <ButtonGroup>
          <Button
            variant="primary"
            leftIcon={<PlusIcon width="24px" />}
            onClick={() => history.push('/product-group-sets/new')}
          >
            Add new set
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default observer(ProductGroupsPageActions);
