import { Flex } from '@chakra-ui/react';
import EmptyBox from '../Detail/EmptyBox';

const EmptyDetail = () => (
  <Flex
    flexDirection="column"
    flex={{ base: '2', xl: '3' }}
    borderTop="1px solid"
    borderColor="gray.200"
  >
    <EmptyBox />
  </Flex>
);

export default EmptyDetail;
