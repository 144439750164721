import { useState } from 'react';
import { observer } from 'mobx-react';
import { useProducts } from '../../../hooks/useStores';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { useSuccessToast, useErrorToast } from '../../../components/toast';
import { useHistory } from 'react-router-dom';

import { Button, Text, Tooltip } from '@chakra-ui/react';
import { DeleteIcon } from '../../../components/Icons/Icons';

const ProductDeleteAction = (): JSX.Element => {
  const { currentProduct, deleteBatchProducts, getProductsList } =
    useProducts();
  const history = useHistory();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  let [buttonIsLoading, setButtonIsLoading] = useState(false);
  let [modalIsOpen, setModalIsOpen] = useState(false);

  const onConfirm = () => {
    setButtonIsLoading(true);
    setModalIsOpen(false);
    deleteBatchProducts([currentProduct.id], onSuccess, onFailure);
  };

  const onSuccess = () => {
    getProductsList(
      () => {
        setButtonIsLoading(false);
        successToast({
          description: `The product has been deleted.`,
        });
        history.push('/products');
      },
      () => {},
    );
  };

  const onFailure = () => {
    setButtonIsLoading(false);
    errorToast();
  };

  return (
    <>
      <Tooltip label="Delete product">
        <Button
          onClick={() => setModalIsOpen(true)}
          leftIcon={<DeleteIcon />}
          isLoading={buttonIsLoading}
        >
          Delete
        </Button>
      </Tooltip>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        titleText={'Are you sure you want to delete this product?'}
        bodyContent={
          <>
            {currentProduct.is_synced && (
              <Text color="red">
                WARNING: This product is synced. A new copy of this product may
                return on the next sync cycle if it is not deleted from the
                external system.
              </Text>
            )}
          </>
        }
        confirmButtonText={'Delete this product'}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default observer(ProductDeleteAction);
