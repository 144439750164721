import {
  RadioProps,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  TextProps,
  Radio,
} from '@chakra-ui/react';
import { Body2, Caption } from '../Typography/Typography';

const RadioRow = ({
  label,
  iconUrl,
  value,
  inputId,
  style,
  subtext,
  captionProps,
  backgroundColor,
  ...radioProps
}: RadioProps & {
  label?: string;
  iconUrl?: string;
  value: string | number;
  subtext?: string | null;
  inputId?: string;
  style?: React.CSSProperties;
  captionProps?: TextProps;
}) => (
  <FormControl
    key={inputId}
    style={{ ...style, marginTop: 0 }}
  >
    <Flex>
      <Radio id={inputId} flex={1} pt={3} pb={3} value={value} variant="modalRow" {...radioProps}>
        <FormLabel
          alignItems="center"
          cursor="pointer"
          htmlFor={inputId}
          mb="0"
          ml={1}
        >
          <HStack>
            <Body2 color="gray.700" fontWeight="normal">
              {label}
            </Body2>
          </HStack>
          {subtext && (
            <Caption
              as="p"
              color="gray.500"
              fontWeight="normal"
              mt={1}
              {...captionProps}
            >
              {subtext}
            </Caption>
          )}
        </FormLabel>
      </Radio>
    </Flex>
  </FormControl>
);

export default RadioRow;
