import { useState } from 'react';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import Ingredient from '../../../models/Ingredient';

import { useIngredients } from '../../../hooks/useStores';

import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { useSuccessToast, useErrorToast } from '../../../components/toast';
import { useHistory } from 'react-router-dom';

import {
  Box,
  HStack,
  Button as ChakraButton,
  ButtonGroup,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { Button } from '../../../components/Button/Button';
import { WarningFillIcon } from '../../../components/Icons/IconsNew';
import { DeleteIcon } from '../../../components/Icons/Icons';

const IngredientDeleteAction = ({
  ingredient,
}: {
  ingredient: Instance<typeof Ingredient>;
}): JSX.Element => {
  const { deleteIngredient, getIngredients } = useIngredients();
  const history = useHistory();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  let [buttonIsLoading, setButtonIsLoading] = useState(false);
  let [modalIsOpen, setModalIsOpen] = useState(false);
  let [undeletableModalIsOpen, setUndeletableModalIsOpen] = useState(false);

  const onConfirm = () => {
    setButtonIsLoading(true);
    setModalIsOpen(false);
    deleteIngredient(ingredient).then(onSuccess).catch(onFailure);
  };

  const onSuccess = () => {
    getIngredients().then(() => {
      setButtonIsLoading(false);
      successToast({
        description: 'The ingredient has been deleted.',
      });
      history.push('/ingredients');
    });
  };

  const onFailure = () => {
    setButtonIsLoading(false);
    errorToast({
      description: 'There was an error deleting the ingredient.',
    });
  };

  const onClick = () => {
    if (ingredient.recipes.length > 0) {
      setUndeletableModalIsOpen(true);
    } else {
      setModalIsOpen(true);
    }
  };

  return (
    <>
      <Tooltip label="Delete ingredient">
        <ChakraButton
          onClick={onClick}
          leftIcon={<DeleteIcon />}
          isLoading={buttonIsLoading}
        >
          Delete
        </ChakraButton>
      </Tooltip>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          setModalIsOpen(false);
        }}
        titleText={'Are you sure you want to delete this ingredient?'}
        confirmButtonText={'Delete this ingredient'}
        onConfirm={onConfirm}
      />

      <Modal
        isOpen={undeletableModalIsOpen}
        onClose={() => {
          setUndeletableModalIsOpen(false);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack alignItems={'top'}>
              <Box pt="1">
                <WarningFillIcon color="red.500" w="24px" />
              </Box>
              <Text>Can not delete ingredient</Text>
            </HStack>
          </ModalHeader>
          <ModalBody>
            <Text>
              This ingredient is used in use by one or more recipes and can't be
              deleted. Please remove this ingredient from any recipes first, and
              try again.
            </Text>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="primary"
                onClick={() => {
                  setUndeletableModalIsOpen(false);
                }}
              >
                Ok
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(IngredientDeleteAction);
