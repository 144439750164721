import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

import { useSourceCategories } from '../../../hooks/useStores';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import EditSourceCategory from './EditSourceCategory';
import ShowSourceCategory from './ShowSourceCategory';
import UnassignedProducts from './UnassignedProducts';

type SourceCategoryDetailProps = {
  sourceCategoryId: any;
};

const SourceCategoryDetail = ({
  sourceCategoryId,
}: SourceCategoryDetailProps): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const { getSourceCategory, currentSourceCategory, setCurrentSourceCategory } =
    useSourceCategories();

  useEffect(() => {
    setLoading(true);

    if (sourceCategoryId) {
      getSourceCategory(sourceCategoryId).then(() => setLoading(false));
    } else {
      setCurrentSourceCategory(undefined);
      setLoading(false);
    }
  }, [sourceCategoryId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/source-categories/new">
        {!currentSourceCategory && <EditSourceCategory />}
      </Route>
      <Route path="/source-categories/unassigned">
        {!currentSourceCategory && <UnassignedProducts />}
      </Route>
      <Route path="/source-categories/:sourceCategoryId/edit">
        {currentSourceCategory && (
          <EditSourceCategory sourceCategory={currentSourceCategory} />
        )}
      </Route>
      <Route path="/source-categories/:sourceCategoryId">
        {currentSourceCategory && (
          <ShowSourceCategory sourceCategory={currentSourceCategory} />
        )}
      </Route>
      <Route path="/source-categories">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(SourceCategoryDetail);
