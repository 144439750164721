import moment, { Moment } from 'moment';

export default function isDeliveryDateAvailable(
  dateToCheck: Date | Moment,
  currentDateTime: Moment,
  deliveryRules: any,
  deliveryRulesEnabled: boolean = true,
  leadTime: number = 0,
) {
  if (!deliveryRulesEnabled) {
    return true;
  }
  const weekIndex = moment(dateToCheck).weekday();
  const deliveryRule = deliveryRules[weekIndex];

  if (!deliveryRule.enabled) {
    return false;
  } else {
    let cutoffDay = '';

    if (weekIndex < deliveryRule.cutoff_day) {
      // we need to support same day delivery
      cutoffDay = moment(dateToCheck)
        .add(-(7 + weekIndex - deliveryRule.cutoff_day), 'days')
        .format('YYYY-MM-DD')
        .toString();
    } else {
      cutoffDay = moment(dateToCheck)
        .add(-(weekIndex - deliveryRule.cutoff_day), 'days')
        .format('YYYY-MM-DD')
        .toString();
    }

    const cutoffDate = cutoffDay + ' ' + deliveryRule.cutoff_time;
    const cutoffDateTimeWithLeadTime =  moment(cutoffDate, 'YYYY-MM-DD hh:mm A').subtract(leadTime, 'hours');
  
    if (currentDateTime.diff(cutoffDateTimeWithLeadTime) > 0) {
      return false;
    } else {
      return true;
    }
  }
}