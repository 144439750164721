import { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './react-date-range-theme.scss'; // theme css file
import format from 'date-fns/format';
import {
  Button,
  ButtonGroup,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
} from '@chakra-ui/react';

import { CrossIcon } from '../Icons/Icons';

export type DateFilterProps = {
  labelText: string;
  currentFromDate: Date | null;
  currentToDate: Date | null;
  setCurrentFromDate: (date: Date | null) => void;
  setCurrentToDate: (date: Date | null) => void;
};

const DateFilter = ({
  labelText,
  currentFromDate,
  currentToDate,
  setCurrentFromDate,
  setCurrentToDate,
}: DateFilterProps): JSX.Element => {
  const [currentRanges, setCurrentRanges] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  function onRangesChange(ranges: any) {
    setCurrentRanges(ranges.selection);
  }

  function applyDates() {
    setCurrentFromDate(currentRanges.startDate);
    setCurrentToDate(currentRanges.endDate);
  }

  function clearDates() {
    setCurrentRanges({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });
    setCurrentFromDate(null);
    setCurrentToDate(null);
  }

  function formatDate(date: Date) {
    return format(date, 'iii dd MMM');
  }

  function currentDateString() {
    if (currentFromDate != undefined && currentToDate != undefined) {
      if (currentFromDate.getTime() === currentToDate.getTime()) {
        return formatDate(currentFromDate);
      } else {
        return formatDate(currentFromDate) + ' - ' + formatDate(currentToDate);
      }
    } else {
      return 'All dates';
    }
  }

  const isFiltered = currentFromDate || currentToDate;

  return (
    <Popover isLazy={true}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <ButtonGroup>
              <Button
                variant="ghost"
                bg={isFiltered ? 'white' : 'gray.200'}
                borderRadius="9999px"
              >
                <Text
                  fontWeight="500"
                  fontSize="12px"
                  color="gray.500"
                  mt="1px"
                  textTransform="uppercase"
                >
                  {labelText}
                </Text>
                <Text fontWeight="500" fontSize="17px" px="2">
                  {currentDateString()}
                </Text>
                {isFiltered && (
                  <CrossIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      clearDates();
                    }}
                  />
                )}
              </Button>
            </ButtonGroup>
          </PopoverTrigger>
          <PopoverContent>
            <DateRange
              minDate={new Date(2018, 0, 1)}
              maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
              ranges={[currentRanges]}
              onChange={onRangesChange}
              rangeColors={['#07a549']} /** green.500 */
            />
            <Divider />
            <ButtonGroup padding="2">
              <Button
                colorScheme="green"
                onClick={() => {
                  applyDates();
                  onClose();
                }}
              >
                Apply dates
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  clearDates();
                  onClose();
                }}
              >
                Clear
              </Button>
            </ButtonGroup>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default DateFilter;
