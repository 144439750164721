import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import ProductsPageActions from './ProductsPageActions';
import ProductDetail from './Detail/ProductDetail';
import ProductsList from './List/ProductsList';

const ProductsPage = ({
  match: {
    params: { productId },
  },
}: RouteComponentProps<{ productId: string }>): JSX.Element => {
  return (
    <AppWrapper>
      <TwoUpWrapper
        headerChildren={<ProductsPageActions />}
        leftChildren={<ProductsList />}
        rightChildren={
          <>
            <ProductDetail productId={productId} />
          </>
        }
      />
    </AppWrapper>
  );
};

export default observer(ProductsPage);
