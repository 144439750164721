import React, { useState, useEffect } from 'react';
import { usePageVisibility } from '../../hooks/usePageVisibility';
import './terms.scss';
import * as Sentry from '@sentry/browser';
import { useAuth } from '../../contexts/auth';
import { useSuccessToast, useErrorToast } from '../toast';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
} from '@chakra-ui/react';
import useAPI from '../../hooks/useAPI';
const useAPIs = () => {
  const [getAPI] = useAPI();
  const [putAPI] = useAPI({
    method: 'PUT',
  });
  const fetchAgreementStatus = async () => getAPI('/v4/terms/agreement_status');
  const fetchLatestTerms = async () => getAPI('/v4/terms/latest');
  const agreeTerms = async (requestOptions?: { body?: string }) =>
    putAPI('/v4/terms/agree', requestOptions);
  return [fetchAgreementStatus, fetchLatestTerms, agreeTerms];
};

const TermsAgreement = (): JSX.Element => {
  const { user } = useAuth();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [agreeButtonLoading, setAgreeButtonLoading] = useState(false);
  const [agreeButtonDisabled, setAgreeButtonDisabled] = useState(true);
  const [termsContent, setTermsContent] = useState<string | null>(null);
  const [termsVersion, setTermsVersion] = useState<number | null>(null);
  const pageIsVisible = usePageVisibility();
  const errorToast = useErrorToast();
  const successToast = useSuccessToast();
  const initialRef = React.useRef() as React.MutableRefObject<HTMLElement>;

  const [fetchAgreementStatus, fetchLatestTerms, agreeTerms] = useAPIs();

  useEffect(() => {
    if (user && !modalIsOpen && pageIsVisible) {
      fetchAgreementStatus()
        .then((response: any) => {
          if (response?.agreement_required) {
            fetchAndRequireLatestTerms(response?.latest_version);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          return error;
        });
    }
  }, [user, pageIsVisible]);

  const fetchAndRequireLatestTerms = (expectedLatestVersion: number) => {
    if (!termsVersion || termsVersion != expectedLatestVersion) {
      fetchLatestTerms({})
        .then((response: any) => {
          setTermsContent(response.html);
          setTermsVersion(response.latest_version);
          setModalIsOpen(true);
        })
        .catch((error) => {
          Sentry.captureException(error);
          return error;
        });
    } else {
      setModalIsOpen(true);
    }
  };
  const onAgree = () => {
    setAgreeButtonLoading(true);

    agreeTerms({
      body: JSON.stringify({
        terms_agreed_version: termsVersion,
      }),
    })
      .then(() => {
        setAgreeButtonLoading(false);
        setModalIsOpen(false);
        successToast({
          title: 'Thanks',
          description: 'You can review the Terms of Service on our website',
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        errorToast();
        setAgreeButtonLoading(false);
      });
  };

  return (
    <Modal
      size="xl"
      isOpen={modalIsOpen}
      initialFocusRef={initialRef}
      onClose={() => {
        /** We don't want users to be able to close this themselves! */
      }}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent ref={initialRef}>
        <ModalHeader>
          <Heading as="h2" fontWeight="500" size="md" marginBottom="3">
            Updates to our Terms of Service
          </Heading>
          <Text fontSize="md" fontWeight="400" marginBottom="3">
            Please read and agree with our updated Terms of Service to continue.
          </Text>
        </ModalHeader>
        <ModalBody
          onScroll={(e) => {
            const target = e.target as HTMLElement;
            if (
              target.scrollHeight <=
              target.scrollTop + target.offsetHeight + 20
            ) {
              setAgreeButtonDisabled(false);
            } else if (!agreeButtonDisabled) {
              setAgreeButtonDisabled(true);
            }
          }}
        >
          <Heading as="h2" fontWeight="600" size="md">
            Terms of Service
          </Heading>
          {!termsContent && <Text color="gray.500">Loading...</Text>}
          {termsContent && (
            <Box
              marginTop="6"
              className="terms-box"
              dangerouslySetInnerHTML={{
                __html: termsContent,
              }}
            ></Box>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button
              colorScheme="green"
              onClick={onAgree}
              isLoading={agreeButtonLoading}
              isDisabled={agreeButtonDisabled}
            >
              Agree
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TermsAgreement;
