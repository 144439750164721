import React, { useState } from 'react';
import { useAuth } from '../contexts/auth';
import * as Sentry from '@sentry/browser';
import { xClientHeaders } from '../utils';

type Context = {
  sendBatchToXero: (
    orderIds: any,
    successCallback: (data: any) => any,
    errorCallback: () => any,
  ) => void;
  batchToXeroError: boolean;
  setBatchToXeroError: (value: any) => void;
  batchRequestCount: number;
  setBatchRequestCount: (value: any) => void;
  deleteBatchOrders: (
    orderIds: any,
    successCallback: () => any,
    errorCallback: () => any,
  ) => void;
  batchXeroErrors: any;
  updateBatchOrders: (
    orderIds: any,
    successCallback: (response: any) => any,
    errorCallback: (response: any) => any,
  ) => void;
};

const BatchOrdersContext = React.createContext<Context>({
  sendBatchToXero: () => {},
  batchToXeroError: false,
  setBatchToXeroError: () => {},
  batchRequestCount: 0,
  setBatchRequestCount: () => {},
  deleteBatchOrders: () => {},
  batchXeroErrors: [],
  updateBatchOrders: () => {},
});

const api_url = `${process.env.REACT_APP_API_URL}/api`;

function BatchOrdersProvider(props: any) {
  const { user } = useAuth();
  const [batchToXeroError, setBatchToXeroError] = useState(false);
  const [batchRequestCount, setBatchRequestCount] = useState(0);
  const [batchXeroErrors, setBatchXeroErrors] = useState([]);

  const sendBatchToXero = (
    orderIds: any,
    successCallback: (data: any) => any,
    errorCallback: () => any,
  ) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
        body: JSON.stringify({
          order_ids: [...orderIds],
        }),
      };

      const url = `${api_url}/v4/accounting_connectors/batch_invoices`;

      fetch(url, requestOptions)
        .then(async (response) => {
          setBatchRequestCount(batchRequestCount + 1);
          if (response.status === 500) {
            if (errorCallback) {
              errorCallback();
            }
            setBatchToXeroError(true);
          } else {
            if (response.ok) {
              const data = await response.json();
              if (successCallback) {
                successCallback(data);
              }
              return data;
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          errorCallback();
          return error;
        });
    }
  };

  const deleteBatchOrders = (
    orderIds: any,
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
        body: JSON.stringify({
          order_ids: orderIds,
        }),
      };

      const url = `${api_url}/v4/orders/batch_delete`;

      fetch(url, requestOptions)
        .then(async (response) => {
          setBatchRequestCount(batchRequestCount + 1);
          if (response.status === 500) {
            errorCallback();
          } else {
            if (response.ok) {
              const data = await response.json();
              successCallback();
              return data;
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          errorCallback();
          return error;
        });
    }
  };

  const updateBatchOrders = (
    updatedData: any,
    successCallback: (response: any) => any,
    errorCallback: (response: any) => any,
  ) => {
    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
        body: JSON.stringify({
          ...updatedData,
        }),
      };

      const url = `${api_url}/v4/orders/batch_update`;

      fetch(url, requestOptions)
        .then(async (response) => {
          if (response.status !== 200) {
            if (errorCallback) {
              errorCallback(response);
            }
          } else {
            if (response.ok) {
              const data = await response;
              if (successCallback) {
                successCallback(response);
              }
              return { data };
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          if (errorCallback) {
            errorCallback(null);
          }
          return error;
        });
    }
  };

  return (
    <BatchOrdersContext.Provider
      value={{
        sendBatchToXero,
        batchToXeroError,
        batchRequestCount,
        deleteBatchOrders,
        batchXeroErrors,
        setBatchXeroErrors,
        updateBatchOrders,
      }}
      {...props}
    />
  );
}

const useBatchOrders = () => React.useContext(BatchOrdersContext);

export { BatchOrdersProvider, useBatchOrders };
