import { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import DetailView from '../../../components/DetailView/DetailView';
import { StandingOrder } from '../../../stores/standingOrders/standingOrder';
import StandingOrderTable from '../../../components/StandingOrders/StandingOrderTable';
import { useStandingOrders } from '../../../hooks/useStores';
import { useCurrentUser } from '../../../hooks/useStores';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import UnlinkedTag from '../../../components/UnlinkedTag/UnlinkedTag';
import PauseSection from '../../../components/StandingOrders/PauseSection';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';

const EditStandingOrder = ({
  standingOrder,
}: {
  standingOrder: StandingOrder;
}) => {
  const { isBuyer } = useCurrentUser();
  const { createStandingOrder, updateStandingOrder, getStandingOrders } =
    useStandingOrders();
  const [saveButtonIsLoading, setSaveButtonIsLoading] = useState(false);
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const history = useHistory();
  const {
    isOpen: isConfirmOpen,
    onOpen: onComfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const handleSaveSuccess = (
    id: string | number,
    toastText: string,
    nextUrl?: string,
  ) => {
    setSaveButtonIsLoading(false);
    successToast({ description: toastText });
    getStandingOrders(
      () => {},
      () => {},
    );
    history.push(nextUrl || `/standing-orders/${id}`);
    window.scrollTo(0, 0);
  };

  const handleSaveError = () => {
    setSaveButtonIsLoading(false);
    errorToast({ description: 'Failed to save.' });
  };

  const handleSave = () => {
    setSaveButtonIsLoading(true);

    if (!standingOrder.hasProducts) {
      onComfirmOpen();
    } else {
      if (standingOrder.id === 'new') {
        createStandingOrder(
          standingOrder.formState,
          (data: any) => {
            handleSaveSuccess(data.id, 'Standing order created.');
          },
          handleSaveError,
        );
      } else {
        updateStandingOrder(
          standingOrder.formState,
          standingOrder.id,
          () => {
            handleSaveSuccess(standingOrder.id, 'Standing order updated.');
          },
          handleSaveError,
        );
      }
    }
  };

  const onConfirmConfirm = () => {
    if (standingOrder.id === 'new') {
      // Just don't let it be created.
      onConfirmCancel();
    } else {
      updateStandingOrder(
        standingOrder.formState,
        standingOrder.id,
        () => {
          handleSaveSuccess(
            standingOrder.id,
            'Standing order deleted.',
            `/standing-orders`,
          );
        },
        handleSaveError,
      );
    }
  };

  const onConfirmCancel = () => {
    onConfirmClose();
    setSaveButtonIsLoading(false);
  };

  const isNew = standingOrder.id === 'new';

  const confirmTitleText = isNew ? 'No Products' : 'No Products - Delete?';
  const confirmBodyContent = isNew
    ? 'At least one product is required to create a standing order.'
    : 'Removing all products will delete this standing order. Are you sure you want to delete it?';
  const confirmButtonText = isNew ? 'Ok' : 'Yes, delete it';

  const otherCompany = isBuyer
    ? standingOrder.supplier
    : standingOrder.customer;

  return (
    <DetailView
      rightActions={
        <>
          <Button
            size="sm"
            onClick={() => {
              window.location.href = `/standing-orders${
                !isNew ? `/${standingOrder.id}` : ''
              }`;
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            isLoading={saveButtonIsLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </>
      }
    >
      <form style={{ backgroundColor: 'white', marginBottom: '80px' }}>
        <Flex direction="row" py="6">
          <Box>
            <Avatar size="xl" src={otherCompany.logo_url} />
          </Box>
          <Box pl="5" pt="8">
            <Heading size="md" as="h4">
              {otherCompany.name}
            </Heading>
            {otherCompany.is_unlinked && <UnlinkedTag />}
          </Box>
        </Flex>

        <VStack align="left" spacing="8" mt="16" maxWidth="525px">
          <StandingOrderTable isEditable={true} standingOrder={standingOrder} />

          <Divider />

          <PauseSection standingOrder={standingOrder} isEditable={true} />
        </VStack>
      </form>

      <ConfirmationDialog
        isOpen={isConfirmOpen}
        onCancel={onConfirmCancel}
        onConfirm={onConfirmConfirm}
        titleText={confirmTitleText}
        bodyContent={confirmBodyContent}
        confirmButtonText={confirmButtonText}
      />
    </DetailView>
  );
};

export default observer(EditStandingOrder);
