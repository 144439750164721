import { ReactNode, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useOrders } from '../../../hooks/useStores';
import useRealtimeData, {
  RealtimeDataEventPayloadType,
} from '../../../hooks/useRealtimeData';
import AddOrderModal from './AddOrderModal';
import { AddOrderProvider } from '../../../contexts/addOrder';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import ShowOrder from './ShowOrder';
import EditOrder from './EditOrder';
import { EditableOrderProvider } from '../../../contexts/editableOrder';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined } from 'lodash';

type OrderDetailProps = {
  orderId: any;
  extraActions?: ReactNode;
};

const OrderDetail = ({
  orderId,
  extraActions,
}: OrderDetailProps): JSX.Element => {
  const { currentOrder, getOrder } = useOrders();
  const [loading, setLoading] = useState(false);

  const { tabularView } = useFlags();
  const { showOrdersTabularView: showOrdersTabularViewPreference } =
    useOrders();
  const showOrdersTabularView =
    tabularView && !isUndefined(showOrdersTabularViewPreference)
      ? showOrdersTabularViewPreference
      : tabularView;

  const onReceived = (payload: RealtimeDataEventPayloadType) => {
    if (
      currentOrder &&
      payload.entityType == 'order' &&
      payload.entityIds.includes(currentOrder.id)
    ) {
      if (payload.eventType == 'update') {
        getOrder(currentOrder.id, () => {});
      } else if (payload.eventType == 'delete') {
        currentOrder.setIsDeleted(true);
      }
    }
  };
  const onTabReactivate = () => {
    if (currentOrder) {
      getOrder(
        currentOrder.id,
        () => {},
        () => {
          // If the response fails, assume the order has been deleted
          // This is safe to assume for now.
          currentOrder.setIsDeleted(true);
        },
      );
    }
  };
  useRealtimeData(
    'DataFeedChannel',
    onReceived,
    [currentOrder],
    onTabReactivate,
  );

  useEffect(() => {
    if (orderId && orderId !== 'new') {
      setLoading(true);
      getOrder(orderId, () => {
        setLoading(false);
      });
    }
  }, [orderId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/orders/new">
        <EmptyDetail />
        <AddOrderProvider>
          <AddOrderModal />
        </AddOrderProvider>
      </Route>
      <Route path="/orders/:orderId/edit">
        {currentOrder && (
          <EditableOrderProvider order={currentOrder}>
            <EditOrder order={currentOrder} />
          </EditableOrderProvider>
        )}
      </Route>
      <Route path="/orders/:orderId">
        {currentOrder && (
          <ShowOrder order={currentOrder} extraActions={extraActions} />
        )}
      </Route>
      <Route path="/orders">{!showOrdersTabularView && <EmptyDetail />}</Route>
    </Switch>
  );
};

export default observer(OrderDetail);
