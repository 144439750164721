import { useEffect, useState } from 'react';
import useAPI from './useAPI';

type JobStatus = null | 'queued' | 'working' | 'retrying' | 'complete' | 'failed' | 'interrupted';

const useJobStatus = (
  pollingMs?: number,
  onComplete?: () => void,
) => {
  const [jobId, setJobId] = useState<String>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [status, setStatus] = useState<JobStatus>(null);
  const [fullData, setFullData] = useState<any>({});
  const [getJobStatus] = useAPI({
    method: 'GET',
  });

  pollingMs = pollingMs || 2500;

  function initPollingForJobStatus() {
    setStatus('queued');
    setFullData({});
    setIsProcessing(true);

    const pollingInterval = setInterval(() => {
      getJobStatus(`/v4/jobs/${jobId}`).then((data: any) => {
        if (data) {
          setStatus(data.status);
          setFullData(data);
          if (data.status == 'complete') {
            clearInterval(pollingInterval);
            setIsProcessing(false);
            if (onComplete) { onComplete(); }
          }
        }
      });
    }, pollingMs);
  }

  useEffect(() => {
    if (jobId) {
      initPollingForJobStatus();
    }
  }, [jobId]);


  return [setJobId, isProcessing, status, fullData] as const;
};

export default useJobStatus;
