import { useState } from 'react';
import { observer } from 'mobx-react';
import {
  ButtonGroup,
  IconButton,
  Tooltip,
  VStack,
  Text,
} from '@chakra-ui/react';

import { useProducts } from '../../../hooks/useStores';
import SelectAllCheckbox from '../../../components/SelectAllCheckbox/SelectAllCheckbox';
import { useSuccessToast, useErrorToast } from '../../../components/toast';
import { ToggleOffIcon, ToggleOnIcon } from '../../../components/Icons/Icons';
import ProductsDeleteAction from './ProductsDeleteAction';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import { pluralizeString } from '../../../utils';
import { is } from 'date-fns/locale';

const ProductsListActions = () => {
  const {
    currentProduct,
    products,
    getProductsList,
    updateBatchProducts,
    setProductsActive,
    currentBulkIds,
    setCurrentBulkIds,
  } = useProducts();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [inactiveButtonIsLoading, setInactiveButtonIsLoading] = useState(false);
  const [activeButtonIsLoading, setActiveButtonIsLoading] = useState(false);
  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const currentPageIds = products
    ? products.map((object: any) => {
        return object.id;
      })
    : null;
  // We either use the checked items, or the currently selected item if there is one.
  const currentBatchActionableIDs = currentBulkIds.length
    ? currentBulkIds
    : currentProduct
    ? [currentProduct.id]
    : [];

  const openModal = (actionName: string) => {
    setModalContent(actionName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalContent('');
    setModalIsOpen(false);
  };

  const handleBulkUpdate = (statusBool: boolean) => {
    setModalIsOpen(false);
    const data = {
      product_ids: currentBatchActionableIDs,
      attributes: {
        active: statusBool,
      },
    };
    if (statusBool) {
      setActiveButtonIsLoading(true);
    } else {
      setInactiveButtonIsLoading(true);
    }
    updateBatchProducts(
      data,
      () => {
        getProductsList(
          () => {},
          () => {},
        );
        successToast({
          description: `We updated ${currentBatchActionableIDs.length} products.`,
        });
        setProductsActive(currentBatchActionableIDs, statusBool);
        setInactiveButtonIsLoading(false);
        setActiveButtonIsLoading(false);
        setCurrentBulkIds([]);
      },
      () => {
        getProductsList(
          () => {},
          () => {},
        );
        errorToast();
        setInactiveButtonIsLoading(false);
        setActiveButtonIsLoading(false);
      },
    );
  };

  const productsAmountSummaryText = () => {
    return currentBatchActionableIDs.length > 1
      ? `${currentBatchActionableIDs.length} ${pluralizeString(
          'product',
          currentBatchActionableIDs.length,
        )}`
      : 'product';
  };

  const areSyncedProductsSelected = () => {
    if (currentBatchActionableIDs && currentBatchActionableIDs.length > 1) {
      const aSyncedProduct = products.find((object: any) => {
        return (
          currentBatchActionableIDs.includes(object.id) && object.is_synced
        );
      });
      return aSyncedProduct ? true : false;
    } else if (currentProduct) {
      return currentProduct.is_synced;
    } else {
      return false;
    }
  };

  return (
    <>
      <SelectAllCheckbox
        selectedIds={currentBulkIds}
        setSelectedIds={setCurrentBulkIds}
        currentPageIds={currentPageIds}
      />

      <ButtonGroup
        variant="ghost"
        spacing="1"
        isDisabled={!currentBatchActionableIDs.length}
      >
        <Tooltip label="Mark products active">
          <IconButton
            onClick={() => openModal('active')}
            icon={<ToggleOnIcon />}
            isLoading={activeButtonIsLoading}
            aria-label="Mark products active"
          />
        </Tooltip>
        <Tooltip label="Mark products inactive">
          <IconButton
            onClick={() => openModal('inactive')}
            icon={<ToggleOffIcon />}
            isLoading={inactiveButtonIsLoading}
            aria-label="Mark products inactive"
          />
        </Tooltip>

        <ProductsDeleteAction
          productIds={currentBatchActionableIDs}
          confirmationDialogModayBodyContent={
            <>
              {areSyncedProductsSelected() && (
                <Text color="red">
                  WARNING: Some or all of the selected products are synced. New
                  copies of these products may return on the next sync cycle if
                  they are not also deleted from the external system.
                </Text>
              )}
            </>
          }
        />
      </ButtonGroup>

      <ConfirmationDialog
        isOpen={modalIsOpen}
        onCancel={() => {
          closeModal();
        }}
        titleText={`Mark ${productsAmountSummaryText()} as ${modalContent}?`}
        bodyContent={
          <VStack alignItems="left">
            <Text>
              {modalContent == 'inactive'
                ? 'Inactive products are not visible to customers.'
                : 'Active products are visible to customers.'}
            </Text>
            {areSyncedProductsSelected() && (
              <Text color="red">
                WARNING: Some or all of the selected products are synced. The
                active status may be overridden on the next sync cycle, if it is
                not also updated in the external system.
              </Text>
            )}
          </VStack>
        }
        confirmButtonText={`Mark ${productsAmountSummaryText()} ${modalContent}`}
        confirmButtonColorScheme="green"
        onConfirm={() => {
          handleBulkUpdate(modalContent === 'active');
          closeModal();
        }}
      />
    </>
  );
};

export default observer(ProductsListActions);
