import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { StandingOrder } from '../../stores/standingOrders/standingOrder';
import { VStack, Box, ButtonGroup, List, ListItem } from '@chakra-ui/react';
import { Button } from '../Button/Button';
import { Caption, Subhead } from '../Typography/Typography';
import moment from 'moment';
import { HiPause } from 'react-icons/hi';
import { CrossIcon } from '../Icons/IconsNew';
import Banner from '../Banner/Banner';
import { DateRange } from 'react-date-range';
import { pluralizeString } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';

const LONG_FORM_DATE_FORMAT = 'dddd, D MMMM YYYY';

function PauseSection({
  standingOrder,
  isEditable,
}: {
  standingOrder: StandingOrder;
  isEditable: boolean;
}) {
  const noPauseOrPauseInPast =
    !standingOrder.pausedFrom || moment().isAfter(standingOrder.pausedTo);
  const [currentRanges, setCurrentRanges] = useState({
    startDate:
      (!noPauseOrPauseInPast && standingOrder.pausedFrom?.toDate()) ||
      new Date(),
    endDate:
      (!noPauseOrPauseInPast && standingOrder.pausedTo?.toDate()) || new Date(),
    key: 'selection',
  });
  const history = useHistory();
  let queryParams = new URLSearchParams(useLocation().search);

  const [addingPause, setAddingPause] = useState(
    standingOrder.pausedFrom || standingOrder.pausedTo ? true : false,
  );

  useEffect(() => {
    if (queryParams.get('initPause') === '1') {
      setAddingPause(true);
    }
  }, []);

  const currentPausedFrom = moment(currentRanges.startDate);
  const currentPausedTo = moment(currentRanges.endDate);

  const onRangesChange = (ranges: any) => {
    setCurrentRanges(ranges.selection);
    standingOrder.setPausedFrom(moment(ranges.selection.startDate));
    standingOrder.setPausedTo(moment(ranges.selection.endDate));
  };

  const clearDates = () => {
    setCurrentRanges({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });
    standingOrder.setPausedFrom(null);
    standingOrder.setPausedTo(null);
  };

  const startAddingPause = () => {
    setAddingPause(true);
  };

  const stopAddingPause = () => {
    setAddingPause(false);
    clearDates();
  };

  const pauseDescriptionList = () => {
    if (currentPausedFrom && currentPausedTo) {
      let textParts = [];
      const numDays = currentPausedTo.diff(currentPausedFrom, 'd') + 1;
      textParts.push(
        `Pause for ${numDays} ${pluralizeString('day', numDays)}.`,
      );
      if (currentPausedFrom.isSame(currentPausedTo)) {
        textParts.push(
          `No standing orders will be created for delivery on ${currentPausedFrom?.format(
            LONG_FORM_DATE_FORMAT,
          )}.`,
        );
      } else {
        textParts.push(
          `No standing orders will be created for delivery days from ${currentPausedFrom?.format(
            LONG_FORM_DATE_FORMAT,
          )} through to ${currentPausedTo.format(LONG_FORM_DATE_FORMAT)}.`,
        );
      }
      textParts.push(
        `Last day for delivery is ${currentPausedFrom
          ?.clone()
          .subtract(1, 'days')
          .format(
            LONG_FORM_DATE_FORMAT,
          )}, and deliveries will resume from ${currentPausedTo
          ?.clone()
          .add(1, 'days')
          .format(LONG_FORM_DATE_FORMAT)}.`,
      );
      return textParts;
    } else {
      return ["Don't pause."];
    }
  };

  const bannerTitle = moment().startOf('day').isBefore(standingOrder.pausedFrom)
    ? 'Upcoming pause'
    : 'Currently paused';
  const bannerDescription = () => {
    if (currentPausedFrom && currentPausedTo) {
      if (moment().startOf('day').isAfter(standingOrder.pausedTo)) {
        // Past pause
        return 'Not paused';
      } else {
        const numDays = currentPausedTo.diff(currentPausedFrom, 'd') + 1;

        let textParts = [];

        if (moment().startOf('day').isBefore(standingOrder.pausedFrom)) {
          // Upcoming pause
          textParts.push(
            `Upcoming pause scheduled for ${numDays} ${pluralizeString(
              'day',
              numDays,
            )}`,
          );
        } else {
          // Current pause
          textParts.push(
            `Paused for ${numDays} ${pluralizeString('day', numDays)}`,
          );
        }

        if (numDays === 1) {
          textParts.push(
            `on ${currentPausedFrom?.format(LONG_FORM_DATE_FORMAT)}.`,
          );
        } else {
          textParts.push(
            `starting on ${currentPausedFrom?.format(
              LONG_FORM_DATE_FORMAT,
            )} and ending on ${currentPausedTo?.format(
              LONG_FORM_DATE_FORMAT,
            )}.`,
          );
        }
        return textParts.join(' ');
      }
    } else {
      return 'Not paused';
    }
  };

  return (
    <VStack alignItems={'left'}>
      <Subhead fontWeight={600}>Pause</Subhead>
      <Caption color="gray.600">
        Place standing orders on pause if you want to stop deliveries on
        particular days.
      </Caption>
      {!isEditable ? (
        <>
          {standingOrder.pausedFrom &&
          standingOrder.pausedTo &&
          !moment().startOf('day').isAfter(standingOrder.pausedTo) ? (
            <Banner
              title={bannerTitle}
              description={bannerDescription()}
              icon={<HiPause width="24px" height="24px" />}
              variant="outline"
              status={
                moment().isBefore(standingOrder.pausedFrom) ? 'info' : 'warning'
              }
            ></Banner>
          ) : (
            <>
              <Caption color="gray.400">No pause scheduled.</Caption>

              <ButtonGroup>
                <Button
                  variant={'secondary'}
                  onClick={() => {
                    history.push(
                      `/standing-orders/${standingOrder.id}/edit?initPause=1`,
                    );
                  }}
                  leftIcon={<HiPause width="24px" height="24px" />}
                >
                  Pause
                </Button>
              </ButtonGroup>
            </>
          )}
        </>
      ) : (
        <>
          {addingPause ? (
            <>
              <DateRange
                minDate={new Date(2018, 0, 1)}
                maxDate={new Date(2099, 0, 1)}
                ranges={[currentRanges]}
                onChange={onRangesChange}
                rangeColors={['#07a549']} /** green.500 */
              />

              <Box
                p="3"
                border={'1px solid'}
                borderColor={'gray.400'}
                borderRadius={'5px'}
              >
                <List fontWeight={500} color="gray.400" fontSize="sm">
                  {pauseDescriptionList().map((text: string, index: number) => (
                    <ListItem key={`pause-desc-${index}`}>{text}</ListItem>
                  ))}
                </List>
              </Box>

              <ButtonGroup>
                <Button
                  variant={'secondary'}
                  onClick={stopAddingPause}
                  leftIcon={<CrossIcon width="24px" height="24px" />}
                >
                  Clear pause
                </Button>
              </ButtonGroup>
            </>
          ) : (
            <ButtonGroup>
              <Button
                variant={'secondary'}
                onClick={startAddingPause}
                leftIcon={<HiPause width="24px" height="24px" />}
              >
                Pause
              </Button>
            </ButtonGroup>
          )}
        </>
      )}
    </VStack>
  );
}

export default observer(PauseSection);
