import { Box } from '@chakra-ui/react';
import { HospoConnect } from '../Logos/Logos';
import bgImage from '../../images/auth-guest-background.svg';

function AuthWrapper({ outterChildren, innerChildren, footerChildren }: any) {
  return (
    <Box
      bg="green.700"
      backgroundSize="100% auto"
      backgroundImage={bgImage}
      minH="100vh"
      py="6"
      px={{ base: '2', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <HospoConnect mx="auto" w="208px" mb={{ base: '1', md: '4' }} />
        {outterChildren}
        <Box
          bg="white"
          py="8"
          px={{ base: '4', md: '10' }}
          shadow="base"
          rounded={{ sm: 'lg' }}
          boxShadow="0px 25px 50px -12px rgb(0 0 0 / 25%)"
        >
          {innerChildren}
        </Box>
        {footerChildren}
      </Box>
    </Box>
  );
}
export default AuthWrapper;
