import { observer } from 'mobx-react';

import { useAuth } from '../../../../contexts/auth';
import ShowIncomingOrder from './ShowIncomingOrder';
import ShowOutgoingOrder from './ShowOutgoingOrder';

const ShowOrder = ({ order, extraActions }: any) => {
  const { user } = useAuth();

  return user.company.id === order.customer_company.id ? (
    <ShowOutgoingOrder order={order} extraActions={extraActions} />
  ) : (
    <ShowIncomingOrder order={order} extraActions={extraActions} />
  );
};

export default observer(ShowOrder);
