import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Flex } from '@chakra-ui/react';

import { Body2, Footnote } from '../../../components/Typography/Typography';
import { pluralizeString } from '../../../utils';
import DraggableListRow from '../../../components/DraggableListRow/DraggableListRow';

const SourceCategoryRow = observer(
  ({ sourceCategory, onDrop, moveItem, findItem, canSort, ...rest }: any) => {
    const history = useHistory();
    return (
      <DraggableListRow
        id={sourceCategory.id}
        moveItem={moveItem}
        findItem={findItem}
        canSort={canSort}
        onDrop={(item) => {
          onDrop(item);
        }}
        onClick={() => {
          history.push(`/source-categories/${sourceCategory.id}`);
        }}
        isCurrent={sourceCategory.isCurrent}
        justify="space-between"
        py="18px"
        {...rest}
      >
        <Flex
          flex="1"
          justifyContent="space-between"
          direction={{ base: 'column', xl: 'row' }}
        >
          <Body2>{sourceCategory.name}</Body2>
          <Footnote color="gray.500">
            {sourceCategory.productsCount}{' '}
            {pluralizeString('product', sourceCategory.productsCount)}
          </Footnote>
        </Flex>
      </DraggableListRow>
    );
  },
);

export default SourceCategoryRow;
