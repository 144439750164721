import { useCurrentUser } from '../../../hooks/useStores';
import { ReactElement } from 'react';

import FormFieldWrapper, {
  FormFieldWrapperProps,
} from '../../../components/Form/FormFieldWrapper';

interface ProductCodeWrapperProps
  extends Omit<FormFieldWrapperProps, 'fieldName' | 'fieldLabel'> {
  fieldName?: string;
  fieldLabel?: string | ReactElement;
}

const ProductCodeWrapper = ({
  children,
  fieldName,
  fieldLabel,
  ...formFieldWrapperProps
}: ProductCodeWrapperProps): JSX.Element => {
  const { isEnforceUniqueProductCodesEnabled } = useCurrentUser();

  const subLabelText = () => {
    let attributes = ['Optional', 'not visible to customers'];
    if (isEnforceUniqueProductCodesEnabled) {
      attributes.push('must be unique');
    }
    return attributes.join(', ');
  };

  return (
    <FormFieldWrapper
      {...formFieldWrapperProps}
      fieldLabel="Code"
      fieldName="product-code"
      subLabel={subLabelText()}
    >
      {children}
    </FormFieldWrapper>
  );
};

export default ProductCodeWrapper;
