import { useState } from 'react';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import useAPI from '../../../hooks/useAPI';
import { useCurrentUser } from '../../../hooks/useStores';

import {
  HStack,
  Input,
  Text,
  InputProps,
  Spinner,
  VStack,
} from '@chakra-ui/react';

interface ProductCodeInputProps extends InputProps {
  product: any;
}

const ProductCodeInput = ({ product, ...props }: ProductCodeInputProps) => {
  const [isUnique, setIsUnique] = useState<boolean | null>(null);
  const { isEnforceUniqueProductCodesEnabled } = useCurrentUser();
  const [checkProductCodeRequest, isCheckProductCodeRequestLoading] = useAPI({
    method: 'GET',
  });

  const onChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }

    if (!isEnforceUniqueProductCodesEnabled) {
      return;
    }

    if (!e.target.value) {
      setIsUnique(null);
    } else {
      checkUniqueness(e.target.value);
    }
  };

  const checkUniqueness = useCallback(
    debounce((productCode: string) => {
      checkProductCodeRequest(
        `/v4/products/product_code_validity?product_code=${productCode}${
          product.id ? `&product_id=${product.id}` : ''
        }`,
      )
        .then(() => {
          setIsUnique(true);
        })
        .catch(() => {
          setIsUnique(false);
        });
    }, 400),
    [],
  );

  return (
    <VStack alignItems="left">
      <Input
        type="text"
        defaultValue={props.defaultValue}
        maxLength={props.maxLength}
        isDisabled={props.isDisabled}
        onChange={onChange}
        isInvalid={props.isInvalid || (isUnique != null && !isUnique)}
      />

      {isCheckProductCodeRequestLoading ? (
        <Spinner size="sm" color="green" />
      ) : (
        isUnique != null && (
          <Text mt="2" fontSize="sm" color={isUnique ? 'green' : 'red'}>
            {isUnique ? 'Code is unique' : 'Code is not unique'}
          </Text>
        )
      )}
    </VStack>
  );
};

export default ProductCodeInput;
