import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import {
  forwardRef,
  Heading,
  HeadingProps,
  Text,
  TextProps,
} from '@chakra-ui/react';

export const H1 = (props: HeadingProps) => {
  return (
    <Heading
      as="h1"
      fontSize="80px"
      lineHeight="75px"
      fontWeight="600"
      letterSpacing="-0.01em"
      {...props}
    />
  );
};

export const H1Dot5 = (props: HeadingProps) => {
  return (
    <Heading
      as="h1"
      fontSize="64px"
      lineHeight="56px"
      fontWeight="600"
      letterSpacing="-0.01em"
      {...props}
    />
  );
};

export const H2 = (props: HeadingProps) => {
  return (
    <Heading
      as="h2"
      fontSize="48px"
      lineHeight="48px"
      fontWeight="600"
      letterSpacing="-0.02em"
      {...props}
    />
  );
};

export const HBody = (props: HeadingProps) => {
  return (
    <Heading
      as="h3"
      fontSize="27px"
      lineHeight="35.1px"
      letterSpacing="-0.02em"
      fontWeight="500"
      {...props}
    />
  );
};

export const H3 = (props: HeadingProps) => {
  return (
    <Heading
      as="h3"
      fontSize="32px"
      lineHeight="36px"
      letterSpacing="-0.02em"
      fontWeight="600"
      {...props}
    />
  );
};

export const H4 = (props: HeadingProps) => {
  return (
    <Heading
      as="h4"
      fontSize="26px"
      lineHeight="30px"
      letterSpacing="-0.02em"
      {...props}
    />
  );
};

export const Headline = (props: HeadingProps) => {
  return (
    <Heading
      as="h5"
      fontSize="19px"
      lineHeight="24.7px"
      letterSpacing="-0.02em"
      {...props}
    />
  );
};

export const Subhead = (props: HeadingProps) => {
  return (
    <Heading
      as="h6"
      fontSize="16px"
      lineHeight="20.8px"
      letterSpacing="-0.01em"
      fontWeight="normal"
      {...props}
    />
  );
};

export const SmallTitle = (props: HeadingProps) => {
  return (
    <Heading
      as="h6"
      fontSize="12px"
      lineHeight="15.6px"
      letterSpacing="0.12em"
      textTransform="uppercase"
      {...props}
    />
  );
};

export const Body = (props: TextProps) => {
  return <Text as="p" fontSize="17px" lineHeight="23.8px" {...props} />;
};

export const Body2 = (props: TextProps) => {
  return <Text as="p" fontSize="16px" lineHeight="20.8px" {...props} />;
};

export interface LabelProps extends Omit<TextProps, 'as'> {
  as?: FunctionComponent;
}

export const Label = (props: LabelProps) => {
  const { as: Component, ...rest } = props;
  if (Component) {
    return (
      <Component
        fontSize="16px"
        lineHeight="20.8px"
        letterSpacing="-0.01em"
        fontWeight="semibold"
        {...rest}
      />
    );
  }
  return (
    <Text
      as="label"
      fontSize="16px"
      lineHeight="20.8px"
      letterSpacing="-0.01em"
      fontWeight="semibold"
      {...rest}
    />
  );
};

export const Footnote = (props: TextProps) => {
  return (
    <Text
      as="p"
      fontSize="15px"
      lineHeight="21px"
      fontWeight="400"
      {...props}
    />
  );
};

export const Caption = forwardRef<TextProps, 'span'>((props: TextProps, ref) => {
  return (
    <Text
      as="span"
      fontSize="13px"
      lineHeight="18.2px"
      letterSpacing="-0.01em"
      ref={ref}
      {...props}
    />
  );
});
