import { useState } from 'react';
import {
  ButtonGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import imageUrl from '../../../../images/mountain-stream.png';
import { Button } from '../../../../components/Button/Button';
import IntegrationListItemWrapper from '../IntegrationListItemWrapper';
import useAPI from '../../../../hooks/useAPI';
import { useSuccessToast, useErrorToast } from '../../../../components/toast';
import { useAccounting } from '../../../../contexts/accounting';

const MountainStreamIntegrationListItem = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getCurrentAccountingConnection } = useAccounting();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();
  const [uniqueIdentifierCode, setUniqueIdentifierCode] = useState('');

  const [callAPIPost, submitIsLoading] = useAPI({
    method: 'POST',
  });

  function onSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    callAPIPost('/v4/accounting_connectors/mountain_stream/connect', {
      body: JSON.stringify({
        unique_identifier_code: uniqueIdentifierCode,
      }),
    })
      .then(() => {
        successToast({
          description:
            'MountainStream is successfully set up and ready to receive Invoices.',
        });
        getCurrentAccountingConnection(() => {
          onClose();
        });
      })
      .catch(async (response: Response) => {
        const errorTitle = 'Error setting up MountainStream';
        if (response.status === 422) {
          const data = await response.json();
          errorToast({
            title: errorTitle,
            description: data.error,
          });
        } else {
          errorToast({
            title: errorTitle,
            description: 'The provided credentials are invalid.',
          });
        }
      });
  }

  return (
    <IntegrationListItemWrapper
      logoImageUrl={imageUrl}
      platformName="MountainStream"
    >
      <Button variant="primary" onClick={onOpen}>
        Connect
      </Button>

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={onSubmit}>
            <ModalHeader>Set up MountainStream email integration</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack alignItems={'left'} spacing="8">
                <Text pb="2">
                  You can find your Unique Identifier Code in MountainStream by
                  navigating to Company &rarr; Settings and then scrolling down
                  to the Email orders section.
                </Text>

                <FormControl id="ms-unique-identifier-code">
                  <FormLabel>Unique Identifier Code</FormLabel>
                  <Input
                    name="ms-unique-identifier-code"
                    tabIndex={1}
                    autoComplete="off"
                    value={uniqueIdentifierCode}
                    onChange={(e) => setUniqueIdentifierCode(e.target.value)}
                  />
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup colorScheme="green">
                <Button
                  variant="tertiary"
                  onClick={onClose}
                  isDisabled={submitIsLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  isLoading={submitIsLoading}
                  tabIndex={6}
                  isDisabled={uniqueIdentifierCode === ''}
                  type="submit"
                >
                  Continue
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </IntegrationListItemWrapper>
  );
};

export default MountainStreamIntegrationListItem;
