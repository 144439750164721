import { VStack, Textarea, Input, Box, Avatar } from '@chakra-ui/react';
import { SnapshotOrInstance } from 'mobx-state-tree';

import {
  UserCompany,
  UserCompanyEditFormValues,
} from '../../../models/UserCompany';
import EditableFormWrapper from '../../../components/Form/EditableFormWrapper';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import FormikEditableFormWrapper from '../../../components/Form/FormikEditableFormWrapper';

const CompanyProfileFields = ({
  company,
  isEditable = false,
  onChange = () => {},
}: {
  company: SnapshotOrInstance<typeof UserCompany> | UserCompanyEditFormValues;
  isEditable?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}) => {
  return (
    <VStack align="left" spacing="8" maxWidth="525px">
      <FormFieldWrapper
        fieldName="logo_url"
        fieldLabel="Logo"
      >
        <Box
          width={{ base: '80px', '2xl': '80px' }}
          mr={{ base: '8px', '2xl': '24px' }}
        >
          <Avatar
            h={'80px'}
            w={'80px'}
            src={company.logo_url || ''}
          />
        </Box>
      </FormFieldWrapper>
      <FormikEditableFormWrapper
        value={company.name || ''}
        EditComponent={Input}
        fieldName="name"
        fieldLabel="Name"
        isEditable={isEditable}
        onChange={onChange}
      />
      <FormikEditableFormWrapper
        value={company.about || ''}
        EditComponent={Textarea}
        fieldName="about"
        fieldLabel="About"
        isEditable={isEditable}
        onChange={onChange}
      />
    </VStack>
  );
};

export default CompanyProfileFields;
