import React, { useState } from 'react';
import { useAuth } from '../contexts/auth';
import * as Constants from '../constants';
import { xClientHeaders } from '../utils';

type Context = {
  customers: any;
  getCustomers: (successCallback: () => any, errorCallback: () => any) => void;
};

const CurrentCompanyContext = React.createContext<Context>({
  customers: [],
  getCustomers: () => {},
});

function CurrentCompanyProvider(props: any) {
  const { user } = useAuth();
  const [customers, setCustomers] = useState(null);

  const getCustomers = (
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    const path = `/v3/customers`;

    if (user) {
      const token = `Bearer ${user.authentication_token}`;
      const requestOptions = {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
          ...xClientHeaders,
        },
      };

      const url = Constants.API_URL + path;
      fetch(url, requestOptions).then(async (response) => {
        if (response.status === 401) {
        } else {
          const data = await response.json();
          if (response.ok) {
            setCustomers(data);
            if (successCallback) {
              successCallback();
            }
            return data;
          } else {
            if (errorCallback) {
              errorCallback();
            }
            return Promise.reject(data);
          }
        }
      });
    }
    return;
  };

  return (
    <CurrentCompanyContext.Provider
      value={{
        customers,
        getCustomers,
      }}
      {...props}
    />
  );
}

const useCurrentCompany = () => React.useContext(CurrentCompanyContext);

export { CurrentCompanyProvider, useCurrentCompany };
