import { RefObject } from 'react';
import { observer } from 'mobx-react';

import useRealtimeData, {
  RealtimeDataEventPayloadType,
} from '../../../hooks/useRealtimeData';
import { useOrders } from '../../../hooks/useStores';
import BuyersOrdersDataGridHeader from './BuyersOrdersDataGridHeader';
import OrdersDataGrid from './OrdersDataGrid';
import BuyersOrdersDataGridRow from './BuyersOrdersDataGridRow';

const BuyersOrdersDataGrid = ({
  dataGridRef,
}: {
  dataGridRef: RefObject<HTMLTableElement>;
}) => {
  const {
    orders,
    ordersAreLoading,
    currentStatusFilter,
    getOrders,
    totalCount,
    clearFilters,
    uncheckOrdersIfHidden,
  } = useOrders();

  const onReceived = (payload: RealtimeDataEventPayloadType) => {
    if (payload.entityType === 'order') {
      if (currentStatusFilter !== 'new' && payload.eventType === 'create') {
        /** Don't bother refreshing for a create event unless we are on the new tab */
        return;
      }

      getOrders(
        () => {
          uncheckOrdersIfHidden(payload.entityIds);
        },
        () => {},
        'outgoing',
      );
    }
  };
  const onTabReactivate = () => {
    getOrders(
      () => {},
      () => {},
      'outgoing',
    );
  };
  useRealtimeData(
    'DataFeedChannel',
    onReceived,
    [currentStatusFilter],
    onTabReactivate,
  );

  return (
    <OrdersDataGrid
      dataGridRef={dataGridRef}
      orders={orders}
      header={<BuyersOrdersDataGridHeader />}
      isLoading={ordersAreLoading}
      totalCount={totalCount}
      clearFilters={clearFilters}
      renderItem={(order) => (
        <BuyersOrdersDataGridRow key={order.id} order={order} />
      )}
    />
  );
};

export default observer(BuyersOrdersDataGrid);
