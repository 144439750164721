import { types } from 'mobx-state-tree';
import UserCompany from './UserCompany';

const User = types
  .model('User', {
    id: types.number,
    fullname: types.string,
    avatar_url: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    firstname: types.string,
    surname: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    job_title: types.maybeNull(types.string),
    account_type: types.maybeNull(types.string),
    authentication_token: types.maybeNull(types.string),
    company_role: types.maybeNull(types.string),
    company: UserCompany,
  });

export default User;
