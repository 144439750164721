import React, { ChangeEvent, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useProducts } from '../../hooks/useStores';
import debounce from 'lodash/debounce';

import {
  ButtonGroup,
  HStack,
  Stack,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { Button } from '../../components/Button/Button';
import { PlusIcon } from '../../components/Icons/IconsNew';
import ActionBar from '../../components/ActionBar/ActionBar';

const ProductsPageActions = () => {
  const {
    getProductsList,
    getCategories,
    getSourceCategories,
    categories,
    currentCategoryFilter,
    setCurrentCategoryFilter,
    currentStatusFilter,
    setCurrentStatusFilter,
    currentSearchQuery,
    setCurrentSearchQuery,
    setCurrentProductsPage,
  } = useProducts();

  useEffect(() => {
    getProductsList(
      () => {},
      () => {},
    );
  }, [currentStatusFilter, currentSearchQuery, currentCategoryFilter]);

  useEffect(() => {
    getCategories(true);
    getSourceCategories();
  }, []);

  const handleFilters = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCurrentProductsPage(1);
    if (target.name === 'status') {
      target.value === 'all'
        ? setCurrentStatusFilter('')
        : setCurrentStatusFilter(target.value);
    } else if (target.name === 'category') {
      target.value === 'all'
        ? setCurrentCategoryFilter('')
        : setCurrentCategoryFilter(target.value);
    }
  };

  const handleSearch = useCallback(
    debounce((e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      setCurrentProductsPage(1);
      setCurrentSearchQuery(target.value);
    }, 500),
    [],
  );

  return (
    <ActionBar
      title="Products"
      rightChildren={
        <HStack>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="gray.300" />}
            />
            <Input
              variant="outline"
              type="search"
              onChange={handleSearch}
              placeholder="Search"
              name="search"
              bg="white"
            />
          </InputGroup>
          <ButtonGroup>
            <Button
              variant="primary"
              href="/products/new"
              leftIcon={<PlusIcon width="24px" />}
            >
              Add product
            </Button>
          </ButtonGroup>
        </HStack>
      }
    >
      <Stack
        spacing="4"
        direction={{ base: 'column', md: 'row' }}
        justify="space-between"
      >
        <HStack>
          <label>
            Category
            <Select
              onChange={handleFilters}
              name="category"
              value={
                currentCategoryFilter == '' ? 'All' : currentCategoryFilter
              }
            >
              <option value="all">All</option>
              {categories &&
                categories.map((category: any, i: number) => {
                  return (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
            </Select>
          </label>
          <label>
            Status
            <Select
              onChange={handleFilters}
              name="status"
              value={currentStatusFilter == '' ? 'All' : currentStatusFilter}
            >
              <option value="all">All</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Select>
          </label>
        </HStack>
      </Stack>
    </ActionBar>
  );
};

export default observer(ProductsPageActions);
