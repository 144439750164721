import { useCallback, useState } from 'react';
import { xClientHeaders } from '../utils';
import * as Sentry from '@sentry/browser';
import merge from 'lodash/merge';

import { useAuth } from '../contexts/auth';
import { api } from '../api';

const useAPI = (requestOptions?: any) => {
  const { user, setAuthError, ejectUser } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const fullRequestOptions = merge(
    {
      headers: {
        'Content-Type': 'application/json',
        ...xClientHeaders,
      },
    },
    user && {
      headers: {
        Authorization: `Bearer ${user.authentication_token}`,
      },
    },
    requestOptions,
  );

  const callAPI = useCallback(
    (url: string, requestData?: any, returnFullReponseObject?: boolean) =>
      new Promise<any>((resolve, reject) => {
        const fullRequestData = merge(fullRequestOptions, requestData);
        setIsLoading(true);
        api
          .client(url, fullRequestData)
          .then(async (response) => {
            setIsLoading(false);
            if (response.ok) {
              if (returnFullReponseObject) {
                return resolve(response);
              } else {
                const responseData = await response.json();
                return resolve(responseData);
              }
            } else {
              const { status } = response;
              if (status === 401) {
                setAuthError(true);
                ejectUser();
              }
              reject(response);
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            return reject(error);
          });
      }),
    [fullRequestOptions, ejectUser, setAuthError],
  );
  return [callAPI, isLoading] as const;
};

export default useAPI;
