export default function prettyPrintQuantity(quantity: number, unit: 'kg' | 'L') {
  if (unit === 'kg') {
    if (quantity >= 1) {
      return `${quantity.toFixed(3)} kg`;
    } else {
      return `${Math.round(quantity * 1000)} g`;
    }
  } else {
    if (quantity >= 1) {
      return `${quantity.toFixed(3)} L`;
    } else {
      return `${Math.round(quantity * 1000)} ml`;
    }
  }
}