import { Flex, FlexProps } from '@chakra-ui/react';
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';

interface LoadingDetailProps extends FlexProps {}

const LoadingDetail = (props: LoadingDetailProps) => (
  <Flex
    flexDirection="column"
    flex={{ base: '2', xl: '3' }}
    borderTop="1px solid"
    borderColor="gray.200"
    {...props}
  >
    <PageLoadSpinner />
  </Flex>
);

export default LoadingDetail;
