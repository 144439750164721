import { TextProps } from '@chakra-ui/layout';

import { BoxProps } from '@chakra-ui/react';
import DetailStatus from './DetailStatus';

type OrderDetailStatusProps = {
  status: 'standard' | 'new' | 'alternate';
  textProps?: TextProps;
};

const AddressStatuses = {
  new: {
    text: 'New address',
    color: 'error.red',
    tooltip:
      'This buyer has updated their address, make sure to pass this on to your delivery drivers.',
  },
  alternate: {
    text: 'Alternate address',
    color: 'error.amber',
    tooltip:
      "This isn't the address this buyer usually uses, make sure your delivery drivers know.",
  },
  standard: null,
};

export const AddressStatus = ({
  status,
  textProps,
  ...containerProps
}: BoxProps & OrderDetailStatusProps) => {
  const statusProps = AddressStatuses[status];
  return statusProps ? (
    <DetailStatus
      icon="HelpIcon"
      textProps={textProps}
      {...statusProps}
      {...containerProps}
    />
  ) : null;
};

export default AddressStatus;
