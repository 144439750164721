import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import IngredientDetail from './Detail/IngredientDetail';
import IngredientsList from './List/IngredientsList';
import IngredientsPageActions from './IngredientsPageActions';

const IngredientsPage = ({
  match: {
    params: { ingredientId },
  },
}: RouteComponentProps<{
  ingredientId: string;
}>): JSX.Element => {
  return (
    <AppWrapper>
      <TwoUpWrapper
        headerChildren={<IngredientsPageActions />}
        leftChildren={<IngredientsList />}
        rightChildren={<IngredientDetail ingredientId={ingredientId} />}
      />
    </AppWrapper>
  );
};

export default observer(IngredientsPage);
