import logoImage from '../../../../images/unleashed-small.png';

import DisconnectButton from '../DisconnectButton';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import Settings from '../Settings';
import { Subhead, Caption } from '../../../../components/Typography/Typography';

import { Image, Box, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import {
  CrossInCircleIcon,
  TickInCircleIcon,
} from '../../../../components/Icons/Icons';

type UnleashedConnectionDetailsProps = {
  connection: any;
};

// TODO: Add link to dashboard from error message https://au.unleashedsoftware.com/v2/Integration/Api

const UnleashedConnectionDetails = ({
  connection,
}: UnleashedConnectionDetailsProps): JSX.Element => {
  return (
    <Box bg="white" p="4">
      {connection.connection_status === 'error' && (
        <ErrorMessage
          style={{ gridColumn: '1 / span 14', marginBottom: '16px' }}
          description={`There’s a problem with your Unleashed connection. Please check Integration -> Unleashed API Access on your Unleashed dashboard.`}
        />
      )}

      <VStack align="left" spacing="4">
        <HStack spacing="3">
          <Image src={logoImage} w="64px"></Image>

          <Box>
            <Subhead fontWeight="600">Unleashed</Subhead>
            <Caption>Connected to: {connection.tenant_name}</Caption>
          </Box>

          <Spacer />

          <Box>
            <VStack spacing="3">
              {connection.connection_status === 'active' && (
                <HStack color="green.600" fontWeight="600">
                  <Text>Connected</Text>
                  <TickInCircleIcon />
                </HStack>
              )}
              {connection.connection_status === 'error' && (
                <HStack color="red.500" fontWeight="600">
                  <Text>Error</Text>
                  <CrossInCircleIcon />
                </HStack>
              )}
              <DisconnectButton platformName={'Unleashed'} />
            </VStack>
          </Box>
        </HStack>

        <Settings
          currentSettings={connection.settings}
          accountingConnectionName={connection.label}
        />
      </VStack>
    </Box>
  );
};

export default UnleashedConnectionDetails;
