import { useEffect, useState, useCallback } from 'react';
import { useAccounting } from '../../contexts/accounting';
import { useAuth } from '../../contexts/auth';
import { useSuccessToast, useErrorToast } from '../toast';

import useAPI from '../../hooks/useAPI';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  ButtonGroup,
  Button,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  RadioGroup,
  Radio,
  Select,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { debounce } from 'lodash';

type ContactResult = {
  id: string;
  name: string;
};

const ContactDropDownModalBody = ({
  user,
  isOpen,
  onClose,
  accountingConnectorName,
  contactName,
  setActiveContactID,
}: any) => {
  // This version is deprecated in favour of the search version, which is better UX.
  const { getAccountingConnectorsContacts, accountingConnectorContacts } =
    useAccounting();
  const errorToast = useErrorToast();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen === true) {
      getAccountingConnectorsContacts(
        '/v4/accounting_connectors/contacts',
        user,
        () => {
          setIsLoading(false);
        },
        () => {
          errorToast({
            description: `Could not retrieve ${accountingConnectorName} contacts. Please check your connection to ${accountingConnectorName}.`,
          });
          onClose();
        },
      );
    }
  }, [isOpen]);

  return (
    <ModalBody py={4} px={6}>
      <VStack spacing="3">
        <Text>
          Choose a {accountingConnectorName} contact to connect with{' '}
          {contactName}. (You'll only need to do this once, we'll save this
          match for all their future invoices.)
        </Text>
        <FormControl>
          <FormLabel>{accountingConnectorName} contacts</FormLabel>
          {isLoading ? (
            <Spinner thickness="4px" size="lg" color="green" />
          ) : (
            <Select
              defaultValue={''}
              onChange={(e) => {
                setActiveContactID(e.target.value);
              }}
            >
              <option value={''}>Select a contact</option>
              {accountingConnectorContacts &&
                accountingConnectorContacts.map((customer: ContactResult) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                ))}
            </Select>
          )}
        </FormControl>
      </VStack>
    </ModalBody>
  );
};

const ContactSearchModalBody = ({
  accountingConnectorName,
  contactName,
  setActiveContactID,
  selectedContactId,
}: any) => {
  const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [fetchFailed, setFetchFailed] = useState(false);

  const [contacts, setContacts] = useState<ContactResult[]>([]);
  const [getContacts, isLoading] = useAPI({
    method: 'GET',
  });

  const handleSearch = useCallback(
    debounce((value: string) => {
      clearResults();
      setCurrentQuery(value);
    }, 400),
    [],
  );

  const clearResults = () => {
    setContacts([]);
  };

  const clearFilters = () => {
    clearResults();
    setCurrentQueryFieldValue('');
    setCurrentQuery('');
  };

  useEffect(() => {
    setFetchFailed(false);
    const url = `/v4/accounting_connectors/contacts${
      currentQuery && '?q=' + currentQuery
    }`;

    getContacts(url)
      .then((results: ContactResult[]) => {
        setResultCount(results.length);
        setContacts(results);
      })
      .catch(() => {
        setFetchFailed(true);
      });
  }, [currentQuery]);

  const onSelect = (contactId: any) => {
    setActiveContactID(contactId);
  };

  const showCustomerID = accountingConnectorName == 'Accredo';

  return (
    <ModalBody p={0}>
      <Box
        py={4}
        px={6}
        bg="gray.100"
        boxShadow="inset 0px 1px 0px #D1D5DB, inset 0px -1px 0px #D1D5DB"
      >
        <VStack>
          <Text>
            Search for, and select an {accountingConnectorName} contact to
            connect with {contactName}. (You'll only need to do this once, we'll
            save this match for all their future invoices.)
          </Text>

          <Input
            variant="outline"
            type="search"
            onChange={(e) => {
              setCurrentQueryFieldValue(e.target.value);
              handleSearch(e.target.value);
            }}
            value={currentQueryFieldValue}
            placeholder="Search"
            name="search"
            bg="white"
            mb={3}
          />
        </VStack>
      </Box>

      {!fetchFailed ? (
        <RadioGroup onChange={onSelect} value={selectedContactId}>
          {resultCount > 0 ? (
            <Box maxHeight="320px" overflowY="scroll">
              {contacts &&
                contacts.map((contact: ContactResult) => {
                  return (
                    <ContactRow
                      key={contact.id}
                      contact={contact}
                      showCustomerID={showCustomerID}
                    />
                  );
                })}
            </Box>
          ) : (
            !isLoading &&
            resultCount == 0 && (
              <VStack px={6} py={4} spacing="4" alignItems="left">
                <Text fontSize="lg" fontWeight="500">
                  No customers found
                </Text>
                <Link fontWeight="400" onClick={clearFilters}>
                  Clear filters
                </Link>
              </VStack>
            )
          )}
          {isLoading && (
            <Center height="100px">
              <Spinner thickness="6px" size="xl" color="green" />
            </Center>
          )}
        </RadioGroup>
      ) : (
        <Alert status="error" fontSize="sm">
          <AlertIcon />
          <AlertDescription>
            Failed to fetch {accountingConnectorName} contacts.
          </AlertDescription>
        </Alert>
      )}
    </ModalBody>
  );
};

const ContactRow = ({
  contact,
  showCustomerID,
}: {
  contact: ContactResult;
  showCustomerID: boolean;
}) => {
  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
    >
      <Radio w="100%" value={String(contact.id)}>
        <Box ml="4" w="100%">
          <Box
            w="100%"
            flex={1}
            display="flex"
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <HStack>
                {showCustomerID && (
                  <Text color="gray.700" fontWeight="600" noOfLines={2}>
                    {contact.id}
                  </Text>
                )}
                <Text color="gray.700" fontWeight="400" noOfLines={2}>
                  {contact.name}
                </Text>
              </HStack>
            </Box>
          </Box>
        </Box>
      </Radio>
    </HStack>
  );
};

const MatchContactsModal = ({
  contactId,
  contactName,
  isOpen,
  onSuccess,
  onClose,
}: any) => {
  const { user } = useAuth();
  const { currentAccountingConnection, matchConnectorsContacts } =
    useAccounting();
  const [selectedContactId, setSelectedContactId] =
    useState<string | null>(null);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const successToast = useSuccessToast();

  const accountingConnectorName =
    currentAccountingConnection && currentAccountingConnection.label;

  const setActiveContactID = (id: string) => {
    setSelectedContactId(id);
  };

  const matchSelectedContacts = (selectedContactId: string | null) => {
    onClose();
    setSubmitButtonLoading(true);

    matchConnectorsContacts(
      '/v4/accounting_connectors/contacts',
      user,
      selectedContactId,
      contactId,
      () => {
        setSubmitButtonLoading(false);
        if (onSuccess) {
          onSuccess();
        }
      },
    );
  };

  const useSearch = ['Unleashed', 'Accredo'].includes(accountingConnectorName);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={0} pb={0}>
          <Text px={6} mt={4} mb={6}>
            Match '{contactName}' to {accountingConnectorName}
          </Text>
        </ModalHeader>

        {useSearch ? (
          <ContactSearchModalBody
            accountingConnectorName={accountingConnectorName}
            contactName={contactName}
            setActiveContactID={setActiveContactID}
            selectedContactId={selectedContactId}
          />
        ) : (
          <ContactDropDownModalBody
            user={user}
            isOpen={isOpen}
            onClose={onClose}
            accountingConnectorName={accountingConnectorName}
            contactName={contactName}
            setActiveContactID={setActiveContactID}
          />
        )}

        <ModalFooter>
          <ButtonGroup colorScheme="green">
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => matchSelectedContacts(selectedContactId)}
              isLoading={submitButtonLoading}
              isDisabled={!selectedContactId}
            >
              Select
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MatchContactsModal;
