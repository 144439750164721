import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

import {
  Avatar,
  Button,
  Tooltip,
  Box,
  Flex,
  Heading,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { Instance } from 'mobx-state-tree';

import DetailView from '../../../components/DetailView/DetailView';
import { EditBoxIcon } from '../../../components/Icons/IconsNew';
import { UserCompany } from '../../../models/UserCompany';
import CompanyProfileContactFields from './CompanyProfileContactFields';
import CompanyProfileFields from './CompanyProfileFields';
import CompanyProfileAddressFields from './CompanyProfileAddressFields';

const ShowCustomer = ({
  company,
}: {
  company?: Instance<typeof UserCompany>;
}) => {
  const history = useHistory();

  return (
    <DetailView
      leftActions={
        <Heading size="md" as="h4">
          {company?.name}
        </Heading>
      }
      rightActions={
        <>
          <Tooltip hasArrow label="Edit profile">
            <Button
              onClick={() => {
                history.push(`/profile/edit`);
              }}
              leftIcon={<EditBoxIcon width="24px" />}
            >
              Edit
            </Button>
          </Tooltip>
        </>
      }
    >
      <Box style={{ backgroundColor: 'white', marginBottom: '80px' }}>
        {company ? (
          <>
            <VStack align="left" spacing="8" mt="16">
              <CompanyProfileFields company={company} />
              <Divider />
              <CompanyProfileContactFields company={company} />
              {company.isBuyer && (
                <>
                  <Divider />
                  <CompanyProfileAddressFields company={company} />
                </>
              )}
            </VStack>
          </>
        ) : (
          <>Oh no! Something went wrong!</>
        )}
      </Box>
    </DetailView>
  );
};
export default observer(ShowCustomer);
