import React, { useEffect, useMemo, useRef, useState } from 'react';
import PageLoadSpinner from '../../components/PageLoadSpinner/PageLoadSpinner';
import {
  SubtextGroupItem,
  parseSubtextGroupItems,
  pluralizeString,
} from '../../utils';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  Flex,
} from '@chakra-ui/react';

import { Button } from '../../components/Button/Button';
import { CustomerGroupSet } from '../../models/CustomerGroupSet';
import { motion } from 'framer-motion';
import SelectModalBody from './SelectModalBody';
import ModalFooterActions from '../Modal/ModalFooterActions';
import SelectCustomersModalBody from './SelectCustomersModalBody';
import {
  CustomerGroupOptions,
  CustomerGroupOption,
  CustomerGroupSetOptions,
  CustomerOptions,
} from './types';

import { CaretLeftIcon } from '../Icons/Icons';
import { UserGroupIcon } from '../Icons/IconsNew';

export type SelectCustomersOptions = 'all' | 'some' | 'none';

type SelectCustomersModalProps = {
  initialCustomerIDs?: number[];
  initialGroupIDs?: number[];
  initialVisibilityOption?: SelectCustomersOptions;
  customers?: any[];
  groupSets?: CustomerGroupSet[];
  onSave: (values: {
    customerIds: string[];
    customerGroupIds: string[];
    hideFromCustomers: boolean;
  }) => any;
  isOpen?: boolean;
  onClose?: () => void;
};

const pageTransition = {
  hidden: { opacity: 0, x: 0 },
  show: {
    opacity: 1,
    // x: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  hide: {
    opacity: 0,
  },
};

const SelectCustomersModal = ({
  initialCustomerIDs = [],
  initialGroupIDs = [],
  initialVisibilityOption = 'all',
  customers = [],
  groupSets = [],
  onSave,
  isOpen = false,
  onClose = () => {},
}: SelectCustomersModalProps): JSX.Element => {
  const [checkedCustomers, setCheckedCustomers] = useState<CustomerOptions>(
    customers.reduce(
      (result, customer) => ({
        ...result,
        [customer.id]: {
          id: `${customer.id}`,
          label: customer.name,
          icon: customer.logo_url,
          selected: initialCustomerIDs.some((id) => id === customer.id),
          groupsSelected: [],
        },
      }),
      {},
    ),
  );

  const [checkedCustomerSetGroups, setCheckedCustomerSetGroups] =
    useState<CustomerGroupSetOptions>(
      groupSets.reduce<CustomerGroupSetOptions>((result, set) => {
        let groups: CustomerGroupOptions = set.groups.reduce(
          (result, group) => ({
            ...result,
            [group.id]: {
              id: `${group.id}`,
              label: group.name,
              selected: initialGroupIDs.some((id) => id === group.id),
              setName: set.name,
              customerIds: Object.keys(group.customersList).map(
                (key) => group.customersList[Number(key)].customerId,
              ),
              customerCount: group.customerCount,
            },
          }),
          {},
        );
        return {
          ...result,
          [set.id]: {
            id: `${set.id}`,
            name: set.name,
            groups,
          },
        };
      }, {}),
    );

  const checkedGroups = useMemo<CustomerGroupOption[]>(
    () =>
      Object.entries(checkedCustomerSetGroups).reduce<CustomerGroupOption[]>(
        (prev, [setID, set]) => {
          const groups = Object.entries(set.groups).reduce<
            CustomerGroupOption[]
          >((filtered, [groupID, group]) => {
            return group.selected ? [...filtered, group] : filtered;
          }, []);
          return [...prev, ...groups];
        },
        [],
      ),
    [checkedCustomerSetGroups],
  );

  const [visibilityOption, setVisibilityOption] = useState(
    initialVisibilityOption,
  );

  const checkedCustomersCount = Object.keys(checkedCustomers).filter(
    (id) => checkedCustomers[id]?.selected,
  ).length;

  const selectedCustomers = Object.keys(checkedCustomers).filter(
    (id) =>
      checkedCustomers[id]?.selected ||
      checkedGroups?.some((group) => group.customerIds.includes(Number(id))),
  );

  const [selectedSubModal, setSelectedSubModal] =
    useState<string | undefined>();

  const currentSet = selectedSubModal
    ? checkedCustomerSetGroups[selectedSubModal]
    : null;

  const onSubmit = () => {
    const customerIds = Object.keys(checkedCustomers).filter(
      (key) => checkedCustomers[key].selected,
    );
    let customerGroupIds: string[] = [];
    Object.keys(checkedCustomerSetGroups).forEach((setKey) => {
      let groups = checkedCustomerSetGroups[setKey].groups;
      customerGroupIds = [
        ...customerGroupIds,
        ...Object.keys(groups).filter((groupKey) => groups[groupKey].selected),
      ];
    });
    onSave({
      customerIds: visibilityOption === 'some' ? customerIds : [],
      customerGroupIds: visibilityOption === 'some' ? customerGroupIds : [],
      hideFromCustomers: visibilityOption === 'none',
    });
    onClose();
  };

  const onCancel = () => {
    setSelectedSubModal(undefined);
    onClose();
  };

  const selectAll = (value: boolean) => {
    setCheckedCustomers(
      Object.keys(checkedCustomers).reduce(
        (result, customerKey) => ({
          ...result,
          [customerKey]: {
            ...checkedCustomers[customerKey],
            selected: value,
          },
        }),
        {},
      ),
    );
  };

  const getCustomerSubtext = (selectedGroups: CustomerGroupOption[]) => {
    if (!selectedGroups?.length) return null;
    const subtextItems: SubtextGroupItem[] = selectedGroups.map((group) => ({
      label: group.setName || '',
      itemLabels: [group.label],
    }));
    const groupsBreadcrumbs = parseSubtextGroupItems({ subtextItems });
    return <>In {groupsBreadcrumbs}</>;
  };

  const handleGroupSelect = ({
    groupID,
    groupSetID,
    value,
  }: {
    groupID: string;
    groupSetID: string;
    value: boolean;
  }) => {
    let set = checkedCustomerSetGroups[groupSetID];
    let group = set.groups[groupID];
    setCheckedCustomerSetGroups({
      ...checkedCustomerSetGroups,
      [groupSetID]: {
        ...set,
        groups: {
          ...set.groups,
          [groupID]: {
            ...group,
            selected: value,
          },
        },
      },
    });
  };

  const selectedCustomersCount =
    visibilityOption === 'all'
      ? customers.length
      : visibilityOption === 'none'
      ? 0
      : selectedCustomers.length;

  const isValid: boolean =
    visibilityOption !== 'some' ||
    !!checkedCustomersCount ||
    !!checkedGroups.length;

  const checkedSummaryText = `${selectedCustomersCount} ${pluralizeString(
    'customer',
    selectedCustomersCount,
  )}`;

  return (
    <Modal isOpen={isOpen} onClose={onCancel} closeOnOverlayClick={isValid}>
      <ModalOverlay>
        <ModalContent style={{ overflow: 'hidden' }} h="620px" maxH="80vh">
          {selectedSubModal ? (
            <>
              <ModalHeader pt={0}>
                <Flex direction="column">
                  <Button
                    alignSelf="flex-start"
                    variant="ghost"
                    border="none"
                    aria-label="Back"
                    mt={2}
                    pl={0}
                    fontWeight={500}
                    leftIcon={<CaretLeftIcon boxSize={5} />}
                    onClick={() => setSelectedSubModal(undefined)}
                  >
                    Back
                  </Button>
                  {selectedSubModal === 'customers'
                    ? 'Customers'
                    : currentSet?.name}
                </Flex>
              </ModalHeader>
            </>
          ) : (
            <ModalHeader>Customer visibility</ModalHeader>
          )}
          {!selectedSubModal && (
            <SelectCustomersModalBody
              loading={!customers}
              groupSets={checkedCustomerSetGroups}
              checkedCustomersCount={checkedCustomersCount}
              handleSetVisibilityOption={setVisibilityOption}
              visibilityOption={visibilityOption}
              onShowModal={setSelectedSubModal}
            />
          )}
          {!!selectedSubModal && (
            <ModalBody
              position="relative"
              overflow="hidden"
              as={motion.div}
              variants={pageTransition}
              initial="hidden"
              animate="show"
              exit="hide"
              display="flex"
              flexDirection="column"
              px={0}
              pb={0}
            >
              {selectedSubModal === 'customers' ? (
                <SelectModalBody
                  search
                  searchPlacholder="Search customers"
                  onSelectAll={selectAll}
                  onSelect={(id, checked) => {
                    setCheckedCustomers({
                      ...checkedCustomers,
                      [id]: {
                        ...checkedCustomers[id],
                        selected: checked,
                      },
                    });
                  }}
                  onBack={() => setSelectedSubModal(undefined)}
                  options={customers.map((customer) => {
                    const customerOption = checkedCustomers[customer.id];
                    const customerSelectedGroups: CustomerGroupOption[] =
                      checkedGroups?.filter((group) =>
                        group.customerIds.includes(Number(customerOption.id)),
                      );
                    return {
                      value: customerOption.id,
                      label: customerOption.label,
                      selected:
                        customerOption.selected ||
                        !!customerSelectedGroups.length,
                      subtext: getCustomerSubtext(customerSelectedGroups),
                      inputId: `customer-${customerOption.id}`,
                      disabled: !!customerSelectedGroups.length,
                    };
                  })}
                />
              ) : (
                <>
                  {!currentSet ? (
                    <PageLoadSpinner />
                  ) : (
                    <SelectModalBody
                      onBack={() => setSelectedSubModal(undefined)}
                      options={Object.entries(currentSet.groups)
                        .map(([key, group]) => ({
                          value: group.id,
                          label: group.label,
                          selected: group.selected,
                          subtext:
                            group.customerCount !== undefined
                              ? `${group.customerCount} customers`
                              : null,
                          inputId: `group-${group.id}`,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                      onSelect={(groupID: string, value: boolean) =>
                        handleGroupSelect({
                          groupID,
                          groupSetID: selectedSubModal,
                          value,
                        })
                      }
                    />
                  )}
                </>
              )}
            </ModalBody>
          )}
          <ModalFooterActions
            leftComponent={
              <>
                <UserGroupIcon
                  boxSize={6}
                  mr={2}
                  color={isValid ? 'gray.500' : 'gray.300'}
                />
                <Text color={isValid ? 'gray.700' : 'gray.400'}>
                  {checkedSummaryText}
                </Text>
              </>
            }
            primaryButton={{
              onClick: selectedSubModal
                ? () => setSelectedSubModal(undefined)
                : onSubmit,
              isDisabled: !selectedSubModal && !isValid,
              tooltip: !isValid
                ? 'Please select at least one customer or group'
                : null,
            }}
            secondaryButton={{ onClick: onCancel }}
            shadow={selectedSubModal === 'customers'}
          />
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default SelectCustomersModal;
