import { useHistory } from 'react-router-dom';
import { Box, HStack, Heading, Text } from '@chakra-ui/react';
import bgImage from '../../images/auth-guest-background.svg';

import { useAuth } from '../../contexts/auth';
import {
  HospoConnect,
  AppStore,
  GooglePlay,
} from '../../components/Logos/Logos';

const NoMobilePage = (): JSX.Element => {
  const { user } = useAuth();
  let history = useHistory();

  if (user) {
    history.push('/orders');
  }

  return (
    <Box
      bg="green.700"
      backgroundSize="100% auto"
      backgroundImage={bgImage}
      minH="100vh"
      py="6"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="343px" mx="auto">
        <HospoConnect mx="auto" w="208px" mb={{ base: '1', md: '4' }} />
        <Box
          bg="white"
          py="8"
          px={{ base: '4', md: '10' }}
          shadow="base"
          rounded={{ sm: 'lg' }}
        >
          <Heading size="lg" fontWeight="700">
            This screen is too small!
          </Heading>
          <Text mt="4" mb="8" maxW="md">
            Please log in on a computer or tablet, or download the mobile app to
            use HospoConnect on your phone.
          </Text>

          <HStack>
            <Box
              as="a"
              float="left"
              w="50%"
              href="https://apps.apple.com/nz/app/hospo-connect/id1151225706"
            >
              <AppStore h={{ base: '35', xs: '42' }} m="0 auto" />
              <Box srOnly>Download from App Store</Box>
            </Box>
            <Box
              as="a"
              float="right"
              w="50%"
              href="https://play.google.com/store/apps/details?id=com.hospoconnect.app"
            >
              <GooglePlay h={{ base: '35', xs: '42' }} m="0 auto" />
              <Box srOnly>Download from Google Play</Box>
            </Box>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

export default NoMobilePage;
