import { Avatar, Box, HStack, Spacer, Text } from '@chakra-ui/react';
import DateText from '../../../../components/Orders/DateText';
import Tag from '../../../../components/Tag/Tag';
import { Body } from '../../../../components/Typography/Typography';
import ObjectLink from '../../../../components/ObjectLink/ObjectLink';
import { useCurrentUser } from '../../../../hooks/useStores';

type OrderDetailTitleRowProps = {
  sentDirection?: 'incoming' | 'outgoing';
  order?: any;
};

export const OrderDetailTitleRow = ({
  sentDirection,
  order,
}: OrderDetailTitleRowProps) => {
  const isOutgoing = sentDirection === 'outgoing';
  const { isBuyer } = useCurrentUser();
  const company = isOutgoing ? order.supplier : order.customer_company;

  return (
    <HStack width="100%" pt="1" mb="6">
      <HStack>
        <Box
          width={{ base: '40px', '2xl': '72px' }}
          mr={{ base: '8px', '2xl': '24px' }}
        >
          <Avatar
            h={{ base: '40px', '2xl': '72px' }}
            w={{ base: '40px', '2xl': '72px' }}
            src={company.logo_url}
          />
        </Box>

        <Box mt="4px">
          <HStack>
            <Text as="h2" fontSize="lg" fontWeight="600">
              {company.name}
            </Text>
            {!isBuyer && (
              <ObjectLink
                name={company.name}
                href={`/customers/${company.id}`}
              />
            )}
          </HStack>
          <Body color="gray.500">
            {order.source === 'standing_order' ? (
              <Tag>Standing</Tag>
            ) : order.source === 'supplier_created' ? (
              <>
                <Tag
                  tooltip={`Added on behalf of customer by ${order.customer.fullname}`}
                >
                  Added
                </Tag>
                {' by '}
                {order.customer.fullname}
              </>
            ) : (
              <>
                <Text as="b" fontWeight="semibold">
                  Sent by
                </Text>
                : {order.customer.fullname}
              </>
            )}
          </Body>
        </Box>
      </HStack>
      <Spacer />
      <Box>
        <Text fontSize="sm" textAlign="right" color="gray.600">
          Order #{order.order_number}
        </Text>
        {order.purchase_order_number && (
          <Text fontSize="sm" textAlign="right" color="gray.600">
            PO: {order.purchase_order_number}
          </Text>
        )}
        <DateText
          fontSize="sm"
          textAlign="right"
          color="gray.600"
          date={order.date}
        />
      </Box>
    </HStack>
  );
};
