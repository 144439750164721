import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import { useRecipes } from '../../../hooks/useStores';
import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import EditRecipe from './EditRecipe';
import ShowRecipe from './ShowRecipe';
import Recipe from '../../../models/Recipe';

const RecipeDetail = ({
  recipeId = null,
}: {
  recipeId: string | null;
}): JSX.Element => {
  const { currentRecipe, getRecipe, setCurrentRecipe } = useRecipes();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (recipeId) {
      getRecipe(recipeId).then(() => {
        setLoading(false);
      });
    } else {
      setCurrentRecipe(undefined);
      setLoading(false);
    }
  }, [recipeId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/recipes/new">{!currentRecipe && <EditRecipe />}</Route>
      <Route path="/recipes/:recipeId/edit">
        {currentRecipe && <EditRecipe recipe={currentRecipe} />}
      </Route>
      <Route path="/recipes/:recipeId">
        {currentRecipe && <ShowRecipe recipe={currentRecipe} />}
      </Route>
      <Route path="/recipes">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(RecipeDetail);
