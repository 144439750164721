import { Flex, FlexProps } from '@chakra-ui/react';

function OneUpScrollPanel({ children, ...rest }: FlexProps) {
  return (
    <Flex flexDirection="column" flex="1" overflowY="auto" {...rest}>
      <Flex height="100vh" flexDirection="column" bg="white">
        {children}
      </Flex>
    </Flex>
  );
}

export default OneUpScrollPanel;
