import accredoLogo from '../images/accredo.png';
import cin7logo from '../images/cin7-core-logo.jpg';

type AccountingMeta = {
  accountingName: string;
  accountingLogo: string;
  syncedObjects: SyncableObject[];
  syncedProductFields: string[];
  syncedCustomerFields: string[];
};

type SyncableObject = 'products' | 'customers' | 'pricing_tiers';
const accountingMetae: Record<string, AccountingMeta> = {
  cin7_core: {
    accountingName: 'Cin7 Core',
    accountingLogo: cin7logo,
    syncedObjects: ['products', 'customers', 'pricing_tiers'],
    syncedProductFields: [
      'name',
      'product_code',
      'category_id',
      'unit_id',
      'units',
      'price',
      'pricing_unit_id',
    ],
    syncedCustomerFields: ['pricing_tier'],
  },
  accredo: {
    accountingName: 'Accredo',
    accountingLogo: accredoLogo,
    syncedObjects: ['products'],
    syncedProductFields: [
      'name',
      'description',
      'category_id',
      'unit_id',
      'units',
      'product_code',
      'price',
      'pricing_unit_id',
    ],
    syncedCustomerFields: [],
  },
};

const emptyMeta: AccountingMeta = {
  accountingName: 'NO_NAME',
  accountingLogo: 'NO_LOGO',
  syncedObjects: [],
  syncedProductFields: [],
  syncedCustomerFields: [],
};

const useAccountingMeta = (connectorType: string) => {
  const {
    accountingName,
    accountingLogo,
    syncedObjects,
    syncedProductFields,
    syncedCustomerFields,
  } = accountingMetae[connectorType] || emptyMeta;

  return {
    accountingName,
    accountingLogo,
    syncedObjects,
    syncedProductFields,
    syncedCustomerFields,
  } as const;
};

export default useAccountingMeta;
