import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Divider,
  Tooltip,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
} from '@chakra-ui/react';

import { useOrders } from '../../../../hooks/useStores';
import { useSuccessToast } from '../../../../components/toast';
import Banner from '../../../../components/Banner/Banner';
import {
  CaretDownIcon,
  EditBoxIcon,
  RubbishIcon,
} from '../../../../components/Icons/IconsNew';
import DetailView from '../../../../components/DetailView/DetailView';
import OrdersPrintAction from '../../OrdersPrintAction';
import OrdersDownloadAction from '../../OrdersDownloadAction';
import OrderExportInvoiceAction from '../OrderExportInvoiceAction';
import OrderDeleteAction from '../OrderDeleteAction';
import StatusDot from '../../../../components/Orders/StatusDot';
import { capitalizeString } from '../../../../utils';
import { OrderDetailTitleRow } from './OrderDetailTitleRow';
import OrderDetailFields from './OrderDetailFields';

export const ShowIncomingOrder = ({ order, extraActions }: any) => {
  const { setCurrentOrder, setOrderStatus } = useOrders();
  let history = useHistory();
  const successToast = useSuccessToast();

  const deletedStyles = order.isDeleted
    ? {
        opacity: 0.4,
        filter: 'grayscale(100%)',
      }
    : {};

  function handleChangeStatus(status: string) {
    if (status !== order.status) {
      setCurrentOrder({
        status: status,
        ...order,
      });
      setOrderStatus(order.id, status, () => {
        successToast({
          description: `Order status updated to ${capitalizeString(status)}.`,
        });
      });
    }
  }

  const deletedProps = order.isDeleted
    ? {
        disabled: true,
        opacity: 0.4,
        filter: 'grayscale(100%)',
      }
    : {};

  return (
    <DetailView
      leftActions={
        <HStack>
          {extraActions}
          <Menu>
            <MenuButton
              bg="gray.100"
              px="2"
              height="32px"
              fontSize="sm"
              fontWeight="500"
              borderRadius="5px"
              {...deletedProps}
            >
              <StatusDot mr="2" status={order.status} />
              {capitalizeString(order.status)}
              <CaretDownIcon
                width="12px"
                display="inline-block"
                mt="-2px"
                ml="6px"
              />
            </MenuButton>
            <MenuList>
              <MenuItem fontSize="sm" onClick={() => handleChangeStatus('new')}>
                <StatusDot mr="2" status={'new'} />
                New
              </MenuItem>
              <MenuItem
                fontSize="sm"
                onClick={() => handleChangeStatus('processing')}
              >
                <StatusDot mr="2" status={'processing'} />
                Processing
              </MenuItem>
              <MenuItem
                fontSize="sm"
                onClick={() => handleChangeStatus('invoiced')}
              >
                <StatusDot mr="2" status={'invoiced'} />
                Invoiced
              </MenuItem>
              <MenuItem
                fontSize="sm"
                onClick={() => handleChangeStatus('complete')}
              >
                <StatusDot mr="2" status={'complete'} />
                Complete
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      }
      rightActions={
        !order.isDeleted && (
          <>
            <OrdersPrintAction
              orderIds={[order.id]}
              tooltipText={'Print order'}
              buttonText={'Print'}
              useIconButton={false}
            />
            <OrdersDownloadAction
              orderIds={[order.id]}
              tooltipText={'Download order'}
              buttonText={'Download'}
              useIconButton={false}
            />

            <OrderExportInvoiceAction />
            <Divider
              orientation="vertical"
              height="24px"
              borderColor="gray.300"
              border="1px"
            />
            <OrderDeleteAction />
            <Tooltip hasArrow label="Edit order">
              <IconButton
                aria-label="Edit"
                onClick={() => {
                  history.push(`/orders/${order.id}/edit`);
                }}
                icon={<EditBoxIcon width="24px" height="24px" />}
              >
                Edit
              </IconButton>
            </Tooltip>
          </>
        )
      }
    >
      <Box mt="8">
        {order.isDeleted && (
          <Banner
            icon={<RubbishIcon width="24px" />}
            title="This order has been deleted"
            description="Someone else in your organisation is also working in this inbox, they've just deleted this order."
            mt="16px"
          />
        )}
        <OrderDetailTitleRow order={order} />
        <Box height="100%" maxWidth="758px">
          {order.isDeleted && (
            <Banner
              icon={<RubbishIcon width="24px" />}
              title="This order has been deleted"
              description="Someone else in your organisation is also working in this inbox, they've just deleted this order."
              mt="16px"
            />
          )}
          <Box pb="10" {...deletedStyles}>
            <OrderDetailFields order={order} />
          </Box>
        </Box>
      </Box>
    </DetailView>
  );
};

export default observer(ShowIncomingOrder);
