import { Image, Link, Button } from '@chakra-ui/react';
import Banner from '../../../components/Banner/Banner';
import useAPI from '../../../hooks/useAPI';
import useJobStatus from '../../../hooks/useJobStatus';
import { useSuccessToast } from '../../../components/toast';
import { useProducts } from '../../../hooks/useStores';
import useAccountingMeta from '../../../hooks/useAccountingMeta';

const AccountingProductBanner = ({
  product,
  allowManualSync = true,
}: {
  product: any;
  allowManualSync?: boolean;
}): JSX.Element => {
  const { accountingName, accountingLogo } = useAccountingMeta(
    product?.accounting_product?.connector_type,
  );
  const { getProduct } = useProducts();
  const successToast = useSuccessToast();
  const [setJobId, isJobProcessing] = useJobStatus(1500, () => {
    getProduct(product.id, () => {
      successToast({
        title: 'Sync complete',
        description: `${product.name} has been freshly synced.`,
      });
    });
  });
  const [syncNowRequest, syncNowIsLoading] = useAPI({
    method: 'PUT',
  });

  if (
    product.accounting_product &&
    product.accounting_product.syncing_enabled
  ) {
    const syncNow = () => {
      syncNowRequest('/v4/accounting/products/sync_now', {
        body: JSON.stringify({
          accounting_product_ids: [product.accounting_product.id],
        }),
      })
        .then((data: any) => {
          setJobId(data.jid);
        })
        .catch(() => {});
    };

    return (
      <>
        <Banner
          icon={<Image src={accountingLogo} w="64px" pr="1" />}
          title={`Connected to ${accountingName}`}
          description={`This product is synced to a product in ${accountingName}. Synced fields are uneditable.`}
          variant="outline"
          status="info"
          bg="white"
          actions={
            allowManualSync
              ? [
                  <Button
                    as={Link}
                    variant="tertiary"
                    onClick={syncNow}
                    isLoading={syncNowIsLoading || isJobProcessing}
                    textDecoration="none"
                  >
                    Sync now
                  </Button>,
                ]
              : []
          }
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default AccountingProductBanner;
