import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import {
  Box,
  Input,
  Text,
  VStack,
  HStack,
  Avatar,
  Center,
  Spinner,
  Link,
  ModalContent,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputRightElement,
  ModalCloseButton,
} from '@chakra-ui/react';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';

import useAPI from '../../hooks/useAPI';
import { useCurrentUser } from '../../hooks/useStores';
import { useScroll } from '../../hooks/useScroll';
import { CaretRightIcon } from '../../components/Icons/Icons';
import { SnapshotOrInstance } from 'mobx-state-tree';
import Customer from '../../models/Customer';
import Supplier from '../../models/Supplier';

type SelectCompanyScreenProps = {
  onStartNewCustomer?: () => void;
  onCompanySelect: (company: any) => void;
  extraRequestParams?: any;
  headerText: string;
};

const SelectCompanyScreen = ({
  onStartNewCustomer,
  onCompanySelect,
  extraRequestParams,
  headerText,
}: SelectCompanyScreenProps) => {
  const limit = 20;
  const [page, setPage] = useState(1);
  const { isBuyer } = useCurrentUser();
  const [currentQueryFieldValue, setCurrentQueryFieldValue] = useState('');
  const [currentQuery, setCurrentQuery] = useState('');
  const [resultCount, setResultCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const scrollRef = useScroll<HTMLDivElement>(() => {
    if (page * limit < resultCount) {
      setPage(page + 1);
    }
  });

  const endpoint = isBuyer ? 'suppliers' : 'customers';
  const searchPlaceholder = `Search ${endpoint}`;

  const [callApi] = useAPI({
    method: 'GET',
  });

  const [companies, setCompanies] = useState<
    SnapshotOrInstance<typeof Supplier | typeof Customer>[]
  >([]);

  const clearResults = () => {
    setCompanies([]);
    setPage(1);
  };

  const clearFilters = () => {
    clearResults();
    setCurrentQueryFieldValue('');
    setCurrentQuery('');
  };

  const handleSearch = useCallback(
    debounce((value: string) => {
      clearResults();
      setCurrentQuery(value);
    }, 400),
    [],
  );

  useEffect(() => {
    const extraParams = extraRequestParams
      ? `&${new URLSearchParams(extraRequestParams).toString()}`
      : '';
    const url = `/v4/${endpoint}?page=${page}&limit=${limit}${
      currentQuery && '&q=' + currentQuery
    }${extraParams}`;
    setIsLoading(true);

    callApi(url).then((data: any) => {
      setResultCount(data.total_count);
      setCompanies(companies.concat(data.results));
      setIsLoading(false);
    });
  }, [page, currentQuery]);

  return (
    <ModalContent>
      <ModalHeader
        px={0}
        pb={0}
        bg="gray.100"
        borderTopRadius="var(--chakra-radii-md)"
        boxShadow="inset 0px -1px 0px #D1D5DB"
      >
        <Text px={6} mt={4} mb={6}>
          {headerText}
        </Text>
        <ModalCloseButton top={4} right={4} />
        <Box py={4} px={6}>
          <InputGroup>
            <Input
              variant="outline"
              type="search"
              onChange={(e) => {
                setCurrentQueryFieldValue(e.target.value);
                handleSearch(e.target.value);
              }}
              value={currentQueryFieldValue}
              placeholder={searchPlaceholder}
              name="search"
              bg="white"
              mb={3}
            />
            <InputRightElement
              pointerEvents="none"
              color="gray.500"
              children={<SearchIcon />}
            />
          </InputGroup>
        </Box>
      </ModalHeader>
      <ModalBody p={0}>
        {companies.length > 0 ? (
          <Box ref={scrollRef} maxHeight="500px" overflowY="scroll">
            {onStartNewCustomer && (
              <NewCustomerRow onClick={onStartNewCustomer} />
            )}
            {companies.map((company: any) => {
              return (
                <CompanyRow
                  key={company.id}
                  company={company}
                  onClick={onCompanySelect}
                />
              );
            })}
          </Box>
        ) : !isLoading && currentQuery === '' && onStartNewCustomer ? (
          <NewCustomerRow onClick={onStartNewCustomer} />
        ) : (
          !isLoading &&
          resultCount === 0 && (
            <VStack px={6} py={4} spacing="4" alignItems="left">
              <Text fontSize="lg" fontWeight="500">
                No {isBuyer ? 'suppliers' : 'customers'} found
              </Text>
              <Link fontWeight="400" onClick={clearFilters}>
                Clear search
              </Link>
            </VStack>
          )
        )}
        {isLoading && (
          <Center height="100px">
            <Spinner thickness="6px" size="xl" color="green" />
          </Center>
        )}
      </ModalBody>
    </ModalContent>
  );
};

const CompanyRow = ({
  company,
  onClick,
}: {
  company: SnapshotOrInstance<typeof Supplier | typeof Customer>;
  onClick: (
    company: SnapshotOrInstance<typeof Supplier | typeof Customer>,
  ) => void;
}) => (
  <HStack
    cursor="pointer"
    justify="space-between"
    boxShadow="inset 0px -1px 0px #E5E7EB"
    px={6}
    py={3}
    _hover={{
      bg: 'gray.50',
    }}
    onClick={() => {
      onClick(company);
    }}
  >
    <HStack>
      <Avatar size="lg" src={company.logo_url || ''} />
      <Text fontWeight="500" fontSize="sm" color="gray.900">
        {company.name}
      </Text>
    </HStack>
    <CaretRightIcon />
  </HStack>
);

const NewCustomerRow = ({ onClick }: { onClick: () => void }) => (
  <HStack
    cursor="pointer"
    justify="space-between"
    boxShadow="inset 0px -1px 0px #E5E7EB"
    px={6}
    py={3}
    _hover={{
      bg: 'gray.50',
    }}
    onClick={onClick}
  >
    <HStack>
      <Avatar
        size="lg"
        bg="transparent"
        borderColor="gray.300"
        color="gray.300"
        showBorder={true}
        icon={<AddIcon />}
      />
      <Text fontWeight="500" fontSize="sm" color="gray.900">
        Someone new
      </Text>
    </HStack>
    <CaretRightIcon />
  </HStack>
);

export default observer(SelectCompanyScreen);
