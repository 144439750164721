import { useHistory } from 'react-router';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  VStack,
  useDisclosure,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';

import { useSourceCategories } from '../../../hooks/useStores';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import { H4, Label } from '../../../components/Typography/Typography';
import { EditBoxIcon, RubbishIcon } from '../../../components/Icons/IconsNew';
import DetailView from '../../../components/DetailView/DetailView';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';

const ShowSourceCategory = ({ sourceCategory }: any) => {
  const { deleteSourceCategory } = useSourceCategories();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  const onConfirm = () => {
    deleteSourceCategory(sourceCategory).then(() => {
      history.push('/source-categories');
    });
  };

  return (
    <DetailView
      leftActions={<H4 my="3">{sourceCategory.name}</H4>}
      rightActions={
        <>
          <Tooltip label="Delete source category">
            <Button onClick={onOpen} leftIcon={<RubbishIcon width="24px" />}>
              Delete
            </Button>
          </Tooltip>

          <Tooltip label="Edit source category">
            <Button
              onClick={() => {
                history.push(`/source-categories/${sourceCategory.id}/edit`);
              }}
              leftIcon={<EditBoxIcon width="24px" />}
            >
              Edit
            </Button>
          </Tooltip>

          <ConfirmationDialog
            isOpen={isOpen}
            onCancel={() => {
              onClose();
            }}
            titleText={'Are you sure you want to delete this source category?'}
            confirmButtonText={'Delete this source category'}
            onConfirm={onConfirm}
          />
        </>
      }
    >
      <form style={{ backgroundColor: 'white', marginBottom: '80px' }}>
        <VStack width="full" align="left" spacing="8" mt="10">
          <FormFieldWrapper fieldName="name" fieldLabel={<Label>Name</Label>}>
            <Text>{sourceCategory.name && sourceCategory.name}</Text>
          </FormFieldWrapper>
          <FormFieldWrapper
            fieldName="amountsRequired"
            fieldLabel={<Label>Item size</Label>}
          >
            <Text>{sourceCategory.amountsRequired ? 'Yes' : 'No'}</Text>
          </FormFieldWrapper>

          <FormFieldWrapper fieldName="unit" fieldLabel={<Label>Unit</Label>}>
            <Text>
              {Boolean(sourceCategory.unit) ? sourceCategory.unit : '-'}
            </Text>
          </FormFieldWrapper>

          {sourceCategory.products.length > 0 ? (
            <Table variant="orderitems" width="100%" size="sm">
              <Thead>
                <Tr>
                  <Th>Product</Th>
                  <Th>Code</Th>
                  {sourceCategory.amountsRequired && <Th>Item Size</Th>}
                </Tr>
              </Thead>
              <Tbody>
                {sourceCategory.products.map((scp: any) => (
                  <Tr key={`table-row-${scp.product.id}`}>
                    <Td>{scp.product.name}</Td>
                    <Td>{scp.product.product_code}</Td>

                    {sourceCategory.amountsRequired && (
                      <Td>
                        {scp.amount} {sourceCategory.unit}
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <FormFieldWrapper
              fieldName="products"
              fieldLabel={<Label>Products</Label>}
            >
              <Button
                variant="secondary"
                colorScheme="green"
                onClick={() => {
                  history.push(`/source-categories/${sourceCategory.id}/edit`);
                }}
              >
                Add products
              </Button>
            </FormFieldWrapper>
          )}
        </VStack>
      </form>
    </DetailView>
  );
};

export default observer(ShowSourceCategory);
