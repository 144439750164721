import { types } from 'mobx-state-tree';

const UnitSetting = types
  .model('UnitSetting', {
    id: types.maybeNull(types.number),
    min_order_quantity: types.maybeNull(types.string), // But it should be a Decimal ie. "5.0"
    unit_id: types.number
  }).views((self) => ({
    get formState() {
      return {
        id: self.id,
        unit_id: self.unit_id,
        min_order_quantity: self.min_order_quantity,
      };
    }
  })).actions((self) => ({
    setMinOrderQuantity(newValue: string) {
      self.min_order_quantity = newValue;
    },
  }));

export default UnitSetting;
