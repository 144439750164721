import { useEffect, useState } from 'react';
import AppWrapper from '../../../../components/Layouts/AppWrapper';

import {
  Box,
  Button,
  ButtonGroup,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
} from '@chakra-ui/react';
import OneUpWrapper from '../../../../components/Layouts/OneUpWrapper';
import ActionBar from '../../../../components/ActionBar/ActionBar';
import OneUpScrollPanel from '../../../../components/Layouts/OneUpScrollPanel';
import useAPI from '../../../../hooks/useAPI';
import { useHistory } from 'react-router-dom';
import { CaretLeftIcon } from '../../../../components/Icons/Icons';
import { H3, Subhead } from '../../../../components/Typography/Typography';

const SyncErrorsPage = (): JSX.Element => {
  const history = useHistory();
  const [syncErrors, setSyncErrors] = useState<any | null>(null);
  const [getSyncErrorsRequest, getSyncErrorsRequestIsLoading] = useAPI({
    method: 'GET',
  });

  useEffect(() => {
    getSyncErrorsRequest('/v4/accounting/products/sync_errors').then(
      (data: any) => {
        setSyncErrors(data);
      },
    );
  }, []);

  return (
    <AppWrapper>
      <OneUpWrapper headerChildren={<ActionBar title="Integrations" />}>
        <OneUpScrollPanel>
          <Box p="40px" bg="white">
            <VStack alignItems="left" spacing="6">
              <ButtonGroup>
                <Button
                  aria-label="Back"
                  fontWeight={500}
                  width="auto"
                  leftIcon={<CaretLeftIcon boxSize={5} />}
                  onClick={() => {
                    history.push('/settings/integrations');
                  }}
                >
                  Back
                </Button>
              </ButtonGroup>

              <VStack alignItems="left" pl="6" spacing="4">
                <H3 as="h3">Product Sync Errors</H3>

                <Subhead maxW="800px">
                  The following products were unable to be synced. If you want a
                  product listed here to be synced, then fix the errors, and
                  they should sync successfully on the next sync cycle. If you
                  see a product listed here that you don't want imported, then
                  just ignore it.
                </Subhead>

                {getSyncErrorsRequestIsLoading && (
                  <Spinner size="xl" thickness="6px" color="green" />
                )}
              </VStack>

              {!getSyncErrorsRequestIsLoading && syncErrors && (
                <Table>
                  <Thead>
                    <Tr>
                      <Th width="150px">Product code</Th>
                      <Th>Product name</Th>
                      <Th>Reason(s)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {syncErrors.map((syncError: any) => (
                      <Tr>
                        <Td fontWeight={'bold'}>{syncError.product_code}</Td>
                        <Td>{syncError.name}</Td>
                        <Td color="red">{syncError.sync_error}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </VStack>
          </Box>
        </OneUpScrollPanel>
      </OneUpWrapper>
    </AppWrapper>
  );
};

export default SyncErrorsPage;
