import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Link } from '@chakra-ui/react';
import Banner from '../../components/Banner/Banner';
import {
  InformationIcon,
  ExternalLinkIcon,
} from '../../components/Icons/IconsNew';

const PricingTiersBanner = (): JSX.Element => {
  const [showBanner, setShowBanner] = useState(
    localStorage.getItem('hidePricingTiersBanner') !== 'true',
  );
  const onDismiss = () => {
    localStorage.setItem('hidePricingTiersBanner', 'true');
    setShowBanner(false);
  };
  if (showBanner) {
    return (
      <Banner
        icon={<InformationIcon width="24px" />}
        title="Show different prices to different customers"
        description="Assign customers to pricing tiers, then set custom prices for these tiers in product settings. "
        onDismiss={onDismiss}
        variant="outline"
        status="info"
        bg="white"
        mx="4"
        mb="4"
        actions={[
          <Button
            as={Link}
            variant="tertiary"
            rightIcon={<ExternalLinkIcon width="18px" />}
            href="https://www.hospoconnect.co.nz/help/pricing-tiers"
            textDecoration="none"
            isExternal
          >
            Learn more
          </Button>,
        ]}
      />
    );
  } else {
    return <></>;
  }
};

export default observer(PricingTiersBanner);
