import { ReactNode } from 'react';
import { Card, HStack, Image, Text, Spacer } from '@chakra-ui/react';

const IntegrationListItemWrapper = (props: {
  logoImageUrl: string;
  platformName: string;
  children: ReactNode;
}) => {
  return (
    <Card
      maxW={{ base: '566px', '2xl': '720px' }}
      p="6"
      borderRadius={24}
      boxShadow="0 1px 2px 2px var(--chakra-colors-gray-200)" // To look like it did previously. For some reason the boxShadow changed without changing the props of the Card component
    >
      <HStack spacing="4">
        <Image src={props.logoImageUrl} width="64px"></Image>
        <Text fontWeight="600">{props.platformName}</Text>
        <Spacer />
        {props.children}
      </HStack>
    </Card>
  );
};

export default IntegrationListItemWrapper;
