import { Td, TableCellProps } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { isUndefined } from 'lodash';

import { useOrders } from '../../../hooks/useStores';

interface DataGridCell extends TableCellProps {
  id: string;
}

const DataGridCell = observer(({ id, children, ...rest }: DataGridCell) => {
  const { columnPreferences } = useOrders();
  const columnPreference = !isUndefined(columnPreferences)
    ? columnPreferences[id]
    : undefined;
  const isHidden = columnPreference?.isHidden;
  if (isHidden) return <></>;
  return <Td {...rest}>{children}</Td>;
});

export default DataGridCell;
