import { Box, Flex } from '@chakra-ui/react';
import { isString } from 'lodash';
import { ReactNode } from 'react';
import { H3, Subhead } from '../Typography/Typography';

interface ActionBarProps {
  title: ReactNode;
  subTitle?: ReactNode;
  rightChildren?: ReactNode;
  children?: ReactNode;
}

const ActionBar = ({
  title,
  subTitle,
  children,
  rightChildren,
}: ActionBarProps) => {
  return (
    <Box
      width="100%"
      h="full"
      bg="background"
      pt="44px"
      pl="24px"
      pr="16px"
      pb={children ? '16px' : '24px'}
    >
      <Flex
        justifyContent="space-between"
        direction={{ base: 'column', sm: 'row' }}
      >
        <Box>
          {isString(title) ? <H3>{title}</H3> : title}
          {isString(subTitle) ? (
            <Subhead mt="8px">{subTitle}</Subhead>
          ) : (
            subTitle
          )}
        </Box>
        {rightChildren}
      </Flex>
      {children && <Box mt="24px">{children}</Box>}
    </Box>
  );
};

export default ActionBar;
