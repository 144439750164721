import React from 'react';
import { useAuth } from '../../contexts/auth';
import { useHistory, useLocation } from 'react-router-dom';

import AuthWrapper from '../../components/Layouts/AuthWrapper';
import ResetPasswordForm from './ResetPasswordForm';

import { Heading } from '@chakra-ui/react';

const ResetPasswordPage = (): JSX.Element => {
  const { user } = useAuth();
  let history = useHistory();
  let urlParams = new URLSearchParams(useLocation().search);
  const token = urlParams.get('t');

  if (user) {
    history.push('/orders');
  }

  return (
    <AuthWrapper
      innerChildren={
        <>
          <Heading size="lg" mb="8" fontWeight="700">
            Choose new password
          </Heading>
          <ResetPasswordForm token={token} />
        </>
      }
    />
  );
};

export default ResetPasswordPage;
