import {
  applySnapshot,
  types,
} from 'mobx-state-tree';
import { api } from '../api';
import * as Sentry from '@sentry/browser';

export type AddressComponents = {
  unit?: string | null;
  street?: string | null;
  suburb?: string | null;
  city?: string | null;
  post_code?: string | null;
  lat?: string | null;
  lng?: string | null;
  place_id?: string | null;
};

export type AddressFieldComponents = {
  id?: number | null;
  company_id?: number | null;
  full_address?: string | null;
  instructions?: string | null;
  is_default?: boolean | null;
} & AddressComponents;

const Address = types
  .model('Address', {
    id: types.maybeNull(types.identifierNumber),
    company_id: types.maybeNull(types.integer),
    full_address: types.optional(types.string, ''),
    instructions: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    post_code: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    suburb: types.maybeNull(types.string),
    unit: types.maybeNull(types.string),
    place_id: types.maybeNull(types.string),
    lat: types.maybeNull(types.string),
    lng: types.maybeNull(types.string),
    is_default: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get editView(): AddressFieldComponents {
      return {
        id: self.id,
        full_address: self.full_address,
        street: self.street,
        unit: self.unit,
        suburb: self.suburb,
        city: self.city,
        post_code: self.post_code,
        lat: self.lat,
        lng: self.lng,
        place_id: self.place_id,
        instructions: self.instructions,
        is_default: self.is_default,
      };
    },
  }))
  .actions((self) => ({
    async updateAddress(address: AddressFieldComponents) {
      const { id, company_id, ...addressdata } = address;
      return api
        .put(
          `/v4/addresses/${self.id}`,
          JSON.stringify({
            ...addressdata,
          }),
        )
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json();
            applySnapshot(self, data);
            return data;
          } else {
            throw Error(response.statusText);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          throw error;
        });
    },
    deleteAddress() {
      return api
        .delete(`/v4/addresses/${self.id}`)
        .then(async (response) => {
          if (response.ok) {
            return true;
          } else {
            throw Error(response.statusText);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error);
          return false;
        });
    },
  }));

export default Address;
