import { useHistory, useLocation } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/react';
import { Button } from '../../components/Button/Button';

import { useAuth } from '../../contexts/auth';
import { HospoConnect } from '../../components/Logos/Logos';
import bgImage from '../../images/auth-guest-background.svg';

const RegisterCtaPage = (): JSX.Element => {
  const { user } = useAuth();
  let history = useHistory();

  let queryParams = new URLSearchParams(useLocation().search);

  if (user) {
    history.push('/orders');
  }

  return (
    <Box
      bg="green.700"
      backgroundSize="100% auto"
      backgroundImage={bgImage}
      minH="100vh"
      py="6"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="343px" mx="auto">
        <HospoConnect mx="auto" w="208px" mb={{ base: '1', md: '4' }} />
        <Box
          bg="white"
          py="6"
          px={{ base: '4', md: '10' }}
          shadow="base"
          rounded={{ sm: 'lg' }}
        >
          <Text mt="4" mb="8" maxW="md">
            With HospoConnect, you can place orders for{' '}
            <strong>{queryParams.get('supplierName')}</strong> quickly and
            easily from your mobile or laptop.
          </Text>

          <Button
            onClick={() =>
              (window.location.href = 'https://hospoconnect.co.nz/register')
            }
            width="100%"
            variant="primary"
          >
            Register now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterCtaPage;
