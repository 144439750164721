import { useEffect } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import {
  FormControl,
  FormLabel,
  HStack,
  Link,
  Text,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { Caption } from '../../../components/Typography/Typography';
import DeliveryRulesDataFields from '../../../components/DeliveryRules/DeliveryRulesDataFields';
import { useDeliveryRules } from '../../../hooks/useStores';

const DeliveryRulesForm = ({
  group,
  isEditable = true,
}: {
  group: any;
  isEditable?: boolean;
}) => {
  const { deliveryRules, getDeliveryRules } = useDeliveryRules();

  useEffect(() => {
    if (isEmpty(deliveryRules)) {
      getDeliveryRules();
    }
  }, []);

  if (!group) {
    return <></>;
  }

  const onToggleEnabled = (e: any) => {
    if (!e.target.checked) {
      group.clearDeliveryRules();
    } else {
      if (isEmpty(deliveryRules)) {
        group.populateDefaultDeliveryRules();
      } else {
        group.setDeliveryRules(toJS(deliveryRules));
      }
    }

    group.setDeliveryRulesEnabled(e.target.checked);
  };

  return (
    <>
      <VStack align="left" spacing="4" w="600px">
        {isEditable ? (
          <HStack>
            <FormControl
              display="flex"
              h="29px"
              w="auto"
              flexDirection="row"
              alignItems="center"
              bg="gray.100"
              borderRadius="20px"
              p="4px 6px 4px 12px"
            >
              <FormLabel
                htmlFor="delivery-rules-enabled"
                fontWeight="500"
                m="0"
                mr="8px"
                fontSize="13px"
                cursor="pointer"
              >
                {group.delivery_rules_enabled
                  ? 'Override defaults'
                  : 'Use defaults'}
              </FormLabel>
              <Switch
                isChecked={group.delivery_rules_enabled}
                isDisabled={!isEditable}
                id="delivery-rules-enabled"
                onChange={onToggleEnabled}
              />
            </FormControl>
          </HStack>
        ) : (
          <Text>
            {group.delivery_rules_enabled
              ? 'Override defaults'
              : 'Use defaults'}
          </Text>
        )}

        {group.delivery_rules_enabled && (
          <DeliveryRulesDataFields
            modelWithDeliveryRules={group}
            isEditable={isEditable}
          />
        )}

        {group.delivery_rules_enabled && (
          <VStack align="left" spacing="2">
            <Caption as="p">
              These settings override the default{' '}
              <Link color="gray.900" href="/settings/delivery-days">
                delivery days & times settings
              </Link>{' '}
              for any customer within this group.
            </Caption>
          </VStack>
        )}
      </VStack>
    </>
  );
};

export default observer(DeliveryRulesForm);
