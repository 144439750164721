const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
const GOOGLE_MAPS_API_LIBRARIES: (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[] = ['places'];
const DEFAULT_TOAST_TIMEOUT_MS = 4000;
const DRAG_AND_DROP_TYPES = {
  LIST_ROW: 'DraggableListRow',
  TABLE_ROW: 'DraggableTableRow',
  CUSTOMER_GROUP_CUSTOMER_ROW: 'DraggableCustomerGroupCustomerRow',
  SOURCE_CATEGORY_PRODUCT_ROW: 'DraggableSourceCategoryProductRow',
};
export {
  API_URL,
  GOOGLE_MAPS_API_KEY,
  DEFAULT_TOAST_TIMEOUT_MS,
  DRAG_AND_DROP_TYPES,
  GOOGLE_MAPS_API_LIBRARIES,
};
