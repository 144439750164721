import { useHistory } from 'react-router-dom';
import { ExternalLinkIcon } from '../Icons/IconsNew';
import { IconButton, Tooltip } from '@chakra-ui/react';

type ObjectLinkProps = {
  name: string;
  href: string;
};

const ObjectLink = ({ name, href }: ObjectLinkProps): JSX.Element => {
  const history = useHistory();

  return (
    <Tooltip label={`Go to ${name}`}>
      <IconButton
        aria-label=""
        size="xs"
        colorScheme="blue"
        variant={'ghost'}
        onClick={() => history.push(href)}
        icon={<ExternalLinkIcon width="18px" />}
      ></IconButton>
    </Tooltip>
  );
};

export default ObjectLink;
