const Menu = {
  baseStyle: {
    groupTitle: {
      fontWeight: '400',
      fontSize: '12px',
      textTransform: 'uppercase',
      letterSpacing: '0.12em',
      color: 'gray.600',
    },
    item: {
      pl: '24px',
      color: 'gray.700',
    },
  },
};

export default Menu;
