import { observer } from 'mobx-react';
import {
  DELIVERY_DAYS,
  DELIVERY_TIMES,
} from '../../pages/DeliveryDaysPage/delivery-options'; //TODO Move this?

import {
  Checkbox,
  Select,
  FormControl,
  FormLabel,
  HStack,
  VStack,
} from '@chakra-ui/react';

const DeliveryRulesDataFields = ({
  modelWithDeliveryRules,
  isEditable = true,
}: {
  modelWithDeliveryRules: any;
  isEditable?: boolean;
}) => {
  if (!modelWithDeliveryRules.delivery_rules) {
    return null;
  }

  return (
    <VStack spacing="2" align="left" mt="6">
      {DELIVERY_DAYS.map((day: any, dayIndex: number) => {
        const deliveryRule = modelWithDeliveryRules.delivery_rules[dayIndex];
        return (
          <HStack key={dayIndex}>
            <FormControl width="20%">
              <HStack>
                <Checkbox
                  bg="white"
                  id={`enable-${day}`}
                  isChecked={deliveryRule.enabled}
                  onChange={(e) => {
                    deliveryRule.setEnabled(e.target.checked);
                  }}
                  isDisabled={
                    !isEditable ||
                    !modelWithDeliveryRules.delivery_rules_enabled
                  }
                />
                <FormLabel htmlFor={`enable-${day}`}>{day}</FormLabel>
              </HStack>
            </FormControl>

            <FormControl width="">
              <HStack>
                <Select
                  defaultValue={DELIVERY_TIMES.find(
                    (time: any) => time === deliveryRule.cutoff_time,
                  )}
                  onChange={(e) => {
                    deliveryRule.setCutoffTime(e.target.value);
                  }}
                  isDisabled={
                    !isEditable ||
                    !modelWithDeliveryRules.delivery_rules_enabled
                  }
                >
                  {DELIVERY_TIMES.map((time: string, timeIndex: number) => (
                    <option value={time} key={timeIndex}>
                      {time}
                    </option>
                  ))}
                </Select>
                <span>On</span>

                <Select
                  defaultValue={deliveryRule.cutoff_day}
                  onChange={(e) => {
                    deliveryRule.setCutoffDay(parseInt(e.target.value, 10));
                  }}
                  isDisabled={
                    !isEditable ||
                    !modelWithDeliveryRules.delivery_rules_enabled
                  }
                >
                  {DELIVERY_DAYS.map((day: string, i: number) => (
                    <option key={i} value={i}>
                      {day}
                    </option>
                  ))}
                </Select>
              </HStack>
            </FormControl>
          </HStack>
        );
      })}
    </VStack>
  );
};

export default observer(DeliveryRulesDataFields);
