import { observer } from 'mobx-react';
import { Instance, SnapshotOrInstance } from 'mobx-state-tree';
import { Box, Text, VStack, Center, Spinner, Link } from '@chakra-ui/react';

import Product from '../../models/Product';
import OrderItem from '../../models/OrderItem';
import ProductRow from './ProductRow';
import OrderItemRow from './OrderItemRow';
import Order from '../../models/Order';
import { useRef } from 'react';
import { useScroll } from '../../hooks/useScroll';
import { useCurrentUser } from '../../hooks/useStores';

type ProductsListProps = {
  order: Instance<typeof Order>;
  products: SnapshotOrInstance<typeof Product>[];
  loadMore?: () => void;
  focusRef?: React.RefObject<HTMLInputElement>;
  isLoading?: boolean;
  resultCount?: number;
  clearFilters?: () => void;
};

const ProductsModalList = observer(
  ({
    order,
    products,
    loadMore = () => {},
    isLoading,
    resultCount,
    clearFilters = () => {},
  }: ProductsListProps) => {
    const scrollRef = useScroll<HTMLDivElement>(loadMore);
    const focusRef = useRef<HTMLInputElement>(null);
    const { isBuyer } = useCurrentUser();

    return (
      <>
        {products.length > 0 ? (
          <Box ref={scrollRef} maxHeight="320px" overflowY="scroll">
            {order
              .productsList(products)
              .map(
                (
                  obj:
                    | SnapshotOrInstance<typeof Product>
                    | Instance<typeof OrderItem>,
                ) => {
                  if ('isOrderItem' in obj) {
                    return (
                      <OrderItemRow
                        key={obj.buyable.product.id}
                        orderItem={obj}
                        customerId={order.customer && order.customer.id}
                        remove={order.removeOrderItem}
                        focusRef={focusRef}
                      />
                    );
                  } else {
                    return (
                      <ProductRow
                        key={obj.id}
                        product={obj}
                        isBuyer={isBuyer}
                        customerId={order.customer && order.customer.id}
                        addProduct={(
                          product: SnapshotOrInstance<typeof Product>,
                        ) => {
                          order.addOrderItem(product);
                          // `setTimeout` with a 0ms delay so the focusRef can be set to the most recently added order item
                          setTimeout(() => {
                            if (focusRef?.current) {
                              focusRef.current.focus();
                            }
                          }, 0);
                        }}
                      />
                    );
                  }
                },
              )}
          </Box>
        ) : (
          !isLoading &&
          resultCount == 0 && (
            <VStack px={6} py={4} spacing="4" alignItems="left">
              <Text fontSize="lg" fontWeight="500">
                No products found
              </Text>
              <Link fontWeight="400" onClick={clearFilters}>
                Clear filters
              </Link>
            </VStack>
          )
        )}

        {isLoading && (
          <>
            {products.length == 0 && <Box h="132px"></Box>}
            <Box
              style={{
                position: 'absolute',
                bottom: '91px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Center height="100px">
                <Spinner thickness="6px" size="xl" color="green" />
              </Center>
            </Box>
          </>
        )}
      </>
    );
  },
);

export default ProductsModalList;
