import { useState } from 'react';
import { applySnapshot, clone, getSnapshot, Instance } from 'mobx-state-tree';

/**
 * Stores a clone of the passed `obj` when editing is enabled, applying that clone to the original `obj`
 * when saved, and reverting back to the original object when cancelled.
 *
 * Returns an array of relevant values and functions:
 * - editState - a local reference to the object being edited
 * - isEditing - whether editing is currently enabled
 * - onStartEditing - function to call when editing begins
 * - onSaveEditing - function to call when editing has finished and the changes need to be committed
 * - onCancelEditing - function to call when editing has finished and the changes need to be discarded
 *
 * @param obj - An instance of a mobx-state-tree model
 * @returns [
 *  editState,
 *  isEditing,
 *  onStartEditing,
 *  onSaveEditing,
 *  onCancelEditing
 * ]
 */
function useEditSnapshot<T>(
  obj: Instance<T>,
): [Instance<T>, boolean, () => void, () => void, () => void] {
  const [editState, setEditState] = useState<Instance<T>>(obj);
  const [isEditing, setIsEditing] = useState(false);

  const onStartEditing = () => {
    setEditState(clone(obj));
    setIsEditing(true);
  };

  const onSaveEditing = () => {
    if (editState) {
      applySnapshot(obj, getSnapshot(editState));
    }
    setEditState(obj);
    setIsEditing(false);
  };

  const onCancelEditing = () => {
    setEditState(obj);
    setIsEditing(false);
  };

  return [editState, isEditing, onStartEditing, onSaveEditing, onCancelEditing];
}

export default useEditSnapshot;
