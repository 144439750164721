import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  VStack,
  ButtonGroup,
  ModalCloseButton,
  HStack,
  ModalFooter,
} from '@chakra-ui/react';
import { Body, Body2 } from '../../components/Typography/Typography';
import { TickInCircleIcon } from '../../components/Icons/IconsNew';
import { usePageVisibility } from '../../hooks/usePageVisibility';

interface OrdersOpenDocumentModalProps {
  isOpen: boolean;
  arePopupsBlocked: boolean;
  onClick: () => void;
  onClose: () => void;
}

const OrdersOpenDocumentModal = ({
  isOpen,
  arePopupsBlocked,
  onClick,
  onClose,
}: OrdersOpenDocumentModalProps) => {
  const pageIsVisible = usePageVisibility();
  const [previousVisibleValue, setPreviousVisibleValue] =
    useState(pageIsVisible);
  useEffect(() => {
    if (isOpen && pageIsVisible && !previousVisibleValue) {
      onClose();
    }
    setPreviousVisibleValue(pageIsVisible);
  }, [isOpen, pageIsVisible]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="16px">
        <ModalCloseButton />
        <ModalBody pt="6" px="6" pb="0">
          <VStack spacing="2" align="flex-start">
            <HStack>
              <TickInCircleIcon width="24px" />
              <Body fontWeight="600">Ready to print</Body>
            </HStack>
            {arePopupsBlocked && (
              <Body2 fontWeight="400">
                Too many clicks? Enable pop-ups in your browser settings to skip
                this step next time.
              </Body2>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup colorScheme="green" justifyContent="flex-end">
            <Button variant="primary" onClick={onClick}>
              Open document
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrdersOpenDocumentModal;
