import React from 'react';
import logoImage from '../../../../images/myob.png';

import DisconnectButton from '../DisconnectButton';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';
import MyobSetup from './MyobSetup';
import Settings from '../Settings';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  CrossInCircleIcon,
  TickInCircleIcon,
} from '../../../../components/Icons/Icons';

type MyobConnectionDetailsProps = {
  connection: any;
};

const MyobConnectionDetails = ({
  connection,
}: MyobConnectionDetailsProps): JSX.Element => {
  return (
    <Box bg="white" p="4">
      {connection.connection_status === 'error' && (
        <ErrorMessage
          style={{ gridColumn: '1 / span 14', marginBottom: '16px' }}
          description={`There's a problem with your MYOB connection. Please disconnect and reconnect again.`}
        />
      )}

      <VStack align="left" spacing="4">
        <HStack>
          <Avatar src={logoImage} size="lg"></Avatar>

          <Spacer />

          <Box>
            <VStack spacing="3">
              {connection.connection_status === 'setup_required' && (
                <MyobSetup />
              )}
              {connection.connection_status === 'active' && (
                <>
                  <HStack color="green.600" fontWeight="600">
                    <Text>Connected</Text>
                    <TickInCircleIcon />
                  </HStack>
                  <DisconnectButton
                    platformName={'MYOB'}
                    requiresManualStep={true}
                  />
                </>
              )}
              {connection.connection_status === 'error' && (
                <>
                  <HStack color="red.500" fontWeight="600">
                    <Text>Error</Text>
                    <CrossInCircleIcon />
                  </HStack>
                  <DisconnectButton
                    platformName={'MYOB'}
                    requiresManualStep={true}
                  />
                </>
              )}
            </VStack>
          </Box>
        </HStack>

        {connection.connection_status === 'setup_required' && (
          <Alert status="error" fontSize="sm">
            <AlertIcon />
            <AlertDescription>
              You need to finish setting up your MYOB connection.
            </AlertDescription>
          </Alert>
        )}

        <Settings
          currentSettings={connection.settings}
          accountingConnectionName={connection.label}
        />
      </VStack>
    </Box>
  );
};

export default MyobConnectionDetails;
