import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Caption } from '../Typography/Typography';
import { observer } from 'mobx-react';
import { PencilIcon } from '../Icons/Icons';
import { CrossIcon } from '../Icons/IconsNew';
import NoteEditor from '../NoteEditor/NoteEditor';
import SelectUnit from '../SelectUnit/SelectUnit';
import ProductsListView from '../ProductModalContent/ProductsListView';

const ReviewOrderItemRow = observer(({ orderItem, isEditing, remove }: any) => {
  const {
    buyable: { product },
  } = orderItem;
  orderItem.validate();

  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
  };

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    orderItem.setAmount(Number(e.target.value));
  };

  if (!isEditing && (!orderItem.amount || orderItem.amount === 0)) {
    return <></>;
  }

  return (
    <HStack
      justify="space-between"
      boxShadow="inset 0px -1px 0px #E5E7EB"
      px={6}
      py={3}
    >
      <Box flex={1}>
        <ProductsListView
          product={product}
          actions={
            <VStack>
              <HStack>
                {isEditing ? (
                  <>
                    {product.units.length > 1 ? (
                      <>
                        <Input
                          type="number"
                          min={0}
                          defaultValue={orderItem.amount}
                          onChange={onAmountChange}
                          onWheel={onWheel}
                          isInvalid={!orderItem.isValid}
                          maxW="20"
                          textAlign="right"
                          placeholder="0"
                        />
                        <SelectUnit
                          units={product.units}
                          unit={orderItem.unit}
                          onChange={(unit) => {
                            orderItem.setUnit(unit);
                          }}
                          w="72px"
                        />
                      </>
                    ) : (
                      <InputGroup>
                        <Input
                          type="number"
                          min={0}
                          defaultValue={orderItem.amount}
                          onChange={onAmountChange}
                          onWheel={onWheel}
                          maxW="20"
                          textAlign="right"
                          placeholder="0"
                        />
                        <InputRightAddon children={orderItem.unit.name} />
                      </InputGroup>
                    )}
                    <IconButton
                      aria-label="Remove"
                      variant="ghost"
                      size="sm"
                      icon={<CrossIcon />}
                      onClick={() => remove(orderItem)}
                    />
                  </>
                ) : (
                  <Text>
                    {orderItem.amount} {orderItem.unit.name}
                  </Text>
                )}
              </HStack>
              {!orderItem.isValid &&
                !orderItem.meetsMinimumQuantityRequirements && (
                  <Caption color="red" fontWeight="semibold">
                    Minimum {Number(orderItem.unit.min_order_quantity)}
                    {orderItem.unit.name}
                  </Caption>
                )}
            </VStack>
          }
        />
        {orderItem.notes && (
          <Box my="8px" bg="gray.100" p="6px 10px">
            <Text fontSize="xs">"{orderItem.notes}"</Text>
          </Box>
        )}
        <NoteEditor
          initialValue={orderItem.notes || ''}
          onSave={(newValue: string) => {
            orderItem.setNotes(newValue);
          }}
          headerText={orderItem.notes ? 'Edit note' : 'Add note'}
        >
          <ButtonGroup>
            <Button
              variant="ghost"
              aria-label="Add / Edit note"
              leftIcon={<PencilIcon />}
              size="xs"
              color="green"
            >
              {orderItem.notes ? 'Edit' : 'Add'} note
            </Button>
          </ButtonGroup>
        </NoteEditor>
      </Box>
    </HStack>
  );
});

export default ReviewOrderItemRow;
