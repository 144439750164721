import React from 'react';
import { Box, Heading, Text, Link } from '@chakra-ui/react';

const ErrorPage = (): JSX.Element => {
  return (
    <Box bg="gray.100" minH="100vh">
      <Box pt="20vh" textAlign="center">
        <Heading size="md">Woops! Something went wrong.</Heading>
        <Text mt="6">
          Our technical team have been notified so we can fix the issue.
        </Text>
        <Text mt="6">
          In the meantime, please{' '}
          <Link onClick={() => window.location.reload()}>refresh the page</Link>{' '}
          and try again.
        </Text>
      </Box>
    </Box>
  );
};

export default ErrorPage;
