import React, { useState } from 'react';
import useAPI from '../../../hooks/useAPI';
import { useAccounting } from '../../../contexts/accounting';
import { isEmpty } from 'lodash';
import { capitalizeString } from '../../../utils';

import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Radio,
  RadioGroup,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import {
  Body2,
  Caption,
  Subhead,
} from '../../../components/Typography/Typography';

type connectorType =
  | 'MYOB'
  | 'Xero'
  | 'Infusion'
  | 'Unleashed'
  | 'Accredo'
  | 'MountainStream';

export interface SettingsProps {
  currentSettings: any;
  accountingConnectionName: connectorType;
}

interface selectOptionInterface {
  key: string;
  label: string;
}

interface connectorMappingInterface {
  MYOB: selectOptionInterface[];
  Xero: selectOptionInterface[];
  Infusion: selectOptionInterface[];
  Unleashed: selectOptionInterface[];
  Accredo: selectOptionInterface[];
  MountainStream: selectOptionInterface[];
}

interface SettingFieldProps {
  fieldName?: string;
  fieldLabel?: string;
  isEditable: boolean;
  value: string;
  accountingConnectionName: connectorType;
  helpText?: string;
}

interface DateFieldProps extends SettingFieldProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  allowNull: boolean;
}

const DateField = ({
  fieldName = 'invoice_date',
  fieldLabel = 'Invoice Date',
  isEditable,
  onChange,
  value,
  accountingConnectionName,
  helpText = '',
  allowNull,
}: DateFieldProps) => {
  const invoiceDateOptions: selectOptionInterface[] = [
    { key: 'order_date', label: 'Date ordered' },
    {
      key: 'delivery_date',
      label: 'Delivery date',
    },
    {
      key: 'exported_date',
      label: `Date sent to ${accountingConnectionName}`,
    },
  ];

  if (allowNull) {
    invoiceDateOptions.unshift({
      key: 'none',
      label: 'None',
    });
  }

  function invoiceDateText() {
    const optionObj = invoiceDateOptions.find(
      (option: selectOptionInterface) => option.key == value,
    );
    return (optionObj && optionObj.label) || '';
  }

  return (
    <FormFieldWrapper fieldName={fieldName} fieldLabel={fieldLabel}>
      {isEditable && (
        <Select onChange={onChange} value={value}>
          {invoiceDateOptions.map((option: selectOptionInterface) => {
            return (
              <option key={option.key} value={option.key}>
                {option.label}
              </option>
            );
          })}
        </Select>
      )}
      {!isEditable && <Text>{invoiceDateText()}</Text>}
      {helpText && <Caption color="gray.500">{helpText}</Caption>}
    </FormFieldWrapper>
  );
};

interface ReferenceFieldProps extends SettingFieldProps {
  onChange: (nextValue: string) => void;
}

const ReferenceField = ({
  fieldName = 'invoice_date',
  isEditable,
  onChange,
  value,
  accountingConnectionName,
}: ReferenceFieldProps) => {
  const invoiceReferenceLabelMap = {
    MYOB: 'PO Number',
    Xero: 'Reference',
    Infusion: '',
    Unleashed: 'Customer Ref',
    Accredo: 'Order No',
    MountainStream: 'Purchase order #',
    'Cin7 Core': 'Reference',
  };
  const invoiceReferenceLabel =
    invoiceReferenceLabelMap[accountingConnectionName];

  const copyMap = {
    purchase_order_priority: {
      label: 'Use Purchase Order number (if supplied)',
      caption: 'Otherwise use HospoConnect order number',
    },
    purchase_order_only: {
      label: 'Only use Purchase Order number (if supplied)',
      caption: 'Never use HospoConnect order number',
    },
    order_number_only: {
      label: 'Only use HospoConnect order number',
      caption: 'Never use Purchase Order number',
    },
  };
  const valueTyped = value as keyof typeof copyMap;

  return (
    <FormFieldWrapper fieldName={fieldName} fieldLabel={invoiceReferenceLabel}>
      {isEditable && (
        <VStack alignItems="left">
          <RadioGroup name={fieldName} onChange={onChange} value={value}>
            <VStack alignItems="left">
              <Radio value="purchase_order_priority">
                <Body2>{copyMap.purchase_order_priority.label}</Body2>
                <Caption color="gray.500">
                  {copyMap.purchase_order_priority.caption}
                </Caption>
              </Radio>
              <Radio value="purchase_order_only">
                <Body2>{copyMap.purchase_order_only.label}</Body2>
                <Caption color="gray.500">
                  {copyMap.purchase_order_only.caption}
                </Caption>
              </Radio>
              <Radio value="order_number_only">
                <Body2>{copyMap.order_number_only.label}</Body2>
                <Caption color="gray.500">
                  {copyMap.order_number_only.caption}
                </Caption>
              </Radio>
            </VStack>
          </RadioGroup>
        </VStack>
      )}
      {!isEditable && (
        <Box>
          <Body2>{copyMap[valueTyped].label}</Body2>
          <Caption color="gray.500">{copyMap[valueTyped].caption}</Caption>
        </Box>
      )}
    </FormFieldWrapper>
  );
};

interface RadioFieldProps extends SettingFieldProps {
  fieldName: string;
  fieldLabel: string;
  options: RadioFieldOptions[];
  onChange: (nextValue: string) => void;
}

interface RadioFieldOptions {
  key: string;
  label: string;
  caption: string;
}

const RadioField = ({
  fieldName,
  fieldLabel,
  isEditable,
  options,
  onChange,
  value,
}: RadioFieldProps) => {
  const currentValue = options.find((option) => option.key === value);

  return (
    <FormFieldWrapper fieldName={fieldName} fieldLabel={fieldLabel}>
      {isEditable && (
        <VStack alignItems="left">
          <RadioGroup name={fieldName} onChange={onChange} value={value}>
            <VStack spacing="3" alignItems="left">
              {options.map((option: RadioFieldOptions) => {
                return (
                  <Radio key={option.key} value={option.key}>
                    <Body2>{option.label}</Body2>
                    <Caption color="gray.500">{option.caption}</Caption>
                  </Radio>
                );
              })}
            </VStack>
          </RadioGroup>
        </VStack>
      )}
      {!isEditable && currentValue && (
        <Box>
          <Body2>{currentValue.label}</Body2>
          <Caption color="gray.500">{currentValue.caption}</Caption>
        </Box>
      )}
    </FormFieldWrapper>
  );
};

const Settings = ({
  currentSettings,
  accountingConnectionName,
}: SettingsProps) => {
  const { getCurrentAccountingConnection } = useAccounting();
  const [formState, setFormState] = useState<any>({});
  const [isEditable, setIsEditable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [callSetSettingsApi] = useAPI({
    method: 'PUT',
  });

  const apiUrl = '/v4/accounting_connectors/settings';

  function resetFormState(data: any) {
    let initialFormState: any = {};
    for (const [key, value] of Object.entries(data)) {
      initialFormState[key] = value;
    }
    setFormState(initialFormState);
  }

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSubmitting(true);
    callSetSettingsApi(apiUrl, {
      body: JSON.stringify(formState),
    })
      .then(() => {
        getCurrentAccountingConnection(() => {
          toggleEditable();
          setIsSubmitting(false);
        });
      })
      .catch(() => {});
  }

  function toggleEditable() {
    if (isEditable) {
      setIsEditable(false);
    }

    if (!isEditable) {
      resetFormState(currentSettings);
      setIsEditable(true);
    }
  }

  const invoiceDueTermOptions: selectOptionInterface[] = [
    { key: 'of_following_month', label: 'of the following month' },
    { key: 'after_invoice', label: 'day(s) after invoice' },
    {
      key: 'after_end_of_invoice_month',
      label: 'day(s) after the end of the invoice month',
    },
    { key: 'of_current_month', label: 'of the current month' },
  ];

  const invoiceTaxInclusivityOptionsMap: connectorMappingInterface = {
    MYOB: [
      { key: 'exclusive', label: 'GST exclusive' },
      { key: 'inclusive', label: 'GST inclusive' },
    ],
    Xero: [
      { key: 'exclusive', label: 'GST exclusive' },
      { key: 'inclusive', label: 'GST inclusive' },
      {
        key: 'no_tax',
        label: 'No GST',
      },
    ],
    Infusion: [],
    Unleashed: [],
    Accredo: [],
    MountainStream: [],
  };
  const invoiceTaxInclusivityOptions =
    invoiceTaxInclusivityOptionsMap[accountingConnectionName];

  const exportTypeMap = {
    MYOB: 'invoice',
    Xero: 'invoice',
    Infusion: 'invoice',
    Unleashed: 'sales order',
    Accredo: 'invoice',
    MountainStream: 'order',
    'Cin7 Core': 'sale',
  };
  const exportName = exportTypeMap[accountingConnectionName];

  function invoiceTaxInclusivityText() {
    if (currentSettings) {
      if (
        'invoice_tax_inclusivity_override' in currentSettings &&
        !currentSettings.invoice_tax_inclusivity_override
      ) {
        return `Use my tax settings in ${accountingConnectionName}`;
      } else {
        const optionObj = invoiceTaxInclusivityOptions.find(
          (option: selectOptionInterface) =>
            option.key == currentSettings.invoice_tax_inclusivity,
        );
        return (optionObj && optionObj.label) || '';
      }
    } else {
      return '';
    }
  }

  function invoiceTermSummaryText() {
    if (currentSettings) {
      if (
        'invoice_due_override' in currentSettings &&
        !currentSettings.invoice_due_override
      ) {
        return `Use my due date settings in ${accountingConnectionName}`;
      } else {
        const optionObj = invoiceDueTermOptions.find(
          (option: selectOptionInterface) =>
            option.key == currentSettings.invoice_due_term,
        );

        return `${invoiceTermNumText()} ${optionObj && optionObj.label}`;
      }
    } else {
      return '';
    }
  }

  function invoiceTermNumText() {
    if (
      currentSettings.invoice_due_term == 'of_following_month' ||
      currentSettings.invoice_due_term == 'of_current_month'
    ) {
      const n = currentSettings.invoice_due_num;
      const dateOrdinal =
        31 == n || 21 == n || 1 == n
          ? 'st'
          : 22 == n || 2 == n
          ? 'nd'
          : 23 == n || 3 == n
          ? 'rd'
          : 'th';
      return `${currentSettings.invoice_due_num}${dateOrdinal}`;
    } else {
      return `${currentSettings.invoice_due_num}`;
    }
  }

  if (isEmpty(currentSettings)) {
    return <></>;
  }

  const DateSettingFieldWrapper = (
    fieldName: string,
    fieldLabel: string,
    helpText: string | null = null,
    allowNull: boolean = false,
  ) => {
    if (fieldName in currentSettings) {
      return (
        <DateField
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            let newFormState = {
              ...formState,
            };
            newFormState[fieldName] = e.target.value;
            setFormState(newFormState);
          }}
          fieldName={fieldName}
          fieldLabel={fieldLabel}
          value={formState[fieldName] || currentSettings[fieldName]}
          isEditable={isEditable}
          accountingConnectionName={accountingConnectionName}
          helpText={helpText || ''}
          allowNull={allowNull}
        />
      );
    } else {
      return <></>;
    }
  };

  const ReferenceFieldWrapper = (fieldName: string) => {
    if (fieldName in currentSettings) {
      return (
        <ReferenceField
          fieldName={fieldName}
          value={formState[fieldName] || currentSettings[fieldName]}
          isEditable={isEditable}
          accountingConnectionName={accountingConnectionName}
          onChange={(value: string) => {
            let newFormState = {
              ...formState,
            };
            newFormState[fieldName] = value;
            setFormState(newFormState);
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <Box>
      <Subhead fontWeight={600}>
        {capitalizeString(exportName)} Settings
      </Subhead>

      <Box pt="6" maxWidth="627px">
        <form onSubmit={submitForm}>
          <VStack spacing="8" alignItems="left">
            {DateSettingFieldWrapper(
              'invoice_date',
              'Invoice Date',
              `Create invoices using this date. You can edit individual invoices in ${accountingConnectionName} to change this.`,
            )}
            {/* Cin7 Core specific fields */}
            {DateSettingFieldWrapper('sale_order_date', 'Sale Order date')}
            {DateSettingFieldWrapper('ship_by_date', 'Ship by date')}
            {/* Accredo specific fields */}
            {DateSettingFieldWrapper('document_date', 'Document Date')}
            {DateSettingFieldWrapper(
              'delivery_date',
              'Delivery Date',
              null,
              true,
            )}
            {/* Unleashed specific fields */}
            {DateSettingFieldWrapper('order_date', 'Order date')}
            {DateSettingFieldWrapper('required_date', 'Required date')}
            {'invoice_due_num' in currentSettings &&
              'invoice_due_term' in currentSettings && (
                <FormFieldWrapper
                  fieldName="invoice_due_override"
                  fieldLabel="Due Date"
                >
                  {isEditable && (
                    <VStack alignItems="left">
                      {'invoice_due_override' in currentSettings && (
                        <RadioGroup
                          onChange={(value) => {
                            setFormState({
                              ...formState,
                              invoice_due_override: value == 'true',
                            });
                          }}
                          value={formState.invoice_due_override.toString()}
                        >
                          <VStack alignItems="left">
                            <Radio value="false">
                              Use my due date settings in{' '}
                              {accountingConnectionName}
                            </Radio>
                            <Radio value="true">
                              Override {accountingConnectionName} settings and
                              use the following
                            </Radio>
                          </VStack>
                        </RadioGroup>
                      )}

                      <HStack>
                        <NumberInput
                          w="100px"
                          value={formState.invoice_due_num}
                          min={0}
                          isDisabled={
                            'invoice_due_override' in currentSettings &&
                            !formState.invoice_due_override
                          }
                          onChange={(value) =>
                            setFormState({
                              ...formState,
                              invoice_due_num: parseInt(value, 10),
                            })
                          }
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <Select
                          value={formState.invoice_due_term}
                          isDisabled={
                            'invoice_due_override' in currentSettings &&
                            !formState.invoice_due_override
                          }
                          onChange={(e) =>
                            setFormState({
                              ...formState,
                              invoice_due_term: e.target.value,
                            })
                          }
                        >
                          {invoiceDueTermOptions.map(
                            (option: selectOptionInterface) => {
                              return (
                                <option key={option.key} value={option.key}>
                                  {option.label}
                                </option>
                              );
                            },
                          )}
                        </Select>
                      </HStack>
                    </VStack>
                  )}
                  {!isEditable && <Text>{invoiceTermSummaryText()}</Text>}

                  <Caption color="gray.500">
                    You can edit individual {exportName}s in{' '}
                    {accountingConnectionName} to change this.
                  </Caption>
                </FormFieldWrapper>
              )}
            {'invoice_tax_inclusivity' in currentSettings && (
              <FormFieldWrapper
                fieldName="invoice_tax_inclusivity_override"
                fieldLabel="GST"
              >
                {isEditable && (
                  <VStack alignItems="left">
                    {'invoice_tax_inclusivity_override' in currentSettings && (
                      <RadioGroup
                        onChange={(value) => {
                          setFormState({
                            ...formState,
                            invoice_tax_inclusivity_override: value == 'true',
                          });
                        }}
                        value={formState.invoice_tax_inclusivity_override.toString()}
                      >
                        <VStack alignItems="left">
                          <Radio value="false">
                            Use my tax settings in {accountingConnectionName}
                          </Radio>
                          <Radio value="true">
                            Override {accountingConnectionName} settings and use
                            the following
                          </Radio>
                        </VStack>
                      </RadioGroup>
                    )}

                    <Select
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          invoice_tax_inclusivity: e.target.value,
                        })
                      }
                      value={formState.invoice_tax_inclusivity}
                      isDisabled={
                        'invoice_tax_inclusivity_override' in currentSettings &&
                        !formState.invoice_tax_inclusivity_override
                      }
                    >
                      {invoiceTaxInclusivityOptions.map(
                        (option: selectOptionInterface) => {
                          return (
                            <option key={option.key} value={option.key}>
                              {option.label}
                            </option>
                          );
                        },
                      )}
                    </Select>
                  </VStack>
                )}
                {!isEditable && <Text>{invoiceTaxInclusivityText()}</Text>}
                <Caption color="gray.500">
                  Create {exportName} using this tax setting. You can edit
                  individual {exportName} in {accountingConnectionName} to
                  change this.
                </Caption>
              </FormFieldWrapper>
            )}
            {ReferenceFieldWrapper('invoice_reference')}
            {ReferenceFieldWrapper('customer_reference')} {/* Cin7Core */}
            {ReferenceFieldWrapper('customer_ref')} {/* Unleashed */}
            {ReferenceFieldWrapper('order_no')} {/* Accredo */}
            {ReferenceFieldWrapper('purchase_order_number')}{' '}
            {/* MountainStream */}
            {'export_type' in currentSettings && (
              <FormFieldWrapper
                fieldName="export_type"
                fieldLabel="Export Type"
              >
                {isEditable && (
                  <Select
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        export_type: e.target.value,
                      })
                    }
                    value={formState.export_type}
                  >
                    <option value="Invoice">Invoice</option>
                    <option value="Quote">Quote</option>
                    <option value="Order">Order</option>
                  </Select>
                )}
                {!isEditable && <Text>{currentSettings.export_type}</Text>}
                <Caption color="gray.500">
                  Export your orders as an {accountingConnectionName} Invoice,
                  Order or Quote. Your preference will depend on your workflow.
                  If in doubt, leave this as Invoice.
                </Caption>
              </FormFieldWrapper>
            )}
            {/* Unleashed specific field... for now */}
            {'order_status' in currentSettings && (
              <FormFieldWrapper fieldName="order_status" fieldLabel="Status">
                {isEditable && (
                  <Select
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        order_status: e.target.value,
                      })
                    }
                    value={
                      formState.order_status || currentSettings.order_status
                    }
                  >
                    <option value="Parked">Parked</option>
                    <option value="Placed">Placed</option>
                  </Select>
                )}
                {!isEditable && <Text>{currentSettings.order_status}</Text>}
                <Caption color="gray.500">
                  Export your sales orders as Placed, or Parked. Your preference
                  will depend on your workflow. If in doubt, leave this as
                  Placed.
                </Caption>
              </FormFieldWrapper>
            )}
            {/* MountainStream specific field */}
            {'cc_email_address' in currentSettings && (
              <FormFieldWrapper
                fieldName="cc_email_address"
                fieldLabel="CC email address (optional)"
              >
                {isEditable && (
                  <Input
                    name="cc_email_address"
                    autoComplete="off"
                    value={
                      formState.cc_email_address ||
                      currentSettings.cc_email_address
                    }
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        cc_email_address: e.target.value,
                      })
                    }
                  />
                )}
                {!isEditable && <Text>{currentSettings.cc_email_address}</Text>}
                <Caption color="gray.500">
                  Get your order emails cc'd to this address, as recommended by
                  MountainStream.
                </Caption>
              </FormFieldWrapper>
            )}
            {'pricing_source' in currentSettings && (
              <RadioField
                fieldName="pricing_source"
                fieldLabel="Pricing"
                options={[
                  {
                    key: 'hospoconnect',
                    label: 'Use HospoConnect prices and units',
                    caption:
                      'Manage all of your prices in HospoConnect, not MYOB. Prices from HospoConnect will flow through to the invoices created in MYOB.',
                  },
                  {
                    key: 'myob',
                    label: 'Use MYOB prices and units',
                    caption: `Use the prices and units defined in MYOB. If you use Price levels, beware that only default Price level names are supported (Level A-F), any renamed price levels will use the Base selling price instead.`,
                  },
                ]}
                value={
                  formState['pricing_source'] ||
                  currentSettings['pricing_source']
                }
                isEditable={isEditable}
                accountingConnectionName={accountingConnectionName}
                onChange={(value: string) => {
                  let newFormState = {
                    ...formState,
                  };
                  newFormState['pricing_source'] = value;
                  setFormState(newFormState);
                }}
              />
            )}
            {'product_data_source' in currentSettings && (
              <RadioField
                fieldName="product_data_source"
                fieldLabel="Product data source"
                options={[
                  {
                    key: 'push_static',
                    label: 'Use HospoConnect',
                    caption: `Recommended. Generated invoices ignore product data in ${accountingConnectionName}. No product matching required.`,
                  },
                  {
                    key: 'match_product_code',
                    label: 'Match product code',
                    caption: `Generated invoices use product data from ${accountingConnectionName} that match ordered products with
                  the same product code. You must keep product data between both
                  platforms in sync manually.`,
                  },
                ]}
                value={
                  formState['product_data_source'] ||
                  currentSettings['product_data_source']
                }
                isEditable={isEditable}
                accountingConnectionName={accountingConnectionName}
                onChange={(value: string) => {
                  let newFormState = {
                    ...formState,
                  };
                  newFormState['product_data_source'] = value;
                  setFormState(newFormState);
                }}
              />
            )}
            <ButtonGroup>
              {isEditable && (
                <>
                  <Button onClick={toggleEditable}>Cancel</Button>
                  <Button
                    type="submit"
                    colorScheme="green"
                    isLoading={isSubmitting}
                  >
                    Save settings
                  </Button>
                </>
              )}
              {!isEditable && (
                <Button onClick={toggleEditable}>Edit settings</Button>
              )}
            </ButtonGroup>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default Settings;
