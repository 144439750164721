import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Link, Text, VStack } from '@chakra-ui/react';

import { useProducts } from '../../../hooks/useStores';
import ListView from '../../../components/ListView/ListView';
import ProductsListActions from './ProductsListActions';
import ProductsRow from './ProductsRow';

const ProductsList = () => {
  const {
    products,
    getProductsList,
    productsAreLoading,
    clearFilters,
    setCurrentProductsPage,
    totalCount,
    totalPages,
    currentPage,
  } = useProducts();
  useEffect(() => {
    getProductsList(
      () => {},
      () => {},
    );
  }, [currentPage]);
  return (
    <ListView
      isLoading={productsAreLoading}
      isEmpty={!productsAreLoading && totalCount === 0}
      pagination={{
        handlePageChange: (pageNumber: number) => {
          setCurrentProductsPage(pageNumber);
        },
        currentPage: currentPage,
        pageCount: totalPages,
        totalCount: totalCount || 0,
        itemsPerPage: 20,
        itemsType: 'products',
      }}
      actionChildren={<ProductsListActions />}
      items={products}
      renderItem={({ item: product, extraProps }) => (
        <ProductsRow
          key={`products-row-${product.id}`}
          product={product}
          {...extraProps}
        />
      )}
      emptyChildren={
        <Box
          mt="12"
          ml="6"
          maxWidth="360px"
          opacity={productsAreLoading ? 0.6 : 1}
        >
          <VStack spacing="4" alignItems="left">
            <Text fontSize="lg" fontWeight="500">
              No products found
            </Text>
            <Text>
              Use the filters above to view products by category, and active
              status.
            </Text>
            <Link
              fontWeight="400"
              onClick={() => {
                clearFilters();
              }}
            >
              Clear filters
            </Link>
          </VStack>
        </Box>
      }
    />
  );
};

export default observer(ProductsList);
