import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import AppWrapper from '../../components/Layouts/AppWrapper';
import TwoUpWrapper from '../../components/Layouts/TwoUpWrapper';
import StandingOrdersPageActions from './StandingOrdersPageActions';
import StandingOrderDetail from './Detail/StandingOrderDetail';
import StandingOrdersList from './List/StandingOrdersList';

const StandingOrdersPage = ({
  match: {
    params: { standingOrderId, deliveryDate },
  },
}: RouteComponentProps<{
  standingOrderId: string;
  deliveryDate: string;
}>): JSX.Element => {
  return (
    <AppWrapper>
      <TwoUpWrapper
        headerChildren={<StandingOrdersPageActions />}
        leftChildren={<StandingOrdersList />}
        rightChildren={
          <StandingOrderDetail
            standingOrderId={standingOrderId}
            deliveryDate={deliveryDate}
          />
        }
      />
    </AppWrapper>
  );
};

export default observer(StandingOrdersPage);
