import { Button, Link, HStack } from '@chakra-ui/react';
import { Caption } from '../../../components/Typography/Typography';
import { ArrowRightIcon } from '../../../components/Icons/IconsNew';

const PricingTiersEmptyMessage = (): JSX.Element => {
  return (
    <HStack>
      <Caption>Need different prices for different customers?</Caption>
      <Button
        as={Link}
        href="/pricing-tiers"
        variant="tertiary"
        textDecoration="none"
        color="green"
        size="sm"
        rightIcon={<ArrowRightIcon width="18px" />}
      >
        Set up pricing tiers
      </Button>
    </HStack>
  );
};

export default PricingTiersEmptyMessage;
