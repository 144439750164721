import React, { useState } from 'react';

import { useAuth } from './auth';
import { RootStore } from '../stores/rootStore';

export const RootContext = React.createContext<RootStore>({} as RootStore);

export const StoreProvider = (props: any) => {
  const { user } = useAuth();
  const [store, _] = useState(new RootStore(user));
  const { children } = props;
  return <RootContext.Provider value={store}>{children}</RootContext.Provider>;
};
