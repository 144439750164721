import { action, computed, makeObservable, observable } from 'mobx';
import * as Sentry from '@sentry/browser';

import { api } from '../api';

export interface DocumentTemplateProps {
  id: number;
  name: string;
  base_type: string;
}

export class DocumentTemplate {
  id: number;
  @observable name: string;
  @observable base_type: string;

  constructor(props: DocumentTemplateProps) {
    const { id, name, base_type } = props;
    this.id = id;
    this.name = name;
    this.base_type = base_type;
  }

  @action
  addData = (props: DocumentTemplateProps) => {
    const { id, name, base_type } = props;
    this.id = id;
    this.name = name;
    this.base_type = base_type;
  };
}

export class DocumentTemplateStore {
  user: any = [];
  @observable documentTemplatesList: { [id: number]: DocumentTemplate } = {};
  @observable documentTemplatesIndex: number[] = [];

  constructor(user?: any) {
    this.user = user;
    makeObservable(this);
  }

  @computed
  get documentTemplates() {
    return this.documentTemplatesIndex.map((key) => {
      return this.documentTemplatesList[key];
    });
  }

  @action
  setDocumentTemplates = (results: any) => {
    results.forEach((documentTemplate: DocumentTemplateProps, index: number) => {
      if (!this.documentTemplatesList[documentTemplate.id]) {
        this.documentTemplatesList[documentTemplate.id] = new DocumentTemplate(
          documentTemplate,
        );
      } else {
        this.documentTemplatesList[documentTemplate.id].addData(documentTemplate);
      }
      this.documentTemplatesIndex[index] = documentTemplate.id;
    });
  };

  @action
  getDocumentTemplates = () => {
    return new Promise((resolve, reject) => {
      api
        .get(
          `/v4/document_templates`,
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setDocumentTemplates(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };
}
