import { action, computed, observable, makeObservable, remove } from 'mobx';
import { Instance } from 'mobx-state-tree';
import update from 'immutability-helper';

import { api } from '../../api';
import SourceCategory, {
  SourceCategoryBaseProps,
} from '../../models/SourceCategory';
import { isUndefined } from 'lodash';
import { PaginationStore } from '../paginationStore';

interface Product {
  id: number;
  name: string;
}

interface SourceCategoriesListInterface {
  [id: number]: Instance<typeof SourceCategory>;
}

export class SourceCategoriesStore {
  user: any = [];

  pageLimit = 20;

  @observable isLoading: boolean = true;
  @observable currentPage: number = 1;

  @observable pagination = new PaginationStore();

  @observable _unassignedProductsCount?: number;
  @observable unassignedProducts?: Product[];

  @observable sourceCategoriesList: SourceCategoriesListInterface = {};
  @observable sourceCategoriesOrder: (number | null)[] = [];
  @observable currentSourceCategoryId?: number;

  constructor(user?: any) {
    this.user = user;
    makeObservable(this);
  }

  findSourceCategoryById = (findId: number) => {
    const id = this.sourceCategoriesOrder.filter((sc) => sc === findId)[0];
    return {
      item: this.sourceCategoriesList[id!],
      index: this.sourceCategoriesOrder.indexOf(id),
    };
  };

  @computed
  get sourceCategories(): Array<Instance<typeof SourceCategory>> {
    return this.sourceCategoriesOrder
      .filter((key): key is number => key !== null && typeof key === 'number')
      .map((key: number) => {
        return this.sourceCategoriesList[key];
      });
  }

  @computed
  get currentSourceCategory(): any {
    if (this.currentSourceCategoryId) {
      return this.sourceCategoriesList[this.currentSourceCategoryId];
    }
  }

  @computed
  get unassignedProductsCount(): number {
    if (!isUndefined(this.unassignedProducts)) {
      return this.unassignedProducts.length;
    }
    return this._unassignedProductsCount || 0;
  }

  @action
  moveSourceCategory = (id: number, atIndex: number) => {
    const { index } = this.findSourceCategoryById(id);
    this.sourceCategoriesOrder = update(this.sourceCategoriesOrder, {
      $splice: [
        [index, 1],
        [atIndex, 0, id],
      ],
    });
  };

  @action
  setUnassignedProducts = (products: Product[]) => {
    this.unassignedProducts = products;
  };

  @action
  removeUnassignedProduct = (product: Product) => {
    if (this.unassignedProducts) {
      this.unassignedProducts = this.unassignedProducts.filter(
        (c) => c.id !== product.id,
      );
    }
  };

  @action
  getUnassignedProducts = () => {
    return new Promise<Response>((resolve, reject) => {
      api
        .get(`/v4/source_categories/unassigned_products`)
        .then(async (response) => {
          const products = await response.json();
          if (response.ok) {
            this.setUnassignedProducts(products);
            resolve(products);
          }
          reject(products);
        });
    });
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  @action
  setSourceCategories = (sourceCategories: any) => {
    this.pagination.setFromResponse(sourceCategories);
    this._unassignedProductsCount = sourceCategories.unassigned_count || 0;
    this.sourceCategoriesOrder = [];
    sourceCategories.results.forEach(
      (sourceCategory: SourceCategoryBaseProps, index: number) => {
        // Only create new instance if source category doesn't exist. But should we merge new data with existing instances?
        if (!this.sourceCategoriesList[sourceCategory.id]) {
          this.sourceCategoriesList[sourceCategory.id] = SourceCategory.create({
            id: sourceCategory.id,
            name: sourceCategory.name,
            sortOrder: sourceCategory.sort_order,
            amountsRequired: sourceCategory.amounts_required,
            unit: sourceCategory.unit,
            _productsCount: sourceCategory.products_count,
          });
        }
        this.sourceCategoriesOrder[index] = sourceCategory.id;
      },
    );
  };

  @action
  clearCurrentSourceCategory = () => {
    if (this.currentSourceCategory) {
      this.currentSourceCategory.setIsCurrent(false);
    }
    this.currentSourceCategoryId = undefined;
  };

  @action
  setCurrentSourceCategory = (sourceCategory?: any) => {
    if (this.currentSourceCategory) {
      this.currentSourceCategory.setIsCurrent(false);
    }

    if (sourceCategory) {
      const sourceCategoryObject = this.sourceCategoriesList[sourceCategory.id];
      if (sourceCategoryObject) {
        sourceCategoryObject.addData(sourceCategory);
        sourceCategoryObject.setIsCurrent(true);
      } else {
        const newSourceCategory = SourceCategory.create({
          id: sourceCategory.id,
          name: sourceCategory.name,
          unit: sourceCategory.unit,
          sortOrder: sourceCategory.sort_order,
          _productsCount: sourceCategory.products_count,
        });
        newSourceCategory.addData(sourceCategory);
        newSourceCategory.setIsCurrent(true);

        this.sourceCategoriesList[sourceCategory.id] = newSourceCategory;
        this.sourceCategoriesOrder[this.sourceCategoriesOrder.length] =
          newSourceCategory.id;
      }
      this.currentSourceCategoryId = sourceCategory.id;
    } else {
      this.currentSourceCategoryId = undefined;
    }
  };

  @action
  getSourceCategories = () => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v4/source_categories`)
        .then(async (response) => {
          const data = await response.json();
          if (response.ok && response.status !== 401) {
            this.setSourceCategories(data);
            resolve(data);
          }
          reject(data);
          this.setIsLoading(false);
        })
        .catch((error) => reject(error));
    });
  };

  @action
  updateSourceCategory = (
    sourceCategory: Instance<typeof SourceCategory>,
    values: any,
  ) => {
    return new Promise((resolve, reject) => {
      api
        .put(
          `/v4/source_categories/${sourceCategory.id}`,
          JSON.stringify({
            source_category: { ...values },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setCurrentSourceCategory(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action
  updateSortOrder = () => {
    return new Promise((resolve, reject) => {
      api
        .put(
          `/v4/source_categories/batch_update`,
          JSON.stringify({
            source_categories: this.sourceCategories.map((sc) => ({
              id: sc.id,
              sort_order: sc.sortOrder,
            })),
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action
  createSourceCategory = (values: any) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          `/v4/source_categories`,
          JSON.stringify({
            source_category: {
              ...values,
              sort_order: this.sourceCategories.length,
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.pagination.addToCount();
            this.setCurrentSourceCategory(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action
  deleteSourceCategory = (sourceCategory: Instance<typeof SourceCategory>) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/v4/source_categories/${sourceCategory.id}`)
        .then(async (response) => {
          if (response.ok) {
            this.pagination.removeFromCount();
            this.delete(sourceCategory);
            resolve(response);
          }
          reject(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  @action
  delete = (sourceCategory: Instance<typeof SourceCategory>) => {
    const index = this.sourceCategoriesOrder.indexOf(sourceCategory.id);
    if (index > -1) {
      remove(this.sourceCategoriesOrder, `${index}`);
      remove(this.sourceCategoriesList, `${sourceCategory.id}`);
    }
  };

  @action
  getSourceCategory = (sourceCategoryId: string) => {
    return new Promise((resolve, reject) => {
      api
        .get(`/v4/source_categories/${sourceCategoryId}`)
        .then(async (response) => {
          this.setCurrentSourceCategory(await response.json());
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  };
}

export default new SourceCategoriesStore();
