import React, { useEffect, useState } from 'react';
import { useAuth } from './auth';
import ActionCable from 'actioncable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePageVisibility } from '../hooks/usePageVisibility';

type ActionCableContextType = {
  actionCableConsumer: ActionCable.Cable | null;
};

const ActionCableContext = React.createContext<ActionCableContextType>({
  actionCableConsumer: null,
});

function ActionCableProvider(props: any) {
  const { children } = props;
  const { user } = useAuth();
  const [consumer, setConsumer] = useState<ActionCable.Cable | null>(null);
  const { realtimeMvp } = useFlags();
  const pageIsVisible = usePageVisibility();

  useEffect(() => {
    if (realtimeMvp && pageIsVisible) {
      const actionCableUrl = process.env.REACT_APP_API_URL
        ? `${process.env.REACT_APP_API_URL.replace(/http|https/g, 'ws')}/cable`
        : 'missing-api-url';

      const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
      const authToken = userInfo && userInfo.authentication_token;
      if (authToken) {
        const consumer = ActionCable.createConsumer(
          `${actionCableUrl}?token=${authToken}`,
        );
        setConsumer(consumer);
      }
    }

    if (consumer && (!pageIsVisible || !realtimeMvp)) {
      consumer.disconnect();
    }
  }, [user, realtimeMvp, pageIsVisible]);

  return (
    <ActionCableContext.Provider
      value={{
        actionCableConsumer: consumer,
      }}
    >
      {children}
    </ActionCableContext.Provider>
  );
}

const useActionCableConsumer = () => React.useContext(ActionCableContext);

export { ActionCableProvider, useActionCableConsumer };
