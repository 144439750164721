const Textarea = {
  baseStyle: {
    bg: 'white',
    backgroundColor: 'white'
  },
  variants: {
    address: {
      field: {
        marginLeft: 10
      }
    }
  }
}

export default Textarea;