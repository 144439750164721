import React from 'react';
import { useAuth } from '../../contexts/auth';
import { useHistory } from 'react-router-dom';

import AuthWrapper from '../../components/Layouts/AuthWrapper';
import ForgotPasswordForm from './ForgotPasswordForm';

import {
  Modal,
  Heading,
  Stack,
  Link,
  Text,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';

const ForgotPasswordPage = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  let history = useHistory();

  if (user) {
    history.push('/orders');
  }

  return (
    <AuthWrapper
      innerChildren={
        <>
          <Heading size="lg" fontWeight="700">
            Forgot password
          </Heading>
          <Text mt="4" mb="8" maxW="md">
            Enter your email address and we'll send you instructions to reset
            your password.
          </Text>

          <ForgotPasswordForm />
        </>
      }
      footerChildren={
        <>
          <Text align="center" mt="14" mb="8" maxW="md">
            <Link color="white" textDecoration="underline" onClick={onOpen}>
              Contact Support
            </Link>
          </Text>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>Contact HospoConnect</ModalHeader>
              <ModalBody>
                <Stack>
                  <Text>
                    You can get in touch with our team over the phone or email.
                  </Text>
                  <Text>
                    Send a message to{' '}
                    <Text display="inline" fontWeight="700">
                      021 040 7510
                    </Text>
                    , or email us at{' '}
                    <Link
                      color="gray.900"
                      href="mailto:hello@hospoconnect.co.nz"
                    >
                      hello@hospoconnect.co.nz
                    </Link>
                  </Text>
                </Stack>
              </ModalBody>
              <ModalFooter />
            </ModalContent>
          </Modal>
        </>
      }
    />
  );
};

export default ForgotPasswordPage;
