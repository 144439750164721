import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useAPI from '../../../hooks/useAPI';
import { useCurrentUser, useOrders } from '../../../hooks/useStores';
import { useAccounting } from '../../../contexts/accounting';
import { useBatchOrders } from '../../../contexts/batchOrders';
import { useErrorToast, useSuccessToast } from '../../../components/toast';
import { pluralizeString } from '../../../utils';
import { countBy } from 'lodash';

import ProcessingModal from '../../../components/ProcessingModal/ProcessingModal';
import AccountingErrorMessage from '../../../components/AccountingErrorMessage/AccountingErrorMessage';

import {
  IconButton,
  Portal,
  Progress,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { ExportIcon } from '../../../components/Icons/Icons';

const OrdersExportAction = ({
  orderIds,
  errorContainerRef,
}: any): JSX.Element => {
  const ALERT_DURATION = 8000;
  const { getOrders, currentBulkIds, setCurrentBulkIds } = useOrders();
  const { isBuyer } = useCurrentUser();
  const { sendBatchToXero } = useBatchOrders();
  const {
    currentAccountingConnection,
    getCurrentAccountingConnection,
    accountingErrors,
    getAccountingErrors,
    setAccountingErrors,
  } = useAccounting();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [asyncProgress, setAsyncProgress] = useState<number | null>(null);
  const [numCurrentlyProcessing, setNumCurrentlyProcessing] =
    useState<number>(0);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  useEffect(() => {
    getCurrentAccountingConnection(() => {});
  }, []);

  useEffect(() => {
    if (currentAccountingConnection) {
      getAccountingErrors();
    }
  }, [currentAccountingConnection]);

  const [getBatchInvoices] = useAPI({
    method: 'GET',
  });

  const isConnectionActive =
    currentAccountingConnection &&
    currentAccountingConnection.connection_status == 'active';

  const accountingConnectorName =
    currentAccountingConnection && currentAccountingConnection.label;

  const isConnectionInErrorState =
    currentAccountingConnection &&
    currentAccountingConnection.status === 'error';

  const isConnectionStale =
    isConnectionActive &&
    currentAccountingConnection &&
    currentAccountingConnection.is_connection_stale;

  const sendInvoices = () => {
    setButtonIsLoading(true);
    setNumCurrentlyProcessing(orderIds.length);
    setModalIsOpen(true);
    sendBatchToXero(
      orderIds,
      (data: any) => {
        const processedOrderData = data.filter((orderResult: any) => {
          if (orderResult.result.status != 'rejected') {
            return orderResult;
          }
        });
        const skippedOrderData = data.filter((orderResult: any) => {
          if (orderResult.result.status == 'rejected') {
            return orderResult;
          }
        });

        if (processedOrderData.length > 0) {
          if (currentAccountingConnection.key === 'infusion') {
            // We need to pass the skipped order data through to be merged with
            // the final data passed to handleSuccess.
            // Otherwise, if we poll already sent orders, they will return "success"
            // status, appearing as if they had been processed this time around.
            initPolling(
              processedOrderData.map((o: any) => o.order_id),
              skippedOrderData,
            );
          } else {
            handleSuccess(data);
          }
        } else {
          successToast({
            duration: ALERT_DURATION,
            title: `Nothing to do`,
            description: `All the selected orders have already been sent to ${accountingConnectorName}.`,
          });
          closeAndResetModal();
        }
      },
      handleError,
    );
  };

  const handleSuccess = (data: any) => {
    // Go through the errors
    var newErrors: any[] = [];
    var successesfulIds: any[] = [];
    var ignoredIds: any[] = [];

    data.map((item: any) => {
      if (item.result.status === 'error') {
        newErrors.push(item);
      } else if (item.result.status === 'success') {
        successesfulIds.push(item.order_id);
      } else {
        ignoredIds.push(item.order_id);
      }
    });

    if (successesfulIds.length || ignoredIds.length) {
      successToast({
        duration: ALERT_DURATION,
        title: 'Done!',
        description: `${
          successesfulIds.length == 0 ? 'No' : successesfulIds.length
        } orders sent to ${accountingConnectorName}. ${
          ignoredIds.length
            ? `${ignoredIds.length} orders already sent to ${accountingConnectorName} were skipped.`
            : ''
        }`,
      });

      getOrders(
        closeAndResetModal,
        () => {},
        isBuyer ? 'outgoing' : 'incoming',
      );

      // Deselect all successes or ignores
      const newBulkIds = currentBulkIds.filter((id: any) => {
        return !successesfulIds.includes(id) && !ignoredIds.includes(id);
      });
      setCurrentBulkIds(newBulkIds);
    } else {
      closeAndResetModal();
    }

    if (newErrors.length) {
      errorToast({
        duration: ALERT_DURATION,
        title: 'Uh oh!',
        description: `${newErrors.length} could not be sent to ${accountingConnectorName}. Resolve the errors and try again.`,
      });
      setAccountingErrors(newErrors);
    } else {
      // Clear any existing errors
      getAccountingErrors();
    }
  };

  function closeAndResetModal() {
    setModalIsOpen(false);
    setAsyncProgress(null);
    setButtonIsLoading(false);
  }

  function initPolling(orderIdsToPoll: any, skippedOrderData: any) {
    setAsyncProgress(0);

    // Poll for the updated data every 3 seconds.
    const pollingInterval = setInterval(() => {
      getBatchInvoices(
        `/v4/accounting_connectors/batch_invoices?order_ids=${orderIdsToPoll.join(
          ',',
        )}`,
      ).then((data: any) => {
        const statuses = data.map((d: any) => d.result.status);

        const totalItems = statuses.length;
        const statusTotals = countBy(statuses);
        const numPending = statusTotals['pending']
          ? statusTotals['pending']
          : 0;
        const numComplete = totalItems - numPending;

        setAsyncProgress((100 * numComplete) / totalItems);

        if (numPending == 0) {
          clearInterval(pollingInterval);
          handleSuccess(data.concat(skippedOrderData));
        }
      });
    }, 3000);
  }

  const handleError = () => {
    setModalIsOpen(false);
    setButtonIsLoading(false);
    errorToast();
  };

  // Disabling bulk send for MYOB
  if (
    currentAccountingConnection &&
    currentAccountingConnection.key == 'myob'
  ) {
    return <></>;
  }

  if (isConnectionActive && !isConnectionStale) {
    return (
      <>
        <Tooltip label={`Send to ${accountingConnectorName}`}>
          <IconButton
            onClick={() => sendInvoices()}
            icon={<ExportIcon />}
            isLoading={buttonIsLoading}
            aria-label={`Send to ${accountingConnectorName}`}
          />
        </Tooltip>

        <ProcessingModal isOpen={modalIsOpen}>
          <VStack alignItems="left" spacing="4">
            <Text>{`Sending ${numCurrentlyProcessing} ${pluralizeString(
              'order',
              numCurrentlyProcessing,
            )} to ${accountingConnectorName}`}</Text>
            <Progress
              colorScheme="green"
              size="sm"
              isIndeterminate={asyncProgress == null}
              value={asyncProgress || 0}
            />
          </VStack>
        </ProcessingModal>

        {accountingErrors.length > 0 && (
          <Portal containerRef={errorContainerRef}>
            <Stack my="6" spacing={3}>
              {accountingErrors.map((errorItem: any) => (
                <AccountingErrorMessage
                  key={`${errorItem.order_id}-${errorItem.result.error_key}`}
                  accountingConnectorName={accountingConnectorName}
                  errorItem={errorItem}
                />
              ))}
            </Stack>
          </Portal>
        )}
      </>
    );
  } else if (isConnectionStale) {
    return (
      <IconButton
        icon={<ExportIcon />}
        isDisabled={true}
        aria-label={`Send to ${accountingConnectorName}`}
        title={`Your integration is offline. Please check the server is running, then refresh this page and try again.`}
      />
    );
  } else if (isConnectionInErrorState) {
    return (
      <IconButton
        icon={<ExportIcon />}
        isDisabled={true}
        aria-label={`Send to ${accountingConnectorName}`}
        title={`There's a problem with your ${accountingConnectorName} connection. Please go to settings and reconnect again.`}
      />
    );
  } else {
    return <></>;
  }
};

export default observer(OrdersExportAction);
