import {
  types
} from 'mobx-state-tree';

const OrderConnectorFields = types.model('ConnectorFields', {
  address_code: types.maybeNull(types.string),
}).actions((self) => ({
  setAddressCode: (newAddressCode: string) => {
    self.address_code = newAddressCode;
  }
}));

export default OrderConnectorFields;
