import {
  Box,
  Collapse,
  Divider,
  ModalBody,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import PageLoadSpinner from '../PageLoadSpinner/PageLoadSpinner';
import { CustomerGroupOption, CustomerGroupSetOptions } from './types';
import { SmallTitle } from '../Typography/Typography';
import { UsersIcon, UserIcon } from '../Icons/IconsNew';
import { pluralizeString } from '../../utils';
import RadioRow from '../Modal/RadioRow';
import { SelectCustomersOptions } from './SelectCustomersModal';
import ButtonRow from '../Modal/ButtonRow';

const pageTransition = {
  hidden: { opacity: 0, x: 0 },
  show: {
    opacity: 1,
    // x: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  hide: {
    opacity: 0,
  },
};

const SelectCustomersModalBody = ({
  loading,
  groupSets,
  visibilityOption,
  handleSetVisibilityOption,
  checkedCustomersCount,
  onShowModal,
}: {
  loading: boolean;
  groupSets: CustomerGroupSetOptions;
  checkedCustomersCount?: number;
  visibilityOption: SelectCustomersOptions;
  handleSetVisibilityOption?: (value: SelectCustomersOptions) => void;
  onShowModal: (value: string) => void;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const groupButtons = Object.entries(groupSets).map(([setID, set]) => {
    const selectedGroups = Object.entries(set.groups).reduce<
      CustomerGroupOption[]
    >((prev, [id, group]) => (group.selected ? [...prev, group] : prev), []);
    const customerCount = selectedGroups.reduce(
      (total, group) => total + group.customerCount,
      0,
    );
    const subtext = selectedGroups.length
      ? `${selectedGroups.length} ${pluralizeString(
          'group',
          selectedGroups.length,
        )}, ${customerCount} ${pluralizeString(
          'customer',
          customerCount,
        )} selected`
      : null;
    return (
      <ButtonRow
        key={`group-link-${setID}`}
        onClick={() => onShowModal(setID)}
        leftIcon={
          <UsersIcon
            color="gray.400"
            width="18px"
            height="18px"
            mr={2}
            boxSize={5}
          />
        }
        text={set.name}
        subtext={subtext}
      />
    );
  });

  return (
    <>
      <ModalBody
        as={motion.div}
        variants={pageTransition}
        initial="hidden"
        animate="show"
        exit="hide"
        overflowY="scroll"
        p={0}
        ref={ref}
      >
        {loading && <PageLoadSpinner />}
        {!loading && (
          <>
            <RadioGroup
              onChange={handleSetVisibilityOption}
              value={visibilityOption}
            >
              <VStack spacing={1}>
                <RadioRow
                  p={3}
                  px={6}
                  inputId="all-customers"
                  value="all"
                  label="All customers"
                />
                <Box width="100%" backgroundColor={visibilityOption === 'some' ? 'gray.50' : ''}>
                  <RadioRow
                    id="some-customers"
                    p={3}
                    px={6}
                    inputId="some-customers"
                    value="some"
                    label="Some customers"
                    subtext={
                      visibilityOption === 'some'
                        ? 'Choose specific customers or groups'
                        : null
                    }
                    variant={
                      visibilityOption === 'some' ? 'darkHighlight' : 'modalRow'
                    }
                  />

                  <Collapse in={visibilityOption === 'some'}>
                    <Box>
                      <VStack align="left" spacing={0}>
                        <ButtonRow
                          flex={1}
                          onClick={() => onShowModal('customers')}
                          leftIcon={
                            <UserIcon
                              color="gray.400"
                              width="18px"
                              height="18px"
                              boxSize={5}
                              mr={2}
                            />
                          }
                          text="Individual customers"
                          subtext={
                            checkedCustomersCount
                              ? `${checkedCustomersCount} customers`
                              : null
                          }
                        />
                      </VStack>
                      {!!groupButtons.length && (
                        <>
                          <SmallTitle
                            color="gray.500"
                            mt={4}
                            ml={14}
                            mb={2}
                            fontWeight="normal"
                          >
                            Customer groups
                          </SmallTitle>
                          <Divider />
                          <VStack align="left" spacing={0}>
                            {groupButtons}
                          </VStack>
                        </>
                      )}
                    </Box>
                  </Collapse>
                </Box>
                <RadioRow
                  inputId="hide-customers"
                  label="Hide from customers"
                  subtext="Add product to orders or include on standing orders, but your customers will not be able to see it"
                  value="none"
                  paddingInline={6}
                  captionProps={{ mt: 0 }}
                />
              </VStack>
            </RadioGroup>
          </>
        )}
      </ModalBody>
    </>
  );
};

export default SelectCustomersModalBody;
