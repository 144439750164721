import React from 'react';

import { Box, Center, Slide, Text } from '@chakra-ui/react';

type LoadingToastProps = {
  isOpen: boolean;
};

const LoadingToast = ({ isOpen }: LoadingToastProps): JSX.Element => {
  return (
    <Slide in={isOpen} direction="top">
      <Center marginTop="12px">
        <Box bg="yellow.200" px="3" py="1" borderRadius="8px">
          <Text fontWeight="600" color="yellow.900">
            Loading...
          </Text>
        </Box>
      </Center>
    </Slide>
  );
};

export default LoadingToast;
