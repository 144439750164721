import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  InputGroup,
  Select,
  Text,
  Divider,
  InputRightAddon,
  Box,
  Flex,
  VStack,
  NumberInput,
  NumberInputField,
  HStack,
} from '@chakra-ui/react';

import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import { Caption, Subhead } from '../../../components/Typography/Typography';
import useAPI from '../../../hooks/useAPI';
import PageLoadSpinner from '../../../components/PageLoadSpinner/PageLoadSpinner';
import { Button } from '../../../components/Button/Button';
import { ArrowRightIcon, PlusIcon } from '../../../components/Icons/IconsNew';
import { useHistory } from 'react-router-dom';

const SourceCategoryProduct = observer(
  ({ product, isEditable, categories, hasError, getErrorMessage }: any) => {
    const [currentCategory, setCurrentCategory] = useState<any | null>(
      categories.filter((cat: any) => cat.id === product.source_category_id)[0],
    );
    return (
      <Box borderWidth="1px" borderRadius="lg" p="6" mt="4">
        <Box>
          <FormFieldWrapper
            fieldLabel="Source category"
            fieldName="source-category"
            width="100%"
          >
            {isEditable && (
              <>
                <Select
                  name="category"
                  width="100%"
                  defaultValue={product.source_category_id}
                  placeholder="Select a source category"
                  onChange={(e: React.SyntheticEvent) => {
                    const target = e.target as HTMLInputElement;
                    product.setSourceCategoryId(Number(target.value));
                    setCurrentCategory(
                      categories.filter(
                        (c: any) => c.id === Number(target.value),
                      )[0],
                    );
                  }}
                >
                  {categories &&
                    categories.map((category: any, i: number) => {
                      return (
                        <option key={i} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                </Select>
                {hasError(`category-${product.id}`) && (
                  <Text mt="2" fontSize="sm" color="red">
                    {getErrorMessage(`category-${product.id}`)}
                  </Text>
                )}
              </>
            )}
            {!isEditable && <Text>{currentCategory?.name}</Text>}
          </FormFieldWrapper>
        </Box>

        {currentCategory?.amounts_required && (
          <Box mt="2">
            <FormFieldWrapper
              fieldLabel="Item size"
              fieldName="item-size"
              width="100%"
            >
              {isEditable && (
                <>
                  <InputGroup width="100%">
                    <NumberInput
                      width="100%"
                      defaultValue={product.amount}
                      onChange={(value) => {
                        product.setAmount(value);
                      }}
                    >
                      <NumberInputField
                        textAlign="right"
                        borderRightRadius={0}
                        pr={3}
                      />
                    </NumberInput>
                    <InputRightAddon>
                      {currentCategory?.unit || 'kg'}
                    </InputRightAddon>
                  </InputGroup>
                  {hasError(`amount-${product.id}`) && (
                    <Text mt="2" fontSize="sm" color="red">
                      {getErrorMessage(`amount-${product.id}`)}
                    </Text>
                  )}
                </>
              )}
              {!isEditable && (
                <Text>
                  {product.amount}
                  {currentCategory?.unit || 'kg'}
                </Text>
              )}
            </FormFieldWrapper>
          </Box>
        )}

        {isEditable && (
          <Flex justify="flex-start" mt="2">
            <Button
              variant="secondary"
              size="sm"
              colorScheme="red"
              onClick={product.markForDeletion}
            >
              Remove
            </Button>
          </Flex>
        )}
      </Box>
    );
  },
);

const SourceCategoryForm = ({
  currentProduct,
  isEditable,
  hasError,
  getErrorMessage,
}: any) => {
  const history = useHistory();
  const [getSourceCategories, isLoading] = useAPI({
    method: 'GET',
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const url = `/v4/source_categories/summary`;
    getSourceCategories(url).then((data: any) => {
      setCategories(data);
    });
  }, []);

  return isLoading ? (
    <PageLoadSpinner />
  ) : (
    <>
      <Divider />

      <VStack align="left">
        <HStack>
          <Subhead fontWeight="600">Production sheet settings</Subhead>

          {!isEditable && categories.length > 0 && (
            <Button
              variant="tertiary"
              rightIcon={<ArrowRightIcon width="16px" />}
              onClick={() => history.push('/source-categories')}
            >
              Manage
            </Button>
          )}
        </HStack>
        {categories.length === 0 && (
          <>
            <Caption as="p">You have no Source Categories yet</Caption>
            <Box>
              <Button mt="3" size="sm" href="/source-categories">
                Manage source categories
              </Button>
            </Box>
          </>
        )}
      </VStack>

      {categories.length > 0 &&
        currentProduct?.sourceCategories &&
        currentProduct.sourceCategories.map((pcp: any) =>
          pcp._destroy ? (
            <Fragment key={pcp.id}></Fragment>
          ) : (
            <SourceCategoryProduct
              key={pcp.id}
              product={pcp}
              isEditable={isEditable}
              categories={categories}
              hasError={hasError}
              getErrorMessage={getErrorMessage}
            />
          ),
        )}

      {categories.length > 0 && (
        <Box marginTop="24px">
          <Button
            leftIcon={<PlusIcon width="16px" />}
            size="sm"
            onClick={
              isEditable
                ? currentProduct.addSourceCategory
                : () => history.push(`/products/${currentProduct.id}/edit`)
            }
          >
            Add source category
          </Button>
        </Box>
      )}
    </>
  );
};

export default observer(SourceCategoryForm);
