import { Select, SelectComponent, SingleValue } from 'chakra-react-select';
import { FormikProps } from 'formik';
import { SnapshotOrInstance } from 'mobx-state-tree';
import { useState } from 'react';
import { Caption } from '../Typography/Typography';
import Address, { AddressFieldComponents } from '../../models/Address';
import AddAddressForm from './AddAddressForm';

type OrderAddressFieldProps = {
  value: string;
  addresses: SnapshotOrInstance<typeof Address>[];
  onSelectAddress: (address: SnapshotOrInstance<typeof Address>) => void;
  addAddressFormRef?: React.RefObject<FormikProps<AddressFieldComponents>>;
  onAddresssFormOpen?: () => void;
  onAddresssFormClose?: () => void;
} & Omit<SelectComponent, 'onChange'>;

type OptionType = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

const OrderAddressField = ({
  addresses,
  onSelectAddress,
  value,
  addAddressFormRef,
  onAddresssFormOpen = () => {},
  onAddresssFormClose = () => {},
  ...rest
}: OrderAddressFieldProps) => {
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);

  const handleChange = (option: SingleValue<OptionType>) => {
    if (option) {
      if (option.value === 'new') {
        setShowNewAddressForm(true);
        onAddresssFormOpen();
        onSelectAddress({ id: null });
      } else {
        const selectedAddress =
          option.value === 'none'
            ? { id: null, full_address: '', instructions: '' }
            : addresses.find((address) => `${address.id}` === option.value);
        if (selectedAddress) {
          onSelectAddress(selectedAddress);
          setShowNewAddressForm(false);
          onAddresssFormClose();
        }
      }
    }
  };

  const selectedAddress = addresses.find(
    (address) => `${address.id}` === value,
  );

  const addressOptions = [
    ...addresses.map((address) => ({
      value: `${address.id}`,
      label: `${address.full_address}`,
    })),
  ];

  const extraOptions = [
    ...(!addresses.length && showNewAddressForm
      ? [
          {
            value: 'none',
            label: 'No address',
          },
        ]
      : []),
    ...(addresses.length || showNewAddressForm
      ? [
          {
            value: 'sparator',
            label: '───',
            isDisabled: true,
          },
        ]
      : []),
    {
      value: 'new',
      label: 'Add new address',
    },
  ];

  const options = [...addressOptions, ...extraOptions];

  return (
    <>
      <Select
        useBasicStyles
        isMulti={false}
        onChange={handleChange}
        options={options}
        defaultValue={options.find((o) => o.value === value)}
        selectedOptionStyle="check"
        placeholder="None selected"
        chakraStyles={{
          option: (styles) => ({
            ...styles,
            _disabled: {
              cursor: 'default',
              py: 0,
            },
            span: {
              mr: 1.5,
            },
            svg: {
              width: '10px',
              color: 'gray.900',
            },
          }),
        }}
      />
      {selectedAddress?.instructions && (
        <Caption color="gray.500">
          Instructions: {selectedAddress?.instructions}
        </Caption>
      )}
      {showNewAddressForm && <AddAddressForm mt={4} />}
    </>
  );
};

export default OrderAddressField;
