import { DeliveryRules } from '../../models/DeliveryRules';
import { makeObservable, observable, action, computed } from 'mobx';
import { Instance } from 'mobx-state-tree';

import { StandingOrderStore } from './standingOrderStore';
import Unit from '../../models/Unit';
import Product from '../../models/Product';
import moment, { Moment } from 'moment';

export type AmountDay =
  | 'amountMon'
  | 'amountTue'
  | 'amountWed'
  | 'amountThu'
  | 'amountFri'
  | 'amountSat'
  | 'amountSun';

export type Day = {
  isoWeekday: number;
  name: string;
  key: AmountDay;
  enabled: boolean;
}

export interface StandingOrderItemProps {
  id?: number;
  amount_mon?: number;
  amount_tue?: number;
  amount_wed?: number;
  amount_thu?: number;
  amount_fri?: number;
  amount_sat?: number;
  amount_sun?: number;
  unit: Instance<typeof Unit>;
  buyable: {
    type: string;
    product: Instance<typeof Product>;
  };
  notes?: string;
}

export class StandingOrderItem {
  id?: number;
  @observable amountMon?: number;
  @observable amountTue?: number;
  @observable amountWed?: number;
  @observable amountThu?: number;
  @observable amountFri?: number;
  @observable amountSat?: number;
  @observable amountSun?: number;
  @observable destroy: boolean = false;
  @observable unit: Instance<typeof Unit>;
  @observable buyable: {
    type: string;
    product: Instance<typeof Product>;
  };
  @observable notes?: string;

  constructor(props: StandingOrderItemProps) {
    const {
      id,
      amount_mon,
      amount_tue,
      amount_wed,
      amount_thu,
      amount_fri,
      amount_sat,
      amount_sun,
      unit,
      buyable,
      notes
    } = props;
    this.id = id;
    this.amountMon = amount_mon;
    this.amountTue = amount_tue;
    this.amountWed = amount_wed;
    this.amountThu = amount_thu;
    this.amountFri = amount_fri;
    this.amountSat = amount_sat;
    this.amountSun = amount_sun;
    this.unit = Unit.create(unit);
    this.buyable = { type: buyable.type, product: Product.create(buyable.product) };
    this.notes = notes;

    makeObservable(this);
  }

  @action setAmount = (day: AmountDay, value: number) => {
    this[day] = value;
  };
  @action setUnit = (id: number) => {
    this.unit.setId(id);
  };
  @action setNotes = (notes: string) => {
    this.notes = notes;
  }
  @action remove = () => {
    this.destroy = true;
  };

  @action getAmountForIsoWeekday(isoWeekday: number) {
    return {
      1: this.amountMon,
      2: this.amountTue,
      3: this.amountWed,
      4: this.amountThu,
      5: this.amountFri,
      6: this.amountSat,
      7: this.amountSun,
    }[isoWeekday];
  }
}

export interface BaseStandingOrderProps {
  id: string | number;
  customer: any;
  supplier: any;
  paused_from: Date | null;
  paused_to: Date | null;
}

export interface StandingOrderProps {
  id: string | number;
  customer: any;
  supplier: any;
  paused_from: Date | null;
  paused_to: Date | null;
  standing_order_items: StandingOrderItem[];
  delivery_rules: Instance<typeof DeliveryRules> | null;
  delivery_rules_enabled: boolean;
  schedule_data?: ScheduleData | null;
}

export type ScheduleData = {
  0: ScheduleDatum;
  1: ScheduleDatum;
  2: ScheduleDatum;
  3: ScheduleDatum;
  4: ScheduleDatum;
  5: ScheduleDatum;
  6: ScheduleDatum;
}

export type ScheduleDatum = {
  cutoff: string;
  generates_at: string;
  next_delivery_date: string;
  already_ordered_product_ids: number[];
};

export class StandingOrder {
  id: string | number;
  @observable store: StandingOrderStore;

  @observable isChecked: boolean = false;
  @observable isCurrent: boolean = false;

  @observable isFullyLoaded: boolean = false;

  @observable customer: any;
  @observable supplier: any;
  @observable pausedFrom: Moment | null = null;
  @observable pausedTo: Moment | null = null;
  @observable items: StandingOrderItem[] = [];
  @observable deliveryRules?: Instance<typeof DeliveryRules> | null;
  @observable deliveryRulesEnabled: boolean = false;
  @observable scheduleData?: ScheduleData | null;

  constructor(store: StandingOrderStore, props: BaseStandingOrderProps | StandingOrderProps) {
    this.store = store;
    this.id = props.id;
    this.customer = props.customer;
    this.supplier = props.supplier;
    this.pausedFrom = props.paused_from ? moment(props.paused_from) : null;
    this.pausedTo = props.paused_to ? moment(props.paused_to) : null;

    const isFullProps = 'standing_order_items' in props;
    if (isFullProps) {
      this.addData(props);
    }

    makeObservable(this);
  }

  @action addData = (props: StandingOrderProps) => {
    this.isFullyLoaded = true;

    this.deliveryRules = props.delivery_rules;
    this.deliveryRulesEnabled = props.delivery_rules_enabled;
    this.items = props.standing_order_items.map(
      (item: StandingOrderItemProps) => new StandingOrderItem(item),
    );
    this.scheduleData = props.schedule_data;
  };

  @action toggleChecked = () => {
    this.isChecked = !this.isChecked;
  };

  @action setIsCurrent = (value: boolean) => {
    this.isCurrent = value;
  };

  @action setDeliveryRulesEnabled = (deliveryRulesEnabled: boolean) => {
    this.deliveryRulesEnabled = deliveryRulesEnabled;
  };

  @action setDeliveryRules = (deliveryRules: Instance<typeof DeliveryRules> | null) => {
    this.deliveryRules = deliveryRules;
  };

  @action setCustomer = (customer: any) => {
    this.customer = customer;
  }

  @action setPausedFrom = (date: Moment | null) => {
    this.pausedFrom = date;
  }

  @action setPausedTo = (date: Moment | null) => {
    this.pausedTo = date;
  }

  @computed get currentProducts() {
    return this.items
      .filter((item) => !item.destroy)
      .map((item) => item.buyable.product.id);
  }

  @computed get hasProducts() {
    return this.items.filter((item) => !item.destroy).length > 0;
  }

  @computed get days(): Day[] {
    if (
      this.deliveryRules &&
      this.deliveryRulesEnabled
    ) {
      return [
        { isoWeekday: 1, name: 'Mon', key: 'amountMon', enabled: this.deliveryRules && this.deliveryRules[1].enabled },
        { isoWeekday: 2, name: 'Tue', key: 'amountTue', enabled: this.deliveryRules && this.deliveryRules[2].enabled },
        { isoWeekday: 3, name: 'Wed', key: 'amountWed', enabled: this.deliveryRules && this.deliveryRules[3].enabled },
        { isoWeekday: 4, name: 'Thu', key: 'amountThu', enabled: this.deliveryRules && this.deliveryRules[4].enabled },
        { isoWeekday: 5, name: 'Fri', key: 'amountFri', enabled: this.deliveryRules && this.deliveryRules[5].enabled },
        { isoWeekday: 6, name: 'Sat', key: 'amountSat', enabled: this.deliveryRules && this.deliveryRules[6].enabled },
        { isoWeekday: 7, name: 'Sun', key: 'amountSun', enabled: this.deliveryRules && this.deliveryRules[0].enabled },
      ];
    } else {
      return [
        { isoWeekday: 1, name: 'Mon', key: 'amountMon', enabled: true },
        { isoWeekday: 2, name: 'Tue', key: 'amountTue', enabled: true },
        { isoWeekday: 3, name: 'Wed', key: 'amountWed', enabled: true },
        { isoWeekday: 4, name: 'Thu', key: 'amountThu', enabled: true },
        { isoWeekday: 5, name: 'Fri', key: 'amountFri', enabled: true },
        { isoWeekday: 6, name: 'Sat', key: 'amountSat', enabled: true },
        { isoWeekday: 7, name: 'Sun', key: 'amountSun', enabled: true },
      ];
    }
  };

  @computed get formState() {
    return {
      supplier_id: this.supplier.id,
      customer_id: this.customer.id,
      paused_from: this.pausedFrom ? this.pausedFrom.format('YYYY-MM-DD') : null,
      paused_to: this.pausedTo ? this.pausedTo.format('YYYY-MM-DD') : null,
      standing_order_items_attributes: this.items.map(
        (item: StandingOrderItem) => {
          return {
            id: item.id,
            amount_mon: item.amountMon,
            amount_tue: item.amountTue,
            amount_wed: item.amountWed,
            amount_thu: item.amountThu,
            amount_fri: item.amountFri,
            amount_sat: item.amountSat,
            amount_sun: item.amountSun,
            buyable_type: 'Product',
            buyable_id: item.buyable.product.id,
            notes: item.notes,
            _destroy: item.destroy,
            unit_id: item.unit.id,
          };
        },
      ),
    };
  }

  @computed get sortedItems() {
    return this.items.slice().sort(
      (
        a: StandingOrderItem,
        b: StandingOrderItem,
      ): number => {
        let nameA = a.buyable.product.name || '';
        let nameB = b.buyable.product.name || '';
        return nameA.localeCompare(nameB);
      },
    );
  }

  @action addProduct = (product: any) => {
    const item = {
      buyable: { product: product, type: 'product' },
      unit: product.unit,
    };
    this.items.push(new StandingOrderItem(item));
  };

  productIdsForDay(day: Day) {
    return this.items
      .filter((item) => item.getAmountForIsoWeekday(day.isoWeekday))
      .map((item) => item.buyable.product.id);
  }

  dayHasAmounts(day: Day) {
    return this.items.some((item) => item.getAmountForIsoWeekday(day.isoWeekday));
  }

  scheduleDataForIsoWeekday(isoWeekday: number) {
    if (this.scheduleData) {
      return {
        1: this.scheduleData[1],
        2: this.scheduleData[2],
        3: this.scheduleData[3],
        4: this.scheduleData[4],
        5: this.scheduleData[5],
        6: this.scheduleData[6],
        7: this.scheduleData[0],
      }[isoWeekday];
    } else {
      return null;
    }
  }
}
