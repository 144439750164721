import { AddressFieldComponents } from '../../models/Address';
import * as yup from 'yup';
import { Box, BoxProps, Divider, Flex, Textarea } from '@chakra-ui/react';
import FormFieldWrapper from '../Form/FormFieldWrapper';
import { EditableFormWrapperProps } from '../Form/EditableFormWrapper';
import AddressAutocomplete from '../../pages/ProfilePage/Detail/AddressAutocomplete';
import { useState } from 'react';
import { Button } from '../Button/Button';
import FormikEditableFormWrapper from '../Form/FormikEditableFormWrapper';
import { SearchIcon } from '../Icons/IconsNew';
import { FormikHelpers, useFormikContext } from 'formik';

export const newAddressValidationSchema = yup.object().shape({
  unit: yup.string().nullable(),
  street: yup.string().nullable().required('Street is required'),
  suburb: yup.string().nullable(),
  city: yup.string().nullable().required('City is required'),
  post_code: yup.string().nullable().required('Postcode is required'),
  instructions: yup.string().nullable(),
});

const manualAddressOverrides = {
  lat: null,
  lng: null,
  place_id: null,
};

const manualFieldProps: Omit<
  EditableFormWrapperProps,
  'onChange' | 'fieldName' | 'fieldLabel'
> = {
  valueWrapperProps: { color: 'gray.600', fontSize: 'md', width: '100%' },
  isEditable: true,
  flexDirection: 'column',
  labelContainerProps: {
    mb: 2,
  },
};

const AddAddressForm = ({
  onSave = () => {},
  ...containerProps
}: {
  onSave?: (values: AddressFieldComponents) => void | Promise<any>;
} & BoxProps) => {
  const [useManualAddress, setUseManualAddress] = useState(false);

  const { values, setValues } = useFormikContext<AddressFieldComponents>();

  const setManualValue = (
    fieldId: string,
    value: string,
    setValues: FormikHelpers<AddressFieldComponents>['setValues'],
  ) => {
    setValues({
      ...manualAddressOverrides,
      ...values,
      [fieldId]: value,
    });
  };

  const switchToAutocomplete = (
    values: AddressFieldComponents,
    setValues: FormikHelpers<AddressFieldComponents>['setValues'],
  ) => {
    setUseManualAddress(false);
    let fullAddress = [values.street, values.suburb, values.city]
      .filter((val) => !!val)
      .join(', ');
    if (values.post_code) {
      fullAddress = `${fullAddress} ${values.post_code}`;
    }
    setValues({
      ...values,
      full_address: fullAddress,
    });
  };

  return (
    <Box
      border="solid 1px"
      borderRadius="8px"
      borderColor="gray.150"
      backgroundColor="gray.50"
      padding={4}
      {...containerProps}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Flex
          flexDirection="column"
          gap={3}
          width="100%"
          flex="1"
          alignItems="flex-start"
        >
          {useManualAddress ? (
            <>
              <FormikEditableFormWrapper
                {...manualFieldProps}
                fieldLabel="Apartment / Unit"
                fieldName={`unit`}
                placeholder="a"
                subLabel="Optional"
                value={values.unit || ''}
                onChange={(e) =>
                  setManualValue('unit', e.target.value, setValues)
                }
              />
              <FormikEditableFormWrapper
                {...manualFieldProps}
                fieldLabel="Street address"
                fieldName={`street`}
                placeholder="123 Streety St"
                value={values.street || ''}
                onChange={(e) =>
                  setManualValue('street', e.target.value, setValues)
                }
              />
              <FormikEditableFormWrapper
                {...manualFieldProps}
                fieldLabel="Suburb"
                fieldName={`suburb`}
                placeholder="Streetsville"
                subLabel="Optional"
                value={values.suburb || ''}
                onChange={(e) =>
                  setManualValue('suburb', e.target.value, setValues)
                }
              />
              <FormikEditableFormWrapper
                {...manualFieldProps}
                fieldLabel="City / Town"
                fieldName={`city`}
                placeholder="Cityton"
                value={values.city || ''}
                onChange={(e) =>
                  setManualValue('city', e.target.value, setValues)
                }
              />
              <FormikEditableFormWrapper
                {...manualFieldProps}
                fieldLabel="Postcode"
                fieldName={`post_code`}
                placeholder="1234"
                value={values.post_code || ''}
                onChange={(e) =>
                  setManualValue('post_code', e.target.value, setValues)
                }
              />

              <Button
                leftIcon={<SearchIcon w="24px" />}
                onClick={() => switchToAutocomplete(values, setValues)}
              >
                Search for address
              </Button>

              <Divider borderColor="gray.300" my={5} borderWidth="1.5px" />
            </>
          ) : (
            <>
              <FormFieldWrapper
                fieldLabel="Location"
                fieldName="full_address"
                valueWrapperProps={{
                  color: 'gray.600',
                  fontSize: 'md',
                  width: '100%',
                }}
                flexDirection="column"
                labelContainerProps={{
                  mb: 2,
                }}
              >
                <AddressAutocomplete
                  initialValue={values.full_address || ''}
                  autoFocus={true}
                  onChange={(newValues) =>
                    setValues({ ...values, ...newValues })
                  }
                  setUseManualAddress={(val) => setUseManualAddress(val)}
                />
              </FormFieldWrapper>
            </>
          )}
          <FormikEditableFormWrapper
            fieldLabel="Instructions"
            fieldName={`instructions`}
            {...manualFieldProps}
            EditComponent={Textarea}
            placeholder="Eg; Please leave by the back door if raining"
            value={values.instructions || ''}
            onChange={(e) =>
              setValues({ ...values, instructions: e.target.value })
            }
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default AddAddressForm;
