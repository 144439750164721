import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
  VStack,
} from '@chakra-ui/react';

function ProcessingModal({ isOpen, children }: any) {
  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody padding="10">
          <VStack spacing="6">
            <Spinner thickness="6px" size="xl" color="green" />
            {children}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
export default ProcessingModal;
