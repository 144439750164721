import { VStack, Textarea, Input, Box, Text } from '@chakra-ui/react';
import EditableFormWrapper from '../../../components/Form/EditableFormWrapper';
import { Subhead } from '../../../components/Typography/Typography';
import {
  UserCompany,
  UserCompanyEditFormValues,
} from '../../../models/UserCompany';
import { SnapshotOrInstance } from 'mobx-state-tree';
import AddressField from './AddressField';
import Address from '../../../models/Address';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Button } from '../../../components/Button/Button';
import { PlusIcon } from '../../../components/Icons/IconsNew';

const newAddress = {
  id: null,
  street: null,
  unit: null,
  suburb: null,
  city: null,
  post_code: null,
  lat: null,
  lng: null,
  place_id: null,
};

const CompanyProfileAddressFields = ({
  company,
  isEditable = false,
  onChange = () => {},
}: {
  company: SnapshotOrInstance<typeof UserCompany> | UserCompanyEditFormValues;
  isEditable?: boolean;
  onChange?: (
    index: number,
    address: SnapshotOrInstance<typeof Address>,
  ) => void;
}) => {
  return (
    <VStack align="left" spacing="8" maxWidth="820px">
      <Subhead fontWeight="600">Delivery addresses</Subhead>
      {isEditable ? (
        <FieldArray
          name="addresses"
          render={(arrayHelpers) => (
            <AddressesField
              addresses={company.addresses}
              arrayHelpers={arrayHelpers}
              isEditable={isEditable}
            />
          )}
        />
      ) : (
        <AddressesField addresses={company.addresses} isEditable={isEditable} />
      )}
    </VStack>
  );
};

type AddressesFieldProps = {
  addresses?: SnapshotOrInstance<typeof Address>[];
  arrayHelpers?: FieldArrayRenderProps;
  isEditable?: boolean;
  errors?: string | Array<any>;
};

const AddressesField = ({
  addresses,
  arrayHelpers,
  isEditable,
}: AddressesFieldProps) => (
  <>
    {addresses?.map((address, index) => (
      <AddressField
        fieldName={`addresses[${index}]`}
        key={`address-${address.id}-field`}
        address={address}
        title={`Address ${index + 1}`}
        isEditable={isEditable}
        onChange={(address) => arrayHelpers?.replace(index, address)}
        onRemove={() => arrayHelpers?.remove(index)}
        onSetDefault={() => {
          const oldDefaultIndex = addresses.findIndex(
            (address) => address.is_default,
          );
          arrayHelpers?.replace(oldDefaultIndex, {
            ...addresses[oldDefaultIndex],
            is_default: false,
          });
          arrayHelpers?.replace(index, {
            ...addresses[index],
            is_default: true,
          });
        }}
      />
    ))}
    {isEditable && (
      <Box>
        <Button
          onClick={() => arrayHelpers?.push(newAddress)}
          leftIcon={<PlusIcon w="24px" />}
        >
          Add address
        </Button>
      </Box>
    )}
    {!isEditable && !addresses?.length && (
      <Text color="gray.400">{'(None supplied)'}</Text>
    )}
  </>
);

export default CompanyProfileAddressFields;
