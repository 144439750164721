import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
  FormControl,
  FormLabel,
  HStack,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  VStack,
  Divider,
  Box,
  BoxProps,
} from '@chakra-ui/react';
import { find } from 'lodash';

import { Caption } from '../../../components/Typography/Typography';
import Banner from '../../../components/Banner/Banner';
import { WarningFillIcon } from '../../../components/Icons/IconsNew';
import { useCustomerGroups, useProducts } from '../../../hooks/useStores';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import { toJS } from 'mobx';
import { Instance } from 'mobx-state-tree';
import { Label } from '../../../components/Typography/Typography';
import { PricingTiersTable, PricingTiersTableRow } from './PricingTiersTable';
import PricingTiersEmptyMessage from './PricingTiersEmptyMessage';
import Product from '../../../models/Product';

const PricingForm = ({
  product,
  isDisabled = false,
}: {
  product: Instance<typeof Product>;
  isDisabled?: boolean;
}) => {
  const { units } = useProducts();
  const { pricingTiersGroupSet, getCustomerGroupSets } = useCustomerGroups();
  const [_isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCustomerGroupSets()
      .then(() => {
        // Ensure that new products have pricing_tiers pre-populated
        if (!product.id) {
          product.addPricingTiers(
            pricingTiersGroupSet.groups?.map((group: any) => {
              return {
                id: group.id,
                name: group.name,
                price: null,
              };
            }),
          );
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const hasError = (fieldName: string) => {
    return getErrorObject(fieldName) != undefined;
  };

  const getErrorMessage = (fieldName: string) => {
    return getErrorObject(fieldName)?.message;
  };

  const getErrorTitle = (fieldName: string) => {
    return getErrorObject(fieldName)?.title;
  };

  const getErrorObject = (fieldName: string) => {
    return product.fieldErrors.find(
      (errorObj: any) => errorObj.fieldName == fieldName,
    );
  };

  return (
    <>
      <Divider />
      <VStack align="left" spacing={6}>
        <Label>Pricing</Label>

        {pricingTiersGroupSet?.groups?.length == 0 && (
          <FormFieldWrapper
            fieldLabel="Price"
            fieldName="price"
            subLabel="GST exclusive"
            maxW="509px"
          >
            <FormControl id="price">
              <HStack>
                <InputGroup flex={2}>
                  <InputLeftAddon children="$" />
                  <NumberInput
                    defaultValue={product?.price || ''}
                    min={0}
                    precision={2}
                    letterSpacing={0}
                    onChange={(value) => product.setPrice(value)}
                    isDisabled={isDisabled}
                  >
                    <NumberInputField
                      paddingInline={2}
                      borderLeftRadius="0"
                      borderRightRadius="md"
                    />
                  </NumberInput>
                </InputGroup>
                <Text paddingInline={2}>/</Text>
                <Select
                  flex={1}
                  minW="45px"
                  defaultValue={
                    (product &&
                      product.pricing_unit &&
                      product.pricing_unit.id) ||
                    'price-unit-placeholder'
                  }
                  onChange={(e) => {
                    const newUnit = find(
                      units,
                      (u) => u.id === parseInt(e.target.value, 10),
                    );
                    product.setPricingUnit(toJS(newUnit));
                  }}
                  isInvalid={hasError('pricing_unit_id')}
                  isDisabled={isDisabled}
                >
                  <option value="price-unit-placeholder" disabled>
                    Unit
                  </option>
                  {units &&
                    units.map((unit: any, i: number) => {
                      return (
                        <option key={i} value={unit.id}>
                          {unit.name}
                        </option>
                      );
                    })}
                </Select>
              </HStack>
            </FormControl>

            {hasError('pricing_unit_id') && (
              <Text mt="2" fontSize="sm" color="red">
                {getErrorMessage('pricing_unit_id')}
              </Text>
            )}
          </FormFieldWrapper>
        )}

        {pricingTiersGroupSet?.groups?.length > 0 ? (
          <>
            <FormFieldWrapper
              fieldLabel="Unit"
              fieldName="pricing_unit"
              maxW="509px"
            >
              <FormControl id="unit">
                <Select
                  flex={1}
                  minW="45px"
                  defaultValue={
                    (product &&
                      product.pricing_unit &&
                      product.pricing_unit.id) ||
                    'price-unit-placeholder'
                  }
                  onChange={(e) => {
                    const newUnit = find(
                      units,
                      (u) => u.id === parseInt(e.target.value, 10),
                    );
                    product.setPricingUnit(toJS(newUnit));
                  }}
                  isInvalid={hasError('pricing_unit_id')}
                  isDisabled={isDisabled}
                >
                  <option value="price-unit-placeholder" disabled>
                    Unit
                  </option>
                  {units &&
                    units.map((unit: any, i: number) => {
                      return (
                        <option key={i} value={unit.id}>
                          {unit.name}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>

              {hasError('pricing_unit_id') && (
                <Text mt="2" fontSize="sm" color="red">
                  {getErrorMessage('pricing_unit_id')}
                </Text>
              )}
            </FormFieldWrapper>

            {hasError('price') && (
              <Banner
                icon={<WarningFillIcon width="24px" />}
                title={getErrorTitle('price') || undefined}
                description={getErrorMessage('price')}
                variant="outline"
                status="error"
                bg="white"
              />
            )}

            <VStack align="left" spacing={2}>
              <FormLabel fontSize="sm">Price</FormLabel>
              <PricingTiersTable
                rows={[
                  <PricingTierRow
                    key={`pricing-row-unassigned`}
                    name="Default"
                    price={product.price || ''}
                    unitName={product.pricing_unit?.name || ''}
                    setPrice={(value) => product.setPrice(value)}
                    isInvalid={hasError('price')}
                    customerCount={
                      pricingTiersGroupSet._unassignedCustomerCount
                    }
                    isDisabled={isDisabled}
                  />,
                  ...pricingTiersGroupSet.groups.map(
                    (group: any, i: number) => {
                      const tier = product?.pricing_tiers?.find(
                        (tier: any) => tier.id === group.id,
                      );
                      return (
                        <PricingTierRow
                          key={`pricing-row-${group.id}`}
                          name={group.name}
                          containerProps={
                            i % 2 == 0 ? { backgroundColor: 'gray.50' } : {}
                          }
                          price={tier?.price || ''}
                          unitName={product.pricing_unit?.name || ''}
                          placeholderPrice={product.price || ''}
                          setPrice={(value) => tier?.setPrice(value)}
                          isInvalid={hasError('price') && !!tier?.price}
                          customerCount={group?.customerCount}
                          isDisabled={isDisabled}
                        />
                      );
                    },
                  ),
                ]}
              />
              <Caption color="gray.500">All prices are GST exclusive</Caption>
            </VStack>
          </>
        ) : (
          <PricingTiersEmptyMessage />
        )}
      </VStack>
    </>
  );
};

const PricingTierRow = ({
  name,
  price,
  unitName,
  placeholderPrice,
  setPrice,
  customerCount,
  isInvalid,
  isReadOnly = false,
  containerProps = {},
  isDisabled = false,
}: {
  name: string;
  unitName: string;
  price?: string;
  placeholderPrice?: string;
  setPrice?: (price: string) => void;
  customerCount?: number;
  isInvalid?: boolean;
  isReadOnly?: boolean;
  containerProps?: BoxProps;
  isDisabled?: boolean;
}) => (
  <PricingTiersTableRow {...containerProps}>
    <Box w="304px">
      <Box>
        <Text
          fontSize="15px"
          as={isReadOnly ? 'i' : undefined}
          color={isReadOnly ? 'gray.600' : 'gray.900'}
        >
          {name}
        </Text>
        <Text fontSize="13px" color="gray.500">
          {customerCount} customers
        </Text>
      </Box>
    </Box>
    <Box w="212px" ml="16px">
      <InputGroup size="sm">
        <InputLeftAddon borderRadius="md">$</InputLeftAddon>
        <NumberInput
          defaultValue={price}
          min={0}
          max={9999.99}
          clampValueOnBlur
          letterSpacing={0}
          precision={2}
          w="100%" // This compensates for the decreased width caused by the addition of the InputGroup
          onChange={
            typeof setPrice === 'function'
              ? (value) => setPrice(value)
              : undefined
          }
          isReadOnly={isReadOnly}
          isDisabled={isDisabled || isReadOnly}
          isInvalid={isInvalid}
        >
          <NumberInputField
            paddingInline={3}
            textAlign="right"
            placeholder={
              placeholderPrice ? `${Number(placeholderPrice)?.toFixed(2)}` : ''
            }
            borderRadius={0}
          />
        </NumberInput>
        <InputRightAddon borderRadius="md">{unitName}</InputRightAddon>
      </InputGroup>
    </Box>
  </PricingTiersTableRow>
);

export default observer(PricingForm);
